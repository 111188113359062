import React, { useEffect, useRef } from 'react';
import { VariableSizeList, FixedSizeGrid as Grid } from 'react-window';
import { GridWrapper } from './Select.css';

// used to create a virtualized list in order to increse performance for big chunk of data
export const VirtualizedList = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement> & { isGrid?: boolean; columns?: number; itemWidth?: number; itemHeight?: number }
>(function (props: any) {
    const { children, isGrid, columns, itemWidth, itemHeight, ...rest } = props;

    const itemData: any[] = [...children];
    const itemCount = itemData.length;
    const itemSize = 36;
    const gridGap = 16;
    const getItemSize = () => {
        return itemSize;
    };

    const getListHeight = () => {
        return (itemCount < 8 ? itemCount : 8) * itemSize;
    };

    const ListItem = ({ index, style, data }: any) => {
        const item = data[index];
        return <div style={style}>{item}</div>;
    };

    const getNoOfGridRows = () => {
        return Math.ceil(itemCount / (columns || 1));
    };

    const getGridHeight = () => {
        const rows = getNoOfGridRows();
        return (rows < 5 ? rows : 5) * (itemHeight + gridGap);
    };

    const getGridWidth = () => {
        return (itemWidth + gridGap) * columns;
    };

    const Cell = ({ rowIndex, columnIndex, data, style }: any) => {
        const index = rowIndex * columns + columnIndex;

        const item = data[index];
        return <div style={style}>{item}</div>;
    };

    if (isGrid) {
        return (
            <GridWrapper>
                <Grid
                    className={'virtualized_grid_wrapper'}
                    columnCount={columns}
                    rowCount={getNoOfGridRows()}
                    columnWidth={itemWidth + gridGap}
                    height={getGridHeight()}
                    rowHeight={itemHeight + gridGap}
                    width={getGridWidth()}
                    itemData={itemData}
                >
                    {Cell}
                </Grid>
            </GridWrapper>
        );
    }

    return (
        <div {...rest}>
            <VariableSizeList height={getListHeight()} width={'100%'} itemCount={itemCount} itemData={itemData} itemSize={getItemSize}>
                {ListItem}
            </VariableSizeList>
        </div>
    );
});

VirtualizedList.displayName = 'virtualizedList';
