import React, { useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    templatesState,
    fetchTemplates,
    createTemplate as _createTemplate,
    updateTemplate as _updateTemplate,
    deleteTemplate as _deleteTemplate,
    releaseTemplate as _releaseTemplate,
    retractTemplate as _retractTemplate
} from '../../../../redux/slices/templatesSlice';
import { ApplicationWrapper, MainContentWrapper } from '../../../../style/styled-components/reusable.css';
import { Template, templateTypes } from '../../../../types/Template';
import Button from '../../../Buttons/Button/Button';
import ScreenTitle from '../../../common/DashboardTitle/ScreenTitle';
import { Loader } from '../../../common/Loader/Loader';
import ToggleViewSwitch from '../../../common/Switch/ToggleViewSwitch';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import { FieldAndToggleContainer, GroupFieldsContainer, GroupField } from '../../../TargetGroups/GroupEdit.css';
import {
    ActionIconHolder,
    ActionsContainer,
    ButtonsContainerList,
    GridViewWrapper,
    GroupAddGrid,
    GroupAddList,
    GroupContainerGrid,
    GroupContainerGridActionRow,
    GroupContainerGridName,
    GroupContainerList,
    GroupNameContainerList,
    ListViewWrapper
} from '../../../TargetGroups/TargetGroups.css';
import { NewGroupTemplate } from './NewGroup';
import Sidebar from '../../Sidebar/Sidebar';
import { dialogConfirm } from '../../../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import { PageRoutes } from '../../../../types/RouteTypes';
import { useNavigate } from 'react-router-dom';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { SearchBarContainer } from '../../../common/SearchBar/SearchBar.css';
import { SearchBar } from '../../../common/SearchBar/SearchBar';

export const SuperadminGroups: React.FC = () => {
    const [groupTemplates, setGroupTemplates] = useState<any[]>([]);
    const [view, setView] = useState<'GRID' | 'LIST'>('GRID');
    const { templates: storeTemplates, loading, error }: templatesState = useAppSelector((state) => state.templates);
    const [activeTab, setActiveTab] = useState<'SYSTEM_TEMPLATES' | 'USER_SUGGESTIONS'>('SYSTEM_TEMPLATES');
    const [openNewTemplate, setOpenNewTemplate] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState<Template | undefined>(undefined);
    const [newId, setNewId] = useState(''); // needed to load the newly created group into the open dialog
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        loadTemplates(searchTerm);
    }, []);

    useEffect(() => {
        if (loading) return;
        if (storeTemplates?.targetGroup?.length) {
            setGroupTemplates(storeTemplates.targetGroup);
        } else setGroupTemplates([]);
    }, [loading]);

    useEffect(() => {
        if (!storeTemplates?.targetGroup?.length) return;
        if (templateToEdit) setTemplateToEdit(storeTemplates?.targetGroup?.find((t) => t._id === templateToEdit._id));
        else if (openNewTemplate && newId) {
            const newGroup = storeTemplates.targetGroup?.find((t) => t._id === newId);
            if (newGroup) {
                setTemplateToEdit(newGroup);
            }
            setNewId('');
        }
    }, [storeTemplates?.targetGroup]);

    const loadTemplates = async (searchTerm?: string) => {
        await dispatch(fetchTemplates({ type: templateTypes.TARGET_GROUP, searchTerm })).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadTemplates(searchTerm);
    };

    const isEmpty = !groupTemplates.length;

    const renderNoTemplates = () => <div>Templates not loaded/non existent</div>;

    const renderGroups = () => {
        return view === 'GRID' ? renderGridView() : renderListView();
    };

    const createTemplate = async (template: Template) => {
        const response = await dispatch(_createTemplate(template)).unwrap();
        if (response) {
            loadTemplates();
            setNewId(response.id);
            setSearchTerm(undefined);
        }
    };

    const updateTemplate = async (values: any) => {
        const response = await dispatch(_updateTemplate(values)).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const deleteTemplate = async (id: string) => {
        const response = await dispatch(_deleteTemplate({ templateId: id, type: templateTypes.TARGET_GROUP })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const releaseTemplate = async (id: string) => {
        const response = await dispatch(_releaseTemplate({ templateId: id, type: templateTypes.TARGET_GROUP })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const retractTemplate = async (id: string) => {
        const response = await dispatch(_retractTemplate({ templateId: id, type: templateTypes.TARGET_GROUP })).unwrap();
        if (response) {
            loadTemplates(searchTerm);
        }
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Group Template',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                deleteTemplate(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Group Template?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one from list
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const renderGridView = () => {
        return (
            <GridViewWrapper>
                {groupTemplates.map((template: Template, index: number) => (
                    <GroupContainerGrid key={index} id={template._id}>
                        <GroupContainerGridName>{template.values.name}</GroupContainerGridName>
                        <GroupContainerGridActionRow>
                            {renderTooltipWithKey(
                                <SVGInline src={!!template.released ? icons.groupActiveIcon : icons.groupInactiveIcon} />,
                                'superadmin_target_groups_templates_icon_released'
                            )}

                            {renderActionsContainer(template)}
                        </GroupContainerGridActionRow>
                        <Button type={'DEFAULT'} label={'Experience'} isDisabled onClick={() => {}} />
                        <Button
                            type={'DEFAULT'}
                            label={'Audiences'}
                            onClick={() => navigate(PageRoutes.SUPERADMIN_AUDIENCES.replace(':group_id', template._id))}
                        />
                    </GroupContainerGrid>
                ))}
                <GroupAddGrid onClick={() => setOpenNewTemplate(true)}>
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'superadmin_target_groups_templates_icon_add')}
                </GroupAddGrid>
            </GridViewWrapper>
        );
    };

    const renderListView = () => {
        return (
            <ListViewWrapper>
                {groupTemplates.map((template: Template, index: number) => (
                    <GroupContainerList key={index}>
                        <GroupNameContainerList>
                            {template.values?.name}
                            {renderTooltipWithKey(
                                <SVGInline src={!!template.released ? icons.groupActiveIcon : icons.groupInactiveIcon} />,
                                'superadmin_target_groups_templates_icon_released'
                            )}
                        </GroupNameContainerList>
                        <ButtonsContainerList>
                            <Button type={'DEFAULT'} label={'Experience'} onClick={() => {}} isDisabled />
                            <Button
                                type={'DEFAULT'}
                                label={'Audiences'}
                                onClick={() => navigate(PageRoutes.SUPERADMIN_AUDIENCES.replace(':group_id', template._id))}
                            />
                        </ButtonsContainerList>
                        {renderActionsContainer(template)}
                    </GroupContainerList>
                ))}
                <GroupAddList onClick={() => setOpenNewTemplate(true)}>
                    Create Group Template
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'superadmin_target_groups_templates_icon_add')}
                </GroupAddList>
            </ListViewWrapper>
        );
    };

    const renderActionsContainer = (template: Template) => {
        return (
            <ActionsContainer>
                <ActionIconHolder>
                    {renderTooltipWithKey(
                        <SVGInline
                            src={icons.editIcon}
                            onClick={() => {
                                setTemplateToEdit(template);
                                setOpenNewTemplate(true);
                            }}
                        />,
                        'superadmin_target_groups_templates_icon_edit'
                    )}
                </ActionIconHolder>
                <ActionIconHolder>
                    {renderTooltipWithKey(
                        <SVGInline src={icons.trashIcon} onClick={() => handleDeleteClick(template._id)} />,
                        'superadmin_target_groups_icon_delete_templates'
                    )}
                </ActionIconHolder>
                <ActionIconHolder>
                    <SVGInline src={icons.moreIcon} />
                </ActionIconHolder>
            </ActionsContainer>
        );
    };

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        withProfile
                        withoutSearch
                        loading={loading}
                        title="Group Templates"
                        withAddButton={!isEmpty}
                        addLabel={'Create Group Template'}
                        onAdd={() => {
                            setOpenNewTemplate(true);
                        }}
                    />
                    <FieldAndToggleContainer>
                        <GroupFieldsContainer>
                            <GroupField onClick={() => setActiveTab('SYSTEM_TEMPLATES')} $active={activeTab === 'SYSTEM_TEMPLATES'}>
                                System Templates
                            </GroupField>
                            <GroupField onClick={() => setActiveTab('SYSTEM_TEMPLATES')} $active={activeTab === 'USER_SUGGESTIONS'}>
                                User Suggestions
                            </GroupField>
                        </GroupFieldsContainer>
                    </FieldAndToggleContainer>
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                        />
                        <ToggleViewSwitch
                            checked={view === 'GRID'}
                            toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                            tooltipTexts={{ list: 'target_groups_icon_switch_view_list', grid: 'target_groups_icon_switch_view_grid' }}
                        />
                    </SearchBarContainer>
                    {loading ? <Loader title={'Group Templates'} /> : isEmpty ? renderNoTemplates() : renderGroups()}
                    <NewGroupTemplate
                        open={openNewTemplate}
                        onSave={(template) => {
                            templateToEdit ? updateTemplate(template) : createTemplate(template);
                        }}
                        onClose={() => {
                            setOpenNewTemplate(false);
                            setTemplateToEdit(undefined);
                            setNewId('');
                        }}
                        onRelease={(id) => releaseTemplate(id)}
                        onRetract={(id) => retractTemplate(id)}
                        group={templateToEdit}
                    />
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
