import React, { useEffect, useState } from 'react';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import {
    ApplicationWrapper,
    MainContentWrapper,
    NoResourcesContainer,
    PageActionButton,
    PageActionsWrapper,
    TruncatedText
} from '../../style/styled-components/reusable.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG,
    ISortConfig
} from '../../utils/fnSort';
import GenericTable, {
    ActionsTableCell,
    HeaderTableCell,
    ImageTableCell,
    ScrollableTableRow,
    SelectAllTableCell,
    SortableHeaderTableCell,
    tableActions
} from '../common/Table/Table';
import { TableBody, TableCell } from '@material-ui/core';
import { generateDateStringForTables } from '../../utils/fnDate';
import { TemplateIcons } from '../../assets/images/icons';
import { WidthTableCell } from '../common/Table/Table.css';
import SVGInline from 'react-inlinesvg';
import { ContentTemplateLogo, ContentTemplateName, NameWrapper, TooltipDivider } from './Modules.css';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { ResourceCard } from '../Cards/ResourceCard/ResourceCard';
import TemplateSelection from '../Pages/Dialogs/TemplateSelection';
import {
    createModule,
    deleteModule,
    deleteModuleList,
    fetchModules,
    fetchModulesTemplates,
    ModulesState,
    unsetModules,
    updateModule
} from '../../redux/slices/moduleSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import { NewModule } from './Dialogs/NewModule';
import _ from 'lodash';
import { Module, templates } from '../../types/Module';
import icons from '../../style';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { actionNameMissingAlert, DIALOG_NAMES, dialogAlert, dialogConfirm, dialogRemove, ToastAlert } from '../../utils/fnDialogs';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import {
    createItem,
    deleteItem,
    fetchContentSourceTypes,
    fetchItemTypes,
    ItemState,
    setItemPreview,
    unSetItemPreview,
    updateItem
} from '../../redux/slices/itemSlice';
import { Item, itemTypes } from '../../types/Item';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { EditorialTableRow } from '../Items/EditorialView.css';
import { VisualEditor } from './VisualEditor';
import { Actions } from '../Items/EditorialView';
import { NewItem } from '../Items/Dialogs/NewItem';
import UseExistingDialog, { EXISTING_ITEMS } from '../PageEdit/Dialogs/UseExistingDialog';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import PageActions from '../common/PageActions/PageActions';
import { Loader } from '../common/Loader/Loader';
import { fetchAudiences } from '../../redux/slices/audienceSlice';
import { fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { API_ERROR_CODES, EMPTY_WORD_STRING, moduleTypes } from '../../utils/Globals';
import { templateTypes } from '../../types/Template';
import { resourceCardImages } from '../../assets/images/resourceCards';
import { applyTemplate, templatesState } from '../../redux/slices/templatesSlice';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import useScreenSize from '../../hooks/useScreenSize';
import { Hint, HINT_TYPES } from '../common/Hints/Hint';
import circleSlugs from '../../utils/circle_slugs.json';
import { renderPublishedDeleteError } from '../../utils/fnPublish';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderABIcon, renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import { useMultiSelect } from '../../hooks/useMultiSelect';
import { fetchSources, SourcesState } from '../../redux/slices/sourceSlice';
import usePrevious from '../../hooks/usePrevious';
import { openDocumentation } from '../../utils/parsers';
import CopyToProjectDialog, { renderItemActionWarningAfterCopy, renderUnsavedWhenCopyingAlert } from '../common/Dialog/CopyToProjectDialog';
import { copyObject } from '../../redux/slices/copyObjectSlice';
import { ObjectTypes } from '../../types/Object';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import {
    ObjectNameTooltipContentHolder,
    ObjectNameTooltipIconHolder,
    ObjectNameTooltipLabelHolder,
    ObjectNameTooltipNameHolder
} from '../common/Tooltips/Tooltips.css';
import { getSearchParam } from '../../utils/fnUrl';
import { ItemsTableSizes, ModulesTableSizes } from '../../types/TableSizes';
import { pagesState } from '../../redux/slices/pagesSlice';
import { renderModuleEmptySlugAlert, renderModuleSlugAlert } from '../PageEdit/PageEdit';
import { ApplicationsState } from '../../redux/slices/applicationsSlice';
import Labels from '../common/Labels/Labels';
import { RemovableObjects } from '../common/Dialog/RemoveObjectDialog';
import { renderFailedObjectDeletions } from '../../utils/fnListDelete';
import { ObjectFilter } from '../../utils/fnFilter';
import PaginationWrapper, { renderUnsavedAlertBeforeAction, ResetCallbackProps } from '../PaginationWrapper/PaginationWrapper';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { searchTermUnsetValue } from '../common/Select/FancyFilter';
import { renderAdminLockedError, renderAdminLockedWarningAlert, renderAdminLockIcon } from '../../utils/fnAdminLocking';

const Modules = () => {
    const [modules, setModules] = useState<Module[]>([]);
    const [openNameTooltip, setOpenNameTooltip] = useState<any>(null);
    const [duplicating, setDuplicating] = useState(false);
    const [moduleToEdit, setModuleToEdit] = useState<Module | undefined>(undefined);
    const [openNewModuleDialog, setOpenNewModuleDialog] = useState(false);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);
    const [openTemplateSelectionDialogForItem, setOpenTemplateSelectionDialogForItem] = useState(false);
    const [showCopyToProjectDialog, setShowCopyToProjectDialog] = useState(false);
    const [showHintScreen, setShowHintScreen] = useState<boolean>(false);

    //ITEMS
    const [openEditViewModuleId, setOpenEditViewModuleId] = useState<string>('');
    const [openNewItemDialog, setOpenNewItemDialog] = useState<boolean>(false);
    const [itemToEdit, setItemToEdit] = useState<Item | undefined>(undefined);
    const [duplicatingCI, setDuplicatingCI] = useState<boolean>(false);
    const [moduleIdForItem, setModuleIdForItem] = useState<string>('');
    const [isAutoCollection, setIsAutoCollection] = useState<boolean>(false);
    const [isContentWorld, setIsContentWorld] = useState<boolean>(false);
    const [includeProviderLogoCardProviderName, setIncludeProviderLogoCardProviderName] = useState<string>('');
    const [openNewResourceDialogForItem, setOpenNewResourceDialogForItem] = useState<boolean>(false);
    const [openUseExistingDialog, setOpenUseExistingDialog] = useState<boolean>(false);
    const [addItemAtIndex, setAddItemAtIndex] = useState<number | null>(null);
    const [InitialCIsOrder, setInitialCIsOrder] = useState<any[]>([]);
    const [moduleToSave, setModuleToSave] = useState<Module | undefined>(undefined);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [isCollectionItem, setIsCollectionItem] = useState<boolean>(false);
    const [collectionType, setCollectionType] = useState<itemTypes | undefined>(undefined);

    //MULTISELECT MODULES
    const [isMultiSelectVisible, setIsMultiSelectVisible] = useState<boolean>(false);
    const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
    const [allModulesSelected, setAllModulesSelected] = useState<boolean>(false);

    // used for location state variable
    const [editModuleId, setEditModuleId] = useState<string>('');
    const [previewModuleId, setPreviewModuleId] = useState<string>('');
    const [openCreateModule, setOpenCreateModule] = useState<boolean>(false);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { contentSourceTypes, storeItemTypes, error: itemError }: ItemState = useAppSelector((state) => state.items);
    const { sources }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);
    const { userPermissions } = useAppSelector((state) => state.permissions);
    const { error: copyObjectError } = useAppSelector((state) => state.copyObject);
    const { error: sourcesError }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { error: pageError }: pagesState = useAppSelector((state) => state.pages);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);
    const {
        modules: storeModules,
        validTemplates,
        totalResults,
        error: modulesError,
        createOrModifyLoading,
        loading
    }: ModulesState = useAppSelector((state) => state.modules);

    const canCopy = userPermissions && (userPermissions.isSuperAdmin || userPermissions.tenantAdminIn?.includes(activeTenantId || ''));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();
    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();
    const { select } = useMultiSelect();

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.MODULES);
    const { lock: lockItem, unlock: unlockItem } = useLockSystem(LockableObjectTypes.ITEMS);

    const prevModuleLoading = usePrevious(loading);

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.lastModified);
    const [activeObjectFilter, setActiveObjectFilter] = useState<ObjectFilter | undefined>(undefined);

    const orderBy = calculateOrderByFromSortConfig(sortConfig);

    const resetCallback = (keep?: ResetCallbackProps) => {
        !keep?.currentPage && setCurrentPage(1);
        !keep?.searchTerm && setSearchTerm(undefined);
        !keep?.sortConfig && setActiveSortingKey(DEFAULT_SORT_CONFIG.field as AcceptedSortField);
        !keep?.sortConfig && setSortConfig(DEFAULT_SORT_CONFIG);
        !keep?.filterObject && setActiveObjectFilter(undefined);
        setIsMultiSelectVisible(false);
        if (!keep?.visualEditor) {
            openEditViewModuleId && unlock(openEditViewModuleId);
            setOpenEditViewModuleId('');
            setPreviewModuleId('');
            setModuleToSave(modules.find((m) => m._id === moduleToSave?._id));
            setModules(modules.map((m) => (m._id === moduleToSave?._id ? storeModules.find((sm) => sm._id === m._id) || m : m)));
        }
    };

    // ----------------------

    const lockModule = (id: string) => {
        lock(id);
        unlockOnClose(id);
    };

    const unsavedDependencyArray = [moduleToSave, InitialCIsOrder];

    useBlockNavigation(showUnsaved, () => renderAlertShowUnsaved(), unsavedDependencyArray);

    // This method is used to load templates and content source types, in case they are not already loaded in the slice
    const loadResources = async () => {
        loadTemplates();
        loadContentSourceTypes();
        if (!storeItemTypes?.length) loadItemTypes();
    };

    useEffect(() => {
        !isDesktop && setOpenEditViewModuleId('');
    }, [isDesktop]);

    useEffect(() => {
        if (!activeProjectId) return;

        // everything should be reset between project changes
        resetCallback();
        setPageSize(10);
        setShowSortArrows(false);

        const redirectModuleId = getSearchParam('id');
        const moduleId = location?.state?.moduleId || redirectModuleId;

        let filter: ObjectFilter = {};
        if (moduleId) {
            filter._id = [moduleId];
            setActiveObjectFilter(filter);
        }

        if (storeModules.length) {
            dispatch(unsetModules());
        }
        loadModules(true, activeProjectId, 10, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG), undefined, filter)
            .then((response: any) => {
                if (response.permissions) {
                    dispatch(setUserPermissions(response.permissions));
                }
            })
            .then(() => {
                loadAudiences();
                loadResources();
                loadConditions();
            });

        return () => {
            setOpenEditViewModuleId('');
        };
    }, [activeProjectId]);

    useEffect(() => {
        setTotalPages(totalResults !== undefined ? Math.ceil(totalResults / pageSize) : 1);
    }, [pageSize, totalResults]);

    useEffect(() => {
        setIsMultiSelectVisible(false);
        setMultiSelectedIds([]);
    }, [currentPage]);

    useEffect(() => {
        // Use to open the editor in new tab
        const redirectModuleId = getSearchParam('id');
        const redirected = location?.state?.redirected || redirectModuleId;
        const moduleId = location?.state?.moduleId || redirectModuleId;

        // when coming from pages list view we set edit view to that module
        if (redirected && !openEditViewModuleId?.length) {
            setPreviewModuleId(moduleId);
            window.history.replaceState({}, '');
        }

        if (location?.state?.openCreate) {
            setOpenCreateModule(true);
            window.history.replaceState({}, '');
        }
        if (moduleId && !redirected) {
            setEditModuleId(moduleId);
            window.history.replaceState({}, '');
        }

        return () => {
            setEditModuleId('');
            setPreviewModuleId('');
            setOpenCreateModule(false);
        };
    }, [activeProjectId]);

    useEffect(() => {
        if (loading || modulesError) return;

        setModules(storeModules);

        // When redirected from Dashboard open Edit Or Create
        if (openCreateModule) {
            setOpenNewResourceDialog(true);
        }
        if (previewModuleId) {
            const module = storeModules.find((m: Module) => m._id === previewModuleId);
            if (module) {
                const locked = isObjectLocked(module);
                const lockedBy = objectIsLockedBy(module);
                locked && renderLockedWarningAlert(lockedBy);
                if (module.adminLocked) {
                    renderAdminLockedWarningAlert(module.name);
                }
                if (activeObjectFilter?._id) {
                    setSearchTerm(module.name || `${EMPTY_WORD_STRING} ID: ${module._id.substring(0, 8)}...`);
                }
            }
            lockModule(previewModuleId);
            setOpenEditViewModuleId(previewModuleId);
        }

        if (editModuleId) {
            const module = modules.find((cm) => cm._id === editModuleId);
            if (module) {
                const locked = isObjectLocked(module);
                const lockedBy = objectIsLockedBy(module);
                if (module.adminLocked) {
                    renderAdminLockedWarningAlert(module.name);
                }
                if (locked && lockedBy) {
                    renderLockedWarningAlert(lockedBy);
                } else {
                    setModuleToEdit(module);
                    setOpenNewModuleDialog(true);
                }
            }
        }
    }, [storeModules]);

    useEffect(() => {
        if (!sources.length) return;
        const newModules: Module[] = [];
        modules.forEach((module) => {
            const newModule = { ...module };
            if (module.items?.length) {
                const newItems = module.items.map((item: Item) => {
                    const newItem = { ...item };
                    if (item.dynamicSourceId && !item.dynamicSource) {
                        newItem.dynamicSource = sources.find((source) => source._id === item.dynamicSourceId);
                    }
                    return newItem;
                });
                _.set(newModule, 'items', newItems);
            }
            newModules.push(newModule);
        });
        setModules(newModules);
    }, [sources]);

    useEffect(() => {
        // whenever we finish loading the modules we should load the sources as well
        prevModuleLoading && !loading && loadSources();
    }, [loading]);

    useEffect(() => {
        const newTooltips = { ...openNameTooltip };
        modules.forEach((_, index) => {
            newTooltips[index] = false;
        });
        setOpenNameTooltip(newTooltips);
    }, [modules]);

    useEffect(() => {
        if (!modules || !openEditViewModuleId) return;

        const initialOrder = modules.find((module) => module._id === openEditViewModuleId)?.itemIds || [];
        setInitialCIsOrder(initialOrder);
    }, [openEditViewModuleId, modules]);

    useEffect(() => {
        const calculateModuleToSave = () => {
            if (!moduleToSave) return false;

            const CIsOrder = moduleToSave.itemIds || [];

            return InitialCIsOrder?.join(',') !== CIsOrder?.join(',');
        };

        setShowUnsaved(calculateModuleToSave());
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        if (!modules?.length) return;
        setAllModulesSelected(multiSelectedIds.length === modules.length);
    }, [multiSelectedIds, modules]);

    useEffect(() => {
        if (!isMultiSelectVisible) {
            setAllModulesSelected(false);
            setMultiSelectedIds([]);
        }
    }, [isMultiSelectVisible]);

    // ITEMS
    const setItemForPreview = (itemId: string, moduleId: string, type: string) => {
        dispatch(setItemPreview({ itemId, moduleId, type }));
    };

    const unsetItemForPreview = () => {
        dispatch(unSetItemPreview());
    };

    const removeItemFromModule = async (module: Module, id: string) => {
        const index = module.itemIds?.findIndex((cId) => cId === id);
        if (index === undefined || index === -1) return;
        const newItemIds = [...(module.itemIds || [])];
        newItemIds.splice(index, 1);
        const newModule: any = {
            _id: module._id,
            itemIds: newItemIds,
            lastModified: module.lastModified,
            slug: module.slug
        };
        unlockItem(id);
        setPreviewModuleId('');
        await modifyModule(newModule);
    };

    const removeItem = async (id: string) => {
        const response = await dispatch(deleteItem(id)).unwrap();
        if (response.ok) loadModules(false, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
    };

    const handleDeleteItem = (item: Item, module: Module) => {
        const label = item.itemType === itemTypes.EDITORIAL ? 'Editorial Item' : 'Dynamic List';

        return dialogRemove(
            RemovableObjects.ITEM,
            { object: item.name, parent: module.name },
            () => {
                removeItem(item._id);
            },
            () => removeItemFromModule(module, item._id),
            label
        );
    };

    const saveItem = async (item: Item) => {
        const response = await dispatch(createItem(item)).unwrap();
        if (!response.id) {
            const values = {
                title: 'Oops.. An error occured.',
                text: response.error
            };
            dialogAlert('', false, values, undefined, true);
        }
        return response.id;
    };

    const modifyItem = async (item: Item) => {
        try {
            const response = await dispatch(updateItem({ item })).unwrap();
            if (!response.ok) {
                const values = {
                    title: 'Oops.. An error occured.',
                    text: response.error
                };
                dialogAlert('', false, values, undefined, true);
                return false;
            }

            loadModules(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            return true;
        } catch (ex) {
            return false;
        }
    };

    const handleSaveClickForItem = async (newItemId: string, removeAutoCollection?: boolean, removeIncludeProviderCardLogo?: boolean) => {
        const selectedCm = { ...modules.find((cm) => cm._id === moduleIdForItem) };
        const newModule: any = {
            _id: moduleIdForItem,
            lastModified: selectedCm.lastModified,
            slug: selectedCm.slug,
            ...(removeAutoCollection && { autoCollection: false }),
            ...(removeIncludeProviderCardLogo && { includeProviderLogoCard: false })
        };
        if (!selectedCm.itemIds?.length) {
            newModule.itemIds = [newItemId];
        } else {
            if (addItemAtIndex === null) {
                newModule.itemIds = [...selectedCm.itemIds, newItemId];
            } else {
                const newItemIds = [...selectedCm.itemIds];
                newItemIds.splice(addItemAtIndex, 0, newItemId);
                newModule.itemIds = newItemIds;
            }
        }
        const result = await modifyModule(newModule);
        setPreviewModuleId('');
        result && lockItem(newItemId);
        return result;
    };

    const itemActionsSwitcher = (type: 'ADD' | 'EDIT' | 'DELETE' | 'DUPLICATE', module: Module, item?: Item, index?: number) => {
        switch (type) {
            case 'ADD':
                addItem(module, index);
                break;

            case 'EDIT':
                if (!item) return;
                setItemToEdit(item);
                setOpenNewItemDialog(true);
                break;
            case 'DELETE':
                if (!item) return;
                handleDeleteItem(item, module);
                break;
            case 'DUPLICATE':
                if (!item) return;
                setItemToEdit(item);
                setDuplicatingCI(true);
                setOpenNewItemDialog(true);
                setModuleIdForItem(module._id);
                break;

            default:
                break;
        }
    };

    const getProviderNameForModule = (module: Module) => {
        if (!module?.includeProviderLogoCard) return '';
        return module?.items?.find((item) => item.itemType === itemTypes.DYNAMIC)?.contentSourceType || '';
    };

    const useTemplate = async (id: string, type: keyof typeof templateTypes = 'MODULE') => {
        const index = !!addItemAtIndex ? addItemAtIndex : undefined;
        const moduleId = !!moduleIdForItem ? moduleIdForItem : undefined;
        const appliedForItemInsideModule = !!moduleId && type === 'ITEM';

        const result = await dispatch(
            applyTemplate({
                templateId: id,
                type: templateTypes[type],
                projectId: `${activeProjectId}`,
                tenantId: `${activeTenantId}`,
                index,
                moduleId: appliedForItemInsideModule ? moduleId : undefined
            })
        ).unwrap();
        if (result.id) {
            resetCallback({
                visualEditor: appliedForItemInsideModule,
                searchTerm: appliedForItemInsideModule,
                filterObject: appliedForItemInsideModule
            });
            const response = await loadModules(
                false,
                activeProjectId,
                pageSize,
                1,
                calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG),
                appliedForItemInsideModule ? searchTerm : undefined,
                appliedForItemInsideModule ? activeObjectFilter : undefined
            );
            const modules = response.modules;
            // If a template was applied for a module, open the visual editor
            if (appliedForItemInsideModule) {
                const module = modules.find((module) => module._id === moduleId);
                const item = module?.items?.find((item) => item._id === result.id);
                item?.itemType === itemTypes.EDITORIAL && setItemForPreview(result.id, moduleIdForItem, item.itemType);
            }
        }
    };

    const addItem = (module: Module, index?: number) => {
        typeof index === 'number' && setAddItemAtIndex(index + 1);
        setOpenNewResourceDialogForItem(true);
        setModuleIdForItem(module._id);
        setIsCollectionItem(module?.moduleType === moduleTypes.COLLECTION);
        setCollectionType(module?.moduleType === moduleTypes.COLLECTION && !!module?.items?.length ? module.items[0].itemType : undefined);
        setIsAutoCollection(module?.autoCollection || false);
        setIsContentWorld(module?.contentWorld || false);
        setIncludeProviderLogoCardProviderName(getProviderNameForModule(module));
    };

    const loadModules = async (
        addPermissions: boolean,
        projectId: string = '',
        pageSize: number,
        pageNumber: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        if (storeModules.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES));
        }
        // if redirected, reset the search term and filter after any action
        const isRedirected = !!activeObjectFilter?._id;
        if (isRedirected) {
            setActiveObjectFilter(undefined);
            setSearchTerm(undefined);
        } else {
            // there might be a searchTerm in the fancy filter but a search was not conducted, so we need to clear the searchTerm inside the fancy filter
            // to do that we assign the searchTerm a special value which will be used in the fancy filter to clear both search terms
            const moduleId = location?.state?.moduleId || getSearchParam('id');
            if (!moduleId && !searchTerm) setSearchTerm(searchTermUnsetValue);
        }
        return await dispatch(
            fetchModules({
                addPermissions,
                projectId,
                pageSize,
                pageNumber,
                orderBy,
                ...(!isRedirected && { searchTerm }),
                ...(!isRedirected && { filter })
            })
        ).unwrap();
    };

    const onSearchTermOrPaginationChange = (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => {
        setPreviewModuleId('');
        setOpenEditViewModuleId('');
        setIsMultiSelectVisible(false);
        loadModules(true, activeProjectId || '', pageSize, currentPage, orderBy, searchTerm, filter);
    };

    const saveModule = async (module: Module, item?: Item) => {
        try {
            if (item) {
                const { id, error } = await dispatch(createItem(item)).unwrap();
                if (error) return false;
                module.itemIds = [id];
            }
            const result = await dispatch(createModule(module)).unwrap();
            if (!result.id) return false;
            if (result.shouldShowEmptySlugMessage) renderModuleEmptySlugAlert();
            if (result.shouldShowSlugWarning) renderModuleSlugAlert();

            resetCallback();
            loadModules(false, activeProjectId, pageSize, 1, calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG));
            return true;
        } catch (ex) {
            return false;
        }
    };

    const modifyModule = async (module: Module, shouldUnlockAfterSave: boolean = false) => {
        const moduleToSet: any = { ...moduleToSave };
        setModuleToSave(undefined);
        try {
            const result = await dispatch(updateModule({ module, shouldUnlockAfterSave })).unwrap();
            if (!result.ok) {
                setModuleToSave(moduleToSet);
                return false;
            }

            resetCallback({ visualEditor: !shouldUnlockAfterSave, searchTerm: true, filterObject: true });
            if (result.shouldShowEmptySlugMessage) renderModuleEmptySlugAlert();
            if (result.shouldShowSlugWarning) renderModuleSlugAlert();
            await loadModules(
                false,
                activeProjectId,
                pageSize,
                1,
                calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG),
                searchTerm,
                activeObjectFilter
            );
            return true;
        } catch (ex) {
            setModuleToSave(moduleToSet);
            return false;
        }
    };

    const removeModule = async (id: string, withItems?: boolean) => {
        const response = await dispatch(deleteModule({ id, withItems })).unwrap();

        if (!!response.failedDeletions) {
            renderFailedObjectDeletions(response.failedDeletions);
        }
        if (response.ok) {
            loadModules(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
            resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
        }
    };

    const removeList = async (modules: string[], withItems?: boolean) => {
        const result = await dispatch(deleteModuleList({ modules, withItems })).unwrap();
        if (result.failedDeletions.length) {
            renderFailedObjectDeletions(result.failedDeletions);
        }
        loadModules(false, activeProjectId, pageSize, currentPage, orderBy, searchTerm, activeObjectFilter);
        resetCallback({ currentPage: true, searchTerm: true, sortConfig: true, filterObject: true });
    };
    const loadTemplates = async () => {
        await dispatch(fetchModulesTemplates(activeProjectId)).unwrap();
    };

    const loadContentSourceTypes = async () => {
        return await dispatch(fetchContentSourceTypes({ projectId: activeProjectId }));
    };
    const loadItemTypes = async () => {
        return await dispatch(fetchItemTypes());
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };
    const loadSources = async () => {
        return await dispatch(fetchSources({ addPermissions: false, projectId: activeProjectId }));
    };

    const reorderModule = async (shouldUnlockAfterSave: boolean = false) => {
        if (!moduleToSave) return;
        const result = await modifyModule(moduleToSave, shouldUnlockAfterSave);
        if (result) {
            setPreviewModuleId('');
            setInitialCIsOrder([]);
        }
    };

    const handleSortIconClick = (field: AcceptedSortField, specifiedDirection?: 'asc' | 'desc', currentPage?: number) => {
        if (showUnsaved) return renderUnsavedAlertBeforeAction();

        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = specifiedDirection || 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc' && !specifiedDirection) {
            direction = 'desc';
        }

        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = calculateOrderByFromSortConfig(config);

        !currentPage && setCurrentPage(1);
        loadModules(false, activeProjectId, pageSize, currentPage || 1, orderBy, searchTerm, activeObjectFilter);
        // make sure sort arrows don't remain open after reloading
        setShowSortArrows(false);
        setPreviewModuleId('');
        setOpenEditViewModuleId('');
        setIsMultiSelectVisible(false);
    };

    const handleCopyToProject = async (
        projectId: string,
        newTemplateValues?: { moduleId: string; template: string }[],
        showItemActionWarningAfterCopy?: boolean,
        withRedirect?: boolean,
        hideSuccessToast?: boolean
    ) => {
        if (!moduleToEdit) return;

        const result = await dispatch(copyObject({ _id: moduleToEdit._id, projectId, objectType: 'modules', newTemplateValues })).unwrap();
        if (result.id) {
            showItemActionWarningAfterCopy && renderItemActionWarningAfterCopy();
            !hideSuccessToast && ToastAlert('success', '', '', undefined, DIALOG_NAMES.OBJECT_COPY);
            if (withRedirect) {
                setOpenEditViewModuleId('');
                navigate(buildPathWithProjectId(projectId, PageRoutes.MODULES), { state: { moduleId: result.id, redirected: true } });
            }
        }

        setShowCopyToProjectDialog(false);
    };

    const handleDeleteIconClick = (module?: Module) => {
        return dialogRemove(
            RemovableObjects.MODULE,
            { object: module?.name },
            (withItems) => {
                module ? removeModule(module._id, withItems) : removeList(multiSelectedIds, withItems);
                setPreviewModuleId('');
            },
            undefined,
            undefined,
            !module
        );
    };

    const handleNewModuleDialogClose = () => {
        // if EDITING the visual editor is NOT open, unlock the module, otherwise keep it locked
        if (moduleToEdit && !openEditViewModuleId) {
            unlock(moduleToEdit._id);
        }
        setOpenNewModuleDialog(false);
        setModuleToEdit(undefined);
        setDuplicating(false);
    };

    const renderVisualEditor = (module: Module, template: any, moduleIndex: number) => {
        const { _id } = module;
        if (openEditViewModuleId !== _id) return;
        const locked = isObjectLocked(module);
        const lockedBy = objectIsLockedBy(module);
        return (
            <EditorialTableRow>
                <TableCell colSpan={8}>
                    <VisualEditor
                        module={module}
                        actions={[Actions.EDIT, Actions.REMOVE, Actions.DUPLICATE]}
                        onDuplicate={() => {
                            setModuleToEdit(module);
                            setOpenNewModuleDialog(true);
                            setDuplicating(true);
                        }}
                        onEdit={() => {
                            setModuleToEdit(module);
                            setOpenNewModuleDialog(true);
                            if (module.adminLocked) {
                                return renderAdminLockedWarningAlert(module.name);
                            }
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                        }}
                        onRemove={() => {
                            if (module.adminLocked) {
                                return renderAdminLockedWarningAlert(module.name);
                            }
                            if (locked) {
                                return renderLockedWarningAlert(lockedBy);
                            }
                            handleDeleteIconClick(module);
                        }}
                        onItemAction={(actionType: any, item?: Item, index?: number) => {
                            itemActionsSwitcher(actionType, module, item, index);
                        }}
                        createItem={(index?: number) => {
                            addItem(module, index);
                        }}
                        setItemForPreview={(ciId, cmId, type) => setItemForPreview(ciId, cmId, type)}
                        onSave={(item) => modifyItem(item)}
                        template={template}
                        moduleIndex={moduleIndex}
                        setOrderedModule={(module) => setModuleToSave(module)}
                        onCancel={() => {
                            if (showUnsaved) {
                                return renderAlertShowUnsaved();
                            }
                            setOpenEditViewModuleId('');
                            setPreviewModuleId('');
                            unlock(openEditViewModuleId);
                        }}
                    />
                </TableCell>
            </EditorialTableRow>
        );
    };

    // TODO: make this universal ( replace the module with object )
    const renderNameTooltip = (module: Module, index: number, locked: boolean, lockedBy: string) => {
        const tooltipValue = (
            <ObjectNameTooltipContentHolder>
                <ObjectNameTooltipIconHolder>
                    <SVGInline src={icons.modulesIcon} />
                </ObjectNameTooltipIconHolder>
                <ObjectNameTooltipNameHolder>{module.name || EMPTY_WORD_STRING}</ObjectNameTooltipNameHolder>
                <TooltipDivider />
                <ObjectNameTooltipLabelHolder>Open Editor</ObjectNameTooltipLabelHolder>
            </ObjectNameTooltipContentHolder>
        );
        return renderTooltip(
            <NameWrapper>
                <TruncatedText
                    onMouseEnter={() =>
                        setOpenNameTooltip((tooltips: any) => {
                            const newOpenNameTooltip = { ...tooltips };
                            Object.keys(newOpenNameTooltip).forEach((key) => {
                                newOpenNameTooltip[key] = key === index.toString();
                            });
                            return newOpenNameTooltip;
                        })
                    }
                >
                    {module.name || EMPTY_WORD_STRING}
                </TruncatedText>
                {<TranslationTooltip translationKey={module.name} />}
                {locked && renderLockIcon(lockedBy)}
                {module.adminLocked && renderAdminLockIcon()}
                {renderABIcon(module)}
            </NameWrapper>,
            tooltipTypes.HTML,
            tooltipValue,
            tooltipPositions.TOP,
            false,
            true,
            false,
            openNameTooltip?.[index] || false
        );
    };

    const calculateContentSourceValue = (module: Module) => {
        if (!(module.items && module.items.length)) return '(No source)';
        if (module.items?.every((ci) => ci.itemType === itemTypes.EDITORIAL)) return 'Editorial';

        if (
            module.items.every(
                (ci) =>
                    ci.itemType === itemTypes.DYNAMIC &&
                    ci.dynamicSource &&
                    ci.dynamicSource.service === module.items?.[0]?.dynamicSource?.service
            )
        ) {
            return contentSourceTypes?.find((cs) => cs.value === module.items?.[0]?.dynamicSource?.service)?.title;
        } else if (module.items.every((ci) => ci.contentSourceType === module.items?.[0]?.contentSourceType)) {
            return contentSourceTypes?.find((cs) => cs.value === module.items?.[0]?.contentSourceType)?.title;
        }
        return 'Multiple sources';
    };
    const buildTableColumns = () => {
        if (loading) return [];

        const columns = [
            <SortableHeaderTableCell
                key={`template_cell`}
                text={'UI'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.template}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.template)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ModulesTableSizes['template']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.template && sortConfig?.direction) || 'asc'}
            />,
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Module Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ModulesTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'content_source_cells'} text={'Content Source'} columnSize={ModulesTableSizes.content_source} />,
            <HeaderTableCell key={'placed_cell'} text={'Placed'} columnSize={ModulesTableSizes.placed} />,
            <HeaderTableCell key={'conditions_cell'} text={'Target Conditions'} columnSize={ModulesTableSizes.conditions} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => setShowSortArrows(true)}
                onMouseLeave={() => setShowSortArrows(false)}
                columnSize={ModulesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];

        columns.push(
            <>
                {isDesktop && <TableCell key={`empty_cell_modules`} />}
                <SelectAllTableCell
                    onDelete={handleDeleteIconClick}
                    onSelectAll={(selected) => {
                        setMultiSelectedIds(selected ? modules.map((module) => module._id) : []);
                    }}
                    allValuesSelected={allModulesSelected}
                    isMultiSelectVisible={isMultiSelectVisible}
                    setIsMultiSelectVisible={(arg) => setIsMultiSelectVisible(arg)}
                    isDeleteDisabled={!multiSelectedIds.length}
                />
            </>
        );
        !isDesktop && columns.splice(5, 1); // remove last modified column if not in desktop view

        return columns;
    };

    const getTemplateNames = (title: string) => {
        return <ContentTemplateName>{title || '(Unknown)'}</ContentTemplateName>;
    };

    const buildTableBody = () => {
        const rows: JSX.Element[] = modules.map((module, index) => {
            const lastModified = generateDateStringForTables(module?.lastModified || 0);
            const template = validTemplates?.find((t) => t.value === module.template);
            const contentSource = calculateContentSourceValue(module);
            const locked = isObjectLocked(module);
            const lockedBy = objectIsLockedBy(module);
            return (
                <>
                    {!loading && (
                        <ScrollableTableRow
                            shouldScroll={previewModuleId === module._id}
                            key={module._id}
                            style={{ cursor: 'pointer' }}
                            data-cy={`module-row-${index}`}
                            onClick={() => {
                                if (!isDesktop) {
                                    return setShowHintScreen(true);
                                }
                                if (module.adminLocked) {
                                    renderAdminLockedWarningAlert(module.name);
                                }
                                // If it's locked by someone show alert and prevent opening
                                if (locked && lockedBy) {
                                    renderLockedWarningAlert(lockedBy);
                                }
                                setOpenEditViewModuleId(module._id);
                                lockModule(module._id);
                            }}
                        >
                            {/* MODULE TEMPLATE TABLE CELL */}
                            <WidthTableCell {...ModulesTableSizes.template}>
                                <ContentTemplateLogo>
                                    <SVGInline src={template ? TemplateIcons[template?.key] : TemplateIcons.GALLERY} />
                                    {getTemplateNames(template?.title)}
                                </ContentTemplateLogo>
                            </WidthTableCell>

                            {/* MODULE NAME TABLE CELL */}
                            <WidthTableCell
                                {...ModulesTableSizes.name}
                                onMouseLeave={() => {
                                    setOpenNameTooltip((tooltips: any) => {
                                        const newOpenNameTooltip = { ...tooltips };
                                        Object.keys(newOpenNameTooltip).forEach((key) => {
                                            newOpenNameTooltip[key] = false;
                                        });
                                        return newOpenNameTooltip;
                                    });
                                }}
                            >
                                {renderNameTooltip(module, index, locked, lockedBy)}
                            </WidthTableCell>

                            {/*  CONTENT SOURCE TABLE CELL */}
                            <WidthTableCell {...ModulesTableSizes.content_source}>
                                <TruncatedText>{contentSource}</TruncatedText>
                            </WidthTableCell>

                            {/* PLACED TABLE CELL */}
                            <WidthTableCell {...ItemsTableSizes.placed}>
                                <Labels
                                    withTranslationTooltip
                                    values={module.placed || []}
                                    type={MoreInfoTypes.PLACED}
                                    noOfLabels={isLargeDesktop ? 3 : isMobile ? 0 : 1}
                                    onClickLabel={(obj) => {
                                        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', obj._id)));
                                    }}
                                />
                            </WidthTableCell>

                            {/*  TARGET CONDITION TABLE CELL */}
                            <WidthTableCell {...ModulesTableSizes.conditions}>
                                <Labels
                                    values={module?.conditions || []}
                                    type={MoreInfoTypes.TARGETS}
                                    noOfLabels={isLargeDesktop ? 3 : isMobile ? 0 : 1}
                                    onClickLabel={(obj) => {
                                        const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                        navigate(
                                            buildPathWithProjectId(
                                                activeProjectId,
                                                isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                            ),
                                            {
                                                state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                            }
                                        );
                                    }}
                                />
                            </WidthTableCell>

                            {/* LAST MODIFIED TABLE CELL */}
                            {isDesktop && <WidthTableCell {...ModulesTableSizes.lastModified}> {lastModified}</WidthTableCell>}

                            {/* MODIFIED BY TABLE CELL */}
                            {isDesktop && (
                                <ImageTableCell
                                    um={'px'}
                                    shape="round"
                                    src={module?.modifiedByUser?.icon || avatarIcon}
                                    toolTipName={module?.modifiedByUser?.name}
                                    imageSize={{ width: 32, height: 32 }}
                                />
                            )}

                            {/* ACTIONS TABLE CELL */}
                            <WidthTableCell {...ModulesTableSizes.actions}>
                                <ActionsTableCell
                                    actions={
                                        isMultiSelectVisible
                                            ? [tableActions.MULTI_SELECT]
                                            : [tableActions.COPY, tableActions.EDIT, tableActions.DUPLICATE, tableActions.REMOVE]
                                    }
                                    canCopy={canCopy}
                                    onCopy={(e: React.MouseEvent<any>) => {
                                        e.stopPropagation();
                                        if (module.adminLocked) {
                                            return renderAdminLockedWarningAlert(module.name);
                                        }
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                        if (showUnsaved) {
                                            return renderUnsavedWhenCopyingAlert();
                                        }
                                        setModuleToEdit(module);
                                        setShowCopyToProjectDialog(true);
                                    }}
                                    onEdit={(e: any) => {
                                        e.stopPropagation();
                                        // If it's locked by someone show alert and prevent opening
                                        setModuleToEdit(module);
                                        setOpenNewModuleDialog(true);
                                        if (module.adminLocked) {
                                            return renderAdminLockedWarningAlert(module.name);
                                        }
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                        lockModule(module._id);
                                    }}
                                    onDuplicate={(e: any) => {
                                        setModuleToEdit(module);
                                        setOpenNewModuleDialog(true);
                                        setDuplicating(true);
                                        e.stopPropagation();
                                    }}
                                    onRemove={(e: any) => {
                                        e.stopPropagation();
                                        if (module.adminLocked) {
                                            return renderAdminLockedWarningAlert(module.name);
                                        }
                                        if (locked) {
                                            return renderLockedWarningAlert(lockedBy);
                                        }
                                        handleDeleteIconClick(module);
                                    }}
                                    tooltipTexts={{
                                        copy: 'modules_icon_copy',
                                        edit: 'modules_icon_edit',
                                        duplicate: 'modules_icon_duplicate',
                                        delete: 'modules_icon_delete'
                                    }}
                                    publishedStatus={module.publishStatus}
                                    onMultiSelect={() => {
                                        const modulesIds = modules.map((module) => module._id);
                                        const newValues = select(modulesIds, multiSelectedIds, module._id);
                                        setMultiSelectedIds([...newValues]);
                                    }}
                                    selected={multiSelectedIds.includes(module._id)}
                                />
                            </WidthTableCell>
                        </ScrollableTableRow>
                    )}
                    {!loading && renderVisualEditor(module, template?.key || 'UNKNOWN', index)}
                </>
            );
        });
        return <TableBody>{rows}</TableBody>;
    };

    const renderAlertShowUnsaved = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                reorderModule().then(() => {
                    setOpenEditViewModuleId('');
                    setModuleToSave(undefined);
                });
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                openEditViewModuleId && unlock(openEditViewModuleId);
                setOpenEditViewModuleId('');
                setPreviewModuleId('');
                setModuleToSave(modules.find((m) => m._id === moduleToSave?._id));
                setModules(modules.map((m) => (m._id === moduleToSave?._id ? storeModules.find((sm) => sm._id === m._id) || m : m)));
            },
            true
        );
    };

    const isEmpty = !storeModules?.length;

    const renderNoModules = () => (
        <NoResourcesContainer>
            <ResourceCard
                image={resourceCardImages.modulesNewCard}
                title={'New Module'}
                subtitle={'Create a module from scratch'}
                primaryButtonLabel={'Create Module'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    setOpenNewModuleDialog(true);
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.modules);
                }}
                createResource
            />
            <ResourceCard
                image={resourceCardImages.modulesCard}
                title={'Select Template'}
                subtitle={'Add a module selecting existing templates'}
                primaryButtonLabel={'Select Template'}
                secondaryButtonLabel={'Learn more'}
                onPrimaryButtonClick={() => {
                    setOpenTemplateSelectionDialog(true);
                }}
                onSecondaryButtonClick={() => {
                    openDocumentation(circleSlugs.templates);
                }}
            />
        </NoResourcesContainer>
    );

    const error = modulesError || templatesError || itemError;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.PUBLISHED_REFERENCE_OBJECT:
                return renderPublishedDeleteError(error);
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                return renderAdminLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    const generalError = error || copyObjectError || sourcesError || pageError || applicationsError;

    return (
        <>
            {generalError && renderError(generalError)}
            <ApplicationWrapper $loading={!!openEditViewModuleId && loading}>
                {!openEditViewModuleId && <Sidebar />}
                <MainContentWrapper noSidebar={!!openEditViewModuleId} noTransition>
                    <ScreenTitle
                        title="Modules"
                        withAddButton={!isEmpty}
                        backIcon={!!openEditViewModuleId}
                        withProfile
                        addLabel={'Create Module'}
                        onAdd={() => {
                            setOpenNewResourceDialog(true);
                        }}
                        onBack={() => {
                            if (openEditViewModuleId) {
                                unlock(openEditViewModuleId);
                            }
                            if (showUnsaved) {
                                return renderAlertShowUnsaved();
                            }
                            setOpenEditViewModuleId('');
                            setPreviewModuleId('');
                            unsetItemForPreview();
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.modules, onboarding: ONBOARDING_CIRCLE_SLUGS.modules }}
                    />
                    <PaginationWrapper
                        orderBy={orderBy}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        activeObjectFilter={activeObjectFilter}
                        onSearchTermOrPaginationChange={onSearchTermOrPaginationChange}
                        setActiveObjectFilter={(value: ObjectFilter) => setActiveObjectFilter(value)}
                        extraPadding={!!openEditViewModuleId}
                        resetCallback={resetCallback}
                        showUnsaved={showUnsaved}
                        type={ObjectTypes.MODULES}
                    >
                        {loading && <Loader title={'Modules'} />}
                        {isEmpty && !loading
                            ? renderNoModules()
                            : !isEmpty && (
                                  <>
                                      <GenericTable body={buildTableBody()} columns={buildTableColumns()} dataCy={'modules-table'} />
                                      {!loading && (
                                          <PageActionsWrapper>
                                              <PageActionButton
                                                  onClick={() => setOpenNewModuleDialog(true)}
                                                  label={'Create Module'}
                                                  type={'BLUE'}
                                              />
                                              <PageActionButton
                                                  onClick={() => setOpenTemplateSelectionDialog(true)}
                                                  label={'Select Template'}
                                                  type={'BLUE'}
                                              />
                                          </PageActionsWrapper>
                                      )}
                                  </>
                              )}
                    </PaginationWrapper>
                </MainContentWrapper>
                <TemplateSelection
                    open={openTemplateSelectionDialog || openTemplateSelectionDialogForItem}
                    onClose={() => {
                        setAddItemAtIndex(null);
                        setOpenTemplateSelectionDialog(false);
                        setOpenTemplateSelectionDialogForItem(false);
                    }}
                    templateUrl={''}
                    callback={(id?: string) => {
                        if (id) {
                            useTemplate(id, openTemplateSelectionDialogForItem ? 'ITEM' : 'MODULE');
                            setAddItemAtIndex(null);
                            setOpenTemplateSelectionDialog(false);
                            setOpenTemplateSelectionDialogForItem(false);
                            setOpenNewResourceDialog(false);
                            setOpenNewResourceDialogForItem(false);
                        } else openTemplateSelectionDialog ? setOpenNewModuleDialog(true) : setOpenNewItemDialog(true);
                    }}
                    isAutoCollection={isAutoCollection}
                    moduleItemsNumber={modules.find((cm) => cm._id === moduleIdForItem)?.items?.length}
                    resourceType={openTemplateSelectionDialogForItem ? templateTypes.ITEM : templateTypes.MODULE}
                    isAppliedInGalleryOrButton={
                        openTemplateSelectionDialogForItem &&
                        ([templates.GALLERY, templates.BUTTON] as any[]).includes(
                            modules.find((cm) => cm._id === moduleIdForItem)?.template
                        )
                    }
                    isContentWorldModule={isContentWorld}
                />
                {!loading && openNewModuleDialog && (
                    <NewModule
                        open={openNewModuleDialog}
                        onClose={handleNewModuleDialogClose}
                        module={moduleToEdit}
                        onSave={async (newModule, newItem) => {
                            let saveResult = false;
                            if (moduleToEdit && !duplicating) {
                                saveResult = await modifyModule(newModule);
                                openEditViewModuleId && lockModule(openEditViewModuleId); // lock/re-lock the module to the user
                            } else {
                                saveResult = await saveModule(newModule, newItem);
                            }
                            if (!saveResult) return;
                            handleNewModuleDialogClose();
                            // in case of duplicating a module, if the visual editor is open, unlock and close it
                            if (duplicating && openEditViewModuleId) {
                                unlock(openEditViewModuleId);
                                setOpenEditViewModuleId('');
                            }
                            setOpenNewModuleDialog(false);
                            setOpenTemplateSelectionDialog(false);
                            setOpenNewResourceDialog(false);
                            setPreviewModuleId('');
                        }}
                        duplicate={duplicating}
                    />
                )}
                <Hint type={HINT_TYPES.SCREEN_SIZE_CIS} showHint={showHintScreen} onHide={() => setShowHintScreen(false)} />
                <NewItem
                    open={openNewItemDialog}
                    module={modules.find((cm) => cm._id === moduleIdForItem)}
                    onClose={() => {
                        setOpenNewItemDialog(false);
                        setItemToEdit(undefined);
                        setDuplicatingCI(false);
                        setAddItemAtIndex(null);
                    }}
                    onSave={async (newItem, autoCollection, removeIncludeProviderCardLogo) => {
                        let result: any = null;
                        if (itemToEdit && !duplicatingCI) {
                            result = await modifyItem(newItem);
                            if (!result) return;
                        } else {
                            result = await saveItem(newItem);
                            if (!result) return;
                            openEditViewModuleId && lockModule(openEditViewModuleId); // lock/re-lock the module to the user
                            const ok = await handleSaveClickForItem(result, autoCollection, removeIncludeProviderCardLogo);
                            if (!ok) return;
                            if (newItem.itemType === itemTypes.EDITORIAL) setItemForPreview(result, moduleIdForItem, newItem.itemType);
                        }
                        setOpenNewResourceDialogForItem(false);
                        setOpenNewItemDialog(false);
                        setModuleIdForItem('');
                        setItemToEdit(undefined);
                        setDuplicatingCI(false);
                        setAddItemAtIndex(null);
                    }}
                    item={itemToEdit}
                    duplicate={duplicatingCI}
                    isCollection={isCollectionItem}
                    collectionType={collectionType}
                    includeProviderCardLogoProviderName={includeProviderLogoCardProviderName}
                    isContentWorldModule={isContentWorld}
                />
                <UseExistingDialog
                    open={openUseExistingDialog}
                    onClose={() => {
                        setOpenUseExistingDialog(false);
                    }}
                    existingItemType={EXISTING_ITEMS.ITEM}
                    onSave={async (
                        existingItemId,
                        contentType,
                        autoCollection,
                        removeIncludeProviderCardLogo,
                        hasActionName,
                        itemObject
                    ) => {
                        if (!existingItemId) return;

                        const module = modules.find((cm) => cm._id === moduleIdForItem);
                        if (([templates.GALLERY, templates.BUTTON] as any[]).includes(module?.template) && !hasActionName) {
                            actionNameMissingAlert();
                        }

                        setOpenNewResourceDialogForItem(false);
                        const ok = await handleSaveClickForItem(existingItemId, autoCollection, removeIncludeProviderCardLogo);

                        ok &&
                            contentType &&
                            contentType === itemTypes.EDITORIAL &&
                            setItemForPreview(existingItemId, moduleIdForItem, contentType);

                        setAddItemAtIndex(null);
                        setOpenUseExistingDialog(false);
                    }}
                    collectionType={collectionType}
                    isCollection={isCollectionItem}
                    isAutoCollection={isAutoCollection}
                    isContentWorld={isContentWorld}
                    includeProviderCardLogoProviderName={includeProviderLogoCardProviderName}
                    itemIdsToExclude={modules.find((cm) => cm._id === moduleIdForItem)?.itemIds || []}
                />
                <CopyToProjectDialog
                    open={showCopyToProjectDialog}
                    objectId={moduleToEdit?._id || ''}
                    objectType={ObjectTypes.MODULES}
                    onClose={() => {
                        setShowCopyToProjectDialog(false);
                        setModuleToEdit(undefined);
                        setAddItemAtIndex(null);
                    }}
                    onCopy={(projectId: string, newTemplateValues, showItemActionWarningAfterCopy, withRedirect, hideSuccessToast) => {
                        handleCopyToProject(projectId, newTemplateValues, showItemActionWarningAfterCopy, withRedirect, hideSuccessToast);
                    }}
                />
                {!isEmpty && !loading && (
                    <CreateResourceDialog
                        title={openNewResourceDialog ? 'Module' : 'List or Item'}
                        open={openNewResourceDialog || openNewResourceDialogForItem}
                        onClose={() => {
                            setOpenNewResourceDialog(false);
                            setOpenNewResourceDialogForItem(false);
                            setAddItemAtIndex(null);
                            setIsCollectionItem(false);
                            setCollectionType(undefined);
                            setIsAutoCollection(false);
                            setIsContentWorld(false);
                            setModuleIdForItem('');
                        }}
                        historyUrl={''}
                        handleOpenTemplateClick={() =>
                            openNewResourceDialog ? setOpenTemplateSelectionDialog(true) : setOpenTemplateSelectionDialogForItem(true)
                        }
                        handleCreateNewResourceClick={() =>
                            openNewResourceDialog ? setOpenNewModuleDialog(true) : setOpenNewItemDialog(true)
                        }
                        withSelectExisting={openNewResourceDialogForItem}
                        handleSelectExistingClick={() => {
                            setOpenUseExistingDialog(true);
                        }}
                    />
                )}
                {openEditViewModuleId && (
                    <PageActions
                        onSave={() => {
                            reorderModule();
                            openEditViewModuleId && lockModule(openEditViewModuleId); // lock/re-lock the module to the user
                        }}
                        onCancel={() => {
                            if (showUnsaved) {
                                return renderAlertShowUnsaved();
                            }
                            setOpenEditViewModuleId('');
                            setPreviewModuleId('');
                            unlock(openEditViewModuleId);
                        }}
                        disabled={{ save: !showUnsaved || createOrModifyLoading }}
                    />
                )}
            </ApplicationWrapper>
        </>
    );
};

export default Modules;
