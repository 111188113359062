import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ColorPickerContainer, ColorPickerDialog } from './ColorPicker.css';
import icons from '../../../style';
import InlineSVG from 'react-inlinesvg';
import { ChromePicker, RGBColor } from 'react-color';
import { alphaHexToRGBA, alphaToHex } from '../../../utils/fnColor';

const { configuratorIcon } = icons;

interface IColorPickerProps {
    value: string;
    onChange: any;
    onToggle?: any;
    applyAlpha?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}
const CustomColorPicker: React.FC<IColorPickerProps> = ({ value, onChange, onToggle, disabled, applyAlpha, onClick }) => {
    const [color, setColor] = useState<{ rgba?: RGBColor; hex?: string }>({});
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (!value) return;
        if (applyAlpha) {
            if (value.length === 6) {
                value = '#FF' + value;
            } else if (value.length === 7 && value.indexOf('#') >= 0) {
                value = '#FF' + value.split('#')[1];
            }
            setColor({ rgba: alphaHexToRGBA(value), hex: value });
        } else {
            if (value.indexOf('#') < 0) {
                value = `#${value}`;
            }
            setColor({ rgba: alphaHexToRGBA(value), hex: value });
        }
    }, [value]);
    const toggleOpen = () => {
        !disabled && setOpen((open) => !open);
        onToggle?.(!open);
    };

    const style = {
        backgroundColor: applyAlpha
            ? `rgba(${color.rgba?.r},${color.rgba?.g},${color.rgba?.b},${color.rgba?.a})`
            : `rgb(${color.rgba?.r},${color.rgba?.g},${color.rgba?.b})`
    };

    return (
        <ColorPickerContainer onClick={() => onClick?.()}>
            <div
                className="color-swatch"
                style={style}
                onClick={() => {
                    toggleOpen();
                }}
            />
            <ColorPickerDialog title="" open={open} onClose={() => toggleOpen()}>
                <ChromePicker
                    color={color.rgba}
                    disableAlpha={!applyAlpha}
                    onChange={(newColor) => {
                        let hex = '';
                        if (applyAlpha) {
                            hex = ('#' + alphaToHex(newColor.rgb.a) + newColor.hex.slice(1)).toUpperCase();
                        } else {
                            hex = newColor.hex.toUpperCase();
                        }

                        setColor((color) => {
                            return {
                                ...color,
                                rgba: newColor.rgb,
                                hex
                            };
                        });
                        onChange(hex);
                    }}
                />
            </ColorPickerDialog>
            <input type="text" value={color.hex?.slice(1) || 'Pick color'} onClick={() => toggleOpen()} readOnly />
            <InlineSVG src={configuratorIcon} onClick={() => toggleOpen()} />
            {open ? <div className="cover" onClick={() => toggleOpen()} /> : null}
        </ColorPickerContainer>
    );
};

export default CustomColorPicker;
