import React, { FC, useEffect, useState } from 'react';
import Sidebar from '../common/Sidebar/Sidebar';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import { ArrowContainer, ServiceTableRow, ServiceTitle, SourceName, SourcePlaced, SourceSmallTableRow } from './Sources.css';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    createSource,
    deleteSource,
    fetchServices,
    fetchSources,
    SourcesState,
    unsetSources,
    updateSource
} from '../../redux/slices/sourceSlice';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { setUserPermissions } from '../../redux/slices/permissionsSlice';
import { fetchLanguageCodes } from '../../redux/slices/languagesSlice';
import { generateDateStringForTables } from '../../utils/fnDate';
import { Tooltip, TableRow } from '@material-ui/core';
import { ActionIconHolder, LastModified } from '../TargetGroups/UXElements/Audiences/Audiences.css';
import { NewSourceDialog } from './Dialogs/NewSource';
import { DynamicSource } from '../../types/DynamicSource';
import { Loader } from '../common/Loader/Loader';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { dialogConfirm } from '../../utils/fnDialogs';
import { RemoveModuleWrapper } from '../Modules/Modules.css';
import Button from '../Buttons/Button/Button';
import {
    ApplicationWrapper,
    MainContentWrapper,
    PageActionsWrapper,
    PlacedInPublishedWarningMessageWrapper
} from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import useScreenSize from '../../hooks/useScreenSize';
import { SearchBar } from '../common/SearchBar/SearchBar';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../utils/fnLockingSystem';
import { API_ERROR_CODES } from '../../utils/Globals';
import { ObjectActions } from '../common/Actions/Actions';
import GenericTable, { HeaderTableCell, tableActions } from '../common/Table/Table';
import { getSearchParam } from '../../utils/fnUrl';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { useNavigate } from 'react-router-dom';
import { ApplicationsState } from '../../redux/slices/applicationsSlice';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import { templateTypes } from '../../types/Template';
import TemplateSelection from '../Pages/Dialogs/TemplateSelection';
import { applyTemplate } from '../../redux/slices/templatesSlice';
import { SearchBarContainer } from '../common/SearchBar/SearchBar.css';
import Labels from '../common/Labels/Labels';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { SourcesTableSizes } from '../../types/TableSizes';
import { WidthTableCell } from '../common/Table/Table.css';

export const Sources: FC<any> = () => {
    const [expandedService, setExpandedService] = useState<string>('');
    const [newSourceDialogOpen, setNewSourceDialogOpen] = useState<boolean>(false);
    const [openCreateSourceDialog, setOpenCreateSourceDialog] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);
    const [sourceToEdit, setSourceToEdit] = useState<DynamicSource | undefined>(undefined);
    const [duplicating, setDuplicating] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { error, loading, sources, services: Services }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);

    const { isMobile, isTablet } = useScreenSize();

    // Locking
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.DYNAMIC_SOURCES);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadSources = async (addPermissions?: boolean, projectId?: string, searchTerm?: string) => {
        if (sources.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SOURCES));
        }
        return await dispatch(fetchSources({ addPermissions, projectId, searchTerm })).unwrap();
    };

    const loadServices = async (projectId: string) => {
        return await dispatch(fetchServices(projectId)).unwrap();
    };

    const loadCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const handleOnSearch = (searchTerm: string) => {
        loadSources(false, activeProjectId, searchTerm);
    };

    const useTemplate = async (id: string) => {
        const result = await dispatch(
            applyTemplate({
                templateId: id,
                type: templateTypes.DYNAMIC_SOURCE,
                projectId: `${activeProjectId}`,
                tenantId: `${activeTenantId}`
            })
        ).unwrap();
        if (result.id) {
            loadSources(false, activeProjectId);
            setSearchTerm('');
        }
    };

    const createDynamicSource = async (source: DynamicSource) => {
        const response = await dispatch(createSource(source)).unwrap();
        if (response) {
            loadSources(false, activeProjectId);
            setSearchTerm('');
        }
    };

    const updateDynamicSource = async (source: DynamicSource) => {
        const response = await dispatch(updateSource({ source, shouldUnlockAfterSave: true })).unwrap();
        if (response) {
            loadSources(false, activeProjectId, searchTerm);
        }
    };

    const deleteDynamicSource = async (id: string) => {
        const response = await dispatch(deleteSource(id)).unwrap();
        if (response) {
            loadSources(false, activeProjectId, searchTerm);
        }
    };

    const handleDeleteIconClick = (id: string) => {
        const values = {
            title: 'Remove Source',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                deleteDynamicSource(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Source?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                    <PlacedInPublishedWarningMessageWrapper>
                        If the Source is placed in a published item, it will also be removed from it!
                    </PlacedInPublishedWarningMessageWrapper>
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    useEffect(() => {
        if (!activeProjectId) return;
        setSearchTerm('');
        if (sources.length) {
            dispatch(unsetSources());
        }
        loadSources(true, activeProjectId)
            .then((response: any) => {
                if (response.permissions) {
                    dispatch(setUserPermissions(response.permissions));
                }
            })
            .then(() => loadServices(activeProjectId || ''))
            .then(() => loadCodes());
    }, [activeProjectId]);

    useEffect(() => {
        if (!sources.length || !Services.length) return;

        // Used to open the editor in a new tab
        const redirectSourceId = getSearchParam('id');

        if (redirectSourceId) {
            const source = sources.find((source) => source._id === redirectSourceId);
            if (source) {
                setSourceToEdit(source);
                setNewSourceDialogOpen(true);
                if (isObjectLocked(source)) {
                    return renderLockedWarningAlert(objectIsLockedBy(source));
                }
                lock(source._id);
                unlockOnClose(source._id);
            }
        }
    }, [sources, Services]);

    const renderExpandedService = (service: string) => {
        const sourcesToShow = sources.filter((source) => source.service === service);
        return sourcesToShow.map((source, index) => {
            const dateString = generateDateStringForTables(source.lastModified || 0);
            const locked = isObjectLocked(source);
            const lockedBy = objectIsLockedBy(source);

            return (
                <SourceSmallTableRow key={index} data-cy={`source-container-${index}`}>
                    <WidthTableCell {...SourcesTableSizes.name}>
                        <SourceName>
                            <div>{source.name}</div>
                            {locked && renderLockIcon(lockedBy)}
                        </SourceName>
                    </WidthTableCell>
                    <WidthTableCell {...SourcesTableSizes.placed}>
                        <SourcePlaced>
                            <Labels
                                withTranslationTooltip
                                values={source.placed || []}
                                type={MoreInfoTypes.PLACED}
                                noOfLabels={isMobile ? 1 : isTablet ? 2 : 3}
                                onClickLabel={(obj) => {
                                    navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), {
                                        state: { itemId: obj._id, openVisualEditor: true }
                                    });
                                }}
                            />
                        </SourcePlaced>
                    </WidthTableCell>
                    <WidthTableCell {...SourcesTableSizes.lastModified}>
                        <LastModified>
                            <span>{dateString}</span>
                            {!isMobile && (
                                <Tooltip title={source?.modifiedByUser?.name || ''} placement="right-start">
                                    <img src={source?.modifiedByUser?.icon || icons.avatarIcon} />
                                </Tooltip>
                            )}
                        </LastModified>
                    </WidthTableCell>

                    <WidthTableCell {...SourcesTableSizes.actions}>
                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                setSourceToEdit(source);
                                setNewSourceDialogOpen(true);
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                lock(source._id);
                                unlockOnClose(source._id);
                            }}
                            onRemove={() => {
                                if (locked) {
                                    return renderLockedWarningAlert(lockedBy);
                                }
                                handleDeleteIconClick(source._id || '');
                            }}
                        />
                    </WidthTableCell>
                </SourceSmallTableRow>
            );
        });
    };

    const buildTableColumns = () => {
        if (loading) return [];
        const columns = [
            <HeaderTableCell key={`name_cell`} text={'Source Name'} columnSize={SourcesTableSizes.name} />,
            <HeaderTableCell key={`placed_cell`} text={'Placed'} columnSize={SourcesTableSizes.placed} />,
            <HeaderTableCell key={`lastModified_cell`} text={'Last Modified'} columnSize={SourcesTableSizes.lastModified} />
        ];

        return columns;
    };

    const buildTableBody = () => {
        const servicesToShow = Services.filter((service) => sources.some((source) => source.service === service.key));

        return (
            <>
                {servicesToShow.map((service, index) => {
                    const isExpanded = expandedService === service.key;
                    return (
                        <>
                            <ServiceTableRow
                                key={index}
                                data-cy={`source-service-${index}`}
                                onClick={() => {
                                    setExpandedService(isExpanded ? '' : service.key);
                                }}
                            >
                                <WidthTableCell colSpan={isMobile ? 3 : 1} {...SourcesTableSizes.name}>
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                </WidthTableCell>

                                {!isMobile && (
                                    <>
                                        <WidthTableCell {...SourcesTableSizes.placed} />
                                        <WidthTableCell {...SourcesTableSizes.lastModified} />
                                    </>
                                )}

                                <WidthTableCell {...SourcesTableSizes.actions}>
                                    <ArrowContainer>
                                        {isExpanded ? 'Close' : 'Open'}
                                        <ActionIconHolder>
                                            {renderTooltipWithKey(
                                                <SVGInline src={isExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} onClick={() => {}} />,
                                                `${isExpanded ? 'sources_icon_hide' : 'sources_icon_show'}`
                                            )}
                                        </ActionIconHolder>
                                    </ArrowContainer>
                                </WidthTableCell>
                            </ServiceTableRow>
                            {isExpanded && renderExpandedService(service.key)}
                            <TableRow style={{ height: '24px' }} />
                        </>
                    );
                })}
            </>
        );
    };

    const isEmpty = !sources.length;
    const generalError = error || applicationsError;

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {generalError && renderError(generalError)}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        loading={loading}
                        title="Sources"
                        withProfile
                        withAddButton
                        addLabel="Create Source"
                        onAdd={() => {
                            setOpenCreateSourceDialog(true);
                        }}
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.sources, onboarding: ONBOARDING_CIRCLE_SLUGS.sources }}
                    />
                    <SearchBarContainer>
                        <SearchBar
                            title={'Search by Name'}
                            disabled={loading}
                            searchTerm={searchTerm}
                            onSearch={handleOnSearch}
                            setSearchTerm={setSearchTerm}
                            tooltipText={'sources_icon_search'}
                        />
                    </SearchBarContainer>
                    {loading ? (
                        <Loader title={'Sources'} />
                    ) : isEmpty ? (
                        <PageActionsWrapper>
                            <Button
                                id="new-source-button"
                                onClick={() => {
                                    setOpenCreateSourceDialog(true);
                                }}
                                label={'Create Source'}
                                type={'BLUE'}
                            />
                        </PageActionsWrapper>
                    ) : (
                        <>
                            <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                        </>
                    )}
                </MainContentWrapper>

                <CreateResourceDialog
                    title={'Source'}
                    open={openCreateSourceDialog}
                    handleOpenTemplateClick={() => {
                        setOpenTemplateSelectionDialog(true);
                    }}
                    onClose={() => {
                        setOpenCreateSourceDialog(false);
                    }}
                    historyUrl={''}
                    handleCreateNewResourceClick={() => {
                        setNewSourceDialogOpen(true);
                    }}
                />

                <TemplateSelection
                    open={openTemplateSelectionDialog}
                    onClose={() => {
                        setOpenTemplateSelectionDialog(false);
                    }}
                    templateUrl={''}
                    callback={(id) => {
                        useTemplate(id || '');
                        setOpenTemplateSelectionDialog(false);
                        setOpenCreateSourceDialog(false);
                    }}
                    resourceType={templateTypes.DYNAMIC_SOURCE}
                />

                <NewSourceDialog
                    open={newSourceDialogOpen}
                    onClose={() => {
                        setNewSourceDialogOpen(false);
                        setSourceToEdit(undefined);
                        setDuplicating(false);
                        if (sourceToEdit && !duplicating) {
                            unlock(sourceToEdit._id);
                        }
                    }}
                    onSave={(source) => {
                        if (sourceToEdit && !duplicating) {
                            updateDynamicSource(source);
                        } else {
                            createDynamicSource(source);
                            setOpenCreateSourceDialog(false);
                        }
                    }}
                    dynamicSource={sourceToEdit}
                />
            </ApplicationWrapper>
        </>
    );
};
