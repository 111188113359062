import { FC, useEffect, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper, ObjectNameLabel } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { DialogDropdownSingle } from '../common/Dialog/GenericDialog';
import Sidebar from '../common/Sidebar/Sidebar';
import SVGInline from 'react-inlinesvg';

import {
    FilterRow,
    FilterTabs,
    SortField,
    FilterTab,
    ResultsContainer,
    ResultWrapper,
    ResultContent,
    ResultType,
    ResultTitle,
    ResultSubtitle,
    NoContentIcon,
    NoContentRow,
    NoContentText,
    ResultDate,
    PreviewContainer,
    MobileHeaderSection,
    MobileResultContent,
    MobileResultWrapper,
    MobilePreviewContainer,
    ResultTags
} from './SearchPage.css';
import icons from '../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { SearchState, setSearchTerm, toggleOpenSearchBar } from '../../redux/slices/searchSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '../common/Loader/Loader';
import { generateDateStringForTables } from '../../utils/fnDate';
import { SearchObject, searchTypes } from '../common/SearchBar/Header/HeaderSearch';
import { docsWidget } from '../App';
import useScreenSize from '../../hooks/useScreenSize';
import { buildPathWithProjectId } from '../../types/RouteTypes';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { OnboardingImages } from '../../assets/images/onboardingCards';
import previewImage from '../../assets/images/movieSearch.png';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';

const filters: any = {
    ALL_RESULTS: 'All results',
    DOCUMENTATION: 'Documentation',
    VIDEO_TUTORIALS: 'Video Tutorials'
};

type customLocationState = {
    shouldShowSearch: boolean;
};

export const NO_CONTENT = 'Sorry your search did not match any results';

export const splitObjectType = (s: string) => {
    return (s.charAt(0).toUpperCase() + s.slice(1)).match(/[A-Z][a-z]+/g)?.join(' ');
};

export const SearchPage: FC<any> = () => {
    const [activeTab, setActiveTab] = useState<string>('ALL_RESULTS');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const { searchResults, searchTerm, loading }: SearchState = useAppSelector((state) => state.search);

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const location = useLocation();
    const locationState = location.state as customLocationState;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isMobile } = useScreenSize();
    // we should display the page only if the user comes from the header search suggestions
    useEffect(() => {
        if (!locationState?.shouldShowSearch) {
            navigate(-1);
        }

        setShowSearch(!!locationState?.shouldShowSearch);

        return () => {
            dispatch(setSearchTerm(''));
            dispatch(toggleOpenSearchBar(false));
            setShowSearch(false);
        };
    }, []);

    useEffect(() => {
        if (showSearch) {
            window.history.replaceState({}, '');
        }
    }, [showSearch]);

    const onSectionClick = (obj: SearchObject) => {
        if (obj.type === searchTypes.OBJECT) {
            return navigate(buildPathWithProjectId(activeProjectId, obj.path));
        }

        if (!docsWidget) return;
        docsWidget.setSlug(obj.path, obj?.spaceSlug);
        return docsWidget.element.click();
    };

    const renderNoResult = () => {
        return (
            <NoContentRow>
                <NoContentIcon>
                    <SVGInline src={icons.warningIcon} />
                </NoContentIcon>
                <NoContentText>{NO_CONTENT}</NoContentText>
            </NoContentRow>
        );
    };

    const renderSearchResults = () => {
        let results: SearchObject[] = [];
        switch (activeTab) {
            case 'DOCUMENTATION':
                results = searchResults.filter((result) => result.type === searchTypes.DOCUMENTATION);
                break;
            case 'VIDEO_TUTORIALS':
                results = searchResults.filter((result) => result.type === searchTypes.TUTORIALS);
                break;
            case 'ALL_RESULTS':
            default:
                results = searchResults;
        }
        return results.map((result, index) => {
            const { type, name, object_type, tags, lastModified } = result;
            const isVideo = type === searchTypes.TUTORIALS;
            const isObject = type === searchTypes.OBJECT;

            const description = isObject
                ? `Click to open the '${object_type}' section and reveal '${name}' content`
                : isVideo
                ? `Click to open tutorials for '${name}'`
                : `Click to open the documentation for '${name}'`;

            if (isMobile) {
                return (
                    <MobileResultWrapper key={index} onClick={() => onSectionClick(result)}>
                        <MobileHeaderSection>
                            <ResultType>{object_type ? splitObjectType(object_type) : type}</ResultType>
                            <ResultDate>{isObject && generateDateStringForTables(lastModified || 0)} </ResultDate>
                        </MobileHeaderSection>
                        {isVideo && <MobilePreviewContainer image={OnboardingImages[name] || previewImage} />}
                        <MobileResultContent>
                            <ResultTitle>{name}</ResultTitle>
                            <ResultSubtitle>{description}</ResultSubtitle>
                            {!isObject && (
                                <ResultTags>
                                    {tags?.map((tag, index) => {
                                        if (index < 4) {
                                            return <ObjectNameLabel key={`tag_${index}`}>{tag}</ObjectNameLabel>;
                                        }
                                    })}
                                </ResultTags>
                            )}
                        </MobileResultContent>
                    </MobileResultWrapper>
                );
            }

            return (
                <ResultWrapper key={index} onClick={() => onSectionClick(result)}>
                    {isVideo && <PreviewContainer image={OnboardingImages[name] || previewImage} />}
                    <ResultContent>
                        <ResultType>{object_type ? splitObjectType(object_type) : type}</ResultType>
                        <ResultTitle>{name}</ResultTitle>
                        <ResultSubtitle>{description}</ResultSubtitle>
                        {!isObject && (
                            <ResultTags>
                                {tags?.map((tag, index) => {
                                    if (index < 4) {
                                        return <ObjectNameLabel key={`tag_${index}`}>{tag}</ObjectNameLabel>;
                                    }
                                })}
                            </ResultTags>
                        )}
                    </ResultContent>
                    <ResultDate>{isObject && generateDateStringForTables(lastModified || 0)} </ResultDate>
                </ResultWrapper>
            );
        });
    };

    const noResults = !!searchTerm && !searchResults.length;

    if (!showSearch) return <></>;

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle loading={false} title="Search" withProfile />

                <FilterRow>
                    <FilterTabs>
                        {Object.keys(filters).map((filter) => (
                            <FilterTab
                                onClick={() => {
                                    setActiveTab(filter);
                                }}
                                key={filter}
                                isActive={activeTab === filter}
                            >
                                {filters[filter]}
                            </FilterTab>
                        ))}
                    </FilterTabs>

                    <SortField>
                        <DialogDropdownSingle noLabel options={[]} placeholder={'Sort by'} onChange={() => {}} value={''} />
                    </SortField>
                </FilterRow>

                <ResultsContainer> {loading ? <Loader /> : noResults ? renderNoResult() : renderSearchResults()}</ResultsContainer>
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};
