import { FC, SetStateAction, useEffect, useState } from 'react';
import { Service, ServiceParamNames, SortOptionsMap } from '../../../utils/Services';
import { DialogDropdownMultiple, DialogDropdownSingle, DialogTextField, DialogToggleButton } from '../../common/Dialog/GenericDialog';
import _ from 'lodash';
import { ApplicationsState } from '../../../redux/slices/applicationsSlice';
import { useAppSelector } from '../../../hooks/redux';
import { capitalizeAndSplitCamelCaseString } from '../../../utils/fnString';
import { InputLabelWithIconWrapper } from '../../common/Dialog/GenericDialog.css';
import { ParamsContainer } from './NewSource.css';
import { IconButton, InputAdornment } from '@material-ui/core';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import ApplicationSelect from '../../Applications/ApplicationSelect';
import { ApplicationValues } from '../../Applications/ApplicationDialog';

export enum ADROID_METHOD_KEYS {
    INSTALLED_APPS = 'installed_apps',
    CHANNELS_API = 'channels_api',
    APPS = 'apps'
}

const APPS_KEY = 'apps';
export const multipleAndroidFieldMethods = ['apps', 'installed_apps'];

export const AndroidLabels = {
    installed_apps_apps: 'First time Ordering',
    channels_api_apps: 'App',
    apps_apps: 'Apps'
};

export const AndroidTooltipText = {
    installed_apps_apps:
        'Apps specified here are shown in the given order at first-time use only. After, the ordering will be based on the specific "sorting" you set. In the case of an Android TV Operator Tier device, the out-of-box order must be: 1. Play Store, 2. Play Movies & TV, 3. YouTube, 4. YouTube Music, and 5. Play Games in order to fulfill the Custom Launcher requirements.',
    channelName: `The Channel’s name could differ based on the system’s language so a language key will be set as a value, and the client apps will use the translation values to retrieve the channels' content.`
};

export type AdroidSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        queryParamsValues: any;
        pathParamsValues: any;
        errors: { method?: string; name?: string; param?: string; queryParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
        setAddMultipleApps: SetStateAction<any>;
        setShowNewApplicationDialog: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

export const AndroidSourceFields: FC<AdroidSourceFieldsProps> = ({
    service,
    fieldSetters: {
        setSelectedMethod,
        setErrors,
        setQueryParamsValues,
        setPathParamsValues,
        setAddMultipleApps,
        setShowNewApplicationDialog
    },
    fieldValues: { selectedMethod, queryParamsValues, errors },
    dropdownOptions: { methodOptions }
}) => {
    const { applications, loading: applicationsLoading }: ApplicationsState = useAppSelector((state) => state.applications);
    const [appsOptions, setAppsOptions] = useState<any[]>([]);

    useEffect(() => {
        const appOptions = [
            ...Object.keys(applications || {}).map((key) => ({
                value: applications[key]?.androidPackageName || '',
                label: key
            }))
        ];
        setAppsOptions(appOptions);
    }, [applications]);

    const renderAppsParams = () => {
        const multipleApps = multipleAndroidFieldMethods.includes(selectedMethod);
        if (multipleApps) {
            const queryValues: string[] = [...(queryParamsValues?.apps || [])];
            const customQueries = queryValues.filter((value) => !appsOptions.some((val) => val.value === value));

            const cancelAdornment = (index: number) => (
                <InputAdornment position="end">
                    <IconButton
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const newQueries = [...queryValues];
                            newQueries.splice(index, 1);
                            setQueryParamsValues({ ...queryParamsValues, apps: newQueries });
                            setErrors(_.omit(errors, 'queryParams'));
                        }}
                    >
                        <SVGInline src={icons.closeIcon} />
                    </IconButton>
                </InputAdornment>
            );

            return (
                <ParamsContainer>
                    <DialogDropdownMultiple
                        key={selectedMethod + APPS_KEY}
                        placeholder={_.get(AndroidLabels, `${selectedMethod}_${APPS_KEY}`) || ''}
                        toolTipText={_.get(AndroidTooltipText, `${selectedMethod}_${APPS_KEY}`)}
                        labelText={_.get(AndroidLabels, `${selectedMethod}_${APPS_KEY}`)}
                        value={appsOptions.filter((opt) => queryParamsValues?.apps?.map((v: any) => `${v}`.trim())?.includes(opt.value))}
                        notSorted
                        onChange={(value: any) => {
                            const newValue = [...new Set(value.map((elem: any) => elem.value))];

                            setQueryParamsValues({ ...queryParamsValues, apps: [...customQueries, ...newValue] });
                        }}
                        options={[...appsOptions]}
                        allowSelectAll
                        clearable
                        newOption={{
                            name: 'Application',
                            onClick: () => {
                                setShowNewApplicationDialog(true);
                                setAddMultipleApps(true);
                            }
                        }}
                        isDisabled={applicationsLoading}
                    />

                    {!!queryValues.length && <InputLabelWithIconWrapper> Selected Package Names</InputLabelWithIconWrapper>}
                    {queryValues.map((value, index) => {
                        return (
                            <DialogTextField
                                key={`package_${index}`}
                                value={value}
                                onChange={(e: any) => {
                                    const newQueries = [...queryValues];
                                    newQueries.splice(index, 1, e.target.value);
                                    setQueryParamsValues({ ...queryParamsValues, apps: newQueries });

                                    const queryErrors = { ...(errors?.queryParams || {}) };
                                    const appsErrors = [...(queryErrors?.apps || [])];
                                    if (appsErrors.length) {
                                        appsErrors.splice(index, 1, '');
                                        queryErrors.apps = appsErrors;
                                        setErrors({ ...errors, queryParams: queryErrors });
                                    }
                                }}
                                label={`Android Package Name ${index + 1}`}
                                InputProps={{ endAdornment: cancelAdornment(index) }}
                                error={errors.queryParams?.apps?.[index]}
                            />
                        );
                    })}
                </ParamsContainer>
            );
        }
        const checkAppExistsAndReturnKey = (androidPackageName: string) => {
            for (const appKey in applications) {
                const isSelected = applications[appKey].androidPackageName === androidPackageName;
                if (isSelected) {
                    return appKey;
                }
            }
            return '';
        };
        return (
            <ParamsContainer>
                <ApplicationSelect
                    key={selectedMethod + APPS_KEY}
                    placeholder={_.get(AndroidLabels, `${selectedMethod}_${APPS_KEY}`) || ''}
                    toolTipText={_.get(AndroidTooltipText, `${selectedMethod}_${APPS_KEY}`)}
                    labelText={_.get(AndroidLabels, `${selectedMethod}_${APPS_KEY}`)}
                    selectedApplication={checkAppExistsAndReturnKey(`${queryParamsValues?.apps}`.trim() || '')}
                    onSelectedApplicationChange={(value: string) =>
                        setQueryParamsValues({ ...queryParamsValues, apps: applications[value]?.androidPackageName })
                    }
                    onApplicationFieldValuesChange={(newValues?: ApplicationValues) => {
                        setQueryParamsValues({ ...queryParamsValues, apps: newValues?.androidPackageName });
                    }}
                />
                {!!queryParamsValues?.apps && (
                    <>
                        <InputLabelWithIconWrapper> Selected Package Name</InputLabelWithIconWrapper>
                        <DialogTextField
                            value={queryParamsValues?.apps || ''}
                            onChange={(e: any) => {
                                setQueryParamsValues({ ...queryParamsValues, apps: e.target.value });
                            }}
                            label={'Android Package Name'}
                        />
                    </>
                )}
            </ParamsContainer>
        );
    };

    const renderQueryParams = () => {
        const values = service.methods.find((m) => m.key === selectedMethod)?.params.query || {};

        return Object.keys(values).map((key) => {
            const value = values[key];
            if (key === APPS_KEY) return renderAppsParams();
            const valueType = Array.isArray(value) ? 'array' : value;

            switch (valueType) {
                case 'string':
                    return (
                        <DialogTextField
                            label={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            toolTipText={_.get(AndroidTooltipText, key)}
                            value={queryParamsValues[key]}
                            onChange={(e: any) => setQueryParamsValues({ ...queryParamsValues, [key]: e.target.value })}
                            withTranslations
                        />
                    );
                case 'boolean':
                    return (
                        <DialogToggleButton
                            text={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            toggleCallback={() => {
                                setQueryParamsValues({ ...queryParamsValues, [key]: !queryParamsValues?.[key] });
                            }}
                            checked={!!queryParamsValues[key]}
                        />
                    );
                case 'array':
                    const options = value.map((val: any) => {
                        return {
                            value: val,
                            label: _.get(SortOptionsMap, val, _.capitalize(val.replace(/["_]/g, ' ').trim()))
                        };
                    });
                    return (
                        <DialogDropdownSingle
                            placeholder={key}
                            options={options}
                            value={options.find((val: any) => val.value === queryParamsValues?.[key] || '')}
                            onChange={(val: any) => {
                                setQueryParamsValues({ ...queryParamsValues, [key]: val.value });
                            }}
                        />
                    );
                default:
                    return;
            }
        });
    };
    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source: any) => source.value === selectedMethod) || ''}
                options={methodOptions}
                placeholder={'Select Method'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, ['method', 'queryParams']));
                    setSelectedMethod(value.value);
                    setQueryParamsValues({});
                    setPathParamsValues({});
                }}
                error={errors.method}
                dataCy={'android-method-source-field'}
            />
            {renderQueryParams()}
        </>
    );
};
