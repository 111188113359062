import React, { FC } from 'react';
import { LabeledColorPickerContainer } from './ColorPicker.css';
import CustomColorPicker from './CustomColorPicker';

interface ILabeledColorPicker {
    value: string;
    onChange: any;
    labelText: string;
}
const LabeledColorPicker: FC<ILabeledColorPicker> = ({ value, onChange, labelText }) => {
    return (
        <LabeledColorPickerContainer>
            <label>{labelText}</label>
            <CustomColorPicker onChange={onChange} value={value} />
        </LabeledColorPickerContainer>
    );
};

export default LabeledColorPicker;
