import React, { FC, useEffect, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import Sidebar from '../common/Sidebar/Sidebar';
import {
    FieldAndToggleContainer,
    GroupField,
    GroupFieldsContainer,
    GroupNameContainer,
    WarningLabel,
    WarningLabelsWrapper,
    WarningTooltip
} from './GroupEdit.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import ToggleViewSwitch from '../common/Switch/ToggleViewSwitch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { fetchTargetGroup, targetGroupsState, unsetSelectedTargetGroup } from '../../redux/slices/targetGroupsSlice';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import useScreenSize from '../../hooks/useScreenSize';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import usePrevious from '../../hooks/usePrevious';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { UiElements } from './UXElements/UXElements';
import { pagesState } from '../../redux/slices/pagesSlice';
import { menusState } from '../../redux/slices/menusSlice';
import { settingsState } from '../../redux/slices/settingsSlice';
import { audiencesState } from '../../redux/slices/audienceSlice';

const AUDIENCES = 'AUDIENCES';
const UX_CONTENT = 'UX_CONTENT';
const AB_TESTING_GROUPS = 'AB_TESTING_GROUPS';

type customLocationState = {
    section: string;
};

export const GroupEdit: FC<any> = () => {
    const { selectedPageLoading: pageLoading }: pagesState = useAppSelector((state) => state.pages);
    const { loading: menuLoading }: menusState = useAppSelector((state) => state.menus);
    const { loading: settingLoading }: settingsState = useAppSelector((state) => state.settings);
    const { loading: audiencesLoading }: audiencesState = useAppSelector((state) => state.audiences);
    const {
        selectedTargetGroup,
        error,
        loading: groupsLoading,
        loadingGroup
    }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { isMobile } = useScreenSize();
    const { group_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as customLocationState;

    const [groupName, setGroupName] = useState<string>('');
    const [view, setView] = useState<'GRID' | 'LIST'>('GRID');
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showCriticalWarning, setShowCriticalWarning] = useState<boolean>(false);
    const [activeField, setActiveField] = useState<string>(locationState?.section || UX_CONTENT);
    const prevActiveField = usePrevious(activeField);

    const loadTargetGroup = async () => {
        await dispatch(fetchTargetGroup({ targetGroupId: group_id! })).unwrap();
    };

    useEffect(() => {
        return () => {
            dispatch(unsetSelectedTargetGroup());
        };
    }, []);

    useEffect(() => {
        group_id && loadTargetGroup();
    }, [group_id]);

    useEffect(() => {
        if ((prevActiveField === AUDIENCES || prevActiveField === AB_TESTING_GROUPS) && activeField === UX_CONTENT) {
            loadTargetGroup();
        }
    }, [activeField]);

    useEffect(() => {
        if (error || !selectedTargetGroup) return;

        setGroupName(selectedTargetGroup.name);
    }, [selectedTargetGroup]);

    useEffect(() => {
        if (!selectedTargetGroup || !activeProjectId) return;

        // in case a user switches between projects, the selected target group should not be displayed
        if (selectedTargetGroup?.projectId !== activeProjectId) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUPS));
        }
    }, [selectedTargetGroup, activeProjectId]);

    const titleJSX = (
        <>
            <span style={{ cursor: 'pointer' }} onClick={() => navigate(buildPathWithProjectId(activeProjectId, PageRoutes.TARGET_GROUPS))}>
                Group
            </span>
            {!isMobile && groupName && (
                <GroupNameContainer>
                    <SVGInline src={icons.arrowRightIcon} />
                    <span>{groupName ?? '(title)'}</span>
                </GroupNameContainer>
            )}
        </>
    );

    const renderUxContent = () => {
        return (
            <UiElements
                view={view}
                warnings={{ warning: showWarning, criticalWarning: showCriticalWarning }}
                handleUniqueWarning={({ warning, criticalWarning }) => {
                    setShowWarning(warning || false);
                    setShowCriticalWarning(criticalWarning || false);
                }}
            />
        );
    };

    const renderAbTesting = () => {
        return <></>;
        // return <AbTestingGroups groupId={group_id} abTestingStatus={selectedTargetGroup?.abTestingStatus} />;
    };

    const tooltip = (isCritical: boolean) => (
        <div key={isCritical ? 'critical_warning_tooltip' : 'warning_tooltip'}>
            <WarningTooltip>{!isCritical ? 'Warning: ' : 'Critical Warning: '} </WarningTooltip>
            Saving will not be aborted
        </div>
    );

    const loading = pageLoading || menuLoading || settingLoading || loadingGroup || groupsLoading || audiencesLoading;

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper $loading={loading}>
                <Sidebar onProjectSelect={(projectId: string) => projectId} disabled={loading} />
                <MainContentWrapper>
                    <ScreenTitle
                        title={titleJSX}
                        withProfile
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.ux_content, onboarding: ONBOARDING_CIRCLE_SLUGS.ux_content }}
                    />

                    <FieldAndToggleContainer withWarning={activeField === UX_CONTENT && (showWarning || showCriticalWarning)}>
                        <GroupFieldsContainer>
                            <GroupField onClick={() => setActiveField(UX_CONTENT)} $active={activeField === UX_CONTENT}>
                                UI & UX Elements
                            </GroupField>

                            {/* <GroupField onClick={() => setActiveField(AB_TESTING_GROUPS)} $active={activeField === AB_TESTING_GROUPS}>
                                A/B Testing
                            </GroupField> */}
                        </GroupFieldsContainer>
                        {activeField === UX_CONTENT && (
                            <WarningLabelsWrapper>
                                {showWarning &&
                                    renderTooltip(
                                        <WarningLabel
                                            onClick={() => {
                                                document.getElementById('warning_label')?.click?.();
                                            }}
                                        >
                                            <SVGInline src={icons.infoIcon} />
                                            <span>Warning</span>
                                        </WarningLabel>,
                                        tooltipTypes.HTML,
                                        tooltip(false),
                                        tooltipPositions.BOTTOM
                                    )}

                                {showCriticalWarning &&
                                    renderTooltip(
                                        <WarningLabel
                                            critical
                                            onClick={() => {
                                                document.getElementById('critical_warning_label')?.click?.();
                                            }}
                                        >
                                            <SVGInline src={icons.warningIcon} />
                                            <span>Critical Warning</span>
                                        </WarningLabel>,
                                        tooltipTypes.HTML,
                                        tooltip(true),
                                        tooltipPositions.BOTTOM
                                    )}
                            </WarningLabelsWrapper>
                        )}
                        {activeField !== AB_TESTING_GROUPS && (
                            <ToggleViewSwitch
                                checked={view === 'GRID'}
                                toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                                tooltipTexts={{ list: 'target_groups_icon_switch_view_list', grid: 'target_groups_icon_switch_view_grid' }}
                            />
                        )}
                    </FieldAndToggleContainer>
                    {activeField === UX_CONTENT ? renderUxContent() : renderAbTesting()}
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
