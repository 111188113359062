import styled, { css } from 'styled-components';
import getTheme from '../../../style/themes/theme';
import { TextField, withTheme } from '@material-ui/core';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const SearchBarContainer = styled.div<{ $extraWidth?: boolean }>`
    background-color: rgba(0, 0, 0, 0.04);
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    padding: 24px;
    display: flex;
    gap: 16px;

    ${(props) =>
        props.$extraWidth &&
        css`
            ${devices.tablet} {
                flex-direction: column;
            }
        `}
`;

export const SearchInput = withTheme(styled(TextField)`
    width: 100%;
    background: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;

    box-sizing: border-box;

    .MuiInput-input {
        padding: 6px 16px;
        height: 24px;
        width: 100%;
    }

    .MuiInput-underline:after {
        content: none;
    }

    .MuiInput-underline:before {
        content: none;
    }
`);

export const SearchIcon = styled.span`
    position: absolute;
    top: 0;
    right: 12px;
    height: 100%;
    display: flex;
    align-items: center;
`;
