import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getHelperText } from '../../../utils/fnDialogs';
import { AddNewOption, SelectWrapper } from './Select.css';
import { CustomArrow, ISelectProps, LoadingAdornment } from './SelectSingle';
import { DROPDOWN_ADD_VALUE, DROPDOWN_GROUP_LABEL_VALUE } from '../../../utils/Globals';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { ShowMoreButton } from '../../../style/styled-components/reusable.css';
import useScreenSize from '../../../hooks/useScreenSize';
import _ from 'lodash';
import { DialogDropdownNewOption } from '../Dialog/GenericDialog';

const SelectMultiple: React.FC<ISelectProps> = (props) => {
    const { value } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [optionsToRender, setOptionsToRender] = useState<any[]>([]);

    const noLabel = props.noLabel === true;
    const labelValue = props.labelText || props.placeholder;

    // set initial options when component mounts
    useEffect(() => {
        setOptionsToRender(props.options);
    }, [props.options.length]);

    // sort selected items only after close
    useEffect(() => {
        !isMenuOpen && setOptionsToRender(sortMultiOptions(props.options || [], value, props.newOption, props.notSorted));
    }, [isMenuOpen, value]);

    const handleChange = (newValue: any[]) => {
        if (props.forceLowerCase) {
            newValue.forEach((value, index) => {
                if (props.forceLowerCase) {
                    value.value = value.value?.toLowerCase();
                }
                newValue.splice(index, 1, value);
            });
        }
        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    const handleBlur = (evt: any) => {
        evt.preventDefault();
        if (props.onBlur) {
            props.onBlur();
        }
    };

    // the newOption button will be the first option in the list so the indexes of the other options will be shifted by 1
    const unclickableIndexes = props.unclickableIndexes?.length
        ? props.newOption
            ? props.unclickableIndexes?.map((index) => index + 1)
            : props.unclickableIndexes
        : undefined;

    const _DropdownIndicator = useCallback((props) => {
        return props.withLoadingIndicator ? LoadingAdornment(props) : CustomArrow(props, isMenuOpen);
    }, []);

    return (
        <SelectWrapper
            id={`select_wrapper_${labelValue}`}
            className={'multiple'}
            $error={!!props.error}
            onBlur={handleBlur}
            tabIndex={1}
            unclickableIndexes={unclickableIndexes}
            $noError={!!props.noError}
        >
            {!noLabel && <label>{labelValue}</label>}
            <Select
                isDisabled={props.isDisabled || false}
                {...props}
                classNamePrefix="cc_select"
                isMulti
                closeMenuOnSelect={false}
                value={value}
                clearable={props.clearable || true}
                options={optionsToRender}
                menuPlacement={props.up ? 'top' : 'bottom'}
                onChange={(value: any) => {
                    if (!props.allowSelectAll && props.options.length === value?.length) {
                        return;
                    }
                    if (props.maxSelected && props.maxSelected === value?.length - 1) {
                        return;
                    }
                    handleChange(value);
                }}
                filterOption={({ label, data }, inputValue) => {
                    const value = inputValue.toLowerCase().trim();
                    if (data?.value === DROPDOWN_GROUP_LABEL_VALUE || data?.value === DROPDOWN_ADD_VALUE) return true;
                    if (!data?.valueForSearch) {
                        return typeof label === 'string' && label.toLowerCase().includes(value);
                    }
                    return data?.valueForSearch.toLowerCase().includes(value);
                }}
                maxMenuHeight={300}
                components={{
                    MultiValue,
                    MultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator: null,
                    IndicatorSeparator: null,
                    DropdownIndicator: _DropdownIndicator,
                    Option: (componentProps) => Option(componentProps, props.newOption)
                }}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                hideSelectedOptions={false}
                openMenuOnClick={props.openOnClick !== false}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
            />
            <label className="error">{getHelperText(props.error, props?.optional)}</label>
        </SelectWrapper>
    );
};

const MultiValue = ({ index, ...props }: any) => {
    const { isSmallMobile, isDesktop } = useScreenSize();

    const elementsToShow = isSmallMobile || isDesktop ? 2 : 1;
    const maxToShow = props?.selectProps?.maxLength ?? elementsToShow;

    return index < maxToShow ? (
        <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : index === maxToShow ? (
        <ShowMoreButton>{`...`}</ShowMoreButton>
    ) : null;
};

const MultiValueLabel = (props: any) => {
    return <components.MultiValueLabel {...props} innerProps={{ className: 'custom-value-label' }}></components.MultiValueLabel>;
};

const MultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <SVGInline src={icons.closeIcon} />
        </components.MultiValueRemove>
    );
};

const Option = (props: any, newOption?: DialogDropdownNewOption) => {
    const selectValue = props.selectProps?.value || [];
    const optionValue = props.value;
    let isFirstUnchecked = false;

    const isChecked = (value: any) => selectValue.map((item: { label: string; value: string }) => item.value).includes(value);
    const checked = isChecked(optionValue);

    if (!checked) {
        props.options.forEach((item: any, index: number) => {
            if (item.value === optionValue && isChecked(props.options[index - 1]?.value)) {
                isFirstUnchecked = true;
            }
        });
    }

    const innerProps = { ...props.innerProps };
    if (isFirstUnchecked) {
        innerProps.className = 'cc_select__option option-with-border';
    }

    return (
        <components.Option {...props} innerProps={innerProps}>
            {props.value === DROPDOWN_ADD_VALUE ? (
                <AddNewOption
                    onClick={(e) => {
                        newOption?.onClick();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <SVGInline src={icons.addIcon} /> {props.label}
                </AddNewOption>
            ) : (
                <>
                    {props.label}
                    {checked && <SVGInline src={icons.selectCheckIcon} />}
                </>
            )}
        </components.Option>
    );
};

const sortMultiOptions = (options: any[], selected: string[], newOption?: DialogDropdownNewOption, notSorted?: boolean) => {
    const _options = [...options];
    let sortedOptions: any[] = [];

    options.forEach((item: any, index) => {
        if ((index !== 0 && item.value === DROPDOWN_GROUP_LABEL_VALUE) || index === options.length - 1) {
            const selectedValues = (selected || []).map((item: any) => item.value);
            const deleteCount = index === options.length - 1 ? index + 1 : index;
            let remaining = _options.splice(0, deleteCount);

            remaining = remaining.map((option: any) => {
                const newOption = { ...option };
                newOption.selected =
                    selectedValues.includes(option.value) ||
                    option.value === DROPDOWN_GROUP_LABEL_VALUE ||
                    option.value === DROPDOWN_ADD_VALUE;
                return newOption;
            });

            if (!notSorted) {
                remaining = remaining.sort((a: any, b: any) => {
                    // group label should always be first
                    if (a.value === DROPDOWN_GROUP_LABEL_VALUE) return -1;
                    if (b.value === DROPDOWN_GROUP_LABEL_VALUE) return 1;

                    let nameA = typeof a.label === 'string' ? a.label : a.valueForSearch || a.value || '';
                    let nameB = typeof b.label === 'string' ? b.label : b.valueForSearch || b.value || '';

                    nameA = nameA.toLowerCase();
                    nameB = nameB.toLowerCase();

                    if (!isNaN(nameA)) {
                        nameA = parseInt(nameA);
                    }
                    if (!isNaN(nameB)) {
                        nameB = parseInt(nameB);
                    }
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            }

            remaining = remaining.sort((a: any, b: any) => b.selected - a.selected).map((option: any) => _.omit(option, 'selected'));

            sortedOptions = [...sortedOptions, ...remaining];
        }
    });

    if (newOption) {
        sortedOptions = [{ value: DROPDOWN_ADD_VALUE, label: `Create ${_.startCase(newOption.name)}`, isDisabled: true }, ...sortedOptions];
    }

    return sortedOptions;
};

export default SelectMultiple;
