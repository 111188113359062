import { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { deleteFile, fetchFiles, FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import icons from '../../../style';
import { ApplicationWrapper, MainContentWrapper } from '../../../style/styled-components/reusable.css';
import { imgixFMBaseUrl } from '../../../utils/Globals';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { DialogFileField } from '../../common/Dialog/GenericDialog';
import { Loader } from '../../common/Loader/Loader';
import { ImageFieldContainer, ImageFields, ImageHeader } from '../../StyleAndBranding/PageStyle/PageStyle.css';
import Sidebar from '../Sidebar/Sidebar';

export const LOGIN_BACKGROUND_PREFIX = 'login_background';
export const LOGIN_BACKGROUND_FULL_NAME = `${LOGIN_BACKGROUND_PREFIX}/login.gif`;

export const LOGIN_BACKGROUND_URL = `${imgixFMBaseUrl}${LOGIN_BACKGROUND_FULL_NAME}`;

export const Branding: FC = () => {
    const { files, loading, error }: FilesState = useAppSelector((state) => state.files);
    const [loginImagePath, setLoginImagePath] = useState<string>('');
    const [imageUrlStamp, setImageUrlStamp] = useState(Date.now);
    const dispatch = useDispatch();

    const loadFiles = async () => {
        return await dispatch(fetchFiles({ prefix: LOGIN_BACKGROUND_PREFIX })).unwrap();
    };

    const removeFile = async (originalPath: string) => {
        const deletedFile = await dispatch(deleteFile(originalPath)).unwrap();
        if (deletedFile) {
            setLoginImagePath('');
            loadFiles();
        }
    };

    useEffect(() => {
        loadFiles();
    }, []);

    useEffect(() => {
        if (!files?.length) return;

        setLoginImagePath(files[0].originalPath || '');
    }, [files]);

    const createFile = async (file: File) => {
        const name = `login.gif`;
        const newFile = new File([file], name, { type: file.type });

        try {
            const response = await dispatch(
                uploadFilesSync({ files: [newFile], prefix: LOGIN_BACKGROUND_PREFIX, overwrite: true })
            ).unwrap();
            loadFiles();
            setImageUrlStamp(Date.now());
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const renderLoginBackgroundSection = () => {
        return (
            <ImageFieldContainer>
                <ImageHeader>Login Screen Background</ImageHeader>
                <ImageFields>
                    <DialogFileField
                        imageInfo={{ previewImage: true, height: 108, width: 188, um: 'px' }}
                        customAddIcon={icons.addIconWhite}
                        preview={loginImagePath ? `${LOGIN_BACKGROUND_URL}?timestamp=${imageUrlStamp}` : undefined}
                        fieldKey={'Login Background'}
                        customLabel={'Login Background' + (!!loginImagePath ? '' : ' (Currently default image is displayed)')}
                        localFileCallBack={(file) => {
                            if (!file) {
                                loginImagePath && removeFile(loginImagePath);
                            } else {
                                createFile(file);
                            }
                        }}
                        localFileOnly
                        alwaysShowLabel
                        withoutPreview
                        accepts={['.jpg', '.png', '.gif']}
                    />
                </ImageFields>
            </ImageFieldContainer>
        );
    };

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle loading={loading} title="CC Branding" withProfile withoutSearch />
                    {loading ? <Loader /> : renderLoginBackgroundSection()}
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};
