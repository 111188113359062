import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import GenericDialog, { DialogButton, DialogDropdownSingle, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import {
    Audience,
    audienceFilterKeys,
    AudienceVersionInterval,
    AudienceVersionTypes,
    deviceTypeValuesForClasses,
    predefinedAudienceValues
} from '../../../types/Audience';
import { FiltersAndSortersContainer, TemplatesContainer } from '../../Pages/Pages.css';
import { DeviceClassCard, DeviceTypeCard, FileInputCard, OSTypeCard, RangeInputCard, SingleInputCard } from './HeaderValueComponents';
import {
    AudienceNameWrapper,
    FilterRow,
    HeaderValueCardsContainer,
    KeyFilterDropdown,
    WarningNoteWrapper
} from './HeaderValueComponents.css';
import { validateVersionFormat } from '../../../utils/fnString';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { audienceValidationErrorParser } from '../../../utils/parsers';
import { DIALOG_NAMES, dialogAlert } from '../../../utils/fnDialogs';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../hooks/redux';
import { audiencesIcons } from '../../../assets/images/icons/audiencesIcons';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

const warningAudienceMessage = 'At least one item from device classes or device types must be selected to continue!';

const filterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Device Class', value: 'x-device-class' },
    { label: 'Device Type', value: 'x-device-type' },
    { label: 'Operating System', value: 'x-operating-system' },
    { label: 'Operating System Version', value: 'x-operating-system-version' },
    { label: 'Client App Version', value: 'x-client-app-version' },
    { label: 'Device IDs', value: 'x-device-id' },
    { label: 'User IDs', value: 'x-user-id' },
    { label: 'Cable Operator', value: 'x-cable-operator' },
    { label: 'Location', value: 'x-location' },
    { label: 'Manufacturer', value: 'x-manufacturer' },
    { label: 'Client App Variants', value: 'x-client-app-variant' }
];

const sorterOptions = [
    { label: 'Default', value: 1 },
    { label: 'Alphabetically', value: 2 }
];

export type NewAudienceProps = {
    open: boolean;
    audience?: Audience;
    duplicate?: boolean;
    noValidation?: boolean;
    withCreateNewOption?: boolean;
    groupId?: string;
    onSave: (arg: Audience) => void;
    onClose?: () => void;
};

const NewAudience: FC<NewAudienceProps> = ({ open, audience, duplicate, onSave, onClose, noValidation, withCreateNewOption, groupId }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    const [filterKey, setFilterKey] = useState<{ label: string; value: string }>({ label: 'All', value: 'all' });
    const [sorterKey, setSorterKey] = useState<{ label: string; value: number }>(sorterOptions[0]);

    const [name, setName] = useState<string>('');

    //Header Values
    const [deviceType, setDeviceType] = useState('');
    const [deviceClass, setDeviceClass] = useState('');
    const [osType, setOsType] = useState('');
    const [filteredDeviceTypes, setFilteredDeviceTypes] = useState(predefinedAudienceValues.deviceType);

    //OSVersion
    const [osVersion, setOsVersion] = useState('');
    const [osSecondVersion, setOsSecondVersion] = useState('');
    const [osRange, setOsRange] = useState<AudienceVersionTypes | null>(null);
    const [osVersionInputType, setOsVersionInputType] = useState<'range' | 'single' | null>(null);

    //Client app version
    const [clientRange, setClientRange] = useState<AudienceVersionTypes | null>(null);
    const [clientVersion, setClientVersion] = useState('');
    const [clientSecondVersion, setClientSecondVersion] = useState('');
    const [clientVersionInputType, setClientVersionInputType] = useState<'range' | 'single' | null>(null);

    //Location
    const [location, setLocation] = useState('');
    const [locationCheck, setLocationCheck] = useState(false);

    //Cable operator
    const [cableOperator, setCableOperator] = useState('');
    const [operatorCheck, setOperatorCheck] = useState(false);

    //Manufacturer
    const [manufacturer, setManufacturer] = useState('');
    const [manufacturerCheck, setManufacturerCheck] = useState(false);

    //DeviceIds
    const [deviceIds, setDeviceIds] = useState('');
    const [deviceIdsFile, setDeviceIdFile] = useState<File | null>(null);
    const [deviceIdsInputType, setDeviceIdsInputType] = useState<'single' | 'file' | null>(null);

    //UserIds
    const [userIds, setUserIds] = useState('');
    const [userIdsFile, setUserIdsFile] = useState<File | null>(null);
    const [userIdsInputType, setUserIdsInputType] = useState<'single' | 'file' | null>(null);

    //ClientAppVariants
    const [clientAppVariants, setClientAppVariants] = useState('');
    const [clientAppVariantsFile, setClientAppVariantsFile] = useState<File | null>(null);
    const [clientAppVariantsInputType, setClientAppVariantsInputType] = useState<'single' | 'file' | null>(null);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state: any) => state.activeItem);

    useEffect(() => {
        setIsOpen(open);
        if (!open) return;
        setDeviceClass(audience?.deviceClass || '');

        if (audience?.deviceClass) {
            setFilteredDeviceTypes(deviceTypeValuesForClasses[audience.deviceClass]);
        }

        setDeviceType(audience?.deviceType || '');
        setOsType(audience?.operatingSystem || '');

        if (audience?.operatingSystemVersion) {
            if (typeof audience?.operatingSystemVersion !== 'string') {
                const opsysVersion = audience.operatingSystemVersion as AudienceVersionInterval;
                setOsRange(opsysVersion?.type);
                setOsVersion(opsysVersion.firstVersion);
                setOsSecondVersion(opsysVersion?.secondVersion || '');
                setOsVersionInputType('range');
            } else {
                setOsVersion(audience.operatingSystemVersion as string);
                audience.operatingSystemVersion && setOsVersionInputType('single');
            }
        } else {
            setOsRange(null);
            setOsVersion('');
            setOsSecondVersion('');
            setOsVersionInputType(null);
        }

        if (audience?.clientAppVersion) {
            if (typeof audience.clientAppVersion !== 'string') {
                const clientAppVersion = audience.clientAppVersion as AudienceVersionInterval;
                setClientRange(clientAppVersion?.type);
                setClientVersion(clientAppVersion.firstVersion);
                setClientSecondVersion(clientAppVersion?.secondVersion || '');
                setClientVersionInputType('range');
            } else {
                setClientVersion(audience.clientAppVersion as string);
                audience.clientAppVersion && setClientVersionInputType('single');
            }
        } else {
            setClientRange(null);
            setClientVersion('');
            setClientSecondVersion('');
            setClientVersionInputType(null);
        }

        setLocation(audience?.location || '');
        setLocationCheck(!!audience?.location);
        setCableOperator(audience?.cableOperator || '');
        setOperatorCheck(!!audience?.cableOperator);
        setManufacturer(audience?.manufacturer || '');
        setManufacturerCheck(!!audience?.manufacturer);
        setUserIdsInputType(audience?.userIds ? 'single' : null);
        setUserIds(audience?.userIds ? audience.userIds.join(',') : '');
        setDeviceIdsInputType(audience?.deviceIds ? 'single' : null);
        setDeviceIds(audience?.deviceIds ? audience.deviceIds.join(',') : '');
        setClientAppVariantsInputType(audience?.clientAppVariants ? 'single' : null);
        setClientAppVariants(audience?.clientAppVariants ? audience.clientAppVariants.join(',') : '');
    }, [audience, open]);

    const validateAudience = async (values: any, projectId: string, audienceId?: string) => {
        if (audienceId) {
            values._id = audienceId;
        }
        const response = await configServiceAPI.validateAudience(values, projectId);
        if (response.error) {
            const result = audienceValidationErrorParser(response.error);
            if (result.isSimilar) {
                dialogAlert(DIALOG_NAMES.SIMILAR_AUDIENCE);
                return false;
            }
            setErrors(result.errors);
            return false;
        }
        return true;
    };

    const handleSaveClick = async () => {
        const deviceIdsValue = deviceIdsInputType === 'file' && deviceIdsFile ? await readCsvFile(deviceIdsFile) : deviceIds;
        const userIdsValue = userIdsInputType === 'file' && userIdsFile ? await readCsvFile(userIdsFile) : userIds;
        const clientAppVariantsValue =
            clientAppVariantsInputType === 'file' && clientAppVariantsFile ? await readCsvFile(clientAppVariantsFile) : clientAppVariants;

        const osVersionValue = osRange ? getVersionIntervalObject(osRange, osVersion, osSecondVersion) : osVersion;
        const clientVersionValue = clientRange ? getVersionIntervalObject(clientRange, clientVersion, clientSecondVersion) : clientVersion;

        const newAudience: Audience = {
            _id: audience?._id || '',
            name: audience?.name || name,
            lastModified: audience?.lastModified,
            projectId: audience?.projectId || activeProjectId || '',
            tenantId: audience?.tenantId || activeTenantId || '',
            targetGroupId: audience?.targetGroupId || groupId || ''
        };
        // Adding keys only if they are set up
        if (!!deviceClass) {
            newAudience.deviceClass = deviceClass;
        }
        if (!!deviceType) {
            newAudience.deviceType = deviceType;
        }
        if (!!deviceIdsInputType) {
            newAudience.deviceIds = deviceIdsValue.split(',').filter((val) => !!val);
        }
        if (!!userIdsInputType) {
            newAudience.userIds = userIdsValue.split(',').filter((val) => !!val);
        }
        if (!!clientAppVariantsInputType) {
            newAudience.clientAppVariants = clientAppVariantsValue.split(',').filter((val) => !!val);
        }
        if (osType) {
            newAudience.operatingSystem = osType;
        }
        if (!!osVersionInputType) {
            newAudience.operatingSystemVersion = osVersionValue;
        }
        if (!!clientVersionInputType) {
            newAudience.clientAppVersion = clientVersionValue;
        }
        if (locationCheck) {
            newAudience.location = location;
        }
        if (operatorCheck) {
            newAudience.cableOperator = cableOperator;
        }
        if (manufacturerCheck) {
            newAudience.manufacturer = manufacturer;
        }

        if (!noValidation && !(await validateAudience(_.pick(newAudience, audienceFilterKeys), newAudience.projectId, newAudience._id)))
            return;

        onSave(newAudience);
        handleCloseClick();
    };
    const handleCloseClick = () => {
        setDeviceClass('');
        setDeviceType('');
        setOsType('');
        setOsRange(null);
        setOsVersionInputType(null);
        setOsVersion('');
        setOsSecondVersion('');
        setClientRange(null);
        setClientVersionInputType(null);
        setDeviceIds('');
        setDeviceIdsInputType(null);
        setDeviceIdFile(null);
        setUserIds('');
        setUserIdsInputType(null);
        setUserIdsFile(null);
        setClientAppVariants('');
        setClientAppVariantsInputType(null);
        setClientAppVariantsFile(null);
        setLocation('');
        setLocationCheck(false);
        setCableOperator('');
        setOperatorCheck(false);
        setManufacturer('');
        setManufacturerCheck(false);
        setErrors({});
        setFilterKey({ label: 'All', value: 'all' });
        setFilteredDeviceTypes(predefinedAudienceValues.deviceType);
        setName('');
        onClose?.();
    };

    const readCsvFile = (file: File) => {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = (evt) => {
                const idsString: string = (evt.target?.result as string).replaceAll('\r\n', ',') || '';
                resolve(idsString);
            };
            reader.readAsText(file);
        });
    };

    const getVersionIntervalObject = (type: AudienceVersionTypes, first: string, second?: string) => {
        const intervalObject: AudienceVersionInterval = {
            type: type,
            firstVersion: first
        };
        if (second) intervalObject.secondVersion = second;
        return intervalObject;
    };

    const saveButton: DialogButton = {
        label: !audience ? 'Create' : 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        disabled: !deviceClass && !deviceType
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const renderHeaderFilters = () => {
        return (
            <FilterRow>
                <KeyFilterDropdown>
                    <DialogDropdownSingle
                        onChange={(newFilterKey: any) => setFilterKey(newFilterKey)}
                        placeholder={'Filter by key'}
                        options={filterOptions}
                        value={filterKey}
                    />
                </KeyFilterDropdown>
            </FilterRow>
        );
    };

    const renderHeaderSorters = () => {
        return (
            <FilterRow>
                <KeyFilterDropdown>
                    <DialogDropdownSingle
                        onChange={(newSorter: any) => setSorterKey(newSorter)}
                        placeholder={'Sort by'}
                        options={sorterOptions}
                        value={sorterKey}
                    />
                </KeyFilterDropdown>
            </FilterRow>
        );
    };

    const renderHeaderName = () => {
        return (
            <FilterRow>
                <AudienceNameWrapper>
                    <DialogTextField
                        value={name}
                        onChange={(e: any) => {
                            setName(e.target.value);
                        }}
                        placeholder={'Audience Title'}
                        label={'Audience Title'}
                    />
                </AudienceNameWrapper>
            </FilterRow>
        );
    };

    const renderDeviceClassCards = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-device-class') return;
        return predefinedAudienceValues.deviceClass.map((dClass, index) => (
            <DeviceClassCard
                key={index}
                deviceClass={dClass}
                onCheck={() => {
                    const newValues = predefinedAudienceValues.deviceType.filter((val: any) => {
                        if (!deviceTypeValuesForClasses[dClass].includes(val)) return;
                        return val;
                    });
                    setFilteredDeviceTypes(() => {
                        return deviceClass === dClass ? predefinedAudienceValues.deviceType : newValues;
                    });
                    setDeviceType(deviceClass === dClass ? deviceType : '');
                    setDeviceClass(deviceClass === dClass ? '' : dClass);
                }}
                checked={deviceClass === dClass}
            />
        ));
    };

    const renderDeviceTypeCards = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-device-type') return;
        return filteredDeviceTypes?.map((type, index) => (
            <DeviceTypeCard
                key={index}
                deviceType={type}
                onCheck={() => setDeviceType(deviceType === type ? '' : type)}
                checked={deviceType === type}
            />
        ));
    };

    const renderOSTypeCards = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-operating-system') return;
        return predefinedAudienceValues.operatingSystem.map((type, index) => (
            <OSTypeCard key={index} osType={type} onCheck={() => setOsType(osType === type ? '' : type)} checked={osType === type} />
        ));
    };

    const renderOsVersionCards = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-operating-system-version') return;
        const subtitle = 'x-operating-system-version';
        return (
            <>
                <RangeInputCard
                    inputValue={osVersion}
                    secondInputValue={osSecondVersion}
                    range={osRange}
                    onInputChange={(newVersion) => {
                        setOsVersion(newVersion);
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    onSecondInputChange={(newVersion) => {
                        setOsSecondVersion(newVersion);
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    onRangeChange={(newRange) => {
                        setOsRange(newRange);
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    checked={osVersionInputType === 'range'}
                    onCheck={() => {
                        setOsRange(null);
                        setOsVersion('');
                        setOsVersionInputType(osVersionInputType === 'range' ? null : 'range');
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    subtitle={osVersionInputType === 'range' ? errors?.operatingSystemVersion || subtitle : subtitle}
                    error={osVersionInputType === 'range' && errors?.operatingSystemVersion}
                    selectUp={filterKey?.value === filterOptions[0].value && sorterKey?.value !== sorterOptions[1].value}
                    customIcon={audiencesIcons.operatingSystemVersion2}
                />
                <SingleInputCard
                    inputValue={osVersion}
                    onChange={(newVersion) => {
                        if (newVersion.slice(-1) === '.' && newVersion.slice(-2)[0] !== '.') setOsVersion(newVersion);
                        else if (validateVersionFormat(newVersion)) setOsVersion(newVersion);
                        else if (!newVersion) setOsVersion(newVersion);
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    onCheck={() => {
                        setOsRange(null);
                        setOsVersion('');
                        setOsVersionInputType(osVersionInputType === 'single' ? null : 'single');
                        setErrors(_.omit(errors, 'operatingSystemVersion'));
                    }}
                    checked={osVersionInputType === 'single'}
                    subtitle={osVersionInputType !== 'range' ? errors?.operatingSystemVersion || subtitle : subtitle}
                    error={osVersionInputType !== 'range' && errors?.operatingSystemVersion}
                    customIcon={audiencesIcons.operatingSystemVersion}
                />
            </>
        );
    };

    const renderAppVersionCards = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-client-app-version') return;
        const subtitle = 'x-client-app-version';
        return (
            <>
                <RangeInputCard
                    inputValue={clientVersion}
                    secondInputValue={clientSecondVersion}
                    range={clientRange}
                    onInputChange={(newVersion) => {
                        setClientVersion(newVersion);
                        setErrors(_.omit(errors, 'clientAppVersion'));
                    }}
                    onSecondInputChange={(newVersion) => {
                        setClientSecondVersion(newVersion);
                        setErrors(_.omit(errors, 'clientAppVersion'));
                    }}
                    onRangeChange={(newRange) => {
                        setClientRange(newRange);
                        setErrors(_.omit(errors, 'clientAppVersion'));
                    }}
                    checked={clientVersionInputType === 'range'}
                    onCheck={() => {
                        setClientRange(null);
                        setClientVersion('');
                        setClientVersionInputType(clientVersionInputType === 'range' ? null : 'range');
                        setErrors(_.omit(errors, 'clientAppVersion'));
                    }}
                    subtitle={clientVersionInputType === 'range' ? errors?.clientAppVersion || subtitle : subtitle}
                    error={clientVersionInputType === 'range' && errors?.clientAppVersion}
                    selectUp={filterKey?.value === filterOptions[0].value && sorterKey?.value !== sorterOptions[1].value}
                    customIcon={audiencesIcons.clientAppVersion2}
                />
                <SingleInputCard
                    inputValue={clientVersion}
                    onChange={(newVersion) => {
                        if (newVersion.slice(-1) === '.' && newVersion.slice(-2)[0] !== '.') setClientVersion(newVersion);
                        else if (validateVersionFormat(newVersion)) setClientVersion(newVersion);
                        else if (!newVersion) setOsVersion(newVersion);
                        setErrors(_.omit(errors, 'clientAppVersion'));
                    }}
                    onCheck={() => {
                        setClientVersionInputType(clientVersionInputType === 'single' ? null : 'single');
                        setClientVersion('');
                        setErrors(_.omit(errors, 'clientAppVersion'));
                        setClientRange(null);
                    }}
                    checked={clientVersionInputType === 'single'}
                    subtitle={clientVersionInputType !== 'range' ? errors?.clientAppVersion || subtitle : subtitle}
                    error={clientVersionInputType !== 'range' && errors?.clientAppVersion}
                    customIcon={audiencesIcons.clientAppVersion}
                />
            </>
        );
    };

    const renderLocationCard = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-location') return;
        return (
            <SingleInputCard
                inputValue={location}
                onChange={(newLocation) => {
                    setLocation(newLocation);
                    setErrors(_.omit(errors, 'location'));
                }}
                onCheck={() => {
                    setLocation('');
                    setLocationCheck(!locationCheck);
                }}
                checked={locationCheck}
                subtitle={errors?.location || 'x-location'}
                error={errors?.location}
                customIcon={audiencesIcons.location}
            />
        );
    };
    const renderCableOperatorCard = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-cable-operator') return;
        return (
            <SingleInputCard
                inputValue={cableOperator}
                onChange={(newOp) => {
                    setCableOperator(newOp);
                    setErrors(_.omit(errors, 'cableOperator'));
                }}
                onCheck={() => {
                    setCableOperator('');
                    setOperatorCheck(!operatorCheck);
                }}
                checked={operatorCheck}
                error={errors?.cableOperator}
                subtitle={errors?.cableOperator || 'x-cable-operator'}
                customIcon={audiencesIcons.cableOperator}
            />
        );
    };

    const renderManufacturerCard = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-manufacturer') return;
        return (
            <SingleInputCard
                inputValue={manufacturer}
                onChange={(newManufacturer) => {
                    setManufacturer(newManufacturer);
                    setErrors(_.omit(errors, 'manufacturer'));
                }}
                onCheck={() => {
                    setManufacturer('');
                    setManufacturerCheck(!manufacturerCheck);
                }}
                checked={manufacturerCheck}
                error={errors?.manufacturer}
                subtitle={errors?.manufacturer || 'x-manufacturer'}
                customIcon={audiencesIcons.manufacturer}
            />
        );
    };

    const renderDeviceIds = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-device-id') return;
        const infoText = 'Values will be split by commas!';
        return (
            <>
                <SingleInputCard
                    inputValue={deviceIds}
                    onChange={(newDeviceIds) => {
                        setDeviceIds(newDeviceIds);
                        setErrors(_.omit(errors, 'deviceIds'));
                    }}
                    onCheck={() => {
                        setDeviceIds('');
                        setDeviceIdFile(null);
                        setDeviceIdsInputType(deviceIdsInputType === 'single' ? null : 'single');

                        setErrors(_.omit(errors, 'deviceIds'));
                    }}
                    checked={deviceIdsInputType === 'single'}
                    subtitle={deviceIdsInputType !== 'file' ? errors?.deviceIds || 'x-device-id' : 'x-device-id'}
                    error={deviceIdsInputType !== 'file' && errors?.deviceIds}
                    infoText={infoText}
                    customIcon={audiencesIcons.deviceIds}
                />
                <FileInputCard
                    onCheck={() => {
                        setDeviceIdFile(null);
                        setDeviceIds('');
                        setDeviceIdsInputType(deviceIdsInputType === 'file' ? null : 'file');

                        setErrors(_.omit(errors, 'deviceIds'));
                    }}
                    checked={deviceIdsInputType === 'file'}
                    subtitle={deviceIdsInputType === 'file' ? errors?.deviceIds || 'x-device-id' : 'x-device-id'}
                    error={deviceIdsInputType === 'file' && errors?.deviceIds}
                    onFileChange={(newFile) => {
                        setDeviceIdFile(newFile);
                        setErrors(_.omit(errors, 'deviceIds'));
                    }}
                    customIcon={audiencesIcons.deviceIds2}
                />
            </>
        );
    };

    const renderUserIds = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-user-id') return;
        const infoText = 'Values will be split by commas!';
        return (
            <>
                <SingleInputCard
                    inputValue={userIds}
                    onChange={(newUserIds) => {
                        setUserIds(newUserIds);
                        setErrors(_.omit(errors, 'userIds'));
                    }}
                    onCheck={() => {
                        setUserIds('');
                        setUserIdsFile(null);
                        setUserIdsInputType(userIdsInputType === 'single' ? null : 'single');
                        setErrors(_.omit(errors, 'userIds'));
                    }}
                    checked={userIdsInputType === 'single'}
                    subtitle={userIdsInputType !== 'file' ? errors?.userIds || 'x-user-id' : 'x-user-id'}
                    error={userIdsInputType !== 'file' && errors?.userIds}
                    infoText={infoText}
                    customIcon={audiencesIcons.userIds}
                />
                <FileInputCard
                    onCheck={() => {
                        setUserIdsFile(null);
                        setUserIds('');
                        setUserIdsInputType(userIdsInputType === 'file' ? null : 'file');
                        setErrors(_.omit(errors, 'userIds'));
                    }}
                    checked={userIdsInputType === 'file'}
                    subtitle={userIdsInputType === 'file' ? errors?.userIds || 'x-user-id' : 'x-user-id'}
                    error={userIdsInputType === 'file' && errors?.userIds}
                    customIcon={audiencesIcons.userIds2}
                    onFileChange={(newFile) => {
                        setUserIdsFile(newFile);
                        setErrors(_.omit(errors, 'userIds'));
                    }}
                />
            </>
        );
    };

    const renderClientAppVariants = () => {
        if (filterKey?.value !== 'all' && filterKey?.value !== 'x-client-app-variant') return;
        const infoText = 'Values will be split by commas!';
        return (
            <>
                <SingleInputCard
                    inputValue={clientAppVariants}
                    onChange={(newClientAppVariants) => {
                        setClientAppVariants(newClientAppVariants);
                        setErrors(_.omit(errors, 'clientAppVariants'));
                    }}
                    onCheck={() => {
                        setClientAppVariants('');
                        setClientAppVariantsFile(null);
                        setClientAppVariantsInputType(clientAppVariantsInputType === 'single' ? null : 'single');
                        setErrors(_.omit(errors, 'clientAppVariants'));
                    }}
                    checked={clientAppVariantsInputType === 'single'}
                    subtitle={
                        clientAppVariantsInputType !== 'file' ? errors?.clientAppVariants || 'x-client-app-variant' : 'x-client-app-variant'
                    }
                    error={clientAppVariantsInputType !== 'file' && errors?.clientAppVariants}
                    infoText={infoText}
                    customIcon={audiencesIcons.clientAppVariants}
                />
                <FileInputCard
                    onCheck={() => {
                        setClientAppVariants('');
                        setClientAppVariantsFile(null);
                        setClientAppVariantsInputType(clientAppVariantsInputType === 'file' ? null : 'file');
                        setErrors(_.omit(errors, 'clientAppVariants'));
                    }}
                    checked={clientAppVariantsInputType === 'file'}
                    subtitle={
                        clientAppVariantsInputType === 'file' ? errors?.clientAppVariants || 'x-client-app-variant' : 'x-client-app-variant'
                    }
                    error={clientAppVariantsInputType === 'file' && errors?.clientAppVariants}
                    onFileChange={(newFile) => {
                        setClientAppVariantsFile(newFile);
                        setErrors(_.omit(errors, 'clientAppVariants'));
                    }}
                    customIcon={audiencesIcons.clientAppVariants}
                />
            </>
        );
    };

    if (!isOpen) return null;

    const renderFunctions: any = {
        deviceClass: renderDeviceClassCards,
        deviceType: renderDeviceTypeCards,
        operatingSystem: renderOSTypeCards,
        operatingSystemVersion: renderOsVersionCards,
        clientAppVersion: renderAppVersionCards,
        location: renderLocationCard,
        cableOperator: renderCableOperatorCard,
        manufacturer: renderManufacturerCard,
        deviceId: renderDeviceIds,
        userId: renderUserIds,
        clientAppVariant: renderClientAppVariants
    };

    return (
        <GenericDialog
            type={DialogTypes.Audience}
            title={audience ? (duplicate ? 'Duplicate Audience' : 'Edit Audience') : 'Add Audience'}
            onClose={() => handleCloseClick()}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.audiences }}
        >
            <TemplatesContainer>
                <FiltersAndSortersContainer>
                    {withCreateNewOption ? renderHeaderName() : renderHeaderFilters()}
                    {renderHeaderSorters()}
                </FiltersAndSortersContainer>
                <HeaderValueCardsContainer>
                    {sorterKey?.value === sorterOptions[1].value
                        ? Object.keys(renderFunctions)
                              .sort()
                              .map((key) => renderFunctions[key]?.())
                        : Object.values(renderFunctions).map((f: any) => f())}
                </HeaderValueCardsContainer>
            </TemplatesContainer>
            {!deviceType && !deviceClass && <WarningNoteWrapper>Note: {warningAudienceMessage}</WarningNoteWrapper>}
        </GenericDialog>
    );
};

export default NewAudience;
