import styled from 'styled-components';
import { devices } from '../../style/styled-components/reusable.css';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { DateContainer } from '../common/DatePicker/DatePicker.css';
import { SelectWrapper } from '../common/Select/Select.css';

const theme: any = getTheme();

export const IngishtsWidgetsContainer = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
`;

export const InsightsWidget = styled.div`
    background: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;
    height: 184px;
    box-sizing: border-box;
    flex-grow: 1;
    width: 30%;

    div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${devices.desktopS} {
        width: 40%;
    }

    ${devices.tablet} {
        width: 100%;
    }
`;

export const MetricTitle = styled.div`
    margin: 24px 36px;
    ${fontSizesCSS.Title3};
    text-align: center;
`;

export const MetricValue = styled.div`
    margin: 0 36px;
    ${fontSizesCSS.Title1};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
`;

export const MetricLink = styled.div`
    margin: 16px 36px 0 36px;
    a {
        ${fontSizesCSS.Caption3};
        float: right;
        color: ${theme.palette.primary.main};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const InsightsDatesContainer = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    ${devices.tablet} {
        justify-content: flex-start;
        gap: 36px;
    }
    ${devices.mobileL} {
        flex-direction: column;
        gap: 9px;
    }
`;

export const Delimiter = styled.span``;
export const DatePickerWrapper = styled.div`
    width: 653px;
    display: flex;
    gap: 8px;
    align-items: center;
    ${devices.mobileL} {
        flex-direction: column;
        gap: 4px;
    }
    ${DateContainer} {
        flex-grow: 1;
        div.MuiTextField-root {
            margin: 0;
            min-height: auto;
            label {
                color: ${theme.palette.black.main};
                ${fontSizesCSS.Caption2};
            }
            div.MuiInput-root {
                input {
                    cursor: pointer;
                }
            }
        }
        &:not(:first-child) {
            div.MuiTextField-root {
                label {
                    display: none;
                }
            }
        }
    }

    div.react-datepicker-popper {
        // these props are marked with important because the popper has a style property that needs to be overridden
        position: fixed !important;
        transform: none !important;
        top: 50% !important;
        left: 50% !important;

        z-index: 1301;
        margin-top: -290px;
        margin-left: -356px;

        ${devices.mobileL} {
            margin-left: -178px;
        }
    }

    ${devices.desktopS} {
        width: calc(50% - 14px);
    }

    ${devices.tablet} {
        flex-grow: 1;
    }

    ${devices.mobileL} {
        width: 100%;
        ${Delimiter} {
            margin-top: 0;
        }
        ${DateContainer} {
            width: 100%;
            &:not(:first-child) {
                div.MuiTextField-root {
                    div.MuiInput-root {
                        margin-top: 0;
                    }
                }
            }
        }
    }
`;

export const DatePickerLabel = styled.div`
    padding: 8px 0;
    ${fontSizesCSS.Caption2};
`;

export const NPAWButton = styled.div`
    padding: 6px 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    span {
        ${fontSizesCSS.Body2};
    }
    border: 1px solid ${theme.palette.background.border};
    border-radius: 4px;
    &:hover {
        background: ${theme.palette.text.main};
        color: ${theme.palette.white.main};
    }
    height: 24px;
    margin-top: 14px;

    ${devices.tablet} {
        span {
            ${fontSizesCSS.Caption3};
            font-weight: bold;
        }
    }

    ${devices.mobileL} {
        width: calc(100% - 37px);
        height: 22px;
        align-self: end;
        margin-top: 0;
    }
`;

export const DifferenceContainer = styled.div<{ $reverseColors?: boolean; $isLower?: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    border-radius: 40px;
    background: ${theme.palette.background.pressed};
    span {
        ${fontSizesCSS.Caption2};
    }
    ${({ $reverseColors, $isLower }) =>
        $reverseColors && `path{ fill: ${$isLower ? theme.palette.success.increase : theme.palette.error.decrease}}`}
`;

export const FilterToggle = styled.div`
    cursor: pointer;

    color: ${theme.palette.primary.main};
    ${fontSizesCSS.Caption1};
    path {
        fill: ${theme.palette.primary.main};
    }
    margin-bottom: 8px;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    gap: 9px;
    align-items: center;

    ${SelectWrapper} {
        width: 215px;
        display: flex;
        align-items: center;
        [class$='container'] {
            width: 100%;
        }
        label {
            display: none;
        }
    }
`;

export const FilterText = styled.div`
    ${fontSizesCSS.Caption2}
`;

export const CardValue = styled.div`
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
`;
