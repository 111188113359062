import { InputAdornment } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    LanguagesState,
    updateLanguageTranslations,
    fetchLanguageCodes,
    fetchAllLanguageTranslations,
    fetchLanguages
} from '../../redux/slices/languagesSlice';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../common/Dialog/GenericDialog';
import { NewTranslationDialogWrapper, TranslationKeyLabelContainer, TranslationKeyValueBox } from './NewTranslationKeyDialog.css';
import { getTranslationKeys } from '../../utils/fnData';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import { validator } from '../../utils/fnValidator';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { AutocompleteSelect } from '../common/Select/AutocompleteSelect';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { renderLockedError } from '../../utils/fnLockingSystem';
import { API_ERROR_CODES } from '../../utils/Globals';
import { dialogAlert, ToastAlert } from '../../utils/fnDialogs';
import icons from '../../assets/images/icons';
import { CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';

export type NewTranslationProps = {
    open: boolean;
    onClose: () => void;
    translationKey?: string;
    onChange?: (e: string) => void;
};

export const NewTranslation: FC<NewTranslationProps> = ({ open, onClose, translationKey, onChange }) => {
    const { languageCodes, error, allTranslations, languages, loading }: LanguagesState = useAppSelector((state) => state.languages);
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { userProfile } = useAppSelector((state) => state.profile);
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectedTranslationKey, setSelectedTranslationKey] = useState<string>('');
    const [translationKeyError, setTranslationKeyError] = useState<string>('');
    const [isExistingTranslationKey, setIsExistingTranslationKey] = useState<boolean>(false);

    const [translationKeys, setTranslationKeys] = useState<string[]>([]);
    const [translationKeysOptions, setTranslationKeysOptions] = useState<any[]>([]);
    const [translationValues, setTranslationValues] = useState<any>({});
    const [initialTranslationValues, setInitialTranslationValues] = useState<any>({});
    const [initialTranslationDescription, setInitialTranslationDescription] = useState('');
    const [translationDescription, setTranslationDescription] = useState('');

    useEffect(() => {
        setIsOpen(open);
        if (open) {
            loadAllTranslations();
            loadLanguageCodes();
            loadLanguages();
        }
    }, [open]);
    const loadAllTranslations = async () => {
        return await dispatch(fetchAllLanguageTranslations({ projectId: activeProjectId || '' })).unwrap();
    };
    const loadLanguageCodes = async () => {
        if (!languageCodes?.length) {
            await dispatch(fetchLanguageCodes()).unwrap();
        }
    };
    const loadLanguages = async () => {
        const shouldLoadLanguages = !window.location.pathname.includes('/languages'); // if on any languages page, loading languages will cause unwanted loading indicators to appear
        if (!languages.length || shouldLoadLanguages) {
            await dispatch(fetchLanguages({ projectId: activeProjectId || '' })).unwrap();
        }
    };
    const updateTranslations = async () => {
        // onSave only the modified + newlyAdded values will be sent
        const values = Object.keys(translationValues).reduce((result, key) => {
            if (translationValues[key] !== initialTranslationValues[key]) {
                result[key] = translationValues[key];
            }
            return result;
        }, {} as { [key: string]: string });

        const response = await dispatch(
            updateLanguageTranslations({
                tenantId: activeTenantId || '',
                projectId: activeProjectId || '',
                key: selectedTranslationKey,
                values: values,
                description: translationDescription
            })
        ).unwrap();
        if (response.lockedLanguages.length) {
            renderLockedLanguagesWarning(response.lockedLanguages);
        }
        loadAllTranslations();
    };

    const validateKey = () => {
        const isKeyValid = validator({ required: true }, selectedTranslationKey);
        setTranslationKeyError(isKeyValid);
        return !isKeyValid;
    };

    const handleSaveClick = async () => {
        if (!validateKey()) return;

        const shouldUpdateTranslations = !isExistingTranslationKey
            ? true
            : Object.values(translationValues).sort().join() !== Object.values(initialTranslationValues).sort().join() ||
              initialTranslationDescription !== translationDescription;

        shouldUpdateTranslations && (await updateTranslations());
        onChange?.(selectedTranslationKey);
        handleCloseClick();
    };
    const handleCloseClick = () => {
        setSelectedTranslationKey('');
        setTranslationValues({});
        setTranslationDescription('');
        setIsExistingTranslationKey(false);
        setTranslationKeyError('');
        onClose();
    };

    const addButton: DialogButton = {
        label: 'Add Translation',
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const lockedLanguagesDialogValues = (_languages?: any[]) => {
        const lockedLangs = _languages || languages.filter((l) => l.locked && l.locked !== userProfile?._id);
        return {
            title: 'Someone Else is Editing!',
            text: (
                <>
                    It looks like language(s) <strong>&quot;{lockedLangs.map((l) => l.name).join(', ')}&quot;</strong> is/are already being
                    edited by user(s) <strong>&quot;{lockedLangs.map((l) => l.lockedBy || l.lockedByUser?.name).join(', ')}&quot;</strong>.
                    Only one person can edit at the same time, so the value of the key for these languages was/is not updated.
                </>
            )
        };
    };

    const renderLockedLanguagesWarning = (languages?: any[]) => {
        ToastAlert(
            'critical_warning',
            lockedLanguagesDialogValues(languages).title,
            lockedLanguagesDialogValues(languages).text,
            icons.lockedObjectIcon,
            undefined,
            () => {
                dialogAlert('', false, lockedLanguagesDialogValues(languages), null, false, icons.lockedObjectIcon, null, true);
            }
        );
    };

    useEffect(() => {
        if (!translationKey) return;
        setSelectedTranslationKey(translationKey);
    }, [translationKey]);

    useEffect(() => {
        if (!languageCodes || !allTranslations || !open) return;
        setTranslationKeys(allTranslations ? Object.keys(allTranslations) : []);
    }, [allTranslations, languageCodes, open]);

    useEffect(() => {
        const options = translationKeys.map((key) => {
            return {
                label: (
                    <TranslationKeyLabelContainer>
                        <TranslationKeyValueBox>{key}</TranslationKeyValueBox>
                        <TranslationTooltip translationKey={key} />
                    </TranslationKeyLabelContainer>
                ),
                value: key
            };
        });
        setTranslationKeysOptions(options);
    }, [translationKeys]);

    useEffect(() => {
        const isTranslationKey = allTranslations ? selectedTranslationKey in allTranslations : false;
        let translationsValues = {};
        if (selectedTranslationKey && isTranslationKey) {
            const translations = getTranslationKeys(selectedTranslationKey, allTranslations || {}, languageCodes);

            Object.keys(allTranslations?.[selectedTranslationKey].values || {}).forEach((key) => {
                const translation = translations.find((trans) => trans?.code === key)?.translation;
                translationsValues = {
                    ...translationsValues,
                    [key]: translation || ''
                };
            });
        }

        const descriptionValue = isTranslationKey ? allTranslations?.[selectedTranslationKey].description || '' : '';
        setInitialTranslationValues(translationsValues);
        setTranslationValues(translationsValues);
        setInitialTranslationDescription(descriptionValue);
        setTranslationDescription(descriptionValue);
        setIsExistingTranslationKey(isTranslationKey);
    }, [selectedTranslationKey]);

    if (!isOpen) return null;

    const flagAdornment = (icon: string) => (
        <InputAdornment position="start">
            <img src={icon} />
        </InputAdornment>
    );

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    const anyLanguageLocked = languages.some((l) => l.locked && l.locked !== userProfile?._id);

    return (
        <GenericDialog
            title={'Add Key'}
            type={DialogTypes.Form}
            actionButtons={[cancelButton, addButton]}
            onClose={handleCloseClick}
            warnings={
                anyLanguageLocked
                    ? [
                          {
                              icon: icons.warningIcon,
                              critical: true,
                              onClick: () => {
                                  dialogAlert('', false, lockedLanguagesDialogValues(), null, false, icons.lockedObjectIcon, null, true);
                              }
                          }
                      ]
                    : undefined
            }
            circlesSlugOptions={{ default: CIRCLE_SLUGS.languages }}
        >
            {error && renderError(error)}
            <NewTranslationDialogWrapper>
                <AutocompleteSelect
                    value={selectedTranslationKey}
                    onChange={(value: any) => {
                        setSelectedTranslationKey(value);
                        setTranslationKeyError('');
                    }}
                    label={'Add Key Name'}
                    placeholder={'Add translation Key'}
                    options={translationKeysOptions}
                    error={translationKeyError}
                />

                {allTranslations ? (
                    <>
                        <DialogTextField
                            key={`translation_key_description`}
                            value={translationDescription}
                            onChange={(e: any) => setTranslationDescription(e.target.value)}
                            label={'Add Description'}
                            placeholder={`Add ${name} Description`}
                            optional
                        />
                        {languages.map((language) => {
                            const { code, locked } = language;
                            const { flag } = languageCodes.find((c) => c.code === code) || {};
                            return (
                                <DialogTextField
                                    key={`translation_key_${code}`}
                                    value={translationValues?.[code] || ''}
                                    onChange={(e: any) => setTranslationValues({ ...translationValues, [code]: e.target.value })}
                                    label={'Add Translation'}
                                    placeholder={loading ? 'Loading' : `Add ${name} translation`}
                                    InputProps={{ startAdornment: flagAdornment(flag || '') }}
                                    toolTipText={
                                        isExistingTranslationKey && !translationValues?.[code] ? 'Missing Translations' : undefined
                                    }
                                    isDisabled={(!!locked && locked !== userProfile?._id) || loading}
                                    optional
                                />
                            );
                        })}
                    </>
                ) : null}
            </NewTranslationDialogWrapper>
        </GenericDialog>
    );
};
