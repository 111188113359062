import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import SVGInline from 'react-inlinesvg';
import { getHelperText } from '../../../utils/fnDialogs';
import { AddNewOption, AdornmentSpan, DropdownIndicatorWrapper, SelectWrapper } from './Select.css';
import { DROPDOWN_ADD_VALUE, DROPDOWN_GROUP_LABEL_VALUE } from '../../../utils/Globals';
import icons from '../../../style';
import { DialogDropdownNewOption } from '../Dialog/GenericDialog';
import { TruncatedText } from '../../../style/styled-components/reusable.css';

export interface ISelectProps {
    forceLowerCase?: boolean;
    className?: string;
    value: any;
    noLabel?: boolean;
    placeholder: string;
    labelText?: any;
    onChange: (value: any) => void;
    onBlur?: any;
    onClick?: any;
    error?: string;
    clearable?: boolean;
    options: any[];
    allowSelectAll?: boolean;
    maxSelected?: number;
    up?: boolean;
    isOpen?: boolean;
    customComponents?: any;
    isDisabled?: boolean;
    optional?: boolean;
    openOnClick?: boolean;
    unclickableIndexes?: number[];
    noError?: boolean;
    maxLength?: number;
    notSorted?: boolean;
    newOption?: DialogDropdownNewOption;
    adornmentStart?: string | JSX.Element;
    dataCy?: string;
    withLoadingIndicator?: boolean;
}

const SingleSelect: React.FC<ISelectProps> = (props) => {
    const [value, setValue] = useState(props.value);
    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    const noLabel = props.noLabel === true;
    const labelValue = props.labelText || props.placeholder || null;
    useEffect(() => {
        const newValue = props.value;
        if (props.forceLowerCase) {
            newValue.value = newValue.value?.toLowerCase();
        }
        setValue(newValue);
    }, [props.value]);

    const handleChange = (newValue: any) => {
        if (props.forceLowerCase) {
            newValue.value = newValue.value?.toLowerCase();
        }
        if (props.onChange) {
            props.onChange(newValue);
        }
        setValue(newValue);
    };

    const handleBlur = (evt: any) => {
        // evt.preventDefault();
        if (props.onBlur) {
            props.onBlur();
        }
    };

    const handleClick = (e: any) => {
        if (props.onClick) {
            props.onClick(e);
        }
    };

    const { notSorted, newOption } = props;
    let options = props.options;
    if (notSorted) {
        if (newOption && !options.find((opt: any) => opt.value === DROPDOWN_ADD_VALUE)) {
            options.unshift({ value: DROPDOWN_ADD_VALUE, label: `Create ${_.startCase(newOption.name)}`, isDisabled: true });
        }
    } else {
        options = sortOptions(options, value, newOption);
    }

    // if newOption prop is present, an option will be added at the start of the options array and the unclickable indexes need to be shifted in accordance
    const unclickableIndexes = props.unclickableIndexes?.length
        ? props.newOption
            ? props.unclickableIndexes?.map((index) => index + 1)
            : props.unclickableIndexes
        : undefined;

    const _DropdownIndicator = useCallback((props) => {
        return props.withLoadingIndicator ? LoadingAdornment(props) : CustomArrow(props, isOpen);
    }, []);

    const _Option = useCallback(
        (compProps) => {
            return Option(compProps, props.notSorted, props.newOption);
        },
        [props.notSorted, props.newOption]
    );
    return (
        <SelectWrapper
            id={`select_wrapper_${labelValue}`}
            $error={!!props.error}
            onBlur={handleBlur}
            onClick={(evt: any) => handleClick(evt)}
            tabIndex={1}
            unclickableIndexes={unclickableIndexes}
            $noError={!!props.noError}
            $isSingle
            data-cy={props?.dataCy}
        >
            {!noLabel && <label>{labelValue}</label>}
            <Select
                {...props}
                classNamePrefix="cc_select"
                isOptionDisabled={(option) => option.isDisabled}
                isDisabled={props.isDisabled || false}
                isClearable={props.clearable}
                isSearchable={props.options?.length > 7}
                options={options}
                menuPlacement={props.up ? 'top' : 'bottom'}
                onChange={(newValue: any) => handleChange(newValue)}
                placeholder={props.placeholder}
                menuIsOpen={isOpen}
                components={{
                    ...props.customComponents,
                    IndicatorSeparator: null,
                    Option: _Option,
                    SingleValue: props.adornmentStart
                        ? (componentProps) => StartAdornedSingleValue(componentProps, props.adornmentStart!)
                        : (componentProps) => (
                              <components.SingleValue {...componentProps} isDisabled={componentProps.data.isDisabled}>
                                  {componentProps.children}
                              </components.SingleValue>
                          ),
                    DropdownIndicator: _DropdownIndicator
                }}
                maxMenuHeight={300}
                filterOption={({ label, data }, inputValue) => {
                    const value = inputValue.toLowerCase().trim();
                    if (data?.value === DROPDOWN_GROUP_LABEL_VALUE || data?.value === DROPDOWN_ADD_VALUE) return true;
                    if (!data?.valueForSearch) {
                        return typeof label === 'string' && label.toLowerCase().includes(value);
                    }
                    return data?.valueForSearch.toLowerCase().includes(value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                onMenuOpen={() => {
                    setIsOpen(true);
                }}
                onMenuClose={() => {
                    setIsOpen(false);
                }}
                openMenuOnClick={props.openOnClick !== false}
            />
            <label className="error">{getHelperText(props.error, props?.optional)}</label>
        </SelectWrapper>
    );
};

export const LoadingAdornment = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <DropdownIndicatorWrapper>
                <SVGInline src={icons.spinnerIcon} />
            </DropdownIndicatorWrapper>
        </components.DropdownIndicator>
    );
};

export const CustomArrow = (props: any, open: boolean) => {
    return (
        <components.DropdownIndicator {...props}>
            {/* Conditionally render different arrow icons based on the isOpen state */}
            <DropdownIndicatorWrapper>
                {open ? <SVGInline src={icons.arrowUpIcon} /> : <SVGInline src={icons.arrowDownIcon} />}
            </DropdownIndicatorWrapper>
        </components.DropdownIndicator>
    );
};

export const Option = (props: any, notSorted = false, newOption?: DialogDropdownNewOption) => {
    const selectValue = props.selectProps?.value;
    const optionValue = props.value;
    let checked;
    let isFirstUnchecked = false;
    if (Array.isArray(selectValue)) {
        checked = selectValue.map((elem) => elem.value).includes(optionValue);
        if (selectValue.length && !notSorted) {
            const unselected = props.options.filter((opt: any) => !selectValue.map((elem) => elem.value).includes(opt.value));

            if (unselected.length) {
                isFirstUnchecked = unselected[0].value === optionValue;
            }
        }
    } else {
        checked = selectValue?.value === optionValue;
    }
    const innerProps = { ...props.innerProps };
    if (isFirstUnchecked) {
        innerProps.className = 'cc_select__option option-with-border';
    }

    return (
        <components.Option {...props} innerProps={innerProps}>
            {props.value === DROPDOWN_ADD_VALUE ? (
                <AddNewOption
                    onClick={(e) => {
                        newOption?.onClick();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <SVGInline src={icons.addIcon} /> {props.label}
                </AddNewOption>
            ) : (
                <>
                    {typeof props.label === 'string' ? <TruncatedText>{props.label}</TruncatedText> : props.label}
                    {checked && <SVGInline src={icons.selectCheckIcon} />}
                </>
            )}
        </components.Option>
    );
};

export const sortOptions = (options: any[], value: any, newOption?: DialogDropdownNewOption) => {
    let sortedOptions = options
        .map((option: any) => {
            const newOption = { ...option };
            if (Array.isArray(value)) {
                newOption.selected = value.map((elem) => elem.value).includes(option.value);
            } else {
                newOption.selected =
                    typeof value === 'string' ? value === option.value || value === DROPDOWN_ADD_VALUE : value?.value === option.value;
            }
            return newOption;
        })
        .sort((a: any, b: any) => {
            let nameA = typeof a.label === 'string' ? a.label : a.valueForSearch || a.value || '';
            let nameB = typeof b.label === 'string' ? b.label : b.valueForSearch || b.value || '';

            nameA = typeof nameA === 'string' ? nameA.toLowerCase() : nameA;
            nameB = typeof nameB === 'string' ? nameB.toLowerCase() : nameB;

            if (!isNaN(nameA) && !isNaN(nameB)) {
                return parseInt(nameA) - parseInt(nameB);
            }

            if (!isNaN(nameA)) {
                return -1;
            }
            if (!isNaN(nameB)) {
                return 1;
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
        .sort((a: any, b: any) => b.selected - a.selected)
        .map((option: any) => _.omit(option, 'selected'));

    if (newOption) {
        sortedOptions = [{ value: DROPDOWN_ADD_VALUE, label: `Create ${_.startCase(newOption.name)}`, isDisabled: true }, ...sortedOptions];
    }

    return sortedOptions;
};

export const StartAdornedSingleValue = (props: any, adornment: string | JSX.Element) => {
    if (typeof adornment === 'string') {
        adornment = <AdornmentSpan>{adornment}&nbsp;</AdornmentSpan>;
    }
    return (
        <components.SingleValue {...props} isDisabled={props.data.isDisabled}>
            {adornment ? adornment : null}
            {props.children}
        </components.SingleValue>
    );
};

export default SingleSelect;
