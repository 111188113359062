import styled from 'styled-components';
import { devices } from '../../../style/styled-components/reusable.css';
import getTheme, { fontSizes, fontSizesCSS } from '../../../style/themes/theme';
import { AudienceContainer, LastModified } from '../../TargetGroups/UXElements/Audiences/Audiences.css';
import { ImageFieldContainer, ImageHeader } from '../PageStyle/PageStyle.css';
import { ContentMarker } from './Dialogs/ContentMarkers';

const theme: any = getTheme();

export const BrandingContainer = styled(AudienceContainer)`
    svg {
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
    }

    ${devices.desktopS} {
        div {
            div.MuiTextField-root {
                width: 223px;
            }
        }
    }

    ${devices.tablet} {
        div {
            div.MuiTextField-root {
                width: 176px;
            }
        }
    }

    ${devices.mobileL} {
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        padding: 20px 36px 20px 24px;
        div {
            div.MuiTextField-root {
                width: 100%;
            }
        }
    }
`;

export const BrandingViewName = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    ${fontSizes.Body2}

    ${devices.desktopS} {
        width: 223px;
    }

    ${devices.tablet} {
        width: 176px;
    }

    &:hover {
        cursor: pointer;
    }
`;
export const BrandingViewTargets = styled.div``;
export const BrandingViewLastModified = styled.div``;

export const AppBrandingsConditionsView = styled.div`
    width: 32%;
    [id$='Target Conditions'] {
        width: 100%;
        margin: 12px 0 0 0;
        min-height: unset;

        [class$='-container'] {
            margin-top: 4px;
        }

        label {
            color: ${theme.palette.label.main};
        }
    }
    ${devices.desktopS} {
        width: 226px;
    }
    ${devices.mobileL} {
        width: 100%;
    }
`;

export const AppBrandingsHolder = styled.div`
    padding-bottom: 18px;
    ${devices.desktopS} {
        display: flex;
        flex-direction: column;
    }
`;

export const SmallScreenFirstRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const SmallScreenSecondRow = styled.div`
    width: 100%;
    .MuiTextField-root {
        margin: 0;
    }
`;

export const SmallScreenThirdRow = styled.div`
    width: 100%;
    [id$='Target Conditions'] {
        margin: 0;
    }
`;

export const LastModifiedBrandings = styled(LastModified)`
    margin-left: 0;
    justify-content: space-between;
    width: 139px;
    img {
        margin-left: 0;
    }
`;
export const ContentMarkersContainer = styled(ImageFieldContainer)``;
export const ContentMarkersHeader = styled(ImageHeader)``;
export const ContentMarkerName = styled.div`
    ${fontSizesCSS.Body2};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        position: absolute;
        display: none;
    }
`;
export const ContentMarkerField = styled.div<{ $contentMarker?: ContentMarker }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 36px;
    margin-bottom: 28px;
    background: ${({ $contentMarker }) => $contentMarker?.backgroundColor || theme.palette.background.imageFields};
    border: 2px ${({ $contentMarker }) => $contentMarker?.borderColor || theme.palette.background.innerBorder};
    border-style: ${({ $contentMarker }) => ($contentMarker?.borderColor ? 'solid' : 'dashed')};
    border-radius: 4px;
    cursor: pointer;
    svg {
        width: 13px;
        height: 13px;
        opacity: 0.7;
    }
    ${ContentMarkerName} {
        color: ${({ $contentMarker }) => $contentMarker?.fontColor || theme.palette.white.main};
    }
    ${({ $contentMarker }) =>
        $contentMarker &&
        `&:hover {
            ${ContentMarkerName} {
            background: ${theme.palette.background.hovered};
            span {
                opacity: 0.7;
            }
            svg {
                display: block;
            }
        }
    }`}
`;
export const DialogColorFieldsContainer = styled.div`
    display: flex;
    gap: 8px;
`;
