import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchServices, SourcesState } from '../../../../redux/slices/sourceSlice';
import { contentSourceTypes } from '../../../../types/Item';
import { additionalSourceFields } from '../../../../types/DynamicSource';
import { createDynamicUrl } from '../../../../utils/fnUrl';
import { validator } from '../../../../utils/fnValidator';
import {
    FilterParamOptionsLabels,
    MultipleSelectFields,
    ServiceParamDefaultValues,
    ServiceParamNames,
    ServiceParamOptionLabels,
    ServiceSubserviceNames
} from '../../../../utils/Services';
import { DialogDropdownMultiple, DialogDropdownSingle, DialogTextField, DialogToggleButton } from '../../../common/Dialog/GenericDialog';
import { InputLabelWithIconWrapper } from '../../../common/Dialog/GenericDialog.css';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import { ParamsContainer } from '../../../Sources/Dialogs/NewSource.css';
import YoutubeSourceFields, {
    YOUTUBE_FIELD_LABELS_MAP,
    YOUTUBE_METHOD_KEYS,
    YOUTUBE_RECO_SHELF_PARAMS
} from '../../../Sources/Dialogs/YoutubeSourceFields';
import { removeEmptyParams } from '../../../../utils/fnData';
import { capitalizeAndSplitCamelCaseString } from '../../../../utils/fnString';
import { DataPreviewDialog, DialogPreviewTypes } from '../../../PageEdit/VisualElements/DataPreviewDialog';
import { Application, ApplicationDialog } from '../../../Applications/ApplicationDialog';
import { createApplication, fetchApplications } from '../../../../redux/slices/applicationsSlice';
import { AndroidSourceFields, multipleAndroidFieldMethods } from '../../../Sources/Dialogs/AndroidSourceFields';
import { Template, TemplateDefaultValues, templateTypes } from '../../../../types/Template';
import { NewTemplate } from '../Common/NewTemplate';

const FILTER_PARAM = 'filter';

type NewSourceTemplateDialogProps = {
    open: boolean;
    template?: Template;
    onClose: () => void;
    onSave: (template: Template) => void;
    onRelease: (id: string) => void;
    onRetract: (id: string) => void;
    preselectedService?: string;
};

export const NewSourceTemplateDialog: FC<NewSourceTemplateDialogProps> = ({
    open,
    onClose,
    onSave,
    onRelease,
    onRetract,
    template,
    preselectedService
}) => {
    const { services: Services, loading: servicesLoading }: SourcesState = useAppSelector((state) => state.dynamicSources);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [requiresSubService, setRequiresSubService] = useState(false);
    const [serviceOptions, setServiceOptions] = useState<any[]>([]);
    const [methodOptions, setMethodOptions] = useState<any[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [selectedService, setSelectedService] = useState<string>('');
    const [selectedSubService, setSelectedSubService] = useState<string>('');
    const [queryParamsValues, setQueryParamsValues] = useState<any>({});
    const [bodyParamsValues, setBodyParamsValues] = useState<any>({});
    const [pathParamsValues, setPathParamsValues] = useState<any>({});
    const [additionalFieldsValues, setAdditionalFieldsValues] = useState<any>({});
    const [filterParamsValues, setFilterParamsValues] = useState<any>({});
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');

    const [errors, setErrors] = useState<{
        service?: string;
        method?: string;
        name?: string;
        param?: string;
        bodyParams?: any;
        pathParams?: any;
        queryParams?: any;
        icon?: string;
    }>({});

    const [showNewApplicationDialog, setShowNewApplicationDialog] = useState<boolean>(false);
    const [addMultipleApps, setAddMultipleApps] = useState<boolean>(false);

    //DATA PREVIEW
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const dispatch = useDispatch();

    const loadServices = async (projectId: string) => {
        return await dispatch(fetchServices(projectId)).unwrap();
    };

    const loadApplications = async () => {
        return await dispatch(fetchApplications()).unwrap();
    };

    const createApp = async (application: Application) => {
        await dispatch(createApplication(application)).unwrap();
        const apps = await loadApplications();
        if (apps.error) return;
        // we update the app query with the new app value
        const querytoAdd = application.androidPackageName;

        setQueryParamsValues({
            ...queryParamsValues,
            apps: addMultipleApps ? [...(queryParamsValues?.apps || []), querytoAdd] : querytoAdd
        });
        setAddMultipleApps(false);
    };

    useEffect(() => {
        if (!template?.values) {
            setAdditionalFieldsValues(ServiceParamDefaultValues);
            return;
        }
        const dynamicSource = template.values;

        const queryParameters = dynamicSource.queryParameters || {};
        if (dynamicSource.method === 'channels_api' && queryParameters.apps) {
            setQueryParamsValues({ ...queryParameters, apps: queryParameters.apps[0] });
        } else {
            setQueryParamsValues(queryParameters);
        }

        setName(dynamicSource.name);
        setSelectedService(dynamicSource.service);
        setSelectedSubService(dynamicSource.subService || '');
        setSelectedMethod(dynamicSource.method);
        setBodyParamsValues(dynamicSource.bodyParameters || {});
        setPathParamsValues(dynamicSource.pathParameters || {});
        setFilterParamsValues(queryParameters?.[FILTER_PARAM] || {});
        setUrl(dynamicSource.url || '');

        const additionalFieldsValues: any = {};
        (Object.keys(dynamicSource) as Array<string>).forEach((key) => {
            if (additionalSourceFields.includes(key)) {
                additionalFieldsValues[key] = dynamicSource[key];
            }
        });

        setAdditionalFieldsValues(additionalFieldsValues);
    }, [template]);

    useEffect(() => {
        if (!selectedService || selectedService !== contentSourceTypes.ANDROID) return;
        loadApplications();
    }, [selectedService]);

    useEffect(() => {
        if (!open) return;
        setServiceOptions(
            Services.map((service) => {
                return {
                    value: service.key,
                    label: service.title
                };
            })
        );
        if (preselectedService) {
            const service = Services.find((s) => s.key === preselectedService);
            service && setSelectedService(service.key);
        }
    }, [Services, preselectedService, servicesLoading, open]);

    useEffect(() => {
        if (!selectedService) return;
        let methodsPerService = Services.find((service) => service.key === selectedService)?.methods;
        if (!methodsPerService) return;

        if (selectedSubService) {
            methodsPerService = methodsPerService.filter((elem) => elem.subService === selectedSubService);
        }

        setMethodOptions(
            methodsPerService.map((method) => {
                return {
                    value: method.key,
                    label: method.title
                };
            })
        );
    }, [selectedService, selectedSubService]);

    useEffect(() => {
        setShowPreview(!!selectedMethod);
        setIsPreviewOpen(!!selectedMethod);
    }, [selectedMethod]);

    useEffect(() => {
        if (!Services || !selectedService) return;

        setUrl(
            createDynamicUrl(
                Services,
                selectedService,
                selectedMethod,
                queryParamsValues,
                pathParamsValues,
                filterParamsValues,
                bodyParamsValues,
                selectedSubService
            )
        );
    }, [Services, selectedService, selectedMethod, queryParamsValues, pathParamsValues, filterParamsValues, bodyParamsValues]);

    useEffect(() => {
        setIsOpen(open);
        open && loadServices('');
    }, [open]);

    useEffect(() => {
        if (selectedService) {
            setRequiresSubService(!!Services.find((service) => service.key === selectedService)?.subServices?.length);
        }
    }, [selectedService]);

    const onReleaseClick = () => {
        if (!template?._id) return;
        onRelease(template._id);
    };

    const onRetractClick = () => {
        if (!template?._id) return;
        onRetract(template._id);
    };

    const onCloseClick = () => {
        setSelectedService('');
        setSelectedMethod('');
        clearParams();
        setMethodOptions([]);
        setUrl('');
        setName('');
        setErrors({});
        onClose && onClose();
    };

    const onSaveClick = (values: TemplateDefaultValues) => {
        const { icon, iconBackground, projectIds, tenantIds } = values;
        if (!validateSource(values)) return;
        const newTemplate: Template = {
            _id: template?._id || '',
            type: templateTypes.DYNAMIC_SOURCE,
            projectIds,
            tenantIds,
            values: {
                ...(template?.values || {}),
                name,
                service: selectedService,
                method: selectedMethod,
                url
            },
            icon,
            iconBackground,
            lastModified: template?.lastModified
        };
        if (selectedSubService) {
            newTemplate.values.subService = selectedSubService;
        }
        if (queryParamsValues) {
            let queryParams = removeEmptyParams(queryParamsValues);

            if (queryParams.apps) {
                const { apps } = queryParams;
                queryParams.apps = typeof apps === 'string' ? [apps] : apps;
            }

            if (!_.isEmpty(filterParamsValues)) {
                const newFilter = removeEmptyParams(filterParamsValues);

                queryParams = { ...queryParams, filter: newFilter };
            }
            newTemplate.values.queryParameters = queryParams;
        }
        if (bodyParamsValues) {
            newTemplate.values.bodyParameters = removeEmptyParams(bodyParamsValues);
        }
        if (pathParamsValues) {
            newTemplate.values.pathParameters = removeEmptyParams(pathParamsValues);
        }
        if (!_.isEmpty(additionalFieldsValues)) {
            Object.keys(additionalFieldsValues).forEach((field) => {
                if (additionalFieldsValues[field] !== undefined && additionalFieldsValues[field] !== null) {
                    _.set(newTemplate.values, field, additionalFieldsValues[field]);
                }
            });
        }

        onSave(newTemplate);
        preselectedService && onCloseClick();
    };

    const validateSource = (values: TemplateDefaultValues) => {
        const { icon } = values;
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, name);
        newErrors.service = validator({ required: true }, selectedService);
        newErrors.method = validator({ required: true }, selectedMethod);
        newErrors.icon = validator({ required: true }, icon);

        if (([YOUTUBE_METHOD_KEYS.RECOMMENDATIONS, YOUTUBE_METHOD_KEYS.RECOMMENDATIONS_SEARCH] as string[]).includes(selectedMethod)) {
            let bodyParamsErrors = { ...(newErrors.bodyParams || {}) };
            bodyParamsErrors.region_code = validator({ required: true }, bodyParamsValues.region_code);
            bodyParamsErrors.language_code = validator({ required: true }, bodyParamsValues.language_code);
            if (!Object.keys(bodyParamsValues || {}).some((key) => key.includes('shelf_params.') && bodyParamsValues[key])) {
                if (selectedMethod === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS && !bodyParamsValues.shelf_params) {
                    bodyParamsErrors.shelf_params = 'A shelf param is required';
                } else {
                    Object.values(YOUTUBE_RECO_SHELF_PARAMS).forEach((elem) => {
                        bodyParamsErrors[elem] = 'A shelf param is required';
                    });
                }
            } else {
                bodyParamsErrors = _.omit(bodyParamsErrors, ['shelf_params', ...Object.values(YOUTUBE_RECO_SHELF_PARAMS)]);
            }
            if (Object.values(bodyParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'bodyParams', bodyParamsErrors);
            } else {
                delete newErrors.bodyParams;
            }
        }

        if (
            (
                [
                    YOUTUBE_METHOD_KEYS.VIDEOS,
                    YOUTUBE_METHOD_KEYS.PLAYLISTS,
                    YOUTUBE_METHOD_KEYS.SEARCH,
                    YOUTUBE_METHOD_KEYS.TRENDING
                ] as string[]
            ).includes(selectedMethod)
        ) {
            let queryParamsErrors = { ...(newErrors.queryParams || {}) };
            const query = YOUTUBE_FIELD_LABELS_MAP.get(selectedMethod);
            if (Array.isArray(query)) {
                const keys = query.map((param) => param.key);
                if (!keys.some((key) => !!queryParamsValues[key])) {
                    queryParamsErrors[selectedMethod] = 'At least one of the params is required';
                } else delete queryParamsErrors[selectedMethod];
            } else {
                const key = query?.key || '';
                queryParamsErrors[key] = validator({ required: true }, queryParamsValues[key]);
            }

            if (Object.values(queryParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'queryParams', queryParamsErrors);
            } else {
                delete newErrors.queryParams;
            }
        }

        if (selectedService === contentSourceTypes.ANDROID && multipleAndroidFieldMethods.includes(selectedMethod)) {
            let queryParamsErrors = { ...(newErrors.queryParams || {}) };
            const apps = [...(queryParamsValues?.apps || [])];
            queryParamsErrors.apps = apps.map((app) => validator({ required: true }, app));

            if (queryParamsErrors.apps.filter((value: string) => !!value).length) {
                _.set(newErrors, 'queryParams', queryParamsErrors);
            } else {
                delete newErrors.queryParams;
            }
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const clearParams = () => {
        setQueryParamsValues({});
        setBodyParamsValues({});
        setPathParamsValues({});
        setFilterParamsValues({});
        setAdditionalFieldsValues({});
    };

    const buildSubServices = () => {
        const subServices = Services.find((s) => s.key === selectedService)?.subServices;

        if (!subServices?.length) return null;

        const options = subServices.map((elem) => {
            return {
                value: elem,
                label: _.get(ServiceSubserviceNames, elem, elem)
            };
        });
        return (
            <DialogDropdownSingle
                placeholder={'Select Sub Service'}
                options={options}
                clearable
                value={options.find((elem: any) => elem.value === selectedSubService) || ''}
                onChange={(evt: any) => {
                    setSelectedSubService(evt.value);
                    setSelectedMethod('');
                    setErrors({});
                    clearParams();
                }}
            />
        );
    };

    const buildDynamicSourceField = (
        key: string,
        value: any,
        valueType: 'string' | 'boolean' | 'array',
        paramType: 'query' | 'path' | 'additional',
        values?: any[]
    ) => {
        const fieldKey = `param_${key}_${paramType}_${valueType}`;

        // when changing a query or additional param, we must identify if it has any depending fields
        // if it does, we must clear the values if the param is changed
        const findDependentKeys = (obj: any, keyToCheck: string, value?: string): string[] => {
            const dependentKeys: string[] = [];

            const traverseObject = (obj: any, currentKey: string, dependsOnKey: string | null) => {
                if (obj && typeof obj === 'object') {
                    if (Array.isArray(obj)) {
                        obj.forEach((item, index) => traverseObject(item, `${currentKey}[${index}]`, dependsOnKey));
                    } else {
                        Object.entries(obj).forEach(([subKey, subValue]) => {
                            if (subKey === 'dependsOn') {
                                if (
                                    dependsOnKey &&
                                    value &&
                                    (subValue as any).some(
                                        (dependency: { key: string; values: string[] }) =>
                                            dependency.key === keyToCheck && dependency.values.includes(value)
                                    )
                                ) {
                                    dependentKeys.push(currentKey);
                                }
                            }
                            traverseObject(subValue, `${currentKey}.${subKey}`, dependsOnKey === null ? subKey : dependsOnKey);
                        });
                    }
                }
            };

            traverseObject(obj, 'obj', null);

            return dependentKeys;
        };

        const onChange = (e: any) => {
            switch (paramType) {
                case 'query':
                    setQueryParamsValues(() => {
                        const newValue =
                            valueType === 'array'
                                ? MultipleSelectFields.includes(key)
                                    ? e.map((elem: any) => elem.value)
                                    : e.value
                                : valueType === 'boolean'
                                ? !queryParamsValues[key]
                                : e.target.value;

                        const oldValue = value;

                        const newParams = {
                            ...queryParamsValues,
                            [key]: newValue
                        };

                        if (oldValue === newValue) return newParams;

                        const params =
                            Services.find((s) => s.key === selectedService)?.methods.find(
                                (m) => (!m.subService || m.subService === selectedSubService) && m.key === selectedMethod
                            )?.params || {};

                        const keys = findDependentKeys(params, key, oldValue);

                        keys.forEach((fullKey) => {
                            const key = _.last(fullKey.split('.'));
                            key && delete newParams[key];
                        });

                        return newParams;
                    });

                    break;
                case 'path':
                    setPathParamsValues({
                        ...pathParamsValues,
                        [key]: valueType === 'array' ? e.value : valueType === 'boolean' ? !pathParamsValues[key] : e.target.value
                    });
                    break;
                case 'additional':
                    setAdditionalFieldsValues(() => {
                        const newValue =
                            valueType === 'array' ? e.value : valueType === 'boolean' ? !additionalFieldsValues[key] : e.target.value;

                        const oldValue = value;

                        const newParams = {
                            ...additionalFieldsValues,
                            [key]: newValue
                        };

                        if (oldValue === newValue) return newParams;

                        const additionalFields = Services.find((s) => s.key === selectedService)?.additionalFields || {};

                        const keys = findDependentKeys(additionalFields, key, oldValue);

                        keys.forEach((fullKey) => {
                            const key = _.last(fullKey.split('.'));
                            key && delete newParams[key];
                        });

                        return newParams;
                    });
                    break;
                default:
                    break;
            }
        };
        switch (valueType) {
            case 'string':
                return (
                    <>
                        <DialogTextField
                            key={fieldKey}
                            value={value}
                            onChange={onChange}
                            label={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        />
                    </>
                );
            case 'boolean':
                return (
                    <DialogToggleButton
                        key={fieldKey}
                        text={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        checked={value}
                        toggleCallback={() => onChange(null)}
                    />
                );
            case 'array':
                const options = !values
                    ? []
                    : values.map((elem: string) => {
                          return {
                              value: elem,
                              label: _.get(ServiceParamOptionLabels, elem, _.capitalize(elem.replace(/["_]/g, ' ').trim()))
                          };
                      });

                if (MultipleSelectFields.includes(key)) {
                    return (
                        <DialogDropdownMultiple
                            key={selectedMethod + key}
                            placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            value={value?.map(
                                (elem: any) => options.find((option: any) => option.value === elem || `"${option.value}"` === elem) || ''
                            )}
                            onChange={onChange}
                            options={options}
                            allowSelectAll
                            clearable
                        />
                    );
                }
                return (
                    <DialogDropdownSingle
                        key={fieldKey}
                        placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        value={options.find((elem: any) => elem.value === value) || ''}
                        onChange={onChange}
                        options={options}
                        clearable
                    />
                );
            default:
                return <></>;
        }
    };

    const buildAdditionalFields = () => {
        const additionalFields = Services.find((s) => s.key === selectedService)?.additionalFields;
        if (!additionalFields || !Object.keys(additionalFields).length) return <></>;

        const fields: any[] = [];
        fields.push(
            <InputLabelWithIconWrapper key={'additional_fields_label'}>
                Additional Fields
                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_additional_fields')}
            </InputLabelWithIconWrapper>
        );

        Object.keys(additionalFields).forEach((field) => {
            const dependencies = additionalFields[field].dependsOn;

            if (dependencies && !dependencies.some((dep: any) => dep.values.includes(additionalFieldsValues[dep.key]))) {
                return;
            }

            const isArray = Array.isArray(additionalFields[field]) || Array.isArray(additionalFields[field].values);
            fields.push(
                buildDynamicSourceField(
                    field,
                    additionalFieldsValues[field],
                    isArray ? 'array' : additionalFields[field],
                    'additional',
                    Array.isArray(additionalFields[field]) ? additionalFields[field] : additionalFields[field].values
                )
            );
        });
        return <ParamsContainer>{fields}</ParamsContainer>;
    };

    const buildParamFields = () => {
        const params = Services.find((s) => s.key === selectedService)?.methods.find(
            (m) => m.key === selectedMethod && (selectedSubService && m.subService ? m.subService === selectedSubService : true)
        )?.params;
        if (!params) return <></>;

        const fields: any[] = [];

        if (Object.keys(params.path || {}).length) {
            fields.push(
                <InputLabelWithIconWrapper key={'path_params_label'}>
                    Path Parameters
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_path_parameters')}
                </InputLabelWithIconWrapper>
            );
            Object.keys(params.path).forEach((param) => {
                fields.push(
                    buildDynamicSourceField(
                        param,
                        pathParamsValues[param],
                        Array.isArray(params.path[param]) ? 'array' : params.path[param],
                        'path',
                        Array.isArray(params.path[param]) ? params.path[param] : undefined
                    )
                );
            });
        }

        if (Object.keys(params.query || {}).length) {
            fields.push(
                <InputLabelWithIconWrapper key={'query_params_label'}>
                    Query Parameters
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_query_parameters')}
                </InputLabelWithIconWrapper>
            );

            //check if there are filter queries applied
            const filterQueries = Object.keys(params.query).filter((param) => param.includes('$'));
            if (filterQueries.length) {
                filterQueries.forEach((key, index) => {
                    const [generalKey, filterKey] = key.split('$');

                    if (params.query[key] === 'string') {
                        fields.push(
                            <DialogTextField
                                optional
                                key={`param_filter_${index}`}
                                value={filterParamsValues[filterKey] || ''}
                                onChange={(e: any) =>
                                    setFilterParamsValues((oldParams: any) => {
                                        const newParams = {
                                            ...oldParams,
                                            [filterKey]: e.target.value
                                        };
                                        return newParams;
                                    })
                                }
                                label={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                toolTipText={filterKey === 'id' ? 'Multiple Ids should be separated by comma' : undefined}
                            />
                        );
                    }

                    if (Array.isArray(params.query[key])) {
                        const options = params.query[key].map((elem: string) => {
                            return {
                                value: elem,
                                label: _.get(FilterParamOptionsLabels, elem, _.capitalize(elem.replace(/["_]/g, ' ').trim()))
                            };
                        });

                        if (MultipleSelectFields.includes(filterKey)) {
                            const value = options.filter((item: any) => filterParamsValues[filterKey]?.includes(item.value));

                            fields.push(
                                <DialogDropdownMultiple
                                    optional
                                    value={value}
                                    options={options}
                                    key={`path_filter_${index}`}
                                    placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                    onChange={(newValue: any) =>
                                        setFilterParamsValues((oldParams: any) => ({
                                            ...oldParams,
                                            [filterKey]: newValue?.map((elem: any) => elem.value)
                                        }))
                                    }
                                    allowSelectAll
                                />
                            );
                        } else {
                            fields.push(
                                <DialogDropdownSingle
                                    optional
                                    options={options}
                                    clearable
                                    key={`path_filter_${index}`}
                                    value={options.find((elem: any) => elem.value === filterParamsValues[filterKey]) || ''}
                                    placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                    onChange={(evt: any) => {
                                        setFilterParamsValues((oldParams: any) => ({ ...oldParams, [filterKey]: evt.value }));
                                    }}
                                />
                            );
                        }
                    }
                });
            }

            Object.keys(params.query)
                .filter((param) => !param.includes('$'))
                .forEach((param) => {
                    const paramObj = params.query[param];
                    const dependencies = paramObj.dependsOn;
                    if (dependencies && !dependencies.some((dep: any) => dep.values.includes(queryParamsValues[dep.key]))) {
                        return;
                    }

                    fields.push(
                        buildDynamicSourceField(
                            param,
                            queryParamsValues[param],
                            Array.isArray(dependencies ? params.query[param].values : params.query[param])
                                ? 'array'
                                : dependencies
                                ? params.query[param].values
                                : params.query[param],
                            'query',
                            Array.isArray(dependencies ? params.query[param].values : params.query[param])
                                ? dependencies
                                    ? params.query[param].values
                                    : params.query[param]
                                : undefined
                        )
                    );
                });
        }

        return <ParamsContainer>{fields}</ParamsContainer>;
    };

    const renderSelectedServiceFields = () => {
        const service = Services.find((s) => s.key === selectedService);
        if (!service) return null;
        switch (selectedService) {
            case contentSourceTypes.YOUTUBE:
            case contentSourceTypes.YOUTUBE_RECO:
                return (
                    <YoutubeSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, queryParamsValues, bodyParamsValues, errors }}
                        fieldSetters={{ setSelectedMethod, setPathParamsValues, setQueryParamsValues, setBodyParamsValues, setErrors }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            case contentSourceTypes.ANDROID:
                return (
                    <AndroidSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, queryParamsValues, errors }}
                        fieldSetters={{
                            setSelectedMethod,
                            setPathParamsValues,
                            setQueryParamsValues,
                            setErrors,
                            setAddMultipleApps,
                            setShowNewApplicationDialog
                        }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            default:
                return (
                    <>
                        {buildSubServices()}
                        {requiresSubService && !selectedSubService ? null : (
                            <DialogDropdownSingle
                                value={methodOptions.find((source) => source.value === selectedMethod) || ''}
                                options={methodOptions}
                                clearable
                                placeholder={'Select Method'}
                                onChange={(value: any) => {
                                    setErrors(_.omit(errors, 'method'));
                                    setSelectedMethod(value.value);
                                    clearParams();
                                }}
                                error={errors.method}
                            />
                        )}
                        {selectedMethod && (
                            <>
                                {buildParamFields()}
                                {buildAdditionalFields()}
                            </>
                        )}
                    </>
                );
        }
    };

    const previewOpen =
        isPreviewOpen &&
        (selectedMethod === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS
            ? Object.keys(bodyParamsValues).some((key) => key.includes('shelf_params.'))
            : true);

    const extraValues = {
        name,
        service: selectedService,
        subService: selectedSubService,
        method: selectedMethod,
        url,
        bodyParameters: bodyParamsValues,
        pathParameters: pathParamsValues,
        queryParameters: queryParamsValues,
        additionalSourceFields: additionalFieldsValues
    };

    const children = (
        <>
            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                placeholder={' Name'}
                label={'Source Name'}
                error={errors.name}
                dataCy={'source-name-field'}
            />
            <DialogDropdownSingle
                value={serviceOptions.find((service) => service.value === selectedService) || ''}
                options={serviceOptions}
                clearable
                placeholder={'Select Service'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, 'service'));
                    setSelectedService(value.value);
                    setSelectedSubService('');
                    setSelectedMethod('');
                    setQueryParamsValues({});
                    setPathParamsValues({});
                    setFilterParamsValues({});
                    setErrors({});
                }}
                isDisabled={servicesLoading || !!(preselectedService && selectedService)}
                dataCy={'source-service-field'}
                error={errors.service}
            />

            {selectedService && renderSelectedServiceFields()}
            {showPreview && (
                <DataPreviewDialog
                    previewType={DialogPreviewTypes.SOURCE}
                    isPreviewOpen={previewOpen}
                    service={selectedService}
                    url={url}
                    togglePreview={() => {
                        setIsPreviewOpen(!isPreviewOpen);
                    }}
                    isSuperadminUI
                />
            )}

            <ApplicationDialog
                open={showNewApplicationDialog}
                onClose={() => {
                    setShowNewApplicationDialog(false);
                }}
                onSave={(app) => createApp(app)}
            />
        </>
    );

    if (!isOpen) return null;

    return (
        <NewTemplate
            open={open}
            templateType={templateTypes.DYNAMIC_SOURCE}
            template={template}
            onRelease={onReleaseClick}
            onRetract={onRetractClick}
            onSave={onSaveClick}
            onClose={onCloseClick}
            errors={errors}
            setErrors={setErrors}
            extraValues={extraValues}
        >
            {children}
        </NewTemplate>
    );
};
