import React, { FC, useEffect, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { fetchAllModules, ModulesState } from '../../../redux/slices/moduleSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchAllItems, ItemState } from '../../../redux/slices/itemSlice';
import SVGInline from 'react-inlinesvg';
import icons, { TemplateIcons } from '../../../assets/images/icons';
import { ExistingMenuOptionsWrapper, ModuleOption, ModuleTemplateIconHolder, PublishedStatusIconHolder } from './UseExistingDialog.css';
import { validator } from '../../../utils/fnValidator';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { Item, itemTypes } from '../../../types/Item';
import { fetchMenus, menusState } from '../../../redux/slices/menusSlice';
import { fetchAllPages, pagesState } from '../../../redux/slices/pagesSlice';
import { EMPTY_WORD_STRING, moduleTypes } from '../../../utils/Globals';
import { fetchSettings, settingsState } from '../../../redux/slices/settingsSlice';
import { DIALOG_NAMES, getPagesSelectOptions, ToastAlert } from '../../../utils/fnDialogs';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { TruncatedText, DropdownOptionWrapper } from '../../../style/styled-components/reusable.css';
import { audiencesState, fetchAudiences } from '../../../redux/slices/audienceSlice';
import { PUBLISHED_STATUS, renderPublishStatusIcon } from '../../../utils/fnPublish';
import { Module, templates } from '../../../types/Module';
import { fetchTemplates, templatesState } from '../../../redux/slices/templatesSlice';
import { parseItemTemplate, parseModuleTemplate, Template, templateType, templateTypes } from '../../../types/Template';
import _ from 'lodash';
import { DataPreviewDialog, DialogPreviewTypes } from '../VisualElements/DataPreviewDialog';
import { ContentSourceLabelContainer } from '../../Items/Items.css';
import { TemplateIconWrapper, TemplateOption } from '../../Modules/Dialogs/NewModule.css';
import ObjectNameTooltip from '../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../../../types/Object';
import { renderAutoCollectionAlertDialog, renderIncludeProviderCardLogoAlertDialog } from '../../Items/Dialogs/NewItem';
import { MenuItemTypes } from '../../../types/Menu';
import { Page } from '../../../types/Page';
import { useNavigate } from 'react-router-dom';

export enum EXISTING_ITEMS {
    MODULE = 'Module',
    ITEM = 'List or Item',
    PAGE = 'Page',
    MENU = 'Menu',
    SETTING = 'Setting',
    AUDIENCE = 'Audience',
    TEMPLATE = 'Template'
}

export type UseExistingDialogProps = {
    open: boolean;
    onClose: any;
    existingItemType: EXISTING_ITEMS;
    onSave?: (
        existingItemId?: string,
        contentType?: itemTypes,
        autoCollection?: boolean,
        removeIncludeProviderCardLogo?: boolean,
        itemHasActionName?: boolean,
        itemObject?: Item
    ) => void;
    onMultipleSave?: (objects?: any[], itemsToAdd?: Page[]) => void;
    itemIdsToExclude: string[];
    collectionType?: itemTypes;
    isCollection?: boolean;
    isAutoCollection?: boolean;
    includeProviderCardLogoProviderName?: string;
    moduleTypeToExclude?: moduleTypes;
    moduleTemplatesToExclude?: templates[];
    excludePreview?: boolean;
    existingTemplateType?: templateType;
    isMultiSelect?: boolean;
    isContentWorld?: boolean;
    restrictedContentWorld?: boolean; // used only for templates, to do not let 2 modules with cw in the same page
    isSuperAdminUI?: boolean;
};

const UseExistingDialog: FC<UseExistingDialogProps> = ({
    open,
    onClose,
    existingItemType,
    onSave,
    onMultipleSave,
    itemIdsToExclude,
    isAutoCollection,
    includeProviderCardLogoProviderName,
    isCollection,
    collectionType,
    moduleTypeToExclude,
    moduleTemplatesToExclude,
    excludePreview,
    existingTemplateType,
    isMultiSelect,
    isContentWorld,
    restrictedContentWorld,
    isSuperAdminUI
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [loading, setLoading] = useState(false);
    const [shouldAddItems, setShouldAddItems] = useState<boolean>(false);
    const { allModules: modules, validTemplates, loadingAllModules: loadingCMs }: ModulesState = useAppSelector((state) => state.modules);
    const {
        allItems: items,
        storeItemTypes,
        contentSourceTypes,
        loadingAllItems: loadingCIs
    }: ItemState = useAppSelector((state) => state.items);
    const { allPages: pages, loadingAllPages: loadingPages }: pagesState = useAppSelector((state) => state.pages);
    const { menus, loading: loadingMenus }: menusState = useAppSelector((state) => state.menus);
    const { settings, loading: loadingSettings }: settingsState = useAppSelector((state) => state.settings);
    const { audiences, loading: loadingAudiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { templates, loading: loadingTemplates }: templatesState = useAppSelector((state) => state.templates);

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [errors, setErrors] = useState<{ selectedItem?: string }>({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [existingItemOptions, setExistingItemOptions] = useState<
        { label: string | JSX.Element; value: string; valueForSearch?: string }[]
    >([]);
    const [filterOptions, setFilterOptions] = useState<{ label: string | JSX.Element; value: string; valueForSearch?: string }[]>([]);
    const [filterValue, setFilterValue] = useState<string>('-1');
    const [existingItem, setExistingItem] = useState<{ label: string | JSX.Element; value: string } | undefined>(undefined);
    //used for multiple selection
    const [existingItems, setExistigItems] = useState<string[]>([]);
    const [showPreview, setShowPreview] = useState<boolean>(true);

    const type =
        existingItemType === EXISTING_ITEMS.TEMPLATE
            ? templates?.[templateTypes.ITEM]?.find((item) => item._id === existingItem?.value)?.values?.itemType
            : items?.find((item) => item._id === existingItem?.value)?.itemType;
    const shouldRenderAutoCollectionAlert =
        (itemIdsToExclude.length > 0 && !!isAutoCollection) ||
        (itemIdsToExclude.length === 0 && !!isAutoCollection && type === itemTypes.EDITORIAL);

    const contentSourceType =
        existingItemType === EXISTING_ITEMS.TEMPLATE
            ? templates?.[templateTypes.ITEM]?.find((item) => item._id === existingItem?.value)?.values?.contentSourceType
            : items?.find((item) => item._id === existingItem?.value)?.contentSourceType;

    const shouldRenderIncludeProviderCardLogoAlert =
        !!includeProviderCardLogoProviderName && type === itemTypes.DYNAMIC && contentSourceType !== includeProviderCardLogoProviderName;

    const generalLoading =
        loadingCIs || loadingCMs || loadingMenus || loadingPages || loadingSettings || loadingAudiences || loadingTemplates;

    const withFilter =
        [EXISTING_ITEMS.ITEM, EXISTING_ITEMS.MODULE].includes(existingItemType) ||
        (existingItemType === EXISTING_ITEMS.TEMPLATE &&
            existingTemplateType &&
            [templateTypes.ITEM as string, templateTypes.MODULE as string].includes(existingTemplateType));

    const existingData = {
        [EXISTING_ITEMS.MODULE]: modules,
        [EXISTING_ITEMS.ITEM]: items,
        [EXISTING_ITEMS.PAGE]: pages,
        [EXISTING_ITEMS.MENU]: menus,
        [EXISTING_ITEMS.SETTING]: settings,
        [EXISTING_ITEMS.AUDIENCE]: audiences,
        [EXISTING_ITEMS.TEMPLATE]: templates
    };

    useEffect(() => {
        setIsOpen(open);

        const dataLoader = {
            [EXISTING_ITEMS.MODULE]: loadModules,
            [EXISTING_ITEMS.ITEM]: loadItems,
            [EXISTING_ITEMS.PAGE]: loadPages,
            [EXISTING_ITEMS.MENU]: loadMenus,
            [EXISTING_ITEMS.SETTING]: loadSettings,
            [EXISTING_ITEMS.AUDIENCE]: loadAudiences,
            [EXISTING_ITEMS.TEMPLATE]: loadTemplates
        };

        if (open) {
            dataLoader[existingItemType]?.(false, activeProjectId);
            // Pages are needed for menu items
            if (existingItemType === EXISTING_ITEMS.MENU) {
                loadPages(false, activeProjectId);
            }
        }
    }, [open, activeProjectId]);

    useEffect(() => {
        setLoading(generalLoading);
        const sortedOptions = [...existingItemOptions].sort((a, b) => {
            const nameA = a.valueForSearch?.toLowerCase() || '';
            const nameB = b.valueForSearch?.toLowerCase() || '';

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        setExistingItem(generalLoading ? undefined : sortedOptions?.[0]);
    }, [generalLoading, existingItemOptions]);

    //ITEMS
    const loadItems = async (_addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchAllItems({ projectId })).unwrap();
    };
    // MODULES
    const loadModules = async (_addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchAllModules({ projectId })).unwrap();
    };
    // PAGES
    const loadPages = async (_addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchAllPages({ projectId })).unwrap();
    };
    // MENUS
    const loadMenus = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchMenus({ addPermissions, projectId })).unwrap();
    };

    // SETTINGS
    const loadSettings = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchSettings({ addPermissions, projectId })).unwrap();
    };

    // AUDIENCES
    const loadAudiences = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchAudiences({ addPermissions, projectId })).unwrap();
    };

    //TEMPLATES
    const loadTemplates = async () => {
        await dispatch(fetchTemplates({ type: existingTemplateType || templateTypes.PAGE })).unwrap();
    };

    // use effect for populating the filter dropdown
    useEffect(() => {
        if (!isOpen || !withFilter) return;

        let options: any[] = [{ value: '-1', label: 'View All' }];

        switch (existingItemType) {
            case EXISTING_ITEMS.ITEM:
                collectionType !== itemTypes.DYNAMIC &&
                    items.some((item) => !itemIdsToExclude.includes(item._id) && item.itemType === itemTypes.EDITORIAL) &&
                    options.push({
                        value: 'editorial',
                        label: (
                            <ContentSourceLabelContainer>
                                <SVGInline src={icons.editorialIcon} />
                                <span>Editorial</span>
                            </ContentSourceLabelContainer>
                        ),
                        valueForSearch: 'Editorial'
                    });
                if (contentSourceTypes?.length && collectionType !== itemTypes.EDITORIAL && !isContentWorld) {
                    options.push(
                        ...contentSourceTypes
                            .filter((source) =>
                                items.some((item) => !itemIdsToExclude.includes(item._id) && item.contentSourceType === source.value)
                            )
                            .map((source) => ({
                                value: source.value,
                                label: (
                                    <ContentSourceLabelContainer>
                                        <SVGInline src={icons.dynamicIcon} />
                                        <span>{source.title}</span>
                                    </ContentSourceLabelContainer>
                                ),
                                valueForSearch: source.title
                            }))
                    );
                }
                setFilterOptions(options);
                break;
            case EXISTING_ITEMS.MODULE:
                options.push(
                    ...(validTemplates
                        ?.filter((module) => (moduleTemplatesToExclude ? !moduleTemplatesToExclude.includes(module.value) : true))
                        .filter((template) =>
                            modules.some((module) => !itemIdsToExclude.includes(module._id) && module.template === template.value)
                        )
                        .map((item, index) => ({
                            value: item.value,
                            label: (
                                <TemplateOption key={index}>
                                    <TemplateIconWrapper>
                                        <SVGInline src={TemplateIcons[item.key]} />
                                    </TemplateIconWrapper>
                                    <span>{item.title}</span>
                                </TemplateOption>
                            ),
                            valueForSearch: item.title
                        })) || [])
                );
                setFilterOptions(options);
                break;
            case EXISTING_ITEMS.TEMPLATE:
                if (existingTemplateType === templateTypes.MODULE) {
                    const modulesTemplates = templates?.[existingTemplateType];
                    options.push(
                        ...(validTemplates
                            ?.filter((module) => (moduleTemplatesToExclude ? !moduleTemplatesToExclude.includes(module.value) : true))
                            .filter((template) =>
                                modulesTemplates?.some(
                                    (module) => !itemIdsToExclude.includes(module._id) && module.values?.template === template.value
                                )
                            )
                            .map((item, index) => ({
                                value: item.value,
                                label: (
                                    <TemplateOption key={index}>
                                        <TemplateIconWrapper>
                                            <SVGInline src={TemplateIcons[item.key]} />
                                        </TemplateIconWrapper>
                                        <span>{item.title}</span>
                                    </TemplateOption>
                                ),
                                valueForSearch: item.title
                            })) || [])
                    );
                    setFilterOptions(options);
                    break;
                }

                if (existingTemplateType === templateTypes.ITEM) {
                    const itemTemplates = templates?.[existingTemplateType];
                    collectionType !== itemTypes.DYNAMIC &&
                        itemTemplates?.some(
                            (item) => !itemIdsToExclude.includes(item._id) && item.values?.itemType === itemTypes.EDITORIAL
                        ) &&
                        options.push({
                            value: 'editorial',
                            label: (
                                <ContentSourceLabelContainer>
                                    <SVGInline src={icons.editorialIcon} />
                                    <span>Editorial</span>
                                </ContentSourceLabelContainer>
                            ),
                            valueForSearch: 'Editorial'
                        });

                    if (contentSourceTypes?.length && collectionType !== itemTypes.EDITORIAL && !isContentWorld) {
                        options.push(
                            ...contentSourceTypes
                                .filter((source) =>
                                    itemTemplates?.some(
                                        (item) => !itemIdsToExclude.includes(item._id) && item.values?.contentSourceType === source.value
                                    )
                                )
                                .map((source) => ({
                                    value: source.value,
                                    label: (
                                        <ContentSourceLabelContainer>
                                            <SVGInline src={icons.dynamicIcon} />
                                            <span>{source.title}</span>
                                        </ContentSourceLabelContainer>
                                    ),
                                    valueForSearch: source.title
                                }))
                        );
                    }
                    setFilterOptions(options);
                    break;
                }

            default:
                break;
        }
    }, [isOpen, existingItemType, contentSourceTypes, validTemplates, collectionType, moduleTemplatesToExclude, items, modules, templates]);

    // Use effect for populating the dropdown menu
    useEffect(() => {
        if (!isOpen) return;

        switch (existingItemType) {
            case EXISTING_ITEMS.MODULE:
                setExistingItemOptions(
                    modules
                        .filter((module) => !itemIdsToExclude.includes(module._id))
                        .filter((module) => (moduleTypeToExclude ? module.moduleType !== moduleTypeToExclude : true))
                        .filter((module) => (moduleTemplatesToExclude ? !moduleTemplatesToExclude.includes(module.template) : true))
                        .filter((module) => (excludePreview ? !module.preview : true))
                        .filter((module) => (filterValue === '-1' ? true : module.template === filterValue))

                        .map((module, index) => {
                            const template = validTemplates?.find((t) => t.value === module.template);
                            return {
                                value: module._id,
                                label: (
                                    <ModuleOption key={index}>
                                        <ModuleTemplateIconHolder>
                                            <SVGInline src={template ? TemplateIcons[template?.key] : TemplateIcons.GALLERY} />
                                        </ModuleTemplateIconHolder>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={module._id}
                                                name={module.name || EMPTY_WORD_STRING}
                                                type={ObjectTypes.MODULES}
                                            />
                                        </TruncatedText>
                                        <div>
                                            <TranslationTooltip translationKey={module.name} />
                                        </div>
                                        <PublishedStatusIconHolder>
                                            {renderPublishStatusIcon(module.publishStatus)}
                                        </PublishedStatusIconHolder>
                                    </ModuleOption>
                                ),
                                valueForSearch: module.name || EMPTY_WORD_STRING
                            };
                        })
                );
                break;
            case EXISTING_ITEMS.ITEM:
                // depending on the collection type we should display only editorial/dynamic items
                // for content world module we should only display editorial items
                const itemsToShow = collectionType
                    ? items.filter((item) => item.itemType === collectionType)
                    : isContentWorld
                    ? items.filter((item) => item.itemType === itemTypes.EDITORIAL)
                    : items;
                setExistingItemOptions(
                    itemsToShow
                        .filter((item) => !itemIdsToExclude.includes(item._id))
                        .filter((item) =>
                            filterValue === '-1'
                                ? true
                                : filterValue === itemTypes.EDITORIAL
                                ? item.itemType === filterValue
                                : item.contentSourceType === filterValue
                        )
                        .map((item) => {
                            const itemType = storeItemTypes?.find((type) => type.value === item.itemType);
                            const contentSource =
                                itemType?.value === itemTypes.DYNAMIC &&
                                contentSourceTypes?.find((source) => source.value === item.contentSourceType);
                            return {
                                value: item._id,
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={item._id}
                                                name={item.name || EMPTY_WORD_STRING}
                                                type={ObjectTypes.ITEMS}
                                            />
                                        </TruncatedText>
                                        <TranslationTooltip translationKey={item.name} />
                                        {`(${contentSource ? contentSource?.title : itemType?.title})`}
                                        <PublishedStatusIconHolder>{renderPublishStatusIcon(item.publishStatus)}</PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                valueForSearch:
                                    `${item.name} ${contentSource ? contentSource?.title : itemType?.title} ` || EMPTY_WORD_STRING
                            };
                        })
                );
                break;

            case EXISTING_ITEMS.MENU:
                setExistingItemOptions(
                    menus
                        .filter((menu) => !itemIdsToExclude.includes(menu._id))
                        .map((menu) => {
                            return {
                                value: menu._id,
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={menu._id}
                                                name={menu.name || EMPTY_WORD_STRING}
                                                type={ObjectTypes.MENUS}
                                            />
                                        </TruncatedText>
                                        <PublishedStatusIconHolder>
                                            {renderPublishStatusIcon(menu.publishStatus, menu.publishAt)}
                                        </PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                valueForSearch: menu.name || EMPTY_WORD_STRING
                            };
                        })
                );
                break;
            case EXISTING_ITEMS.PAGE:
                setExistingItemOptions(
                    getPagesSelectOptions(
                        pages.filter((page) => !itemIdsToExclude.includes(page._id)),
                        activeProjectId || '',
                        navigate
                    )
                );
                break;
            case EXISTING_ITEMS.SETTING:
                setExistingItemOptions(
                    settings
                        .filter((setting) => !itemIdsToExclude.includes(setting._id))
                        .map((setting) => {
                            return {
                                value: setting._id,
                                label: (
                                    <DropdownOptionWrapper>
                                        <TruncatedText>
                                            <ObjectNameTooltip
                                                id={setting._id}
                                                name={setting.name || EMPTY_WORD_STRING}
                                                type={ObjectTypes.SETTINGS}
                                            />
                                        </TruncatedText>

                                        <PublishedStatusIconHolder>
                                            {renderPublishStatusIcon(setting.publishStatus, setting.publishAt)}
                                        </PublishedStatusIconHolder>
                                    </DropdownOptionWrapper>
                                ),
                                valueForSearch: setting.name || EMPTY_WORD_STRING
                            };
                        })
                );
                break;
            case EXISTING_ITEMS.AUDIENCE:
                // We should display only audiences which are not assigned to any target group
                setExistingItemOptions(
                    audiences
                        .filter((audience) => !itemIdsToExclude.includes(audience._id))
                        .filter((audience) => !audience.targetGroupId)
                        .map((audience) => {
                            return {
                                value: audience._id,
                                label: (
                                    <ObjectNameTooltip
                                        id={audience._id}
                                        name={audience.name || EMPTY_WORD_STRING}
                                        type={ObjectTypes.AUDIENCES}
                                    />
                                ),
                                valueForSearch: audience.name || EMPTY_WORD_STRING
                            };
                        })
                );
                break;
            case EXISTING_ITEMS.TEMPLATE:
                const existingTemplates = templates?.[existingTemplateType || templateTypes.MODULE] || [];
                let templateToShow = existingTemplates;
                if (existingTemplateType === templateTypes.ITEM) {
                    templateToShow = collectionType
                        ? existingTemplates.filter((template) => template.values?.itemType === collectionType)
                        : isContentWorld
                        ? existingTemplates.filter((template) => template.values?.itemType === itemTypes.EDITORIAL)
                        : existingTemplates;
                }
                setExistingItemOptions(
                    templateToShow
                        .filter((template) => !itemIdsToExclude.includes(template._id))
                        .filter((template) => (moduleTypeToExclude ? template.values?.moduleType !== moduleTypeToExclude : true))
                        .filter((template) =>
                            moduleTemplatesToExclude ? !moduleTemplatesToExclude.includes(template.values?.template) : true
                        )
                        .filter((template) => (restrictedContentWorld ? !template.values?.contentWorld : true))
                        .filter((template) => (excludePreview ? !template.values?.preview : true))
                        .filter((template) => {
                            if (filterValue === '-1') return true;

                            return existingTemplateType === templateTypes.MODULE
                                ? template.values?.template === filterValue
                                : filterValue === itemTypes.EDITORIAL
                                ? template.values?.itemType === filterValue
                                : template.values?.contentSourceType === filterValue;
                        })
                        .map((template, index) => {
                            if (existingTemplateType === templateTypes.MODULE) {
                                const moduleTemplate = validTemplates?.find((t) => t.value === template.values?.template);
                                return {
                                    value: template._id,
                                    label: (
                                        <ModuleOption key={index}>
                                            <span>
                                                <SVGInline
                                                    src={moduleTemplate ? TemplateIcons[moduleTemplate?.key] : TemplateIcons.GALLERY}
                                                />
                                            </span>
                                            <span>{template.values?.name || EMPTY_WORD_STRING}</span>
                                            {renderPublishStatusIcon(
                                                template.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED,
                                                undefined,
                                                template?.released
                                                    ? 'superadmin_module_templates_icon_released'
                                                    : 'superadmin_module_templates_icon_unreleased'
                                            )}
                                        </ModuleOption>
                                    ),
                                    valueForSearch: template.values.name || EMPTY_WORD_STRING
                                };
                            }

                            if (existingTemplateType === templateTypes.ITEM) {
                                const contentSource =
                                    template.values?.itemType === itemTypes.DYNAMIC &&
                                    contentSourceTypes?.find((source) => source.value === template.values.contentSourceType);
                                return {
                                    value: template._id,
                                    label: (
                                        <DropdownOptionWrapper>
                                            <TruncatedText>{template.values.name || EMPTY_WORD_STRING}</TruncatedText>
                                            {`(${contentSource ? contentSource?.title : _.capitalize(template.values?.itemType)})`}
                                            {renderPublishStatusIcon(
                                                template.released ? PUBLISHED_STATUS.PUBLISHED : PUBLISHED_STATUS.UNPUBLISHED,
                                                undefined,
                                                template?.released
                                                    ? 'superadmin_items_templates_icon_released'
                                                    : 'superadmin_items_templates_icon_unreleased'
                                            )}
                                        </DropdownOptionWrapper>
                                    ),
                                    valueForSearch:
                                        `${template.values.name} ${contentSource ? contentSource?.title : template.values.itemType} ` ||
                                        EMPTY_WORD_STRING
                                };
                            }

                            return {
                                value: '',
                                label: ''
                            };
                        })
                );
                break;
            default:
                break;
        }
        setLoading(false);
    }, [
        isOpen,
        existingItemType,
        items,
        modules,
        pages,
        menus,
        settings,
        audiences,
        templates,
        collectionType,
        filterValue,
        excludePreview
    ]);

    const getMenuItemsPage = () => {
        const menuPages: Page[] = [];

        const existingMenus = menus.filter((menu) => existingItems.includes(menu._id));
        if (!existingMenus.length) return [];

        for (const menu of existingMenus) {
            const { items } = menu;

            if (!!items.length) {
                items.forEach((elem: any) => {
                    //we look for the page id only in menus with pageType items
                    if (elem?.type === MenuItemTypes.PAGE) {
                        const page = pages.find((page) => page._id === elem.pageId);
                        page && menuPages.push(page);
                    }
                });
            }
        }

        return menuPages;
    };

    const handleCloseClick = (evt?: any) => {
        evt?.preventDefault();
        setExistingItem(undefined);
        setErrors({});
        setFilterValue('-1');
        setShowPreview(true);
        setShouldAddItems(false);
        setExistigItems([]);
        if (onClose) {
            onClose();
        }
    };

    const validateSelection = () => {
        const newErrors = { ...errors };
        newErrors.selectedItem = validator({ required: true }, isMultiSelect ? existingItems : existingItem?.value || '');
        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const handleSaveClick = () => {
        if (!validateSelection()) return;

        let itemPages: Page[] = [];
        let objectsToAdd: any[] = [];
        const item =
            existingItemType === EXISTING_ITEMS.TEMPLATE
                ? parseItemTemplate(templates.item?.find((item) => item._id === existingItem?.value))
                : items.find((item) => item._id === existingItem?.value);
        const type = item?.itemType;
        if (collectionType && type !== collectionType) return;

        let hasActionName = true;
        if (type === itemTypes.EDITORIAL && !item?.actionName) {
            hasActionName = false;
        }
        if (existingItemType === EXISTING_ITEMS.MENU && shouldAddItems) {
            itemPages = getMenuItemsPage();
        }

        if (shouldRenderAutoCollectionAlert) {
            ToastAlert(
                'critical_warning',
                '',
                '',
                undefined,
                DIALOG_NAMES.DYNAMIC_ITEM_AUTOCOLLECTION,
                () => {
                    renderAutoCollectionAlertDialog();
                },
                shouldRenderIncludeProviderCardLogoAlert
                    ? () => {
                          ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                              renderIncludeProviderCardLogoAlertDialog();
                          });
                      }
                    : undefined
            );
        }
        if (shouldRenderIncludeProviderCardLogoAlert && !shouldRenderAutoCollectionAlert) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                renderIncludeProviderCardLogoAlertDialog();
            });
        }

        if (isMultiSelect) {
            const objects = existingData[existingItemType];
            if (Array.isArray(objects)) {
                objectsToAdd = (objects as any).filter((object: any) => {
                    return existingItems.includes(object._id);
                });
            }
        }

        isMultiSelect
            ? onMultipleSave?.(objectsToAdd, itemPages)
            : onSave?.(
                  existingItem?.value,
                  type,
                  shouldRenderAutoCollectionAlert,
                  shouldRenderIncludeProviderCardLogoAlert,
                  hasActionName,
                  hasActionName ? undefined : item
              );

        handleCloseClick();
    };
    const saveButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: handleSaveClick,
        disabled: generalLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const getObjectForPreview = (): Item | Module | Template | undefined => {
        switch (existingItemType) {
            case EXISTING_ITEMS.ITEM:
                return items.find((item) => existingItem?.value === item._id);
            case EXISTING_ITEMS.MODULE:
                return modules.find((module) => existingItem?.value === module._id);
            case EXISTING_ITEMS.TEMPLATE:
                if (existingTemplateType === templateTypes.ITEM) {
                    const item = templates?.[existingTemplateType]?.find((template) => existingItem?.value === template._id);
                    return item ? parseItemTemplate(item) : undefined;
                } else {
                    const module = templates?.[existingTemplateType || templateTypes.MODULE]?.find(
                        (template) => existingItem?.value === template._id
                    );
                    return module ? parseModuleTemplate(module) : undefined;
                }

            default:
                break;
        }
    };

    if (!isOpen) {
        return null;
    }

    const renderMenuOptions = () => {
        return (
            <ExistingMenuOptionsWrapper>
                <DialogRadioButton
                    active={!shouldAddItems}
                    onClick={() => {
                        setShouldAddItems(false);
                    }}
                    text={'Menu only'}
                />
                <DialogRadioButton
                    active={shouldAddItems}
                    onClick={() => {
                        setShouldAddItems(true);
                    }}
                    text={'Menu and linked pages'}
                />
            </ExistingMenuOptionsWrapper>
        );
    };

    const renderFilteredDialog = () => {
        return (
            <>
                <DialogDropdownSingle
                    labelText={'Filter'}
                    value={filterOptions.find((opt) => opt.value === filterValue)}
                    options={filterOptions}
                    placeholder={'Filter'}
                    toolTipText={
                        existingItemType === EXISTING_ITEMS.MODULE
                            ? 'Filter the modules, based on their UI Template'
                            : 'Filter the items or lists based on their source'
                    }
                    onChange={(value: any) => {
                        setFilterValue(value.value);
                    }}
                />
                <DialogDropdownSingle
                    labelText={`${existingItemType}`}
                    error={errors?.selectedItem}
                    value={existingItem}
                    options={loading ? [{ label: 'Loading...', value: '-1' }] : existingItemOptions}
                    placeholder={loading ? 'Loading...' : `There's no ${existingItemType} to choose from`}
                    onChange={(value: any) => setExistingItem(value)}
                    toolTipText={
                        isCollection
                            ? 'The collection can be composed of either one item with a Dynamic Source or multiple Editorial items'
                            : undefined
                    }
                    isDisabled={loading}
                />

                <DataPreviewDialog
                    previewType={
                        existingItemType === EXISTING_ITEMS.TEMPLATE
                            ? existingTemplateType === templateTypes.ITEM
                                ? DialogPreviewTypes.ITEM
                                : DialogPreviewTypes.MODULE
                            : existingItemType === EXISTING_ITEMS.MODULE
                            ? DialogPreviewTypes.MODULE
                            : DialogPreviewTypes.ITEM
                    }
                    object={getObjectForPreview()}
                    isPreviewOpen={showPreview}
                    togglePreview={() => {
                        setShowPreview(!showPreview);
                    }}
                    isSuperadminUI={isSuperAdminUI}
                />
            </>
        );
    };

    const warnings: any[] = [];
    if (shouldRenderAutoCollectionAlert) {
        warnings.push({ icon: icons.warningIcon, critical: true, onClick: () => renderAutoCollectionAlertDialog() });
    }

    if (shouldRenderIncludeProviderCardLogoAlert) {
        warnings.push({ icon: icons.infoIcon, onClick: () => renderIncludeProviderCardLogoAlertDialog() });
    }

    return (
        <GenericDialog
            title={`Add existing ${isMultiSelect ? `${existingItemType} (s)` : existingItemType}`}
            actionButtons={[cancelButton, saveButton]}
            type={DialogTypes.Form}
            onClose={handleCloseClick}
            dataCy={`use-existing-dialog-${existingItemType}`}
            warnings={warnings}
        >
            {withFilter ? (
                renderFilteredDialog()
            ) : (
                <>
                    {' '}
                    {isMultiSelect ? (
                        <DialogDropdownMultiple
                            labelText={`${existingItemType} (s)`}
                            options={loading ? [{ label: 'Loading...', value: '-1' }] : existingItemOptions}
                            error={errors?.selectedItem}
                            value={existingItemOptions.filter((opt) => existingItems.includes(opt.value))}
                            onChange={(value: any) => {
                                setExistigItems(value.map((elem: any) => elem.value));
                                setErrors({});
                            }}
                            placeholder={loading ? 'Loading...' : `Select at least one ${existingItemType} `}
                            isDisabled={loading}
                            allowSelectAll
                        />
                    ) : (
                        <DialogDropdownSingle
                            labelText={`${existingItemType}`}
                            error={errors?.selectedItem}
                            value={existingItem}
                            options={loading ? [{ label: 'Loading...', value: '-1' }] : existingItemOptions}
                            placeholder={loading ? 'Loading...' : `There's no ${existingItemType} to choose from`}
                            onChange={(value: any) => setExistingItem(value)}
                            toolTipText={
                                collectionType
                                    ? 'The collection can be composed of either one item with a Dynamic Dource or multiple Editorial items'
                                    : undefined
                            }
                            isDisabled={loading}
                        />
                    )}
                    {existingItemType === EXISTING_ITEMS.MENU && renderMenuOptions()}
                </>
            )}
        </GenericDialog>
    );
};

export default UseExistingDialog;
