import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { ButtonWrapper } from '../../Buttons/Button/Button.css';
import createResourceImg from '../../../assets/images/createResource.png';
import useTemplateImg from '../../../assets/images/useTemplate.png';
import { SelectWrapper } from '../../common/Select/Select.css';

const theme: any = getTheme();

export const DashboardCardWrapper = styled.div`
    float: left;
    display: inline-block;
    vertical-align: top;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    width: 360px;
    min-height: 482px;
    position: relative;
    img {
        width: 360px;
        height: 202px;
        display: block;
        vertical-align: bottom;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    ${SelectWrapper} {
        label.error {
            display: none;
        }
        margin: 0;
        min-height: unset;
    }
`;

export const Picture = styled.picture`
    height: 0;
`;

export const DashboardCardDetails = styled.div`
    background: ${theme.palette.white.main};
    border-radius: 4px;
    border: transparent;
    padding: 24px 36px 28px 36px;
    ${ButtonWrapper} {
        margin: 16px 0;
    }
`;

export const LandingCardTitle = styled.div`
    height: 32px;
    padding-bottom: 24px;
    ${fontSizesCSS.Title3};
`;

export const LandingCardSubtitle = styled.div`
    height: 40px;
    padding-bottom: 4px;
    ${fontSizesCSS.Caption1};
`;

export const LandingCardLatestActive = styled.div`
    height: 20px;
    padding-bottom: 8px;
    color: ${theme.palette.primary.main};
`;

export const StyledIndicatorsContainer = styled.div`
    cursor: pointer;
    div {
        color: ${theme.palette.primary.main};
        &:hover {
            color: ${theme.palette.primary.main};
        }
    }
`;

export const StyledSingleValue = styled.div`
    cursor: pointer;
`;

export const DashboardCardSelectWrapper = styled.div`
    [class$='indicatorSeparator'] {
        display: none;
    }
`;

export const ResourceDefaultImage = styled.div<{ $createResource?: boolean }>`
    width: 100%;
    height: 202px;
    background: url(${(props) => (props.$createResource ? createResourceImg : useTemplateImg)});
    background-size: cover;
`;
