import styled from 'styled-components';
import { ButtonWrapper } from './Button/Button.css';
import getTheme from '../../style/themes/theme';

const theme: any = getTheme();

export const FeedbackButtonWrapper = styled(ButtonWrapper)<{ $withActions?: boolean }>`
    position: fixed;
    bottom: ${({ $withActions }) => ($withActions ? '108px' : '24px')};
    right: 36px;
    width: 179px;
    background: ${theme.palette.background.feedback};
    color: ${theme.palette.white.main};
    margin: 0;
    padding: 6px 12px;
    &:hover {
        background: ${theme.palette.background.feedbackHover};
    }
    z-index: 1301;
`;

export const FeedbackButtonInner = styled.div`
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
        width: 16px;
        height: 16px;
    }
`;
