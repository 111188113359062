import { FC, useEffect, useState } from 'react';
import { Template, TemplateDefaultValues, templateType, templateTypes } from '../../../../types/Template';
import GenericDialog, { DialogButton, DialogDropdownMultiple, DialogTypes } from '../../../common/Dialog/GenericDialog';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';
import { templatesState } from '../../../../redux/slices/templatesSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import _ from 'lodash';
import { TemplatePreview } from './TemplatePreview';
import getTheme from '../../../../style/themes/theme';
import { TenantsState, fetchTenants } from '../../../../redux/slices/tenantsSlice';
import { ProjectsState, fetchProjects } from '../../../../redux/slices/projectsSlice';
import { additionalSourceFields } from '../../../../types/DynamicSource';

export type TemplateProps = {
    open: boolean;
    templateType: templateType;
    template?: Template;
    extraValues?: any;
    errors?: any;
    warnings?: any[];
    setErrors?: (arg: any) => void;
    onClose: () => void;
    onSave: (arg: TemplateDefaultValues) => void;
    onRelease: () => void;
    onRetract: () => void;
};

const templateTitles = {
    [templateTypes.TARGET_GROUP]: 'Group',
    [templateTypes.PAGE]: 'Page',
    [templateTypes.MODULE]: 'Module',
    [templateTypes.ITEM]: 'Item',
    [templateTypes.PAGE_STYLE]: 'Page Style',
    [templateTypes.DYNAMIC_SOURCE]: 'Source',
    [templateTypes.AUDIENCE]: 'Audience'
};

const tagTooltip = 'The tags are used to categorize the templates, so the users have an easier overview in the projects.';

const theme: any = getTheme();

export const NewTemplate: FC<TemplateProps> = ({
    open,
    template,
    errors,
    templateType,
    extraValues,
    warnings,
    setErrors,
    onClose,
    onSave,
    onRelease,
    onRetract,
    children
}) => {
    const { loading, templatesTags, tagsLoading, templateTypeLoading, templates }: templatesState = useAppSelector(
        (state) => state.templates
    );

    const { tenants, loading: tenantsLoading }: TenantsState = useAppSelector((state) => state.tenants);
    const { projects, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);

    const dispatch = useDispatch();
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [released, setReleased] = useState<boolean>(false);

    //iconRelated
    const [templateIcon, setTemplateIcon] = useState<string>('');
    const [templateIconBackground, setTemplateIconBackground] = useState<string>(theme.palette.primary.main);

    //template tags
    const [templateTags, setTemplateTags] = useState<string[]>([]);
    const [templateTagsOptions, setTemplateTagsOptions] = useState<{ label?: string; value?: string }[]>([]);

    // project tenant
    const [templateProjectIds, setTemplateProjectIds] = useState<string[]>([]);
    const [templateTenantIds, setTemplateTenantIds] = useState<string[]>([]);

    const [projectOptions, setProjectOptions] = useState<{ label?: string; value?: string }[]>([]);
    const [tenantOptions, setTenantOptions] = useState<{ label?: string; value?: string }[]>([]);

    const loadProjects = async () => {
        return await dispatch(fetchProjects({})).unwrap();
    };

    const loadTenants = async () => {
        return await dispatch(fetchTenants({})).unwrap();
    };

    const resetState = () => {
        setReleased(false);
        setTemplateIcon('');
        setTemplateIconBackground(theme.palette.primary.main);
        setTemplateTags([]);
        setTemplateProjectIds([]);
        setTemplateTenantIds([]);
    };

    const handleClose = () => {
        onClose();
        resetState();
    };

    const handleSave = () => {
        onSave({
            icon: templateIcon,
            iconBackground: templateIconBackground,
            projectIds: templateProjectIds,
            tenantIds: templateTenantIds,
            tags: templateTags
        });
    };
    const generalLoading = loading || projectsLoading || tenantsLoading;

    const saveButton: DialogButton = {
        label: template ? 'Save' : 'Add',
        type: 'BLUE',
        onClick: handleSave,
        loading: generalLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleClose
    };

    const releaseButton: DialogButton = {
        label: 'Release',
        type: 'BLUE',
        onClick: onRelease,
        disabled: !template || hasChanges,
        loading: generalLoading
    };

    const retractButton: DialogButton = {
        label: 'Retract',
        type: 'DEFAULT',
        onClick: onRetract,
        loading: generalLoading,
        disabled: hasChanges
    };

    useEffect(() => {
        if (!template) return;

        const _hasChanges =
            [...(template.projectIds || [])].sort().join(',') !== [...templateProjectIds].sort().join(',') ||
            [...(template.tenantIds || [])].sort().join(',') !== [...templateTenantIds].sort().join(',') ||
            [...(template.tags || [])].sort().join(',') !== [...templateTags].sort().join(',') ||
            (template.icon || '') !== templateIcon ||
            (template.iconBackground || theme.palette.primary.main) !== templateIconBackground ||
            Object.keys(extraValues).some((key) => {
                const valueToCheck = extraValues[key];
                const existingValue = template.values?.[key];

                if (key === 'additionalSourceFields') {
                    return !_.isEqual(_.pick(template.values, additionalSourceFields) || {}, valueToCheck);
                }

                if (key === 'subService') {
                    return !!existingValue && existingValue !== valueToCheck;
                }

                if (key == 'autoScroll') {
                    return existingValue && existingValue !== parseInt(valueToCheck) * 1000;
                }

                if (key === 'maxNumberOfItems') {
                    return existingValue && valueToCheck !== `${existingValue}`;
                }

                if (key === 'videoAutoplay') {
                    return !!valueToCheck !== !!existingValue;
                }

                if (Array.isArray(valueToCheck)) {
                    return [...(valueToCheck || [])].sort().join(',') !== [...(existingValue || [])].sort().join(',');
                }

                if (typeof valueToCheck === 'object' && !!valueToCheck) {
                    return !_.isEqual(existingValue || {}, valueToCheck);
                }

                return valueToCheck !== existingValue;
            });

        setHasChanges(_hasChanges);
    }, [extraValues, template, templateIcon, templateIconBackground, templateTenantIds, templateProjectIds, templateTags]);

    useEffect(() => {
        const tagsOptions = templatesTags.map((key) => {
            return {
                label: _.startCase(key.replace('_', ' ').toLowerCase()),
                value: key
            };
        });

        setTemplateTagsOptions(tagsOptions);
    }, [templatesTags]);

    useEffect(() => {
        if (!open) return;
        !projects.length && loadProjects();
        !tenants.length && loadTenants();
    }, [open]);

    useEffect(() => {
        if (!tenants.length) return;

        setTenantOptions(
            tenants.map((tenant) => ({
                label: tenant.name,
                value: tenant._id
            }))
        );
    }, [tenants]);

    useEffect(() => {
        if (!projects.length) return;

        const filteredProjects = projects.filter((project) =>
            !!templateTenantIds.length ? templateTenantIds.includes(project.tenantId) : true
        );

        setProjectOptions(
            filteredProjects.map((project) => ({
                label: project.name,
                value: project._id
            }))
        );

        const filteredSelectedProjects = templateProjectIds.filter((id) => filteredProjects.map((proj) => proj._id).includes(id));
        setTemplateProjectIds(filteredSelectedProjects);
    }, [projects, templateTenantIds]);

    useEffect(() => {
        if (!template) return;
        setTemplateIconBackground(template.iconBackground || theme.palette.primary.main);
        setTemplateIcon(template.icon || '');
        setReleased(!!template.released);
        setTemplateTags(template.tags || []);
        setTemplateProjectIds(template.projectIds || []);
        setTemplateTenantIds(template.tenantIds || []);
    }, [template]);

    return (
        <GenericDialog
            type={DialogTypes.Form}
            actionButtons={[cancelButton, released ? retractButton : releaseButton, saveButton]}
            title={`${template ? 'Edit' : 'Create'} ${templateTitles[templateType]} Template`}
            onClose={handleClose}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.items }} // TODO: Adapt per templateType
            warnings={warnings}
        >
            {children}
            {templateType === templateTypes.ITEM && (
                <DialogDropdownMultiple
                    value={templateTagsOptions.filter((elem: any) => templateTags.includes(elem.value)) || []}
                    options={templateTagsOptions}
                    onChange={(value: any) => {
                        const newValues = value.map((elem: any) => elem.value);
                        setTemplateTags(newValues);
                    }}
                    placeholder={'Template Tags'}
                    labelText={'Template Tags'}
                    toolTipText={tagTooltip}
                    isDisabled={tagsLoading}
                    allowSelectAll
                    optional
                />
            )}
            <DialogDropdownMultiple
                value={tenantOptions.filter((opt) => templateTenantIds.includes(opt.value || ''))}
                options={tenantOptions}
                onChange={(value: any) => {
                    setTemplateTenantIds([...value.map((v: any) => v.value)]);
                }}
                placeholder={'Tenants'}
                labelText={'Tenants'}
                toolTipText={''}
                allowSelectAll
                optional
                isDisabled={tenantsLoading}
            />
            <DialogDropdownMultiple
                value={projectOptions.filter((opt) => templateProjectIds.includes(opt.value || ''))}
                options={projectOptions}
                onChange={(value: any) => setTemplateProjectIds([...value.map((v: any) => v.value)])}
                placeholder={'Projects'}
                labelText={'Projects'}
                toolTipText={''}
                allowSelectAll
                optional
                isDisabled={projectsLoading}
            />

            <TemplatePreview
                name={extraValues?.name}
                icon={templateIcon}
                iconBackground={templateIconBackground}
                setIcon={setTemplateIcon}
                setIconBackground={setTemplateIconBackground}
                iconError={errors?.icon}
                unsetIconError={() => setErrors?.(_.omit(errors, 'icon'))}
            />
        </GenericDialog>
    );
};
