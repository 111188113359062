import React, { FC, useEffect, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import {
    MenuDialogFieldsContainer,
    MenuDialogItemContainer,
    MenuDialogItemWrapper,
    MenuDialogOptionsWrapper,
    MenuItemTitle,
    NewMenuItemIcon
} from '../Menus.css';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { buildNoOfItemsOptions } from '../Menus';
import { NewMenuItemDialog } from './NewMenuItem';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { fetchAllPages, pagesState } from '../../../redux/slices/pagesSlice';
import { Menu, MenuItemTypes, MenuTypes } from '../../../types/Menu';
import { DeepLinkSpan } from '../../../style/styled-components/reusable.css';
import { PageRoutes, buildPathWithProjectId } from '../../../types/RouteTypes';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import { useNavigate } from 'react-router-dom';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { renderPublishStatusIcon } from '../../../utils/fnPublish';
import { validator } from '../../../utils/fnValidator';
import { fetchMenuTypes, menusState } from '../../../redux/slices/menusSlice';
import _ from 'lodash';
import { ObjectActions } from '../../common/Actions/Actions';
import { tableActions } from '../../common/Table/Table';
import { Page } from '../../../types/Page';
import { OTHER_APPLICATION_VALUE } from '../../Items/Dialogs/ActionDialog';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';

export type NewMenuDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: Menu, itemsToAdd?: Page[]) => void;
};

export const NewMenuDialog: FC<NewMenuDialogProps> = ({ open, onClose, onSave }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [noOfItems, setNoOfItems] = useState<number>(1);
    const [conditionIds, setConditionIds] = useState<string[]>([]);
    const [errors, setErrors] = useState<{ name?: string; type?: string }>({});
    const [openNewMenuItem, setOpenNewMenuItem] = useState<boolean>(false);
    const [items, setItems] = useState<any[]>([]);
    const [itemToEdit, setItemToEdit] = useState<any>(null);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [menuType, setMenuType] = useState<MenuTypes>(MenuTypes.MAIN);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<string[]>([]);
    const [shouldAddItemsToGroup, setShouldAddItemsToGroup] = useState<boolean>(false);

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { allPages: pages, loadingAllPages: pagesLoading }: pagesState = useAppSelector((state) => state.pages);
    const { menuTypes }: menusState = useAppSelector((state) => state.menus);

    const noOfItemsOptions = buildNoOfItemsOptions();
    const noOfItemsValue = noOfItemsOptions.find((opt) => opt.value === noOfItems) || noOfItemsOptions[1];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadPages = async (projectId: string) => {
        return await dispatch(fetchAllPages({ projectId })).unwrap();
    };
    const loadMenuTypes = async (projectId?: string) => {
        return await dispatch(fetchMenuTypes(projectId || '')).unwrap();
    };

    const menuTypeOptions = menuTypes.map((menuType: any) => {
        return {
            value: menuType.value,
            label: menuType.key === 'MAIN' ? 'Main' : 'Customer Service'
        };
    });

    useEffect(() => {
        if (!activeProjectId || !open) return;
        loadPages(activeProjectId);
        if (!menuTypes?.length) loadMenuTypes(activeProjectId);
    }, [open, activeProjectId]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleSaveClick = () => {
        if (!validateCreateMenu()) return;
        let menu: Menu = {
            _id: '',
            type: menuType || MenuTypes.MAIN,
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            name,
            conditionIds,
            items,
            bindToPages: false,
            abTestingGroupIds
        };
        let itemsToAdd: Page[] = [];
        if (shouldAddItemsToGroup) {
            itemsToAdd = getMenuItemsPage();
        }

        onSave(menu, itemsToAdd.length ? itemsToAdd : undefined);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setNoOfItems(1);
        setName('');
        setErrors({});
        setItems([]);
        setConditionIds([]);
        setAbTestingGroupIds([]);
        setMenuType(MenuTypes.MAIN);
        setShouldAddItemsToGroup(false);
        onClose();
    };

    const saveButton: DialogButton = {
        label: 'Create',
        type: 'BLUE',
        onClick: handleSaveClick,
        disabled: pagesLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const handleItemDeleteClick = (index: number) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };
    const handleItemAddClick = (item: any) => {
        if (editingIndex < 0) {
            setItems([...items, item]);
            setItemToEdit(null);
            return;
        }

        //CASE OF EDITING
        const newItems = [...items];
        newItems.splice(editingIndex, 1, item);
        setItems(newItems);
        setEditingIndex(-1);
    };

    const validateCreateMenu = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, name);
        newErrors.type = validator({ required: true }, menuType);

        setErrors(newErrors);
        return !Object.values(newErrors).filter((value) => !!value).length;
    };

    const getMenuItemsPage = () => {
        const menuPages: Page[] = [];

        if (!!items.length) {
            items.forEach((elem: any) => {
                //we look for the page id only in menus with pageType items
                if (elem?.type === MenuItemTypes.PAGE) {
                    const page = pages.find((page) => page._id === elem.pageId);
                    page && menuPages.push(page);
                }
            });
        }

        return menuPages;
    };

    const renderMenuItems = () => {
        const itemsLength = noOfItems || 1;
        const menuItems: JSX.Element[] = [];

        for (let i = 0; i < itemsLength; i++) {
            const item = items.length < i + 1 ? null : items[i];
            let currentItem: JSX.Element | null = null;

            if (item) {
                const { icon } = item;
                const isPage = item.type === MenuItemTypes.PAGE;
                if (menuType === MenuTypes.CUSTOMER_SERVICE) {
                    currentItem = <MenuDialogItemWrapper>{item.title}</MenuDialogItemWrapper>;
                } else {
                    if (isPage) {
                        const page: any = pages.find((page) => page._id === item.pageId) || {};
                        currentItem = (
                            <MenuDialogItemWrapper>
                                Page:
                                <DeepLinkSpan
                                    onClick={() =>
                                        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', page._id)))
                                    }
                                >
                                    {page?.name || EMPTY_WORD_STRING}
                                </DeepLinkSpan>
                                <TranslationTooltip translationKey={page.name} />
                                {renderPublishStatusIcon(page.publishStatus, page.publishAt)}
                            </MenuDialogItemWrapper>
                        );
                    } else {
                        currentItem = <MenuDialogItemWrapper> App: {item.appName || OTHER_APPLICATION_VALUE}</MenuDialogItemWrapper>;
                    }
                }

                menuItems.push(
                    <MenuDialogItemContainer $withIcon={!!icon}>
                        {currentItem}
                        {!!icon && (
                            <NewMenuItemIcon>
                                <SVGInline src={icon} />
                            </NewMenuItemIcon>
                        )}

                        <ObjectActions
                            actions={[tableActions.EDIT, tableActions.REMOVE]}
                            onEdit={() => {
                                setOpenNewMenuItem(true);
                                setItemToEdit(item);
                                setEditingIndex(i);
                            }}
                            onRemove={() => {
                                handleItemDeleteClick(i);
                            }}
                            tooltipTexts={{ edit: 'menus_components_icon_edit', delete: 'menus_components_icon_delete' }}
                        />
                    </MenuDialogItemContainer>
                );
            } else {
                currentItem = (
                    <MenuDialogItemContainer $creating onClick={() => setOpenNewMenuItem(true)}>
                        <MenuItemTitle>
                            <span>Create Menu Item</span>
                            <SVGInline src={icons.addIcon} />
                        </MenuItemTitle>
                    </MenuDialogItemContainer>
                );
                menuItems.push(currentItem);
            }
        }

        return menuItems;
    };

    const renderMenuItemsOptions = () => {
        return (
            <MenuDialogOptionsWrapper>
                <DialogRadioButton
                    active={!shouldAddItemsToGroup}
                    onClick={() => {
                        setShouldAddItemsToGroup(false);
                    }}
                    text={'Menu only'}
                />
                <DialogRadioButton
                    active={shouldAddItemsToGroup}
                    onClick={() => {
                        setShouldAddItemsToGroup(true);
                    }}
                    text={'Menu and linked pages'}
                />
            </MenuDialogOptionsWrapper>
        );
    };

    if (!isOpen) return null;
    return (
        <GenericDialog
            title={'Create Menu'}
            type={DialogTypes.Form}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.menus }}
        >
            <MenuDialogFieldsContainer>
                <DialogTextField
                    label={'Menu Name'}
                    placeholder={'Add a Menu Name'}
                    value={name}
                    onChange={(evt: any) => {
                        setName(evt.target.value);
                        setErrors(_.omit(errors, 'name'));
                    }}
                    error={errors.name}
                    dataCy={'menu-name-input'}
                />
                <DialogDropdownSingle
                    options={menuTypeOptions}
                    placeholder={'Menu Type'}
                    onChange={(evt: any) => {
                        setMenuType(evt.value);
                        setItems([]);
                        setErrors(_.omit(errors, 'type'));
                    }}
                    value={menuTypeOptions.find((opt) => opt.value === menuType)}
                    labeledSelect
                    error={errors.type}
                />
                <DialogDropdownSingle
                    options={noOfItemsOptions}
                    placeholder={'Amount of items'}
                    onChange={(evt: any) => {
                        setNoOfItems(evt.value);
                    }}
                    value={noOfItemsValue}
                    labeledSelect
                    unclickableIndexes={[0, 5, 8]}
                    notSorted
                />
                <TargetConditionsSelect
                    targets={conditionIds}
                    onChange={(val) => {
                        setConditionIds(val);
                    }}
                    hideCreateNew
                    inDialog
                />
                {/* <ABTestingGroupSelect
                    inDialog
                    selectedTargetConditions={conditionIds}
                    selectedGroups={abTestingGroupIds}
                    onChange={(newValues) => setAbTestingGroupIds(newValues)}
                /> */}

                {renderMenuItems()}
                {renderMenuItemsOptions()}
            </MenuDialogFieldsContainer>

            <NewMenuItemDialog
                open={openNewMenuItem}
                onClose={() => {
                    setOpenNewMenuItem(false);
                    setEditingIndex(-1);
                    setItemToEdit(null);
                }}
                onSave={(item) => {
                    handleItemAddClick(item);
                }}
                menuItem={itemToEdit}
                withoutNewOption
                menuType={menuType}
            />
        </GenericDialog>
    );
};
