import styled, { css } from 'styled-components';
import getTheme, { fontSizes } from '../../../style/themes/theme';

const theme: any = getTheme();

export const DateTextSection = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 68px;
    align-items: center;

    svg {
        margin: 0 10px;
    }
`;

export const CalendarContainer = styled.div<{ oneMonth: boolean }>`
    position: absolute;
    width: ${({ oneMonth }) => (oneMonth ? '400px' : '713px')};
    height: 580px;
    background: ${theme.palette.white.main};
    border-radius: 12px;

    .react-datepicker__header {
        background: unset;
        border: unset;
    }

    .react-datepicker-year-header {
        padding: 20px 24px;
    }

    .react-datepicker__year--container {
        margin-top: 104px;
        height: 236px;
    }

    .react-datepicker__year-wrapper {
        padding: 0 24px;
        max-width: unset;
        justify-content: center;
    }

    .react-datepicker__year-text {
        height: 88px;
        width: 120px;
        line-height: 88px;
        margin: 0;
        border-radius: 0;
        background-color: unset;
        color: ${theme.palette.black.main};
    }

    .react-datepicker__month {
        margin-top: 20px;
    }

    .react-datepicker__day {
        height: 44px;
        width: 44px;
        line-height: 44px;
        margin: 0;
        border-radius: 0;
        background-color: unset;
        color: ${theme.palette.black.main};
    }

    .react-datepicker__day--selected {
        border-radius: 50%;
        background: ${theme.palette.primary.main};
        color: ${theme.palette.white.main};
    }
    .react-datepicker__day--disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
    .react-datepicker__navigation {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        top: 20px;
        &:hover {
            background: ${theme.palette.background.pressed};
        }
    }
    .react-datepicker__day--outside-month {
        height: 0;
    }

    .react-datepicker__day-names {
        font-weight: bold;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 12px;
    }

    .react-datepicker__current-month {
        margin-bottom: 16px;
        margin-top: 20px;
        ${fontSizes.Caption2}
    }

    .react-datepicker__month-container {
        margin-top: 104px;

        ${({ oneMonth }) =>
            oneMonth
                ? css`
                      width: 100%;
                      border: unset;
                      border-top: 1px solid ${theme.palette.background.pressed};
                      border-bottom: 1px solid ${theme.palette.background.pressed};
                  `
                : css`
                      height: 406px;
                      width: 354px;
                      border: 1px solid ${theme.palette.background.pressed};
                  `};
    }

    .react-datepicker__week {
        height: 44px;
        z-index: 1;
        position: relative;
    }

    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__year-text--in-selecting-range {
        color: ${theme.palette.primary.main};
        background: ${theme.palette.background.pressed};
        font-weight: bold;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end {
        border-radius: 50%;
        background: ${theme.palette.primary.main};
        color: ${theme.palette.white.main};
    }

    .react-datepicker__year-text--range-start,
    .react-datepicker__year-text--selecting-range-start,
    .react-datepicker__year-text--range-end,
    .react-datepicker__year-text--selecting-range-end {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.white.main};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start {
        position: relative;

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            right: 0;
            width: 22px;
            height: 44px;
            background: ${theme.palette.background.pressed};
        }
    }

    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end {
        position: relative;

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            left: 0;
            width: 22px;
            height: 44px;
            background: ${theme.palette.background.pressed};
        }
    }

    .react-datepicker__day--today:not(.react-datepicker__day--in-range, .react-datepicker__day--selecting-range-start) {
        background: ${theme.palette.background.pressed};
        color: ${theme.palette.black.main};
        border-radius: 50%;
    }

    ${DateTextSection} {
        .MuiFormControl-root {
            ${({ oneMonth }) =>
                oneMonth
                    ? css`
                          max-width: 150px;
                          height: 100%;
                      `
                    : css`
                          height: unset;
                          min-height: unset;
                      `};
        }
    }
`;

export const DateContainer = styled.div`
    div.react-datepicker__tab-loop {
        position: absolute;
        top: 0;
        left: -50px;
    }
    label {
        z-index: 0;
        &:hover {
            z-index: 1;
        }
    }
    .MuiInputBase-root.Mui-disabled {
        background: ${theme.palette.white.main};
    }
    .MuiInputBase-adornedEnd {
        .MuiIconButton-label {
            svg {
                width: unset;
                height: unset;
            }
        }
    }
    div.react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container input {
            pointer-events: none;
        }

        label.MuiFormLabel-root {
            z-index: 0;
        }
    }
    :focus {
        outline: none;
    }
`;

export const MonthHeaderContainer = styled.div`
    margin-top: 20px;
`;

export const DateTimeHeaderContainer = styled.div<{ isDate?: boolean }>`
    width: 100%;
    height: 104px;
    align-items: center;
    ${(props) => props.isDate && 'position: absolute'};
    top: 0;
    display: flex;
    justify-content: space-between;
`;
export const DateTimeTitle = styled.div`
    ${fontSizes.Title3};
    padding: 36px;
`;

export const DateTimeCloseIcon = styled.div`
    padding-right: 36px;
    cursor: pointer;
`;

export const DateTimeActionsWrapper = styled.div`
    svg {
        padding: 0;
        width: 20px;
        height: 20px;
    }
    display: flex;
`;
