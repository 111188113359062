import styled from 'styled-components';
import SVGInline from 'react-inlinesvg';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const ArrowRight = styled(SVGInline)`
    z-index: 100;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 0;
    opacity: 0;
    transition: opacity linear 200ms;
`;

export const ArrowLeft = styled(SVGInline)`
    z-index: 100;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    opacity: 0;
    transition: opacity linear 200ms;
`;

export const CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    &:hover {
        ${ArrowLeft},${ArrowRight} {
            opacity: 1;
        }
    }
`;

export const CarouselItem = styled.div<{ visible: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity ease-in-out 500ms;
`;
