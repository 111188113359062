import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { CheckboxContainer, RadioText, ToggleContainer } from '../../common/Dialog/GenericDialog.css';

const theme: any = getTheme();

export const TemplateOption = styled.div`
    height: 44px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const OptionSection = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .MuiInputBase-adornedEnd {
        span {
            opacity: 0.7;
            padding: 6px 8px;
        }
    }

    ${ToggleContainer} {
        width: fit-content;
    }
`;

export const TemplateIconWrapper = styled.div`
    height: 24px;
    svg {
        height: 24px;
    }
`;

export const OptionsToggle = styled.div`
    color: ${theme.palette.primary.main};
    ${fontSizesCSS.Caption1};
    path {
        fill: ${theme.palette.primary.main};
    }
    cursor: pointer;
    width: fit-content;
`;

export const OptionEntry = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 24px;
    a {
        margin-bottom: 0;
    }
`;

export const TranslationToolTipContainer = styled.div``;

export const TranslationToolTipRow = styled.div`
    height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;

    img {
        width: 26px;
        height: 16px;
    }
`;

export const TooltipName = styled.div`
    margin-left: 10px;
    white-space: nowrap;
`;

export const TooltipTranslation = styled.div`
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
    padding-left: 10px;
    border-left: 0.5px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 100%;
`;

export const ModuleTypeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    // Remove the margin-bottom of the CheckboxContainer
    a {
        margin-bottom: unset;
    }
    svg {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const AutoCollectionExtraFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.background.menuItem};
    padding: 16px;
    ${fontSizesCSS.Body2};
    ${RadioText} {
        font-weight: normal;
    }
`;

export const CheckboxListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    max-width: 100%;
    overflow: hidden;
`;
