import {
    Table as MUITable,
    TableCell as MUITableCell,
    TableContainer as MUITableContainer,
    TableHead,
    TableRow,
    withTheme
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import { devices } from '../../../style/styled-components/reusable.css';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { ActionContainer, ActionIconHolder, ActionIconHolder as Holder, ActionWrapper } from '../Actions/Actions.css';

const theme: any = getTheme();

export const CheckboxTableCellWrapper = styled.div`
    align-items: end;
    display: flex;
`;

export const SelectAllText = styled.div`
    ${fontSizesCSS.Caption3};
    margin-left: auto;
    cursor: pointer;
    display: block;
    ${devices.tablet} {
        display: none;
    }
`;

export const SelectAllWrapper = styled.div<{ withCloseIcon?: boolean }>`
    border-radius: ${(props) => (props.withCloseIcon ? '0 8px 8px 0' : '4px')};
    margin-right: ${(props) => (props.withCloseIcon ? '8px' : '28px')};
    background: ${theme.palette.white.main};
    width: fit-content;
    margin-left: 8px;
    display: flex;
    gap: 4px;

    ${devices.tablet} {
        margin-right: ${(props) => (props.withCloseIcon ? '0' : '20px')};
        margin-left: auto;
    }

    // Remove the margin-bottom of the CheckboxContainer
    a {
        margin-bottom: unset;
        height: 16px;
    }
    svg {
        height: 16px;
        width: 16px;
        padding: 0;
    }
`;

export const DeleteIconWrapper = styled.div<{ disabled?: boolean }>`
    ${(props) =>
        props.disabled
            ? css`
                  svg {
                      cursor: unset;
                      path {
                          fill: ${theme.palette.background.hover};
                      }
                      opacity: 0.7;
                  }
              `
            : css`
                  svg {
                      cursor: pointer;
                      path {
                          fill: ${theme.palette.error.main};
                      }
                  }
              `}
`;

export const CloseSelectWrapper = styled.div`
    width: 16px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

export const TableHeader = styled(TableHead)`
    align-items: center;
    border: none;
    tr {
        .MuiTableCell-head {
            ${fontSizesCSS.Caption3}
            text-transform: capitalize;
            padding-bottom: 8px;
            padding-left: 24px;
            border-spacing: 2px;
            border-bottom: none;
            position: relative;
            height: 16px;

            ${devices.tablet} {
                padding-left: 16px;
            }
            svg {
                cursor: pointer;
            }
        }
    }
`;

export const ImageCell = styled(MUITableCell)<{ $width?: number; $um?: '%' | 'px' | 'vw' }>`
    width: ${(props) => (props.$width && props.$um ? props.$width + props.$um : 0)};
`;

export const DraggableTableCell = styled(MUITableCell)<{ $disabled?: boolean }>`
    ${(props) => props.$disabled && `pointer-events: none`};
    cursor: move;
    // This width forces the table cell to occupy just enough space to fit the drag icon in it
    width: 0;
    svg {
        padding: 0;
        opacity: 0.7;
        vertical-align: middle;

        ${devices.mobileL} {
            padding-right: 4px;
        }
        &:hover {
            opacity: 1;
        }
    }
`;

export const GroupedByTableRow = styled(TableRow)``;
export const BlankTableRow = styled(TableRow)``;
export const TitleTableRow = styled(TableRow)``;
export const GroupedByTitle = styled.span``;
export const GroupedByIcon = styled.img``;

export const WidthTableCell = styled(MUITableCell)<{
    $width?: number;
    $wMobS?: number;
    $wMobL?: number;
    $wTab?: number;
    $wDesktopS?: number;
    $wDesktopL?: number;
    $um: '%' | 'px' | 'vw';
    $fixedSize?: boolean;
}>`
    user-select: none;

    max-width: ${(props) => (props.$width ? props.$width + props.$um : 'auto')};
    ${(props) => props.$fixedSize && `width: ${props.$width ? props.$width + props.$um : 'auto'}`};

    ${(props) =>
        props.$wDesktopL &&
        `${devices.desktopL} {
            max-width: ${props.$wDesktopL + props.$um};
            ${props.$fixedSize && `width: ${props.$wDesktopL + props.$um}`}
        }`}

    ${(props) =>
        props.$wDesktopS &&
        `${devices.desktopS} {
            max-width: ${props.$wDesktopS + props.$um};
            ${props.$fixedSize && `width: ${props.$wDesktopS + props.$um}`}
        }`};

    ${(props) =>
        props.$wTab &&
        `${devices.tablet} {
            max-width: ${props.$wTab + props.$um};
            ${props.$fixedSize && `width: ${props.$wTab + props.$um}`}
        }`};

    ${(props) =>
        props.$wMobL &&
        `${devices.mobileL} {
            max-width: ${props.$wMobL + props.$um};
            ${props.$fixedSize && `width: ${props.$wMobL + props.$um}`}
        }`};

    ${(props) =>
        props.$wMobS &&
        `${devices.mobileS} {
            max-width: ${props.$wMobS + props.$um};
            ${props.$fixedSize && `width: ${props.$wMobS + props.$um}`}
        }`};
`;

export const TablePublishStatusHolder = styled.div`
    width: 24px;
    height: 24px;
    padding: 4px;
    &:hover {
        cursor: pointer;
    }
`;
export const EncodeStatusHolder = styled.div`
    width: 24px;
    height: 24px;
    padding: 4px;
    opacity: 0.7;
    &:hover {
        opacity: 1;
        cursor: pointer;
    }
`;
export const TablePlacedStatusHolder = styled.div`
    display: none;
`;

export const Table = withTheme(styled(MUITable)`
    table-layout: auto;

    .MuiTableCell-root {
        padding: 0 0 0 24px;
        ${devices.tablet} {
            padding: 0 0 0 16px;
        }
    }
    .MuiTableBody-root {
        background-color: ${(props) => props.theme.palette.white.main};

        .MuiTableRow-root {
            height: 76px;
            &:hover {
                background: ${(props) => props.theme.palette.background.active};
                transition: background-color 100ms ease-in-out;
                .td-actions {
                    display: flex;
                }
                .td-actions-more {
                    display: none;
                }
                .MuiTableCell-root {
                    ${ActionWrapper} {
                        ${Holder} {
                            cursor: pointer;
                            display: block;
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
                ${TablePlacedStatusHolder} {
                    display: block;
                }
            }
        }
        ${ActionContainer} {
            padding-right: 24px;
            ${devices.tablet} {
                padding-right: 16px;
            }
        }
        ${GroupedByTableRow} {
            height: 24px;
            background-color: ${(props) => props.theme.palette.background.pressed};
            td {
                padding-left: 24px;
                color: ${(props) => props.theme.palette.text.main};
                ${fontSizesCSS.Caption4};
                border: none;
            }
            ${GroupedByTitle} {
                display: inline-block;
                margin: 4px;
            }
            ${GroupedByIcon} {
                width: 16px;
                height: 16px;
                margin: -4px 0;
            }
        }
        ${BlankTableRow} {
            height: 24px;
            background: transparent;
        }
        ${TitleTableRow} {
            background-color: ${(props) => props.theme.palette.background.pressed};
            height: 40px;
            td {
                ${fontSizesCSS.Body2};
                border: none;
            }
        }

        .MuiTableCell-root {
            ${fontSizesCSS.Body1}

            a {
                text-decoration: none;
                color: ${(props) => props.theme.palette.primary.main};
            }
        }
    }
`);

export const TableRowWrapper = styled(TableRow)`
    background: ${theme.palette.white.main};
    height: 104px;
    ${WidthTableCell} {
        border: none;
    }

    ${ActionContainer} {
        margin-left: auto;
        padding-right: 24px;
    }

    &:hover {
        .MuiTableCell-root > ${ActionContainer} {
            ${ActionWrapper} {
                ${ActionIconHolder} {
                    display: inline;
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
`;

export const SmallTableRowWrapper = styled(TableRowWrapper)`
    height: 76px;
    ${WidthTableCell} {
        border-top: 1px solid ${theme.palette.background.pressed};
        border-bottom: none;
    }
`;

export const SquareImageContainer = styled.div<{
    $src: string;
    $height?: number;
    $width?: number;
    $um?: string;
}>`
    width: ${({ $width, $um }) => ($width ? `${$width}${$um || 'px'}` : '48px')};
    height: ${({ $height, $um }) => ($height ? `${$height}${($um === 'vw' ? 'vh' : $um) || 'px'}` : '48px')};
    background-image: url('${(props) => props.$src}');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
`;

export const RoundImageContainer = styled.div<{
    $src: string;
    $height?: number;
    $width?: number;
    $um?: string;
}>`
    width: ${({ $width, $um }) => ($width ? `${$width}${$um || 'px'}` : '48px')};
    height: ${({ $height, $um }) => ($height ? `${$height}${($um === 'vw' ? 'vh' : $um) || 'px'}` : '48px')};
    border-radius: 50%;
    background-image: url('${(props) => props.$src}');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
`;

export const TableContainer = styled(MUITableContainer)`
    width: auto;
    overflow: visible;
    position: relative;
`;

export const TableHeaderCell = styled.div`
    align-items: center;
    user-select: none;
    display: flex;
    span {
        display: unset;
    }
`;

export const SortableTableHeaderCell = styled(TableHeaderCell)`
    cursor: pointer;
`;

export const HeaderSortIcon = styled.div`
    align-items: center;
    padding-left: 8px;
    min-width: 12px;
    display: flex;
    height: 16px;
    width: 12px;
`;
