import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../../common/Dialog/GenericDialog';
import { StyleTextFieldWrapper } from '../../../StyleAndBranding/PageStyle/PageStyle.css';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../../hooks/redux';
import icons from '../../../../assets/images/icons';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import {
    Condition,
    ConditionStateColumn,
    ConditionsWrapper,
    ConditionTitle,
    ConditionValues,
    LanguageFlag,
    SubTitleWrapper
} from '../Conditions.css';
import { NewConditionDialog } from './NewCondition';

import _ from 'lodash';
import { ObjectNameLabel } from '../../../../style/styled-components/reusable.css';
import { getFullDate, generateTimeString } from '../../../../utils/fnDate';
import { MoreInfoDialog, MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';
import { fetchCountryCodes, fetchLanguageCodes, LanguagesState } from '../../../../redux/slices/languagesSlice';
import { validator } from '../../../../utils/fnValidator';
import { ObjectActions } from '../../../common/Actions/Actions';
import { tableActions } from '../../../common/Table/Table';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';
import { CategoriesKeys, keysTitle, valueKeys } from '../../../../types/DisplayCondition';

export type DisplayConditionDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (condition: any) => void;
};

export const DisplayConditionDialog: FC<DisplayConditionDialogProps> = ({ open, onClose, onSave }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [values, setValues] = useState<any>({});
    const [inverted, setInverted] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    const [showNewCondition, setShowNewCondition] = useState(false);
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    const [editingKey, setEditingKey] = useState<string>('');

    const { languageCodes, countryCodes }: LanguagesState = useAppSelector((state) => state.languages);

    const dispatch = useDispatch();

    const loadLanguageCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const loadCountryCodes = async () => {
        return await dispatch(fetchCountryCodes()).unwrap();
    };

    useEffect(() => {
        setIsOpen(open);
        if (open) {
            !languageCodes.length && loadLanguageCodes();
            !countryCodes.length && loadCountryCodes();
        }
    }, [open]);

    const handleSave = async () => {
        const invalidName = validator({ required: true, minLength: 4 }, name);
        if (invalidName) {
            return setErrors({ name: invalidName });
        }
        const condition = Object.keys(values).reduce((prev, curr) => {
            return {
                ...prev,
                [curr]: {
                    is: !inverted[curr],
                    value: values[curr]
                }
            };
        }, {});
        onSave({ ...condition, name });
        handleClose();
    };

    const handleClose = () => {
        setName('');
        setValues({});
        setInverted({});
        setErrors({});
        onClose();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSave
    };

    const closeButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleClose
    };

    const renderConditionValues = (key: CategoriesKeys, value: any) => {
        switch (key) {
            case CategoriesKeys.SEGMENTS:
                const segmentsLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < 2) {
                        return <ObjectNameLabel key={`segment_${i}`}>{value}</ObjectNameLabel>;
                    }
                });
                if (value.length > 2) {
                    segmentsLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.SEGMENTS,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            return { name: v };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return segmentsLabels;
            case CategoriesKeys.WEEK_DAYS:
                const daysLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < 2) {
                        return <ObjectNameLabel key={`day_${i}`}>{_.capitalize(value)}</ObjectNameLabel>;
                    }
                });
                if (value.length > 2) {
                    daysLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.DAYS,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            return { name: v };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return daysLabels;
            case CategoriesKeys.LANGUAGES:
                const languageLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < 2) {
                        const name = languageCodes.find((elem) => elem.code === value)?.name;
                        const img = languageCodes.find((elem) => elem.code === value)?.flag;
                        return (
                            <ObjectNameLabel key={`lang_${i}`}>
                                <LanguageFlag src={img} />
                                {name}
                            </ObjectNameLabel>
                        );
                    }
                });
                if (value.length > 2) {
                    languageLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.LANGUAGES,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            const name = languageCodes.find((elem) => elem.code === v)?.name;
                                            const img = languageCodes.find((elem) => elem.code === v)?.flag;
                                            return { name, img };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return languageLabels;
            case CategoriesKeys.COUNTRIES:
                const countryLabels: JSX.Element[] = value?.map((value: any, i: number) => {
                    if (i < 2) {
                        const { flag: img, name } =
                            countryCodes.find(
                                (elem) => elem.code === value.toLowerCase() || elem.name.toLowerCase() === value.toLowerCase()
                            ) || {};
                        return (
                            <ObjectNameLabel key={`lang_${i}`}>
                                <LanguageFlag src={img} />
                                {name}
                            </ObjectNameLabel>
                        );
                    }
                });
                if (value.length > 2) {
                    countryLabels.push(
                        <ObjectNameLabel
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreDialog({
                                    type: MoreInfoTypes.COUNTRIES,
                                    show: true,
                                    data:
                                        value.map((v: any) => {
                                            const { flag: img, name } =
                                                countryCodes.find(
                                                    (elem) =>
                                                        elem.code === value.toLowerCase() || elem.name.toLowerCase() === value.toLowerCase()
                                                ) || {};
                                            return { name, img };
                                        }) || []
                                });
                            }}
                        >
                            ...
                        </ObjectNameLabel>
                    );
                }
                return countryLabels;
            case CategoriesKeys.DATES:
                if (!value.length) return <></>;
                return (
                    <>
                        <div>{`${getFullDate(value?.[0]?.startDate)} - ${getFullDate(value?.[0]?.endDate)}`}</div>
                        {value.length > 1 && (
                            <ObjectNameLabel
                                style={{ marginLeft: '10px' }}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setShowMoreDialog({
                                        type: MoreInfoTypes.DATE_INTERVAL,
                                        show: true,
                                        data:
                                            value.map((v: any) => {
                                                return { name: `${getFullDate(v.startDate)} - ${getFullDate(v.endDate)}` };
                                            }) || []
                                    });
                                }}
                            >
                                ...
                            </ObjectNameLabel>
                        )}
                    </>
                );
            case CategoriesKeys.TIMES:
                if (!value.length) return <></>;
                return (
                    <>
                        <div>{`${generateTimeString(value?.[0]?.startTime)} - ${generateTimeString(value?.[0]?.endTime)}`}</div>
                        {value.length > 1 && (
                            <ObjectNameLabel
                                style={{ marginLeft: '10px' }}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setShowMoreDialog({
                                        type: MoreInfoTypes.TIME_INTERVAL,
                                        show: true,
                                        data:
                                            value.map((v: any) => {
                                                return { name: `${generateTimeString(v.startTime)} - ${generateTimeString(v.endTime)}` };
                                            }) || []
                                    });
                                }}
                            >
                                ...
                            </ObjectNameLabel>
                        )}
                    </>
                );
            case CategoriesKeys.RATING:
                return <ObjectNameLabel>{`${value} + `}</ObjectNameLabel>;
            case CategoriesKeys.SUBSCRIBED:
            case CategoriesKeys.PERSONA:
            case CategoriesKeys.HAPPINESS:
                return <div>{value}</div>;
            case CategoriesKeys.GUEST_MODE:
                return <div>{value ? 'Guest' : 'Logged-In'}</div>;
            default:
                return null;
        }
    };

    const renderConditionsItems = (condition: any) => {
        return (
            <ConditionsWrapper $dialog>
                {Object.keys(condition).map((key, index) => {
                    if (valueKeys.includes(key as CategoriesKeys)) {
                        return (
                            <Condition
                                onClick={() => {
                                    setEditingKey(key);
                                    setShowNewCondition(true);
                                }}
                                key={index}
                            >
                                <ConditionTitle>
                                    <SubTitleWrapper>{keysTitle[key as CategoriesKeys]}</SubTitleWrapper>
                                </ConditionTitle>
                                <ConditionStateColumn>
                                    <SubTitleWrapper>{!inverted[key] ? 'Is' : 'Is Not'}</SubTitleWrapper>
                                </ConditionStateColumn>
                                <ConditionValues>{renderConditionValues(key as CategoriesKeys, condition[key])}</ConditionValues>
                                <ObjectActions
                                    actions={[tableActions.EDIT, tableActions.REMOVE]}
                                    onEdit={(e) => {
                                        e.stopPropagation();
                                        setEditingKey(key);
                                        setShowNewCondition(true);
                                    }}
                                    onRemove={(e) => {
                                        e.stopPropagation();
                                        setValues(_.omit(values, key));
                                        setInverted(_.omit(inverted, key));
                                    }}
                                    tooltipTexts={{ edit: 'conditions_icon_edit', delete: 'conditions_icon_delete' }}
                                />
                            </Condition>
                        );
                    }
                })}

                <Condition onClick={() => setShowNewCondition(true)}>
                    <ConditionTitle>
                        <SubTitleWrapper>Add Condition</SubTitleWrapper>
                        {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'conditions_icon_add')}
                    </ConditionTitle>
                </Condition>
            </ConditionsWrapper>
        );
    };

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <GenericDialog
                title={'New Condition'}
                type={DialogTypes.DisplayCondition}
                actionButtons={[closeButton, saveButton]}
                onClose={handleClose}
                circlesSlugOptions={{ default: CIRCLE_SLUGS.conditions }}
            >
                <StyleTextFieldWrapper>
                    <DialogTextField
                        label={'Condition Name'}
                        value={name}
                        onChange={(evt: any) => {
                            setName(evt.target.value);
                            setErrors({});
                        }}
                        error={errors.name}
                    />
                </StyleTextFieldWrapper>
                {renderConditionsItems(values)}
            </GenericDialog>
            <NewConditionDialog
                open={showNewCondition}
                onClose={() => {
                    setShowNewCondition(false);
                    setEditingKey('');
                }}
                onSave={(condition) => {
                    if (editingKey) {
                        const newValues = {
                            [editingKey]: (condition as any)[editingKey]?.value
                        };
                        const newInverted = {
                            [editingKey]: !(condition as any)[editingKey]?.is
                        };
                        setValues({ ..._.omit(values, editingKey), ...newValues });
                        setInverted({ ..._.omit(inverted, editingKey), ...newInverted });
                    } else {
                        const newInverted: any = {};
                        const newValues: any = Object.keys(condition || {}).reduce((prev, curr) => {
                            newInverted[curr] = !condition[curr].is;
                            return {
                                ...prev,
                                [curr]: condition[curr]?.value
                            };
                        }, {});
                        setValues({ ...values, ...newValues });
                        setInverted({ ...inverted, ...newInverted });
                    }
                    setEditingKey('');
                    setShowNewCondition(false);
                }}
                conditionsKeysToExclude={editingKey ? valueKeys.filter((key) => key !== editingKey) : Object.keys(values)}
                condition={{
                    [editingKey]: {
                        is: !inverted[editingKey],
                        value: values[editingKey]
                    }
                }}
            />
            <MoreInfoDialog
                type={showMoreDialog.type!}
                open={showMoreDialog.show}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
                data={showMoreDialog.data}
            />
        </>
    );
};
