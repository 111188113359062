import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import icons from '../../../style';
import { ProfileSVGInline } from '../../../style/styled-components/reusable.css';
import { BreadcrumbsWrapper, LinkPlaceholder, MobileBreadcrumbsWrapper } from './Breadcrumbs.css';
import useScreenSize from '../../../hooks/useScreenSize';
import SVGInline from 'react-inlinesvg';

type BreadcrumbsProps = {
    path?: string;
    callback?: (arg: string) => void;
    placeholder?: string;
    insideTableHeader?: boolean;
    unclickable?: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ path, callback, placeholder, insideTableHeader, unclickable }) => {
    const [items, setItems] = useState<{ title?: string; path?: string }[]>([]);
    const { isDesktop } = useScreenSize();

    useEffect(() => {
        if (!path) return;

        const pathParts = path.split('/');
        const newItems: any[] = [];
        pathParts.forEach((part, index) => {
            newItems.push({
                title: index === 0 ? placeholder || '' : part,
                path: pathParts.slice(0, index + 1).join('/')
            });
        });

        setItems(newItems);
    }, [path]);

    if (!isDesktop) {
        const currentItem = items?.[items.length - 1];
        const previousItem = items?.[items.length - 2];
        return (
            <MobileBreadcrumbsWrapper $unclickable={unclickable}>
                {previousItem && (
                    <SVGInline
                        onClick={() => {
                            callback?.(previousItem?.path || '');
                        }}
                        src={icons.arrowLeftIcon}
                    />
                )}
                <LinkPlaceholder>{currentItem?.title}</LinkPlaceholder>
            </MobileBreadcrumbsWrapper>
        );
    }
    return (
        <BreadcrumbsWrapper
            $unclickable={unclickable}
            insideTableHeader={insideTableHeader}
            separator={<ProfileSVGInline src={icons.arrowRightIcon} />}
        >
            {_.uniqBy(items, 'title').map((item, index) => (
                <LinkPlaceholder
                    key={`item_${index}`}
                    onClick={() => {
                        callback?.(item.path || '');
                    }}
                >
                    {item.title}
                </LinkPlaceholder>
            ))}
        </BreadcrumbsWrapper>
    );
};

export default Breadcrumbs;
