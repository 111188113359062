import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import {
    abortPublishPage,
    createPage as insertPage,
    fetchPage,
    fetchPageIntents,
    pagesState,
    publishPage,
    unsetSelectedPage,
    updatePage as editPage
} from '../../redux/slices/pagesSlice';
import { ApplicationWrapper, MainContentWrapper, PageActionsWrapper, TruncatedText } from '../../style/styled-components/reusable.css';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import PageActions from '../common/PageActions/PageActions';
import { TableBody, TableCell } from '@material-ui/core';
import GenericTable, { ActionsTableCell, DraggableTableRow, HeaderTableCell, ImageTableCell, tableActions } from '../common/Table/Table';
import {
    API_ERROR_CODES,
    DRAGGABLE_TYPES,
    EMPTY_WORD_STRING,
    getImgixUrl,
    imgixBaseUrl,
    imgixDefaultQuery,
    imgixFMBaseUrl,
    moduleTypes
} from '../../utils/Globals';
import icons from '../../style';
import {
    actionNameMissingAlert,
    DIALOG_NAMES,
    dialogAlert,
    dialogConfirm,
    dialogRemove,
    handleCheckBeforeSave,
    ToastAlert
} from '../../utils/fnDialogs';
import { useDrop } from 'react-dnd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    AddModulestyle,
    ModuleDividerDiv,
    ModuleSeparatorContainer,
    ModuleSeparatorWrapper,
    PageOptionsContainer,
    PageOptionsTitle,
    PageSEOFields,
    SeoInputWrapper,
    SVGAddIconContainer,
    SVGRoundIconContainer
} from './PageEdit.css';
import Button from '../Buttons/Button/Button';
import BackendErrorDialog from '../common/Dialog/BackendErrorDialog';
import { Page, PageIntents, PageIntentsWithPlaceHolders } from '../../types/Page';
import PageValues, { PAGE_VIEWS, pageValues, renderGalleryAlert } from './PageValues';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { PageNameContainer, PagePrefix, UnsavedSpan } from '../common/DashboardTitle/DashboardTitle.css';
import TemplateSelection from '../Pages/Dialogs/TemplateSelection';
import { NewModule, PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW } from '../Modules/Dialogs/NewModule';
import CreateResourceDialog from '../common/Dialog/CreateResourceDialog';
import { Module, templates } from '../../types/Module';
import { Item, itemTypes } from '../../types/Item';
import {
    createItem,
    deleteItem,
    fetchContentSourceTypes,
    fetchItems,
    fetchItemTypes,
    ItemState,
    setItemPreview,
    unSetItemPreview,
    updateItem
} from '../../redux/slices/itemSlice';
import {
    createModule,
    deleteModule,
    fetchModules,
    fetchModulesTemplates,
    ModulesState,
    updateModule
} from '../../redux/slices/moduleSlice';
import { ContentTemplateLogo, NameWrapper, TooltipDivider } from '../Modules/Modules.css';

import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../common/Tooltips/Tooltips';
import { generateDateStringForTables } from '../../utils/fnDate';
import { WidthTableCell } from '../common/Table/Table.css';
import { TemplateIcons } from '../../assets/images/icons';
import avatarIcon from '../../assets/images/icons/ico-avatar.svg';
import EPGImage from '../../assets/images/intentsPlaceHolders/epg_large.png';
import UseExistingDialog, { EXISTING_ITEMS } from './Dialogs/UseExistingDialog';
import { DraggableModule } from './VisualEditor';
import {
    AdvancedBackgroundGap,
    CollectionContainer,
    CollectionGrid,
    CollectionItem,
    CollectionPlaceholderModule,
    CollectionTitle,
    PlaceholderActions,
    PlaceholderImage,
    PlaceholderModule,
    PlaceholderModuleAction,
    PlaceholderWrapper,
    SearchIconContainer,
    SearchTitle,
    VisualEditorCardsWrapper
} from './VisualEditor.css';
import { Actions } from '../Items/EditorialView';
import { NewItem } from '../Items/Dialogs/NewItem';
import configServiceAPI from '../../utils/api/configServiceAPI';
import { Loader } from '../common/Loader/Loader';
import { CardsStripe } from './VisualElements/CardsStripe';
import { GalleryAndPreview } from './VisualElements/GalleryAndPreview';
import { audiencesState, fetchAudiences } from '../../redux/slices/audienceSlice';
import { PageStyle } from '../../types/PageStyle';
import { DisplayConditionsState, fetchDisplayConditions } from '../../redux/slices/displayConditionsSlice';
import { createPageStyle, fetchPageStyles, PageStylesState } from '../../redux/slices/pageStylesSlice';
import { templateTypes } from '../../types/Template';
import { Collection } from './Collection';
import { applyTemplate, templatesState } from '../../redux/slices/templatesSlice';
import TranslationTooltip from '../common/TranslationTooltip/TranslationTooltip';
import { alphaHexToRGBA, calculateItemBackgroundToShow } from '../../utils/fnColor';
import useScreenSize from '../../hooks/useScreenSize';
import { MoreInfoTypes } from '../common/Dialog/MoreInfoDialog';
import { Hint, HINT_TYPES } from '../common/Hints/Hint';
import { buildPathWithProjectId, PageRoutes } from '../../types/RouteTypes';
import { PageStyleDialog } from '../StyleAndBranding/PageStyle/Dialogs/PageStyleDialog';
import { PUBLISHED_STATUS, renderPublishedDeleteError, renderPublishStatusIcon } from '../../utils/fnPublish';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import useLockSystem, { LockableObjectTypes } from '../../hooks/useLockSystem';
import { renderLockedError, renderLockedWarningAlert } from '../../utils/fnLockingSystem';
import { checkSameOrder } from '../../utils/fnData';
import {
    ObjectNameTooltipContentHolder,
    ObjectNameTooltipIconHolder,
    ObjectNameTooltipLabelHolder,
    ObjectNameTooltipNameHolder
} from '../common/Tooltips/Tooltips.css';
import { ObjectTypes } from '../../types/Object';
import ObjectNameTooltip from '../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { fetchTargetGroups, targetGroupsState } from '../../redux/slices/targetGroupsSlice';
import { ImageFields } from '../StyleAndBranding/PageStyle/PageStyle.css';
import { DialogCheckbox, DialogFileField, DialogTextField, DialogToggleButton } from '../common/Dialog/GenericDialog';
import { supportedResolutions, supportedResolutionsType } from '../Items/Dialogs/BackgroundDialog';
import { extractFileNameFromAzureURL, isOpenFileUrl } from '../../utils/fnUrl';
import { uploadFilesSync } from '../../redux/slices/fileManagerSlice';
import { SourcesState } from '../../redux/slices/sourceSlice';
import { ModulesTableSizes } from '../../types/TableSizes';
import { ApplicationsState } from '../../redux/slices/applicationsSlice';
import Labels from '../common/Labels/Labels';
import { RemovableObjects } from '../common/Dialog/RemoveObjectDialog';
import { renderFailedObjectDeletions } from '../../utils/fnListDelete';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { usePersistentState } from '../../hooks/usePersistentState';
import { AcceptedSortField, DEFAULT_SORT_CONFIG } from '../../utils/fnSort';
import { renderAdminLockedError, renderAdminLockedWarningAlert } from '../../utils/fnAdminLocking';
import { IntentsPlaceHolders } from '../../assets/images/intentsPlaceHolders';
import { ToggleButtonContainer } from '../common/Dialog/GenericDialog.css';

export interface IModuleSeparator {
    onPlus?: any;
}

type customLocationState = {
    duplicatePageId: string;
};

export const ModuleSeparator: FC<IModuleSeparator> = ({ onPlus }) => {
    const handlePlusClick = (evt: any) => {
        evt.preventDefault();
        onPlus?.();
    };

    return (
        <ModuleSeparatorWrapper>
            <ModuleSeparatorContainer onClick={handlePlusClick}>
                <ModuleDividerDiv />
                <SVGRoundIconContainer>
                    <SVGInline src={icons.separatorRoundIconDark} />
                </SVGRoundIconContainer>
                <SVGAddIconContainer>
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'pages_edit_icon_add_to_position_module')}
                </SVGAddIconContainer>
                <ModuleDividerDiv />
            </ModuleSeparatorContainer>
        </ModuleSeparatorWrapper>
    );
};

export const handlePageImages = async (
    imgFiles: supportedResolutionsType,
    imgUrls: supportedResolutionsType,
    applyForAll: boolean,
    existingBackground: any,
    createFiles: (files: File[]) => Promise<string[]>
) => {
    const imageUrls = { ...imgUrls };
    const imageFiles = { ...imgFiles };
    let imgixUrls: any = {};
    const imageUrl = `${Object.values(imageUrls).find((elem) => elem) || ''}`;
    if (imageUrl) {
        // if there is an imageUrl, it means we are not uploading files but picking urls
        if (applyForAll) {
            const isOpenUrl = isOpenFileUrl(imageUrl);
            Object.values(supportedResolutions).forEach((value, index) => {
                if (isOpenUrl) {
                    // if it's openUrl, just replicate the same url for each key
                    imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(imageUrl);
                    return;
                }
                const [width, height] = value.split('x');
                const query = `&width=${width}&height=${height}`;
                // if picked by the FM, it already is a imgix url so just append the width/height query
                imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(`${imageUrl}${query}`);
            });
            return imgixUrls;
        }
        return { ...existingBackground, ...imageUrls };
    }
    const imagesToUpload = Object.values(imageFiles).filter((image): image is File => typeof image !== 'undefined');
    if (!imagesToUpload.length) {
        // if there is not value  push it's initial value
        return existingBackground;
    }

    const urls = await createFiles(imagesToUpload);
    if (!urls?.length) return null;
    if (applyForAll) {
        // if apply for all, generate imgix urls for the save picture, but use width and height in query
        const fileName = extractFileNameFromAzureURL(urls[0]);
        Object.values(supportedResolutions).forEach((value, index) => {
            const [width, height] = value.split('x');
            const query = `${imgixDefaultQuery}&width=${width}&height=${height}`;
            imgixUrls[Object.keys(supportedResolutions)[index]] = encodeURIComponent(getImgixUrl(fileName, query, true));
        });
    } else {
        Object.keys(imageFiles).forEach((key, index) => {
            // if not apply for all, still generate imgix url, but the resolution is kept as inputed
            const fileName = extractFileNameFromAzureURL(urls[index]);
            imgixUrls[key] = encodeURIComponent(getImgixUrl(fileName, undefined, true));
        });
    }
    return { ...existingBackground, ...imgixUrls };
};

const PageEdit: React.FC<any> = () => {
    const [pageName, setPageName] = useState('');
    const [pageIntent, setPageIntent] = useState('');
    const [pageStyleId, setPageStyleId] = useState('');
    const [pageTargets, setPageTargets] = useState<string[]>([]);
    const [slug, setSlug] = useState<string | undefined>(undefined);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<string[]>([]);
    const [canonical, setCanonical] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [lastModified, setLastModified] = useState<number>(1);
    const [pageView, setPageView] = useState<PAGE_VIEWS>(PAGE_VIEWS.EDITOR_VIEW);
    const [pageBackgroundImage, setPageBackgroundImage] = useState<supportedResolutionsType>({});
    const [initialBackgroundImage, setInitialBackgroundImage] = useState<supportedResolutionsType>({});
    const [pageIncreasedTopMargin, setPageIncreasedTopMargin] = useState<boolean | undefined>(undefined);
    const [initialOrder, setInitialOrder] = useState<string[]>([]);
    const [showUnsaved, setShowUnsaved] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const {
        error: pageError,
        selectedPageLoading,
        intentsLoading,
        createPageLoading,
        selectedPage,
        intents,
        loading: pageLoading
    }: pagesState = useAppSelector((state) => state.pages);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [, drop] = useDrop(() => ({ accept: DRAGGABLE_TYPES.MODULE }));
    const [modulesToSave, setModulesToSave] = useState<string[]>([]);
    const [savePage, setSavePage] = useState(false);
    const [pageStyle, setPageStyle] = useState<any>({});
    const [showHintScreen, setShowHintScreen] = useState<boolean>(false);

    const [showPageStyleDialog, setShowPageStyleDialog] = useState<boolean>(false);
    const [newPageStyleId, setNewPageStyleId] = useState<string>('');
    const [pageHasCollection, setPageHasCollection] = useState<boolean>(false);
    const [allowedNumberOfModules, setAllowedNumberOfModules] = useState<number>(0);
    const [isPageOptionsOpen, setIsPageOptionsOpen] = useState<boolean>(false);
    const [applyBackgroundForAll, setApplyBackgroundForAll] = useState<boolean>(true);
    const [backgroundImageFiles, setBackgroundImageFiles] = useState<supportedResolutionsType>({});
    const [backgroundImageUrls, setBackgroundImageUrls] = useState<supportedResolutionsType>({});

    // MODULES
    const [modules, setModules] = useState<Module[]>([]);
    const [showGalleryWarning, setShowGalleryWarning] = useState<boolean>(false);
    const [openNewModuleDialog, setOpenNewModuleDialog] = useState(false);
    const [openUseExistingModuleDialog, setOpenUseExistingModuleDialog] = useState(false);
    const [openNewResourceDialog, setOpenNewResourceDialog] = useState(false);
    const [openTemplateSelectionDialog, setOpenTemplateSelectionDialog] = useState(false);
    const [moduleToEdit, setModuleToEdit] = useState<Module | undefined>(undefined);
    const {
        validTemplates,
        error: modulesError,
        createOrModifyLoading: moduleLoading
    }: ModulesState = useAppSelector((state) => state.modules);
    const { contentSourceTypes, storeItemTypes, error: itemsError }: ItemState = useAppSelector((state) => state.items);
    const [openNameTooltip, setOpenNameTooltip] = useState<any>(null);
    const [showIntentTooltip, setShowIntentTooltip] = useState<boolean>(false);
    const [addModuleAtIndex, setAddModuleAtIndex] = useState<number | null>(null);
    const [duplicatingCM, setDuplicatingCM] = useState(false);
    const [initialCIsOrder, setInitialCIsOrder] = useState<any>(null);
    const [isAutoCollection, setIsAutoCollection] = useState<boolean>(false);
    const [includeProviderLogoCardProviderName, setIncludeProviderLogoCardProviderName] = useState<string>('');
    // ------

    // ITEMS
    const [openNewItemDialog, setOpenNewItemDialog] = useState(false);
    const [openUseExistingItemDialog, setOpenUseExistingItemDialog] = useState(false);
    const [openNewResourceDialogForItem, setOpenNewResourceDialogForItem] = useState(false);
    const [openTemplateSelectionDialogForItem, setOpenTemplateSelectionDialogForItem] = useState(false);
    const [moduleIdForItem, setModuleIdForItem] = useState('');
    const [itemToEdit, setItemToEdit] = useState<Item | undefined>(undefined);
    const [duplicatingCI, setDuplicatingCI] = useState(false);
    const [itemsToShow, setItemsToShow] = useState<{ [key: string]: Item } | undefined>(undefined);
    const [previewImageToShow, setPreviewImageToShow] = useState<{ [key: string]: string } | undefined>(undefined);
    const [addItemAtIndex, setAddItemAtIndex] = useState<number | null>(null);
    const [templateType, setTemplateType] = useState<string>('');
    const [pageStyleOptions, setPageStyleOptions] = useState<any[]>([]);
    const [isCollectionItem, setIsCollectionItem] = useState<boolean>(false);
    const [collectionType, setCollectionType] = useState<itemTypes | undefined>(undefined);
    const [isContentWorld, setIsContentWorld] = useState<boolean>(false);
    const [isGalleryLoading, setIsGalleryLoading] = useState<boolean>(false);

    const { pageStyles, loading: stylesLoading, error: pageStylesError }: PageStylesState = useAppSelector((state) => state.pageStyles);
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { audiences: storeAudiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { targetGroups }: targetGroupsState = useAppSelector((state) => state.targetGroups);

    const { error: templatesError }: templatesState = useAppSelector((state) => state.templates);
    const { error: sourcesError }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as customLocationState;
    const { isMobile, isDesktop, isLargeDesktop } = useScreenSize();
    const pageId = useParams().page_id || locationState?.duplicatePageId;
    const isFetching = useRef(false); // prevent double loading of the selectedPage
    const { store: paginationState, set: setPaginationState } = usePersistentState('paginationState');
    const unsavedDependencyArray = [
        selectedPage,
        pageName,
        pageStyleId,
        pageTargets,
        abTestingGroupIds,
        modules,
        pageIntent,
        pageIncreasedTopMargin,
        pageBackgroundImage,
        slug,
        canonical,
        description
    ];
    const generalLoading =
        selectedPageLoading || intentsLoading || pageLoading || isGalleryLoading || stylesLoading || createPageLoading || moduleLoading;
    const error = pageError || templatesError || pageStylesError || itemsError || modulesError || sourcesError || applicationsError;

    useBlockNavigation(showUnsaved, () => renderAlertShowUnsaved(true), unsavedDependencyArray);
    const { lock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.PAGES);
    const { unlock: unlockModule } = useLockSystem(LockableObjectTypes.MODULES);
    const { unlock: unlockItem } = useLockSystem(LockableObjectTypes.ITEMS);

    // This method is used to load templates and content source types, in case they are not already loaded in the slice
    const loadResources = async () => {
        loadTemplates();
        loadContentSourceTypes();
        if (!storeItemTypes?.length) loadItemTypes();
    };

    useEffect(() => {
        loadResources();
        loadAudiences();
        loadConditions();
        loadPageStyles();
        loadPageIntents();
        loadTargetGroups();

        return () => {
            resetState();
        };
    }, []);

    useEffect(() => {
        setPageView(isDesktop ? PAGE_VIEWS.EDITOR_VIEW : PAGE_VIEWS.LIST_VIEW);
    }, [isDesktop]);

    useEffect(() => {
        setPageStyleOptions(
            pageStyles?.map((style) => {
                return {
                    label: <ObjectNameTooltip id={style._id} name={style.name || EMPTY_WORD_STRING} type={ObjectTypes.PAGE_STYLES} />,
                    valueForSearch: style.name || EMPTY_WORD_STRING,
                    value: style._id
                };
            })
        );

        if (newPageStyleId && pageStyles?.find((s) => s._id === newPageStyleId)) {
            return setPageStyleId(newPageStyleId);
        }

        if (!selectedPage?.pageStyleId) {
            setPageStyleId(pageStyles?.length ? pageStyles.find((s) => s.isDefault)?._id || '' : '');
        }
    }, [pageStyles]);

    useEffect(() => {
        if (pageId && !isFetching.current) {
            loadPage();
            isFetching.current = true;
        }
    }, [pageId, selectedPageLoading]);

    useEffect(() => {
        if (!selectedPage || !activeProjectId) return;
        // in case a user switches between projects, the selected page should not be displayed
        // commented for now - only applies when the project id is changed via the URL. does more harm than good
        // if (selectedPage?.projectId !== activeProjectId) {
        //     navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGES));
        // }

        if (selectedPage.adminLocked) {
            return renderAdminLockedWarningAlert(selectedPage.name);
        }
        if (isObjectLocked(selectedPage) && !duplicating) {
            const pageIsLockedBy = objectIsLockedBy(selectedPage);
            return renderLockedWarningAlert(pageIsLockedBy);
        }

        lock(selectedPage._id);
        unlockOnClose(selectedPage._id);
        // loading added to the dependency array, to make sure when something new is added to the page, it gets relocked with the new modules/items
    }, [selectedPage, activeProjectId, selectedPageLoading]);

    useEffect(() => {
        if (pageError) return;
        if (selectedPageLoading) {
            const loadItems = async (addPermissions: boolean, projectId?: string) => {
                return await dispatch(fetchItems({ addPermissions, projectId })).unwrap();
            };
            const loadModules = async (addPermissions?: boolean, projectId?: string) => {
                return await dispatch(fetchModules({ addPermissions, projectId })).unwrap();
            };
            loadModules(false, activeProjectId);
            loadItems(false, activeProjectId);
            return;
        }
        const isDuplicate = !!locationState?.duplicatePageId;
        if (selectedPage) {
            setPageName(selectedPage.name);
            setPageStyleId(selectedPage.pageStyleId || '');
            setPageStyle(selectedPage.pageStyle || {});
            setPageIntent(selectedPage.intent);
            setSlug(selectedPage.slug);
            setCanonical(selectedPage.canonical);
            setDescription(selectedPage.description);
            setPageTargets(selectedPage.conditionIds || []);
            setAbTestingGroupIds(selectedPage.abTestingGroupIds || []);
            setModules(selectedPage?.modules || []);
            setInitialOrder(selectedPage?.moduleIds || []);
            setPageIncreasedTopMargin(selectedPage?.increasedTopMargin || false);
            setPageBackgroundImage(selectedPage?.backgroundImage || {});
            setInitialBackgroundImage(selectedPage?.backgroundImage || {});
            setApplyBackgroundForAll(calculateApplyForAll(selectedPage?.backgroundImage || {}));
            setLastModified(selectedPage.lastModified || 1);
            if (selectedPage?.modules?.length) {
                const CIsOrder: any = {};
                selectedPage.modules.forEach((cm) => {
                    CIsOrder[cm._id] = cm.itemIds || [];
                });
                setInitialCIsOrder(CIsOrder);
            }
        }
        setDuplicating(isDuplicate);
    }, [selectedPageLoading, selectedPage]); // adding selectedPage is a possible fix for the new page-> use template issue and has to be tested on dev (to be removed if it doesn't solve the problem)

    useEffect(() => {
        const calculateShowUnsaved = () => {
            if (!pageId || duplicating || !selectedPage) {
                return true;
            }
            // checking change in order
            if (
                !checkSameOrder(
                    initialOrder,
                    modules.filter((cm) => cm._id !== 'search').map((cm) => cm._id)
                )
            ) {
                return true;
            }

            return (
                selectedPage.name !== pageName ||
                selectedPage.intent !== pageIntent ||
                (selectedPage.increasedTopMargin || false) !== pageIncreasedTopMargin ||
                (pageStyleId && selectedPage.pageStyleId !== pageStyleId) ||
                JSON.stringify(selectedPage.backgroundImage || {}) !== JSON.stringify(pageBackgroundImage) ||
                !_.isEqual([...(selectedPage.conditionIds || [])].sort(), [...pageTargets].sort()) ||
                !_.isEqual([...(selectedPage.abTestingGroupIds || [])].sort(), [...abTestingGroupIds].sort()) ||
                selectedPage.slug !== slug ||
                selectedPage.canonical !== canonical ||
                selectedPage.description !== description
            );
        };

        const calculateModulesToSave = () => {
            const validModules = modules.filter((cm) => cm._id !== 'search');
            if (!validModules.length) return false;
            const newModulesToSave = [...modulesToSave];
            const CIsOrder: any = {};
            validModules.forEach((cm) => {
                CIsOrder[cm._id] = cm.itemIds || [];
            });
            for (const cmId in initialCIsOrder) {
                if (!CIsOrder[cmId]) continue;
                const initialOrder = initialCIsOrder[cmId];
                const currentOrder = CIsOrder[cmId];
                const index = newModulesToSave.indexOf(cmId);
                if (initialOrder?.join(',') !== currentOrder?.join(',')) {
                    if (index < 0) newModulesToSave.push(cmId);
                } else {
                    if (index >= 0) newModulesToSave.splice(index, 1);
                }
            }
            setModulesToSave(newModulesToSave.length ? newModulesToSave : []);
            return newModulesToSave.length > 0;
        };
        if (!calculateShowUnsaved()) {
            // if in here, means the page does not need saving. calculate if modules need saving
            setSavePage(false);
            setShowUnsaved(calculateModulesToSave());
        } else {
            // else it means the page needs saving. need to calculate if also modules need saving
            calculateModulesToSave();
            setSavePage(true);
            setShowUnsaved(true);
        }
    }, [...unsavedDependencyArray, selectedPageLoading]);

    useEffect(() => {
        const oldPreviews = { ...previewImageToShow };
        const selected: any = {};
        const previews: any = {};
        modules
            .filter((cm) => cm?._id)
            .forEach((cm) => {
                if (!cm?.items?.length) {
                    selected[cm._id] = undefined;
                    return;
                }
                const itemToShowForModule =
                    (itemsToShow?.[cm._id] && cm.items.find((elem) => elem._id === itemsToShow[cm._id]._id)) || cm.items[0];

                selected[cm._id] = itemToShowForModule;
            });

        for (const moduleId in selected) {
            if (!selected[moduleId]) {
                previews[moduleId] = '';
                continue;
            }
            //in case of reordering the items we should keep the old previews instead of selecting new ones
            if (!!oldPreviews?.[moduleId]) {
                previews[moduleId] = oldPreviews[moduleId];
                continue;
            }
            previews[moduleId] = calculateItemBackgroundToShow(selected[moduleId], modules.find((m) => m._id === moduleId)?.preview, '');
        }
        setItemsToShow(selected);
        setPreviewImageToShow(previews);
        checkTemplatesOrder();
    }, [modules]);

    useEffect(() => {
        const searchIndex = modules.findIndex((cm) => cm._id === 'search');
        if (pageIntent === PageIntents.SEARCH) {
            searchIndex === -1 &&
                setModules((cms) => {
                    const newCms = [...cms];
                    newCms.unshift({
                        _id: 'search',
                        name: 'search',
                        tenantId: activeTenantId || '',
                        projectId: activeProjectId || '',
                        template: templates['16X9_M'],
                        moduleType: moduleTypes.STRIPE
                    });
                    return newCms;
                });
        } else {
            searchIndex > -1 &&
                setModules((cms) => {
                    const newCms = [...cms];
                    newCms.splice(searchIndex, 1);
                    return newCms;
                });
        }
    }, [pageIntent, modules]);

    useEffect(() => {
        setPageHasCollection(
            modules.some((module) => module.moduleType === moduleTypes.COLLECTION) || pageIntent === PageIntents.AUTO_COLLECTION
        );
        setAllowedNumberOfModules(modules.some((module) => module.moduleType === moduleTypes.COLLECTION) ? 1 : 0);
    }, [modules, pageIntent]);

    useEffect(() => {
        // when there is no background image selected the increasedTopMargin value should be false
        if (!Object.keys(pageBackgroundImage).length) {
            setPageIncreasedTopMargin(false);
        }
    }, [pageBackgroundImage]);

    // PAGES
    const loadPage = async (id?: string) => {
        return await dispatch(fetchPage({ pageId: id || pageId })).unwrap();
    };

    const loadPageIntents = async () => {
        if (!intents.length) {
            return await dispatch(fetchPageIntents(null)).unwrap();
        }
    };

    const createPage = async (page: Page, addObjectToGroup?: boolean, shouldLeavePage?: boolean, publishing?: boolean) => {
        const response = await dispatch(insertPage({ page, addObjectToGroup })).unwrap();
        if (response.shouldShowSlugWarning) renderSlugAlert();
        if (response.shouldShowEmptySlugMessage) renderEmptySlugAlert();

        const showSuccessToast = !response.shouldShowEmptySlugMessage && !response.shouldShowSlugWarning && !showGalleryWarning;

        if (response.id) {
            setPaginationState(undefined);
            showSuccessToast &&
                ToastAlert(
                    'success',
                    '',
                    '',
                    undefined,
                    publishing
                        ? page.publishAt
                            ? DIALOG_NAMES.PAGE_SCHEDULED_PUBLISH_AND_SAVED
                            : DIALOG_NAMES.PAGE_PUBLISHED_AND_SAVED
                        : DIALOG_NAMES.PAGE_CREATED
                );
            loadTargetGroups();
            !shouldLeavePage && navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', response.id)));
        }
        return response.id;
    };

    const updatePage = async (page: Page, addObjectToGroup?: boolean) => {
        const response = await dispatch(editPage({ page, addObjectToGroup })).unwrap();
        if (response.shouldShowSlugWarning) renderSlugAlert();
        if (response.shouldShowEmptySlugMessage) renderEmptySlugAlert();
        if (paginationState) {
            const newState = {
                ...paginationState,
                currentPage: 1,
                sortConfig: DEFAULT_SORT_CONFIG,
                activeSortingKey: DEFAULT_SORT_CONFIG.field as AcceptedSortField
            };

            setPaginationState(newState);
        }

        loadTargetGroups();
        return {
            ok: response.ok,
            showSuccessToast: !response.shouldShowEmptySlugMessage && !response.shouldShowSlugWarning
        };
    };

    const loadTargetGroups = async () => {
        return dispatch(fetchTargetGroups({ projectId: activeProjectId }));
    };

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    const loadConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    const loadPageStyles = async () => {
        return await dispatch(fetchPageStyles({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    //ITEMS
    const setItemForPreview = (itemId: string, moduleId: string, type: itemTypes) => {
        dispatch(setItemPreview({ itemId, moduleId, type }));
    };

    const unsetItemForPreview = () => {
        dispatch(unSetItemPreview());
    };

    const saveItem = async (item: Item) => {
        const response = await dispatch(createItem(item)).unwrap();
        if (!response.id) {
            const values = {
                title: 'Oops.. An error occured.',
                text: response.error
            };
            dialogAlert('', false, values, undefined, true);
        }
        return response.id;
    };

    const modifyItem = async (item: Item) => {
        try {
            const response = await dispatch(updateItem({ item })).unwrap();
            if (!response.ok) {
                const values = {
                    title: 'Oops.. An error occured.',
                    text: response.error
                };
                dialogAlert('', false, values, undefined, true);
                return false;
            }
            loadPage().then(() => unsetItemForPreview());
            return true;
        } catch (ex) {
            return false;
        }
    };

    const removeItemFromModule = async (module: Module, id: string) => {
        const index = module.itemIds?.findIndex((cId) => cId === id);
        if (index === undefined || index === -1) return;
        const newItemIds = [...(module.itemIds || [])];
        newItemIds.splice(index, 1);
        const newModule: any = {
            _id: module._id,
            itemIds: newItemIds,
            lastModified: module.lastModified,
            slug: module.slug
        };
        showUnsaved && handleSaveClick();
        unlockItem(id);
        await modifyModule(newModule, !duplicating);
    };

    const removeItem = async (id: string) => {
        const response = await dispatch(deleteItem(id)).unwrap();
        if (response.ok) loadPage();
    };

    // MODULES
    const saveModule = async (module: Module, item?: Item) => {
        try {
            if (item) {
                const { id, error } = await dispatch(createItem(item)).unwrap();
                if (error) return false;
                module.itemIds = [id];
            }
            const { id, error, shouldShowEmptySlugMessage, shouldShowSlugWarning } = await dispatch(createModule(module)).unwrap();
            if (error) return false;

            if (shouldShowEmptySlugMessage) renderModuleEmptySlugAlert();
            if (shouldShowSlugWarning) renderModuleSlugAlert();
            await handleSaveClick(id);
            return true;
        } catch (ex) {
            return false;
        }
    };

    const modifyModule = async (module: Module, reload = true) => {
        try {
            const result = await dispatch(updateModule({ module })).unwrap();
            if (!result.ok) return false;
            if (result.shouldShowEmptySlugMessage) renderModuleEmptySlugAlert();
            if (result.shouldShowSlugWarning) renderModuleSlugAlert();
            reload && (await loadPage());
            return true;
        } catch (ex) {
            return false;
        }
    };

    const createStyle = async (newStyle: PageStyle) => {
        const response = await dispatch(createPageStyle(newStyle)).unwrap();

        if (response.id) {
            setNewPageStyleId(response.id);
            loadPageStyles();
        }
    };

    const removeModuleFromPage = async (id: string) => {
        const index = modules.findIndex((cm) => cm._id === id);
        if (index === -1) return;
        const newCms = [...modules];
        newCms.splice(index, 1);
        setModules(newCms);
    };

    const removeModule = async (id: string, withItems?: boolean) => {
        const response = await dispatch(deleteModule({ id, withItems })).unwrap();

        if (!!response?.failedDeletions) {
            renderFailedObjectDeletions(response.failedDeletions);
        }
        if (response.ok) loadPage();
    };

    const checkTemplatesOrder = () => {
        const hasGallery = modules.find((module) => module.template === templates.GALLERY);
        const newModuleTemplates = modules.map((module) => module.template) || [];

        if (hasGallery) {
            for (let index = 1; index < newModuleTemplates.length; index++) {
                const prevTemplate = newModuleTemplates[index - 1];
                if (prevTemplate !== templates.GALLERY && newModuleTemplates[index] === templates.GALLERY) {
                    return setShowGalleryWarning(true);
                }
            }
        }
        setShowGalleryWarning(false);
    };

    const loadTemplates = async () => {
        await dispatch(fetchModulesTemplates(activeProjectId)).unwrap();
    };
    // pId is used when we apply template to a page that hasn't been created yet, in that scenario,
    // the pId will be passed as an argument to the useTemplate function, rather than using the one from
    const useTemplate = async (id: string, pId?: string) => {
        const itemIndex = !!addItemAtIndex ? addItemAtIndex : undefined;

        // if the page has a collection module, the added module should be placed above it.
        const index = !!addModuleAtIndex ? addModuleAtIndex : pageHasCollection ? 0 : undefined;
        const result = await dispatch(
            applyTemplate({
                templateId: id,
                type: addItemAtIndex ? templateTypes.ITEM : templateTypes.MODULE,
                projectId: `${activeProjectId}`,
                tenantId: `${activeTenantId}`,
                pageId: pageId || pId,
                index: moduleIdForItem ? itemIndex : index,
                moduleId: moduleIdForItem
            })
        ).unwrap();
        if (result.id) {
            const { page } = await loadPage(pageId || pId);
            // If a template was applied for a module, open the visual editor
            if (moduleIdForItem) {
                const addedItem = page?.modules
                    ?.find((module) => module._id === moduleIdForItem)
                    ?.items?.find((item) => item._id === result.id);
                if (!addedItem) return;
                updateSelectionForItemsToShow(moduleIdForItem, addedItem);
                addedItem?.itemType === itemTypes.EDITORIAL && setItemForPreview(result.id, moduleIdForItem, itemTypes.EDITORIAL);
            }
            if (paginationState) {
                const newState = {
                    ...paginationState,
                    currentPage: 1,
                    sortConfig: DEFAULT_SORT_CONFIG,
                    activeSortingKey: DEFAULT_SORT_CONFIG.field as AcceptedSortField
                };

                setPaginationState(newState);
            }
        }
    };

    const loadContentSourceTypes = async () => {
        await dispatch(fetchContentSourceTypes({ projectId: activeProjectId }));
    };
    const loadItemTypes = async () => {
        return await dispatch(fetchItemTypes());
    };

    // DRAG AND DROP LOGIC - MODULES
    const findModule = useCallback(
        (id: string) => {
            const module = modules.find((cm: Module) => cm._id === id) || modules[0];
            return {
                module,
                index: modules.indexOf(module)
            };
        },
        [modules]
    );

    const moveModule = useCallback(
        (id: string, atIndex: number) => {
            const { module, index } = findModule(id);
            setModules((prevState) => {
                const newCms = [...prevState];
                newCms.splice(index, 1);
                newCms.splice(atIndex, 0, module);
                return newCms;
            });
        },
        [findModule, modules, setModules]
    );

    // DRAG AND DROP LOGIC - ITEMS
    const findItem = useCallback(
        (id: string, moduleIndex: number) => {
            const items = [...(modules[moduleIndex]?.items || [])];
            const item = items.find((ci) => ci._id === id);
            return {
                item,
                index: items.indexOf(item)
            };
        },
        [modules]
    );

    const moveItem = useCallback(
        (id: string, moduleIndex: number, atIndex: number) => {
            const { item, index } = findItem(id, moduleIndex);
            const newCms = [...modules];
            const newCm = { ...newCms[moduleIndex] };
            const newCis = [...(newCm.items || [])];
            newCis.splice(index, 1);
            newCis.splice(atIndex, 0, item);
            _.set(newCm, 'items', newCis);
            _.set(
                newCm,
                'itemIds',
                newCis.map((elem) => elem._id)
            );
            newCms[moduleIndex] = newCm;
            setModules(newCms);
        },
        [findItem, modules, setModules]
    );

    // --------------
    const handlePageValuesChange = (values: Record<keyof pageValues, any>) => {
        'name' in values && setPageName(values.name);
        'pageStyle' in values && setPageStyleId(values.pageStyle);
        'abTestingGroupIds' in values && setAbTestingGroupIds(values.abTestingGroupIds);
        'targets' in values && setPageTargets(values.targets);
        'intent' in values && setPageIntent(values.intent);
        'pageView' in values && setPageView(values.pageView);

        if (!isDesktop && values.pageView === PAGE_VIEWS.EDITOR_VIEW) {
            setShowHintScreen(true);
        }
    };

    //Change pageStyle real time
    useEffect(() => {
        setPageStyle(pageStyles?.find((pageStyle) => pageStyle._id === pageStyleId) || {});
    }, [pageStyleId]);

    const resetState = () => {
        setPageName('');
        setPageStyleId('');
        setPageTargets([]);
        setAbTestingGroupIds([]);
        setPageStyle({});
        setNewPageStyleId('');
        setPageBackgroundImage({});
        setInitialBackgroundImage({});
        setSlug(undefined);
        setCanonical(undefined);
        setDescription(undefined);
        dispatch(unsetSelectedPage());
    };

    const resetModuleState = () => {
        setAddItemAtIndex(null);
        setAddModuleAtIndex(null);
        setIsCollectionItem(false);
        setCollectionType(undefined);
        setIsContentWorld(false);
        setIsAutoCollection(false);
        setModuleIdForItem('');
    };

    const validatePage = () => {
        // todo: probably validate options.
        // name/targets/branding can't really be validated here
        return true;
    };

    const handleSaveClick = (
        newModuleId?: string,
        publishAt?: number,
        shouldLeavePage?: boolean,
        publishing?: boolean
    ): Promise<string | Page> | undefined => {
        return new Promise((resolve) => {
            const onSave = async (addObjectToGroup?: boolean) => {
                const page = await handleSave(newModuleId, publishAt, addObjectToGroup, shouldLeavePage, publishing);
                resolve(page || '');
            };

            handleCheckBeforeSave(
                storeAudiences,
                targetGroups,
                selectedPage || null,
                { name: pageName, conditionIds: pageTargets },
                'pages',
                onSave
            );
        });
    };

    const handleSave = async (
        newModuleId?: string,
        publishAt?: number,
        addObjectToGroup?: boolean,
        shouldLeavePage?: boolean,
        publishing?: boolean
    ) => {
        let newModuleIds = modules?.length ? modules.filter((cm) => cm._id !== 'search').map((cm) => cm._id) : [];

        if (newModuleId) {
            if (addModuleAtIndex === null) {
                pageHasCollection ? newModuleIds.unshift(newModuleId) : newModuleIds.push(newModuleId);
            } else {
                newModuleIds?.splice(addModuleAtIndex, 0, newModuleId);
            }
        }

        // at the moment of saving, unlock the modules that were deleted from the page
        const modulesToUnlock = selectedPage?.moduleIds?.filter((oldId) => !newModuleIds.includes(oldId)) || [];
        // no need to wait them to finish
        Promise.all(modulesToUnlock?.map((id) => unlockModule(id)));
        const page: Page = {
            _id: pageId && !duplicating ? pageId : '',
            name: pageName,
            intent: pageIntent || PageIntents.BASIC,
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            options: {},
            moduleIds: newModuleIds || [],
            conditionIds: pageTargets || [],
            abTestingGroupIds: abTestingGroupIds || [],
            slug,
            canonical,
            description,
            lastModified,
            ...(pageIncreasedTopMargin !== undefined && { increasedTopMargin: pageIncreasedTopMargin })
        };

        if (publishAt) {
            page.publishAt = publishAt;
        }
        if (pageStyleId) {
            page.pageStyleId = pageStyleId;
        }

        const imageResult = await handlePageImages(
            backgroundImageFiles,
            backgroundImageUrls,
            applyBackgroundForAll,
            initialBackgroundImage,
            createFiles
        );

        if (!!imageResult) {
            page.backgroundImage = imageResult;
        }

        if (!validatePage()) return;

        if (modulesToSave.length) {
            const promises = modulesToSave.map((id) => {
                const module = modules.find((cm) => cm._id === id);
                const newCm: any = {
                    _id: id,
                    itemIds: module?.itemIds || [],
                    lastModified: module?.lastModified
                };
                return modifyModule(newCm, false);
            });
            const moduleSaveResults = await Promise.all(promises);
            if (moduleSaveResults.some((result) => !result)) return;
        }
        if (!savePage && !newModuleId && !publishAt) return loadPage().then(({ page }) => page);
        if (showGalleryWarning) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.GALLERY_NOT_FIRST_MODULE, () => {
                renderGalleryAlert();
            });
        }
        if (!pageId || duplicating) {
            // when the page is created and the user stays on it this state must be set to false prior creation to prevent navigation block while redirecting on pageEdit
            // otherwise, this will be set only after creation to prevent redirection on other pages before the page is completely created
            !shouldLeavePage && setShowUnsaved(false);
            const newPage = await createPage(page, addObjectToGroup, shouldLeavePage, publishing);
            shouldLeavePage && setShowUnsaved(false);
            return newPage;
        } else {
            return updatePage(page, addObjectToGroup).then((response) => {
                return loadPage().then(({ page }) => {
                    if (response.ok && response.showSuccessToast && !showGalleryWarning) {
                        const showScheduledPublishAlert = publishAt && selectedPage?.publishAt !== publishAt;
                        showScheduledPublishAlert
                            ? ToastAlert('success', '', '', icons.scheduledPublishWhiteIcon, DIALOG_NAMES.PAGE_SCHEDULED_PUBLISH_AND_SAVED)
                            : ToastAlert(
                                  'success',
                                  '',
                                  '',
                                  undefined,
                                  publishing ? DIALOG_NAMES.PAGE_PUBLISHED_AND_SAVED : DIALOG_NAMES.PAGE_UPDATED
                              );
                    }
                    return page;
                });
            });
        }
    };

    const _publishPage = async (id: string) => {
        await dispatch(publishPage({ pageId: id })).unwrap();
        if (!showUnsaved) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.PAGE_PUBLISHED);
        }
    };

    const _abortPublishPage = async (id: string) => {
        const response = await dispatch(abortPublishPage({ pageId: id })).unwrap();
        if (response.ok) {
            ToastAlert('success', '', '', undefined, DIALOG_NAMES.PAGE_ABORT_PUBLISH);
        }
        return response;
    };

    const handleAbortPublishPageClick = async (id: string) => {
        await _abortPublishPage(id);
        loadPage();
    };
    const handlePublishClick = async () => {
        // If there are changes we save first then publish the new page!
        let pId;
        if (showUnsaved) {
            // when CREATING a page, the ID will be returned and we use it for publishing and loading the page,
            // since pageId is undefined at this stage
            const page = await handleSaveClick(undefined, undefined, undefined, true);
            if (!page) return;
            pId = typeof page === 'string' ? page : page._id;
            if (!pId) return;
        }
        await _publishPage(pId || pageId);
        loadPage(pId || '');
    };

    const handleSaveClickForItem = async (
        newItemId: string,
        removeAutoCollection?: boolean,
        removeIncludeProviderCardLogo?: boolean,
        updatedModules?: Module[]
    ) => {
        const selectedModules = updatedModules?.length ? updatedModules : modules;
        const selectedCm = { ...selectedModules.find((cm) => cm._id === moduleIdForItem) };
        const newModule: any = {
            _id: moduleIdForItem,
            lastModified: selectedCm.lastModified,
            slug: selectedCm.slug,
            ...(removeAutoCollection && { autoCollection: false }),
            ...(removeIncludeProviderCardLogo && { includeProviderLogoCard: false })
        };
        if (!selectedCm.itemIds?.length) {
            newModule.itemIds = [newItemId];
        } else {
            if (addItemAtIndex === null) {
                newModule.itemIds = [...selectedCm.itemIds, newItemId];
            } else {
                const newItemIds = [...selectedCm.itemIds];
                newItemIds.splice(addItemAtIndex, 0, newItemId);
                newModule.itemIds = newItemIds;
            }
        }
        const template = validTemplates?.find((t) => t.value === selectedCm.template)?.key || 'UNKNOWN';
        const isGalleryOrPreview = template === 'GALLERY' || selectedCm.preview || false;
        const modifyModuleResult = await modifyModule(newModule, !duplicating);
        return modifyModuleResult ? isGalleryOrPreview : undefined;
    };

    // TABLE
    const buildTableColumns = () => {
        const columns = Object.entries({
            template: 'UI',
            name: 'Module Name',
            placed: 'Content Source',
            conditions: 'Target Condition',
            lastModified: 'Last Modified'
        }).map(([key, value]) => {
            return <HeaderTableCell text={value} key={key} />;
        });

        columns.unshift(<TableCell />);
        isMobile && columns.splice(5, 1); // remove last modified column if not in desktop view

        return columns;
    };
    const buildTableRows = () => {
        const rows: JSX.Element[] = modules.map((module, index) => {
            const lastModified = generateDateStringForTables(module?.lastModified || 0);
            const template = validTemplates?.find((t) => t.value === module.template) || '(Unknown)';
            const contentSource = calculateContentSourceValue(module);
            return (
                <DraggableTableRow
                    key={module._id}
                    id={`${module._id}`}
                    type={DRAGGABLE_TYPES.MODULE}
                    moveItem={moveModule}
                    findItem={findModule}
                >
                    {/* MODULE TEMPLATE TABLE CELL */}
                    <WidthTableCell {...ModulesTableSizes.template}>
                        <ContentTemplateLogo>
                            <SVGInline src={template ? TemplateIcons[template?.key] : TemplateIcons.GALLERY} />
                            <div>{template?.title || '(Unknown)'}</div>
                        </ContentTemplateLogo>
                    </WidthTableCell>

                    {/* MODULE NAME TABLE CELL */}
                    <WidthTableCell
                        {...ModulesTableSizes.name}
                        onMouseLeave={() => {
                            setOpenNameTooltip((tooltips: any) => {
                                const newOpenNameTooltip = { ...tooltips };
                                Object.keys(newOpenNameTooltip).forEach((key) => {
                                    newOpenNameTooltip[key] = false;
                                });
                                return newOpenNameTooltip;
                            });
                        }}
                    >
                        {renderNameTooltip(module, index)}
                    </WidthTableCell>

                    {/*  CONTENT SOURCE TABLE CELL */}
                    <WidthTableCell {...ModulesTableSizes.content_source}>
                        <TruncatedText>{contentSource}</TruncatedText>
                    </WidthTableCell>
                    <WidthTableCell $width={20} $um={'%'}>
                        <Labels
                            type={MoreInfoTypes.TARGETS}
                            values={module?.conditions || []}
                            noOfLabels={isDesktop ? 3 : isMobile ? 0 : 1}
                            onClickLabel={(obj) => {
                                const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                navigate(
                                    buildPathWithProjectId(
                                        activeProjectId,
                                        isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                    ),
                                    {
                                        state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                    }
                                );
                            }}
                        />
                    </WidthTableCell>

                    {/* LAST MODIFIED TABLE CELL */}

                    {!isMobile && <WidthTableCell {...ModulesTableSizes.lastModified}> {lastModified}</WidthTableCell>}

                    {/* MODIFIED BY TABLE CELL */}
                    {!isMobile && (
                        <ImageTableCell
                            um={'%'}
                            shape="round"
                            src={module?.modifiedByUser?.icon || avatarIcon}
                            toolTipName={module?.modifiedByUser?.name}
                            imageSize={{ width: 32, height: 32 }}
                        />
                    )}

                    {/* ACTIONS TABLE CELL */}
                    <WidthTableCell {...ModulesTableSizes.actions}>
                        <ActionsTableCell
                            actions={[tableActions.EDIT, tableActions.DUPLICATE, tableActions.REMOVE]}
                            onEdit={() => handleEditIconClick(module)}
                            onDuplicate={() => handleDuplicateIconClick(module)}
                            onRemove={() => handleRemoveIconClick(module)}
                            tooltipTexts={{
                                edit: 'pages_edit_icon_edit_module',
                                duplicate: 'pages_edit_icon_duplicate_module',
                                delete: 'pages_edit_icon_delete_module'
                            }}
                            publishedStatus={module.publishStatus}
                        />
                    </WidthTableCell>
                </DraggableTableRow>
            );
        });

        return <TableBody ref={drop}>{rows}</TableBody>;
    };
    // TABLE ACTIONS

    // MODULE ACTIONS
    const handleEditIconClick = (module: Module) => {
        setModuleToEdit(module);
        setOpenNewModuleDialog(true);
    };

    const handleDuplicateIconClick = (module: Module) => {
        setModuleToEdit(module);
        setOpenNewModuleDialog(true);
        setDuplicatingCM(true);
    };

    const handleRemoveIconClick = (module: Module) => {
        return dialogRemove(
            RemovableObjects.MODULE,
            { object: module.name, parent: pageName },
            (withItems) => {
                removeModule(module._id, withItems);
            },
            () => removeModuleFromPage(module._id)
        );
    };

    //ITEM ACTIONS

    const updateSelectionForItemsToShow = (cm_id: string, ci: Item) => {
        setItemsToShow((old) => ({ ...old, [cm_id]: ci }));
    };
    const updatePreviewImageToShow = (cm_id: string, previewImage: string) => {
        setPreviewImageToShow((old) => ({ ...old, [cm_id]: previewImage }));
    };

    const handleItemEditIconClick = (item: Item) => {
        setItemToEdit(item);
        setOpenNewItemDialog(true);
    };
    const handleItemRemoveIconClick = (item: Item, module: Module) => {
        const label = item.itemType === itemTypes.EDITORIAL ? 'Editorial Item' : 'Dynamic List';

        return dialogRemove(
            RemovableObjects.ITEM,
            { object: item.name, parent: module.name },
            () => {
                removeItem(item._id);
            },
            () => removeItemFromModule(module, item._id),
            label
        );
    };

    // PAGE OPTIONS

    const createFiles = async (files: File[]) => {
        const prefix = `${activeProjectId}/page_backgrounds`;
        try {
            const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    const calculateApplyForAll = (backgroundImages: any) => {
        const values: any[] = Object.values(backgroundImages);
        if (!values.length) return true;
        const firstUrl = decodeURIComponent(values[0]);
        const isOpenUrl = isOpenFileUrl(firstUrl);
        const firstFileName = !isOpenUrl
            ? firstUrl.split(values[0].includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
            : firstUrl.split('/').pop();
        return (
            !values
                .filter((_, index) => index > 0)
                .some((value) => {
                    const url = decodeURIComponent(value);
                    const isOpenUrl = isOpenFileUrl(url);
                    const fileName = !isOpenUrl
                        ? url.split(value.includes(imgixBaseUrl) ? imgixBaseUrl : imgixFMBaseUrl)[1].split('?')[0]
                        : url.split('/').pop();
                    return fileName !== firstFileName;
                }) && values.length === 4
        );
    };

    const handleImageSelect = (image: File | string, key: keyof supportedResolutionsType) => {
        let newImages = { ...backgroundImageFiles };
        let newUrls = { ...backgroundImageUrls };
        let pageImage = { ...pageBackgroundImage };
        let initialPageImage = { ...initialBackgroundImage };

        if (!image) {
            if (applyBackgroundForAll) {
                newImages = {};
                newUrls = {};
                pageImage = {};
                initialPageImage = {};
            } else {
                newImages = _.omit(newImages, key);
                newUrls = _.omit(newUrls, key);
                pageImage = _.omit(pageImage, key);
                initialPageImage = _.omit(initialPageImage, key);
            }
        } else {
            if (typeof image === 'string') {
                const isOpenUrl = !image.includes(`${activeProjectId}`);
                _.set(newImages, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(newUrls, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
                _.set(pageImage, key, isOpenUrl ? image : getImgixUrl(image, undefined, true));
            } else {
                _.set(newImages, key, image);
                _.set(pageImage, key, image);
            }
        }

        setBackgroundImageFiles(newImages);
        setBackgroundImageUrls(newUrls);
        setPageBackgroundImage(pageImage);
        setInitialBackgroundImage(initialPageImage);
    };

    const handleNewModuleDialogClose = () => {
        setOpenNewModuleDialog(false);
        setModuleToEdit(undefined);
        setDuplicatingCM(false);
        setAddModuleAtIndex(null);
    };

    // --------------
    // TOOLTIPS

    useEffect(() => {
        const newTooltips = { ...openNameTooltip };
        modules.forEach((_, index) => {
            newTooltips[index] = false;
        });
        setOpenNameTooltip(newTooltips);
    }, [modules]);

    const renderIntentSpecificSection = () => {
        if (PageIntentsWithPlaceHolders.includes(pageIntent)) {
            const tooltipKey = `pages_edit_${_.last(pageIntent.split('_'))?.toLowerCase() || ''}_placeholder`;
            const withLargeImg = pageIntent === PageIntents.EPG && isLargeDesktop;
            return (
                <PlaceholderModule $extraHeight={withLargeImg}>
                    {renderTooltipWithKey(
                        <PlaceholderModuleAction>
                            <SVGInline src={icons.closeEditIcon} />
                        </PlaceholderModuleAction>,
                        tooltipKey,
                        tooltipPositions.BOTTOM
                    )}

                    <PlaceholderWrapper onMouseEnter={() => setShowIntentTooltip(true)} onMouseLeave={() => setShowIntentTooltip(false)}>
                        <PlaceholderImage src={withLargeImg ? EPGImage : IntentsPlaceHolders[pageIntent]} />
                        {renderTooltipWithKey(<div />, tooltipKey, tooltipPositions.TOP, undefined, true, showIntentTooltip)}
                    </PlaceholderWrapper>
                </PlaceholderModule>
            );
        }
        switch (pageIntent) {
            case PageIntents.AUTO_COLLECTION:
                const divs = [];
                const withAdvancedBackground = hasIncreasedTopMargin(pageStyle, modules.length ? 1 : 0);
                const colors = pageStyle?.colors;
                for (let i = 0; i < 108; i++) {
                    divs.push(i);
                }
                return (
                    <CollectionPlaceholderModule
                        isAdvanced={withAdvancedBackground}
                        background={getBackgroundImage(pageStyle, modules.length ? 1 : 0)}
                    >
                        <PlaceholderActions />
                        <CollectionContainer>
                            {withAdvancedBackground && <AdvancedBackgroundGap />}
                            <CollectionTitle color={colors?.body}>
                                Auto Collection
                                {renderTooltipWithKey(
                                    <SVGInline src={icons.collectionPlaceholderInfoIcon} />,
                                    'pages_edit_collection_placeholder'
                                )}
                            </CollectionTitle>

                            <CollectionGrid>
                                {divs.map((elem) => (
                                    <CollectionItem key={`div_${elem}`}>
                                        <SVGInline src={icons.noImageIcon} />
                                    </CollectionItem>
                                ))}
                            </CollectionGrid>
                        </CollectionContainer>
                    </CollectionPlaceholderModule>
                );
            default:
                return;
        }
    };

    const renderNameTooltip = (module: Module, index: number) => {
        const tooltipValue = (
            <ObjectNameTooltipContentHolder>
                <ObjectNameTooltipIconHolder>
                    <SVGInline src={icons.modulesIcon} />
                </ObjectNameTooltipIconHolder>
                <ObjectNameTooltipNameHolder>{module.name || 'This Module has no name set'}</ObjectNameTooltipNameHolder>
                <TooltipDivider />
                <ObjectNameTooltipLabelHolder>Open Editor</ObjectNameTooltipLabelHolder>
            </ObjectNameTooltipContentHolder>
        );
        return renderTooltip(
            <NameWrapper>
                {<TranslationTooltip translationKey={module.name} />}
                <TruncatedText>
                    <Link
                        to={{
                            pathname: buildPathWithProjectId(activeProjectId, PageRoutes.MODULES)
                        }}
                        state={{ moduleId: module._id, redirected: true }}
                        onMouseEnter={() =>
                            setOpenNameTooltip((tooltips: any) => {
                                const newOpenNameTooltip = { ...tooltips };
                                Object.keys(newOpenNameTooltip).forEach((key) => {
                                    newOpenNameTooltip[key] = key === index.toString();
                                });
                                return newOpenNameTooltip;
                            })
                        }
                    >
                        {module.name || EMPTY_WORD_STRING}
                    </Link>
                </TruncatedText>
            </NameWrapper>,
            tooltipTypes.HTML,
            tooltipValue,
            tooltipPositions.TOP,
            false,
            true,
            false,
            openNameTooltip?.[index] || false
        );
    };

    const calculateContentSourceValue = (module: Module) => {
        if (!(module.items && module.items.length)) return '(No source)';
        if (module.items?.every((ci) => ci.itemType === itemTypes.EDITORIAL)) return 'Editorial';
        if (module.items.every((ci) => ci.contentSourceType === module.items?.[0]?.contentSourceType)) {
            return contentSourceTypes?.find((cs) => cs.value === module.items?.[0]?.contentSourceType)?.title;
        }
        return 'Multiple sources';
    };
    // --------------
    const renderAddModuleActions = () => {
        if (
            [PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent) ||
            (pageHasCollection && modules.length > allowedNumberOfModules)
        )
            return;
        return (
            <PageActionsWrapper>
                {isMobile ? (
                    <Button type={'BLUE'} label={'Add Module'} style={AddModulestyle} onClick={() => setOpenNewResourceDialog(true)} />
                ) : (
                    <>
                        <Button type={'BLUE'} label={'Create Module'} style={AddModulestyle} onClick={() => setOpenNewModuleDialog(true)} />
                        <Button
                            type={'BLUE'}
                            label={'Add Existing'}
                            style={AddModulestyle}
                            onClick={() => setOpenUseExistingModuleDialog(true)}
                        />
                        <Button
                            type={'BLUE'}
                            label={'Select Template'}
                            style={AddModulestyle}
                            onClick={() => setOpenTemplateSelectionDialog(true)}
                        />
                    </>
                )}
            </PageActionsWrapper>
        );
    };

    const titleJSX = () => {
        return (
            <PageNameContainer>
                <PagePrefix>
                    <span>Pages</span>
                    <SVGInline src={icons.arrowRightIcon} />
                </PagePrefix>
                <TruncatedText>{pageName || EMPTY_WORD_STRING}</TruncatedText>
                <div style={{ display: 'flex' }}>
                    <TranslationTooltip translationKey={pageName} />
                    {selectedPage?.publishStatus && renderPublishStatusIcon(selectedPage?.publishStatus, selectedPage?.publishAt)}
                    {showUnsaved && <UnsavedSpan>[Unsaved]</UnsavedSpan>}
                </div>
            </PageNameContainer>
        );
    };

    const getBackgroundImage = (style: PageStyle, index: number) => {
        if (!_.isEmpty(pageBackgroundImage)) {
            const selectedResolution = (Object.keys(supportedResolutions) as Array<keyof supportedResolutionsType>).find(
                (key) => !!pageBackgroundImage[key]
            );
            const backgroundImage = selectedResolution ? pageBackgroundImage[selectedResolution] : '';

            if (index === 0) {
                return `url('${backgroundImage}')`;
            }
        }

        if (_.isEmpty(style)) return undefined;

        let backgroundColor;

        if (style.colors?.background) {
            const { r, g, b, a } = alphaHexToRGBA(style.colors.background);
            backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        }

        if (!style.pageBackgroundImage || !Object.values(style.pageBackgroundImage).length) return backgroundColor;

        const backgroundImage = Object.values(style.pageBackgroundImage).find((val) => !!val);

        if (index === 0) {
            return `url('${backgroundImage}')`;
        }

        return backgroundColor;
    };

    const hasIncreasedTopMargin = (style: PageStyle, index: number) => {
        // Only when a backgroundImage is applied on page level the increasedTopMargin from the page should be taken in consideration
        return !!Object.keys(pageBackgroundImage).length ? pageIncreasedTopMargin && index === 0 : style?.increasedTopMargin && index === 0;
    };

    const getProviderNameForModule = (module: Module) => {
        if (!module?.includeProviderLogoCard) return '';
        return module?.items?.find((item) => item.itemType === itemTypes.DYNAMIC)?.contentSourceType || '';
    };

    const renderListView = () =>
        modules?.length ? (
            <>
                <GenericTable columns={buildTableColumns()} body={buildTableRows()} />
                {renderAddModuleActions()}
            </>
        ) : (
            renderAddModuleActions()
        );

    const renderVisualEditorCards = () => {
        const cards: (JSX.Element | null)[] = modules.map((module, mindex) => {
            if ([PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent)) return null;
            const isLastModule = modules.length === mindex + 1;
            const colors = pageStyle?.colors;
            if (module._id === 'search') {
                return (
                    <>
                        {renderTooltipWithKey(
                            <span>
                                <DraggableModule
                                    key={module._id + `${mindex}`}
                                    id={`${module._id}`}
                                    type={DRAGGABLE_TYPES.MODULE}
                                    template={'16x9_S'}
                                    moduleType={module.moduleType}
                                    moveItem={moveModule}
                                    findItem={findModule}
                                    isSearchModule
                                    background={getBackgroundImage(pageStyle, mindex)}
                                    advancedBackground={hasIncreasedTopMargin(pageStyle, mindex)}
                                >
                                    <div style={{ position: 'absolute', top: '36px', left: '100px' }}>
                                        <SearchTitle color={colors?.body}>Search (Results) Component</SearchTitle>
                                        <SearchIconContainer>
                                            <SVGInline src={icons.bigSearchIcon} />
                                        </SearchIconContainer>
                                    </div>
                                </DraggableModule>
                            </span>,
                            'pages_edit_search_placeholder'
                        )}
                        {!isLastModule &&
                            ![PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT, PageIntents.AUTO_COLLECTION].includes(pageIntent) && (
                                <ModuleSeparator
                                    key={mindex + '_separator'}
                                    onPlus={() => {
                                        setOpenNewResourceDialog(true);
                                        setAddModuleAtIndex(0);
                                    }}
                                ></ModuleSeparator>
                            )}
                    </>
                );
            }
            const template = validTemplates?.find((t) => t.value === module.template)?.key || 'UNKNOWN';
            const isGallery = template === 'GALLERY';
            const isPreview = !!module.preview;
            // If the template type is GALLERY or PREVIEW, for the cards we use the size of the 16X9_S
            const cardType = isGallery ? '16X9_S' : template;

            const isCollection = module?.moduleType === moduleTypes.COLLECTION;

            const itemActionsSwitcher = (type: 'ADD' | 'EDIT' | 'DELETE' | 'DUPLICATE', module: Module, item?: Item, index?: number) => {
                switch (type) {
                    case 'ADD':
                        typeof index === 'number' && setAddItemAtIndex(index + 1);
                        setOpenNewResourceDialogForItem(true);
                        setModuleIdForItem(module._id);
                        setTemplateType(cardType);
                        setIsAutoCollection(module?.autoCollection || false);
                        setIncludeProviderLogoCardProviderName(getProviderNameForModule(module));
                        setIsContentWorld(module?.contentWorld || false);
                        break;
                    case 'EDIT':
                        if (!item) return;
                        handleItemEditIconClick(item);
                        break;
                    case 'DELETE':
                        if (!item) return;
                        handleItemRemoveIconClick(item, module);
                        break;
                    case 'DUPLICATE':
                        if (!item) return;
                        setDuplicatingCI(true);
                        setModuleIdForItem(module._id);
                        handleItemEditIconClick(item);
                        break;
                    default:
                        break;
                }
            };

            const renderModuleContent = () => {
                if (isGallery || isPreview) {
                    return (
                        <GalleryAndPreview
                            module={module}
                            findItem={findItem}
                            moveItem={moveItem}
                            setItemForPreview={setItemForPreview}
                            unsetItemForPreview={unsetItemForPreview}
                            item={itemsToShow?.[module._id]}
                            onBackgroundChange={(newPreview) => {
                                updatePreviewImageToShow(module._id, newPreview);
                            }}
                            onCardClick={(ci: Item) => updateSelectionForItemsToShow(module._id, ci)}
                            type={isGallery ? 'GALLERY' : 'PREVIEW'}
                            onItemAction={(actionType, item, index) => itemActionsSwitcher(actionType, module, item, index)}
                            onSave={(item) => {
                                showUnsaved && handleSaveClick();
                                modifyItem(item);
                            }}
                            moduleIndex={mindex}
                            style={pageStyle}
                        />
                    );
                }

                if (isCollection) {
                    return (
                        <Collection
                            module={module}
                            type={cardType}
                            onEdit={(ci) => {
                                handleItemEditIconClick(ci);
                            }}
                            onDelete={(ci) => {
                                handleItemRemoveIconClick(ci, module);
                            }}
                            addItem={(index) => {
                                typeof index === 'number' && setAddItemAtIndex(index + 1);
                                setOpenNewResourceDialogForItem(true);
                                setModuleIdForItem(module._id);
                                setIsCollectionItem(true);
                                setCollectionType(!!module?.items?.length ? module.items[0].itemType : undefined);
                            }}
                            setItemForPreview={setItemForPreview}
                            unsetItemForPreview={unsetItemForPreview}
                            onItemSave={(item) => {
                                showUnsaved && handleSaveClick();
                                modifyItem(item);
                            }}
                            moveItem={moveItem}
                            findItem={findItem}
                            moduleIndex={mindex}
                        />
                    );
                }

                return (
                    <CardsStripe
                        module={module}
                        findItem={findItem}
                        cardType={cardType}
                        moveItem={moveItem}
                        setItemForPreview={setItemForPreview}
                        unsetItemForPreview={unsetItemForPreview}
                        onItemAction={(actionType, item, index) => itemActionsSwitcher(actionType, module, item, index)}
                        onSave={(item) => {
                            showUnsaved && handleSaveClick();
                            modifyItem(item);
                        }}
                        moduleIndex={mindex}
                        style={pageStyle}
                    />
                );
            };

            return (
                <>
                    <DraggableModule
                        key={module._id + `${mindex}`}
                        id={`${module._id}`}
                        type={DRAGGABLE_TYPES.MODULE}
                        template={cardType}
                        moduleType={module.moduleType}
                        moveItem={moveModule}
                        findItem={findModule}
                        actions={[Actions.EDIT, Actions.DUPLICATE, Actions.REMOVE]}
                        onEdit={() => handleEditIconClick(module)}
                        onDuplicate={() => handleDuplicateIconClick(module)}
                        onRemove={() => handleRemoveIconClick(module)}
                        background={isGallery || isPreview ? previewImageToShow?.[module._id] : getBackgroundImage(pageStyle, mindex)}
                        advancedBackground={
                            ![
                                PageIntents.TV_MOVIE_DETAILS,
                                PageIntents.TV_SERIES_DETAILS,
                                PageIntents.VOD_MOVIE_DETAILS,
                                PageIntents.VOD_SERIES_DETAILS
                            ].includes(pageIntent) &&
                            !isGallery &&
                            !isPreview &&
                            hasIncreasedTopMargin(pageStyle, mindex)
                        }
                        draggableDisabled={pageHasCollection}
                        isGalleryOrPreview={isGallery || isPreview}
                    >
                        {renderModuleContent()}
                    </DraggableModule>
                    {!isLastModule && ![PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent) && !pageHasCollection && (
                        <ModuleSeparator
                            key={mindex + '_separator'}
                            onPlus={() => {
                                setOpenNewResourceDialog(true);
                                pageIntent === PageIntents.SEARCH ? setAddModuleAtIndex(mindex) : setAddModuleAtIndex(mindex + 1);
                            }}
                        />
                    )}
                </>
            );
        });
        return <VisualEditorCardsWrapper ref={drop}>{cards}</VisualEditorCardsWrapper>;
    };

    const renderPageOptions = () => {
        return (
            <PageOptionsContainer>
                <PageOptionsTitle>SEO Settings</PageOptionsTitle>
                <PageSEOFields>
                    <SeoInputWrapper>
                        <DialogTextField
                            value={slug || ''}
                            label="Slug"
                            onChange={(evt: any) => {
                                const newValue = evt.target.value;
                                const sanitizedValue = newValue.replace(/[^a-zA-Z0-9\-]/g, '');
                                setSlug(sanitizedValue);
                            }}
                        />
                    </SeoInputWrapper>
                    <SeoInputWrapper>
                        <DialogTextField
                            value={description || ''}
                            label="Description"
                            onChange={(evt: any) => setDescription(evt.target.value)}
                        />
                    </SeoInputWrapper>
                    <SeoInputWrapper>
                        <DialogTextField
                            value={canonical || ''}
                            label="Canonical"
                            onChange={(evt: any) => setCanonical(evt.target.value)}
                        />
                    </SeoInputWrapper>
                </PageSEOFields>
                <PageOptionsTitle>
                    Page Background Image {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'pages_edit_page_background_image')}
                </PageOptionsTitle>
                <ImageFields>
                    <DialogFileField
                        imageInfo={{ previewImage: true, height: 108, width: 188, um: 'px' }}
                        customAddIcon={icons.addIconWhite}
                        preview={pageBackgroundImage.bigScreen}
                        fieldKey={'Big Screen (1920x1080)'}
                        customLabel={applyBackgroundForAll ? 'All Screens' : 'Big Screen (1920x1080)'}
                        localFileCallBack={(file) => handleImageSelect(file, 'bigScreen')}
                        fileManagerCallback={(url: string) => handleImageSelect(url, 'bigScreen')}
                        openUrlCallback={(url: string) => handleImageSelect(url, 'bigScreen')}
                        alwaysShowLabel
                    />
                    {!applyBackgroundForAll && (
                        <>
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 108, width: 188, um: 'px' }}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage?.pc}
                                fieldKey={'PC (1280x720)'}
                                customLabel={'PC (1280x720)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'pc')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'pc')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'pc')}
                                alwaysShowLabel
                            />
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 108, width: 188, um: 'px' }}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage?.tablet}
                                fieldKey={'Tablet (960x540)'}
                                customLabel={'Tablet (960x540)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'tablet')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'tablet')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'tablet')}
                                alwaysShowLabel
                            />
                            <DialogFileField
                                imageInfo={{ previewImage: true, height: 108, width: 188, um: 'px' }}
                                customAddIcon={icons.addIconWhite}
                                preview={pageBackgroundImage?.mobile}
                                fieldKey={'Mobile (360x202)'}
                                customLabel={'Mobile (360x202)'}
                                localFileCallBack={(file) => handleImageSelect(file, 'mobile')}
                                fileManagerCallback={(url: string) => handleImageSelect(url, 'mobile')}
                                openUrlCallback={(url: string) => handleImageSelect(url, 'mobile')}
                                alwaysShowLabel
                            />
                        </>
                    )}
                </ImageFields>

                <ToggleButtonContainer>
                    <DialogToggleButton
                        checked={applyBackgroundForAll}
                        toggleCallback={() => {
                            setApplyBackgroundForAll(!applyBackgroundForAll);
                            setBackgroundImageFiles({});
                            setBackgroundImageUrls({});
                            setPageBackgroundImage({});
                            setInitialBackgroundImage({});
                        }}
                        text={'Apply for all screens'}
                        tooltipText={applyBackgroundForAll ? 'apply_for_all_on' : 'apply_for_all_off'}
                    />
                </ToggleButtonContainer>

                <DialogCheckbox
                    active={!!pageIncreasedTopMargin}
                    value={!!pageIncreasedTopMargin}
                    onClick={() => {
                        setPageIncreasedTopMargin(!pageIncreasedTopMargin);
                    }}
                    text={'Increased Top Margin'}
                    disabled={!Object.keys(pageBackgroundImage).length}
                    tooltipText={'style_and_branding_increased_margin'}
                />
            </PageOptionsContainer>
        );
    };

    const renderEditorView = () =>
        modules?.length ? (
            <>
                {pageHasCollection ? (
                    <>
                        {renderAddModuleActions()}
                        {renderVisualEditorCards()}
                    </>
                ) : (
                    <>
                        {renderVisualEditorCards()}
                        {renderAddModuleActions()}
                    </>
                )}
            </>
        ) : (
            renderAddModuleActions()
        );

    const renderAlertShowUnsaved = (shouldLeavePage?: boolean) => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => handleSaveClick(undefined, undefined, shouldLeavePage),
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                setShowUnsaved(false);
            },
            true
        );
    };

    const renderSlugAlert = () => {
        ToastAlert('warning', '', '', undefined, DIALOG_NAMES.PAGE_EXISTING_SLUG, () => {
            dialogAlert(DIALOG_NAMES.PAGE_EXISTING_SLUG, false, null, null, false, icons.warningYellowIcon);
        });
    };
    const renderEmptySlugAlert = () => {
        ToastAlert('warning', '', '', undefined, DIALOG_NAMES.PAGE_EMPTY_SLUG, () => {
            dialogAlert(DIALOG_NAMES.PAGE_EMPTY_SLUG, false, null, null, false, icons.warningYellowIcon);
        });
    };

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            case API_ERROR_CODES.PUBLISHED_REFERENCE_OBJECT:
                return renderPublishedDeleteError(error);
            case API_ERROR_CODES.ADMIN_LOCKED_ERROR:
                return renderAdminLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <ApplicationWrapper $loading={generalLoading}>
            <MainContentWrapper noSidebar>
                <ScreenTitle
                    title={titleJSX()}
                    withProfile
                    withAddButton={
                        ![PageIntents.EPG, PageIntents.PROFILE_MANAGEMENT].includes(pageIntent) &&
                        !(pageHasCollection && modules.length > allowedNumberOfModules)
                    }
                    backIcon
                    addLabel={'Add Module'}
                    onAdd={() => setOpenNewResourceDialog(true)}
                    onBack={() => {
                        const locationToGo = buildPathWithProjectId(activeProjectId, PageRoutes.PAGES);
                        unsetItemForPreview();
                        navigate(locationToGo);
                    }}
                    circlesSlugOptions={{ default: CIRCLE_SLUGS.pages, onboarding: ONBOARDING_CIRCLE_SLUGS.page_edit }}
                />
                {error && renderError(error)}
                {generalLoading && <Loader title={'Page'} />}
                <PageValues
                    onChange={handlePageValuesChange}
                    initialValues={{
                        name: pageName,
                        pageStyle: pageStyleId,
                        targets: pageTargets,
                        pageView: pageView,
                        abTestingGroupIds,
                        intent: pageIntent || (intents.length ? intents[0].value : '')
                    }}
                    pageStyles={pageStyleOptions}
                    showWarning={showGalleryWarning}
                    showAllIntents={
                        modules.filter((elem) => elem._id !== 'search').length < 2 &&
                        //in case a page has a collection module, the user should not be able to change the intent to auto_collection
                        !modules.some((module) => module.moduleType === moduleTypes.COLLECTION)
                    }
                    handleCreateNewOption={() => setShowPageStyleDialog(true)}
                    hasGalleryOrPreview={!!modules.find((elem) => [templates.GALLERY].includes(elem.template) || elem.preview)}
                    onOptionsClick={() => {
                        setIsPageOptionsOpen(!isPageOptionsOpen);
                    }}
                />
                {isPageOptionsOpen && renderPageOptions()}
                {pageView === PAGE_VIEWS.LIST_VIEW && renderListView()}
                {pageView === PAGE_VIEWS.EDITOR_VIEW && renderIntentSpecificSection()}
                {pageView === PAGE_VIEWS.EDITOR_VIEW && renderEditorView()}
            </MainContentWrapper>
            <PageActions
                onSave={handleSaveClick}
                onPublish={(publishAt) => {
                    publishAt ? handleSaveClick('', publishAt, undefined, true) : handlePublishClick();
                }}
                disabled={{
                    save: !showUnsaved || conditionsLoading,
                    publish: selectedPage?.publishStatus === PUBLISHED_STATUS.PUBLISHED && !showUnsaved
                }}
                onCancel={() => {
                    unsetItemForPreview();
                    navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGES));
                }}
                publishedStatus={selectedPage?.publishStatus || PUBLISHED_STATUS.UNPUBLISHED}
                onAbortPublish={() => handleAbortPublishPageClick(pageId)}
                withSchedule
                timestamp={selectedPage?.publishAt}
            />
            <TemplateSelection
                open={openTemplateSelectionDialog || openTemplateSelectionDialogForItem}
                onClose={() => {
                    setAddItemAtIndex(null);
                    setAddModuleAtIndex(null);
                    setOpenTemplateSelectionDialog(false);
                    setOpenTemplateSelectionDialogForItem(false);
                }}
                templateUrl={''}
                callback={async (id?: string) => {
                    let pId;
                    if (showUnsaved) {
                        const page = await handleSaveClick();
                        if (!page) return;

                        pId = typeof page === 'string' ? page : page._id;
                    }
                    if (id) {
                        useTemplate(id, pId);
                    }
                    setOpenTemplateSelectionDialogForItem(false);
                    setOpenTemplateSelectionDialog(false);
                    setOpenNewResourceDialogForItem(false);
                    setOpenNewResourceDialog(false);
                    resetModuleState();
                }}
                isAutoCollection={isAutoCollection}
                resourceType={openTemplateSelectionDialog ? templateTypes.MODULE : templateTypes.ITEM}
                moduleItemsNumber={modules.find((cm) => cm._id === moduleIdForItem)?.items?.length}
                isAppliedInGalleryOrButton={
                    openTemplateSelectionDialogForItem &&
                    ([templates.GALLERY, templates.BUTTON] as any[]).includes(modules.find((cm) => cm._id === moduleIdForItem)?.template)
                }
                restrictedCollection={pageHasCollection || modules.length > 1}
            />
            <NewModule
                page={
                    selectedPage
                        ? {
                              ...selectedPage,
                              intent: pageIntent // we need to pass the currently selected intent not the saved one
                          }
                        : undefined
                }
                open={openNewModuleDialog}
                onClose={handleNewModuleDialogClose}
                module={moduleToEdit}
                onSave={async (newModule, newItem) => {
                    let saveResult = false;
                    if (moduleToEdit && !duplicatingCM) {
                        saveResult = await modifyModule(newModule);
                        showUnsaved && saveResult && (await handleSaveClick());
                    } else {
                        saveResult = await saveModule(newModule, newItem);
                    }
                    if (!saveResult) return;

                    setOpenTemplateSelectionDialog(false);
                    setOpenNewResourceDialog(false);
                    resetModuleState();
                    handleNewModuleDialogClose();
                }}
                duplicate={duplicatingCM}
                restrictedCollection={pageHasCollection || modules.length > 1}
            />
            <NewItem
                open={openNewItemDialog}
                module={modules.find((cm) => cm._id === moduleIdForItem)}
                onClose={() => {
                    setOpenNewItemDialog(false);
                    setAddItemAtIndex(null);
                    setItemToEdit(undefined);
                    setDuplicatingCI(false);
                }}
                onSave={async (newItem, autoCollection, removeIncludeProviderCardLogo) => {
                    // in order to add a new item after the page changes were saved we need the up to date modules
                    let updatedModules: Module[] = [];
                    if (showUnsaved) {
                        const page = await handleSaveClick();
                        if (!!page && typeof page !== 'string') {
                            updatedModules = page.modules ? [...page.modules] : [];
                        }
                        if (!page) return;
                    }
                    let result: any = null;
                    let isGalleryOrPreview: boolean | undefined = undefined;
                    if (itemToEdit && !duplicatingCI) {
                        result = await modifyItem(newItem);
                        isGalleryOrPreview = false;
                        if (!result) return;
                    } else {
                        result = await saveItem(newItem);
                        if (!result) return;
                        isGalleryOrPreview = await handleSaveClickForItem(
                            result,
                            autoCollection,
                            removeIncludeProviderCardLogo,
                            updatedModules
                        );

                        if (newItem.itemType === itemTypes.EDITORIAL && typeof isGalleryOrPreview === 'boolean') {
                            if (isGalleryOrPreview) {
                                setIsGalleryLoading(true);
                                const itemResult = await configServiceAPI.getItemById(result);
                                setIsGalleryLoading(false);

                                if (!itemResult) return;
                                updateSelectionForItemsToShow(moduleIdForItem, itemResult.response as any);
                                setItemForPreview(result, moduleIdForItem, newItem.itemType);
                            } else {
                                setItemForPreview(result, moduleIdForItem, newItem.itemType);
                            }
                        }
                    }
                    if (!result || isGalleryOrPreview === undefined) return;

                    setOpenNewItemDialog(false);
                    setOpenTemplateSelectionDialogForItem(false);
                    setOpenNewResourceDialogForItem(false);
                    setItemToEdit(undefined);
                    setDuplicatingCI(false);
                    setAddItemAtIndex(null);
                    resetModuleState();
                }}
                item={itemToEdit}
                templateType={templateType}
                duplicate={duplicatingCI}
                isCollection={isCollectionItem}
                collectionType={collectionType}
                includeProviderCardLogoProviderName={includeProviderLogoCardProviderName}
                isContentWorldModule={isContentWorld}
                loading={isGalleryLoading}
            />
            <UseExistingDialog
                open={openUseExistingModuleDialog || openUseExistingItemDialog}
                onClose={() => {
                    setOpenUseExistingModuleDialog(false);
                    setOpenUseExistingItemDialog(false);
                }}
                existingItemType={openUseExistingModuleDialog ? EXISTING_ITEMS.MODULE : EXISTING_ITEMS.ITEM}
                onSave={async (existingItemId, contentType, autoCollection, removeIncludeProviderCardLogo, hasActionName, itemObject) => {
                    setOpenUseExistingModuleDialog(false);
                    setOpenNewResourceDialog(false);
                    setOpenUseExistingItemDialog(false);
                    setOpenNewResourceDialogForItem(false);
                    resetModuleState();

                    // When adding a Item via UseExisting dialog, we save the changes made to the page
                    // in order to add a new item after the page changes were saved we need the up to date modules
                    let updatedModules: Module[] = [];
                    if (openUseExistingItemDialog && showUnsaved) {
                        const page = await handleSaveClick();
                        if (!!page && typeof page !== 'string') {
                            updatedModules = page.modules ? [...page.modules] : [];
                        }
                    }
                    if (existingItemId) {
                        if (openUseExistingModuleDialog) {
                            handleSaveClick(existingItemId);
                        } else {
                            const module = modules.find((cm) => cm._id === moduleIdForItem);
                            if (([templates.GALLERY, templates.BUTTON] as any[]).includes(module?.template) && !hasActionName) {
                                actionNameMissingAlert();
                            }
                            const isGalleryOrPreview = await handleSaveClickForItem(
                                existingItemId,
                                autoCollection,
                                removeIncludeProviderCardLogo,
                                updatedModules
                            );
                            if (isGalleryOrPreview === undefined) return;
                            if (contentType === itemTypes.EDITORIAL) {
                                if (isGalleryOrPreview) {
                                    setIsGalleryLoading(true);
                                    const itemResult = await configServiceAPI.getItemById(existingItemId);
                                    setIsGalleryLoading(false);

                                    if (!itemResult) return;
                                    updateSelectionForItemsToShow(moduleIdForItem, itemResult.response as any);
                                }
                                setItemForPreview(existingItemId, moduleIdForItem, contentType);
                            }
                        }
                    }
                }}
                itemIdsToExclude={
                    (openUseExistingItemDialog ? modules.find((cm) => cm._id === moduleIdForItem)?.itemIds : selectedPage?.moduleIds) || []
                }
                isCollection={isCollectionItem}
                collectionType={collectionType}
                isAutoCollection={isAutoCollection}
                includeProviderCardLogoProviderName={includeProviderLogoCardProviderName}
                moduleTypeToExclude={
                    openUseExistingModuleDialog && (pageHasCollection || modules.length > 1) ? moduleTypes.COLLECTION : undefined
                }
                moduleTemplatesToExclude={
                    pageIntent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(pageIntent) ? [templates.GALLERY] : undefined
                }
                excludePreview={!!pageIntent && !PAGE_INTENTS_THAT_ALLOW_GALLERY_AND_PREVIEW.includes(pageIntent)}
                isContentWorld={isContentWorld}
            />
            <Hint
                type={HINT_TYPES.SCREEN_SIZE_PAGE}
                showHint={showHintScreen}
                onHide={() => {
                    setPageView(PAGE_VIEWS.LIST_VIEW);
                    setShowHintScreen(false);
                }}
            />
            <CreateResourceDialog
                title={openNewResourceDialogForItem ? 'Item or List' : 'Module'}
                open={openNewResourceDialog || openNewResourceDialogForItem}
                onClose={() => {
                    setOpenNewResourceDialog(false);
                    setOpenNewResourceDialogForItem(false);
                    resetModuleState();
                }}
                historyUrl={''}
                handleOpenTemplateClick={() =>
                    openNewResourceDialogForItem ? setOpenTemplateSelectionDialogForItem(true) : setOpenTemplateSelectionDialog(true)
                }
                handleCreateNewResourceClick={() => {
                    openNewResourceDialog ? setOpenNewModuleDialog(true) : setOpenNewItemDialog(true);
                }}
                withSelectExisting
                handleSelectExistingClick={() => {
                    openNewResourceDialog ? setOpenUseExistingModuleDialog(true) : setOpenUseExistingItemDialog(true);
                }}
            />

            <PageStyleDialog
                open={showPageStyleDialog}
                onClose={() => setShowPageStyleDialog(false)}
                onSave={(pageStyle) => {
                    createStyle(pageStyle);
                }}
            />
        </ApplicationWrapper>
    );
};

export const renderModuleSlugAlert = () => {
    ToastAlert('warning', '', '', undefined, DIALOG_NAMES.MODULE_EXISTING_SLUG, () => {
        dialogAlert(DIALOG_NAMES.MODULE_EXISTING_SLUG, false, null, null, false, icons.warningYellowIcon);
    });
};

export const renderModuleEmptySlugAlert = () => {
    ToastAlert('warning', '', '', undefined, DIALOG_NAMES.MODULE_EMPTY_SLUG, () => {
        dialogAlert(DIALOG_NAMES.MODULE_EMPTY_SLUG, false, null, null, false, icons.warningYellowIcon);
    });
};

export default PageEdit;
