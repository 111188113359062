import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export type PlatformAnalyticsData = { id: string; value: number; comparedValue?: number; label?: string }[];
export type PlatformAnalyticsResponse = {
    unique_devices?: { value: number; comparedValue?: number };
    unique_customers?: { value: number; comparedValue?: number };
    sessions?: { value: number; comparedValue?: number };
    average_session_duration?: { value: number; comparedValue?: number };
    device_category?: PlatformAnalyticsData;
    device_type?: PlatformAnalyticsData;
    device_manufacturer?: PlatformAnalyticsData;
    device_model?: PlatformAnalyticsData;
    os?: PlatformAnalyticsData;
    os_version?: PlatformAnalyticsData;
    client_version?: PlatformAnalyticsData;
    geo_country?: PlatformAnalyticsData;
    movie_title?: PlatformAnalyticsData;
};

export const fetchPlatformAnalytics = createAsyncThunk<
    { platformData: PlatformAnalyticsResponse; error: { message: string; code: string } | null; permissions?: any },
    { fromDate: number; toDate: number; tenantId: string; projectId: string; deviceCategory: string }
>('analytics/platform/fetchPlatformAnalytics', async ({ fromDate, toDate, deviceCategory, tenantId, projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getPlatformData(fromDate, toDate, deviceCategory, tenantId, projectId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { platformData: result.response as any, error: null, permissions: result.permissions };
});

export interface AnalyticsSlice {
    platformData: PlatformAnalyticsResponse;
    loading: boolean;
    error: {
        message: string;
        code?: string;
    } | null;
}

const initialState: AnalyticsSlice = {
    platformData: {},
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'analytics/platform',
    initialState,
    reducers: {
        unsetAnalyticsError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlatformAnalytics.fulfilled, (state, action) => {
                state.platformData = action.payload.platformData || {};
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchPlatformAnalytics.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.platformData = {};
                state.loading = false;
            })
            .addCase(fetchPlatformAnalytics.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetAnalyticsError } = slice.actions;
export default slice.reducer;
