import React, { FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import {
    HelpIconContainer,
    OnboardingTipArrow,
    OnboardingTipCloseIcon,
    OnboardingTipContainer,
    OnboardTipCheckbox,
    OnboardTipSubtitle,
    OnboardTipTitle
} from './HelpIcon.css';
import { docsWidget } from '../../App';
import { renderTooltipWithKey } from '../Tooltips/Tooltips';
import { DialogCheckbox } from '../Dialog/GenericDialog';
import { useAppSelector } from '../../../hooks/redux';
import { usePersistentState } from '../../../hooks/usePersistentState';

export const CIRCLE_SLUGS = {
    login: 'login',
    projects: 'projects',
    users: 'users',
    user_groups: 'user-groups',
    users_and_groups: 'manage-users',
    profile: 'profile-management',
    dashboard: 'dashboard-getting-started',
    target_groups: 'target-groups',
    ux_content: 'ux-content',
    audiences_groups: 'audience',
    audiences: '06-audiences-overview',
    pages: 'pages',
    modules: 'modules',
    items: 'lists-items',
    menus: 'menus',
    style_and_branding: 'style-branding',
    app_branding: 'app-branding',
    page_styles: 'page-style',
    settings: 'settings-702629',
    sources: 'sources',
    languages: 'languages',
    conditions: 'conditions',
    file_manager: 'file-manager',
    insights: 'insights',
    templates: 'templates'
} as const;

export const ONBOARDING_CIRCLE_SLUGS = {
    projects: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-login-and-projects-85cd22db-71be-486a-814d-b45d28c43dbc',
    users: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-manage-users-e3a7f075-861f-41d0-a182-91bfe5739413',
    user_groups: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-user-groups-068ca4f4-57ed-4085-b35d-79606225c494',
    items: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-lists-items-bd10ecd1-b562-4893-b639-a5133356d810',
    modules: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-content-modules-8902cd1a-5375-4556-9eac-fd48de3bab1d',
    pages: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-pages-a7016fe3-69cc-43dc-81e3-b428aa631721',
    page_edit: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-visual-editor-c7926beb-e8fd-4fd8-a654-e95149c14564',
    menus: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-menus-236caeda-4812-44d4-8ced-653b52c62c26',
    settings: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-settings-bb086845-419c-46e3-b587-60e0d6488e45',
    sources: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-sources-f26f0da2-71b7-4995-af98-40de4c7048f1',
    style_and_branding: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-style-and-branding-74169e85-5e9e-4a74-a0f1-0cae4d7c2540',
    target_groups: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-target-groups-ac91e58a-3b33-41df-96e2-298321b5b15d',
    audiences: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-audiences-9bd759ee-88d2-428d-89e8-81b901f96bd7',
    ux_content: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-ux-content-d7329065-e7ab-4bbf-9ffe-9db2a9f9b7fa',
    file_manager: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-file-manager-c9a18bc1-7500-430f-b450-67ae3546ce4e',
    insights: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-insights-9c2cf29a-98bc-424c-85af-a381a7cf4410',
    languages: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-languages-bb7f49fa-4f37-49e0-84b6-bac37716a094',
    conditions: 'https://plus.3ready.tv/c/cc-onboarding/onboarding-display-conditions-6af16371-9566-435c-96e1-63bec1c6d215'
} as const;

export type CircleSlug = typeof CIRCLE_SLUGS[keyof typeof CIRCLE_SLUGS];
export type CircleOnboardingSlug = typeof ONBOARDING_CIRCLE_SLUGS[keyof typeof ONBOARDING_CIRCLE_SLUGS];

export type CircleSlugOption = {
    default: CircleSlug | '';
    onboarding?: CircleOnboardingSlug;
};

const MAXIMUM_NUM_OF_TIMES_TO_SHOW_TIP_PER_SECTION = 1;
const MAXIMUM_NUM_OF_TIMES_TO_SHOW_TIP_GLOBALLY = 3;

const HelpIcon: React.FC<{ circlesSlugOptions?: CircleSlugOption; inDialog?: boolean }> = ({
    circlesSlugOptions = { default: '' },
    inDialog
}) => {
    const [isTipVisible, setIsTipVisible] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    const getSectionKeyForSlug = (slug: CircleSlug | ''): string => {
        return Object.keys(CIRCLE_SLUGS).find((key) => (CIRCLE_SLUGS as any)[key] === slug) || '';
    };

    const { userProfile } = useAppSelector((state) => state.profile);
    const { store: visitedSections, set: setVisitedSections } = usePersistentState('visitedSections', {});
    const autoHideTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleTipClose = () => {
        setIsTipVisible(false);
        if (dontShowAgain) {
            const newVisitedSections = { ...visitedSections };
            Object.keys(ONBOARDING_CIRCLE_SLUGS).forEach((key) => {
                _.set(newVisitedSections || {}, [userProfile._id, key], MAXIMUM_NUM_OF_TIMES_TO_SHOW_TIP_PER_SECTION);
            });
            setVisitedSections(newVisitedSections);
        }
    };

    const startAutoHideTimeout = () => {
        autoHideTimeout.current = setTimeout(() => {
            handleTipClose();
        }, 6000);
    };

    const cancelAutoHideTimeout = () => {
        autoHideTimeout.current && clearTimeout(autoHideTimeout.current);
    };

    useEffect(() => {
        if (!userProfile?._id || !docsWidget) return;

        // for now, show the tip only for sections with onboarding sections available
        if (!circlesSlugOptions.onboarding) return;

        const amountOfTimesShown = _.get(visitedSections, [userProfile._id, getSectionKeyForSlug(circlesSlugOptions.default)]);
        // previous implementation used only true false and we cannot clear the users' local storages,
        // but now we need to track and show it maximum of 1 times / section and 3 times globally
        let numOfTimesShownForSection: number;
        switch (typeof amountOfTimesShown) {
            case 'number':
                numOfTimesShownForSection = amountOfTimesShown;
                break;
            case 'boolean':
                numOfTimesShownForSection = amountOfTimesShown ? 1 : 0;
                break;
            default:
                numOfTimesShownForSection = 0;
        }
        const numOfTimesShownGlobally = Object.values((visitedSections?.[userProfile._id] || {}) as (boolean | number)[]).reduce<number>(
            (sum, curr) => {
                sum += curr ? 1 : 0;
                return sum;
            },
            0
        );
        if (
            numOfTimesShownGlobally >= MAXIMUM_NUM_OF_TIMES_TO_SHOW_TIP_GLOBALLY ||
            numOfTimesShownForSection >= MAXIMUM_NUM_OF_TIMES_TO_SHOW_TIP_PER_SECTION
        )
            return;
        setTimeout(() => {
            setVisitedSections(_.set(visitedSections, [userProfile._id, getSectionKeyForSlug(circlesSlugOptions.default)], 1));
            setIsTipVisible(true);
        }, 1000); // the tip should show after 2 seconds, the DOCS Widget is created after 1 second so we add 1 more sec here
    }, [userProfile, docsWidget]);

    useEffect(() => {
        if (!isTipVisible) return;
        startAutoHideTimeout();
        return () => {
            cancelAutoHideTimeout();
        };
    }, [isTipVisible]);

    const handleClick = (evt: any) => {
        evt.preventDefault();
        if (!circlesSlugOptions) return;
        if (!docsWidget) return;

        let slugToSet: string = circlesSlugOptions.default;
        if (isTipVisible && circlesSlugOptions.onboarding) {
            slugToSet = circlesSlugOptions.onboarding;
        }

        docsWidget.setSlug(slugToSet, '', isTipVisible);
        docsWidget.element.click();
    };

    return (
        <HelpIconContainer inDialog={inDialog}>
            {renderTooltipWithKey(
                <SVGInline src={isTipVisible ? icons.helpIconBlue : icons.helpIcon} onClick={handleClick} />,
                'generic_icon_help'
            )}
            {isTipVisible && (
                <OnboardingTip
                    dontShowAgain={dontShowAgain}
                    setDontShowAgain={setDontShowAgain}
                    onClose={handleTipClose}
                    onMouseEnter={cancelAutoHideTimeout}
                    onMouseLeave={startAutoHideTimeout}
                />
            )}
        </HelpIconContainer>
    );
};

export default HelpIcon;

const OnboardingTip: FC<{
    dontShowAgain: boolean;
    setDontShowAgain: any;
    onClose: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}> = ({ dontShowAgain, setDontShowAgain, onClose, onMouseEnter, onMouseLeave }) => {
    return (
        <OnboardingTipContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <OnboardingTipArrow />
            <OnboardingTipCloseIcon>
                <SVGInline src={icons.closeWhiteIcon} onClick={() => onClose()} />
            </OnboardingTipCloseIcon>
            <OnboardTipTitle>Quick Tip: Explore Documentation!</OnboardTipTitle>
            <OnboardTipSubtitle>Explore more with quick guides under each page. </OnboardTipSubtitle>
            <OnboardTipCheckbox>
                <DialogCheckbox
                    onClick={() => {
                        setDontShowAgain(!dontShowAgain);
                    }}
                    text={`Don't show anymore`}
                    value={dontShowAgain}
                    active={dontShowAgain}
                    whiteTheme
                />
            </OnboardTipCheckbox>
        </OnboardingTipContainer>
    );
};
