import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { FileFieldContainer } from '../../common/Dialog/GenericDialog.css';
import { DragAndDropWrapper } from '../../../HOCs/DragAndDrop/DragAndDrop.css';
import { ColorPickerContainer } from '../../common/ColorPicker/ColorPicker.css';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const StyleViewName = styled.div`
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    ${fontSizesCSS.Body2};
`;

export const TemplateStyleViewName = styled(StyleViewName)`
    color: ${theme.palette.primary.main};
`;

export const StyleViewPages = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyleDefault = styled.div`
    span {
        padding: 0;
    }
`;

export const StyleViewLastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
        ${devices.tablet} {
            display: none;
        }
    }}
`;
export const ColorsContainerTitle = styled.div`
    margin-bottom: 24px;
`;
export const ColorsContainerValues = styled.div``;

export const ColorCard = styled.div`
    width: 188px;
    height: 84px;
    box-sizing: border-box;
    background: linear-gradient(0deg, ${theme.palette.background.active}, ${theme.palette.background.active}), ${theme.palette.white.main};
    border-radius: 3px;
    &:not(:last-child) {
        margin-right: 16px;
    }
    display: inline-block;
    padding: 16px;

    ${ColorPickerContainer} {
        .color-swatch {
            left: 8px;
        }
        width: 100%;
        input {
            padding-left: 28px;
            width: 100%;
        }
        svg {
            right: 8px;
        }
    }
`;

export const ColorName = styled.div`
    margin-bottom: 8px;
    ${fontSizesCSS.Caption3};
    font-weight: bold;
`;

export const ColorsContainer = styled.div`
    contain: size;
    margin-top: 8px;
    height: 184px;
    background: ${theme.palette.white.main};
    padding: 24px;
    box-sizing: border-box;

    ${ColorsContainerValues} {
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            width: 0;
        }
    }
`;

export const StyleFieldsContainer = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
  
    .MuiFormControl-root {
        width: 320px;
        margin: 0
    }
    
    [id$='_Placed'] {
        width: 414px;
        margin: -12px 0 0 0;
       
        .cc_select__multi-value {
            padding: 6px;
            div.custom-value-label {
                color ${theme.palette.primary.main};
                ${fontSizesCSS.Caption3};
            }
        }
    }

    ${devices.tablet} {
        [id$='_Placed'] {
            width: 175px 
        }
        .MuiFormControl-root {
            width: auto;
        } 
    }
`;

export const ImageHeader = styled.div`
    ${fontSizesCSS.Body2};
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const ImageFieldContainer = styled.div<{ isDialog?: boolean }>`
    background-color: ${theme.palette.background.main};
    margin: 8px 0;
    padding: 24px;
    ${(props) =>
        props.isDialog
            ? css`
                  overflow: auto;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                      display: none;
                  }
                  ${ImageFields} {
                      width: 100%;
                  }
              `
            : css``};

    ${devices.desktopS} {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
export const ImageFields = styled.div<{ $applyForAll?: boolean }>`
    gap: 16px;
    display: flex;
    padding-bottom: 30px;

    ${devices.desktopS} {
        width: calc(100vw - 192px);
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    ${/*for now revert to not showing fields if apply for all is true */ ''}
    ${FileFieldContainer} {
        &:not(:first-child) {
            ${({ $applyForAll }) =>
                $applyForAll &&
                `
            opacity: 0.6;
            pointer-events: none;`}
        }
        height: 108px;
        width: 188px;
        border: none;
        padding: 0;
        ${DragAndDropWrapper} {
            background: ${theme.palette.background.imageFields};
            padding: 0;
            border-radius: 3px;
            border: 2px dashed ${theme.palette.background.innerBorder};
        }
    }
    label {
        ${fontSizesCSS.Caption3}
    }
    svg {
        opacity: 0.7;
    }
`;

export const StyleTextFieldWrapper = styled.div`
    ${devices.tablet} {
        width: 100%;
    }

    width: 500px;
`;
export const PageStyleTogglesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const PageStyleArrowsContainer = styled.div`
    cursor: pointer;
    svg:first-child {
        transform: rotate(180deg);
    }
`;

export const PageStyleSectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    span {
        ${fontSizesCSS.Body2};
    }
`;
