import React, { FC, useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, CarouselContainer, CarouselItem } from './Carousel.css';
import icons from '../../../style';

// IMPORTANT! The Carousel will take the width and height of the parent element
const Carousel: FC<{ components: JSX.Element[]; timeout?: number }> = ({ components, timeout }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const numOfItems = components.length;
    useEffect(() => {
        const indexInterval = setTimeout(() => setCurrentIndex((currentIndex + 1) % numOfItems), timeout || 3000);
        return () => clearTimeout(indexInterval);
    }, [currentIndex]);

    return (
        <CarouselContainer>
            <ArrowRight
                src={icons.arrowRightIcon}
                onClick={(evt) => {
                    evt.stopPropagation();
                    setCurrentIndex((currentIndex + 1) % numOfItems);
                }}
            />
            <ArrowLeft
                src={icons.arrowLeftIcon}
                onClick={(evt) => {
                    evt.stopPropagation();
                    setCurrentIndex(currentIndex === 0 ? numOfItems - 1 : currentIndex - 1);
                }}
            />
            {components.map((component, index) => (
                <CarouselItem key={index} visible={currentIndex === index}>
                    {component}
                </CarouselItem>
            ))}
        </CarouselContainer>
    );
};

export default Carousel;
