import React, { useState } from 'react';
import { MoreInfoDialog, MoreInfoTypes } from '../Dialog/MoreInfoDialog';
import { ObjectNameLabel, ShowMoreButton, TruncatedText, LabelContainer } from '../../../style/styled-components/reusable.css';
import { Tooltip } from '@material-ui/core';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import TranslationTooltip from '../TranslationTooltip/TranslationTooltip';
import { LabelsWrapper } from './Labels.css';

export interface ILabel {
    values: any[];
    customLabels?: any[];
    type: typeof MoreInfoTypes[keyof typeof MoreInfoTypes];
    noOfLabels: number;
    withTranslationTooltip?: boolean;
    onClickLabel?: (obj: any) => void;
}

const Labels: React.FC<ILabel> = ({ values, customLabels, type, noOfLabels, withTranslationTooltip, onClickLabel }) => {
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    const renderConditionsLabels = () => {
        if (!values.length) {
            return <></>;
        }

        const labels = customLabels ? customLabels : [];

        if (!customLabels) {
            values.forEach((obj: any, index: number) => {
                if (index < noOfLabels) {
                    labels.push(
                        <Tooltip key={index} title={obj.name || EMPTY_WORD_STRING} placement={'top'}>
                            <ObjectNameLabel onClick={() => onClickLabel && onClickLabel(obj || {})}>
                                <TruncatedText>{obj.name || EMPTY_WORD_STRING}</TruncatedText>

                                {withTranslationTooltip && (
                                    <div>
                                        <TranslationTooltip translationKey={obj.name} />
                                    </div>
                                )}
                            </ObjectNameLabel>
                        </Tooltip>
                    );
                }
            });
        }

        values.length > noOfLabels &&
            labels.push(
                <ShowMoreButton
                    onClick={(e: React.MouseEvent<any>) => {
                        e.stopPropagation();
                        setShowMoreDialog({
                            type: type,
                            show: true,
                            data: values
                        });
                    }}
                >
                    {labels.length < 1 ? `+${values.length}` : '...'}
                </ShowMoreButton>
            );

        return labels;
    };

    return (
        <LabelsWrapper onClick={(e) => (onClickLabel || showMoreDialog.show) && e.stopPropagation()}>
            <LabelContainer>{renderConditionsLabels()}</LabelContainer>

            <MoreInfoDialog
                open={showMoreDialog.show}
                type={showMoreDialog.type!}
                data={showMoreDialog.data}
                onClickLabel={onClickLabel}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
            />
        </LabelsWrapper>
    );
};

export default Labels;
