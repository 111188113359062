export const validateVersionFormat = (version: string) => {
    const parts = version.split('.');
    if (parts.length > 4) return false;

    for (const part of parts) {
        if (!/^-?\d+$/.test(part) && part !== 'x') return false;
    }

    // after x in the format, only x is accepted (no numbers, for ex: 1.2.x.2 is incorrect)
    let firstXIndex = parts.indexOf('x');
    if (firstXIndex >= 0) return !parts.some((part, i) => i >= firstXIndex && part !== 'x');
    return true;
};

export const capitalizeAndSplitCamelCaseString = (s: string) => {
    let str = `${s[0].toUpperCase()}${s.substring(1)}`;
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const sanitizeFileName = (name: string) => {
    return name.trim().replace(/\s+/g, '_');
};
