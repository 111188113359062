import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { DialogDropdownSingle } from '../common/Dialog/GenericDialog';
import _ from 'lodash';
import {
    ApplicationsState,
    createApplication,
    deleteApplication,
    fetchApplications,
    updateApplication
} from '../../redux/slices/applicationsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../hooks/redux';
import { Application, ApplicationDialog, ApplicationValues } from './ApplicationDialog';
import { ApplicationOption, ApplicationOptionSpacer } from './Applications.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../style';
import { PermissionsState } from '../../redux/slices/permissionsSlice';
import { DIALOG_NAMES, dialogConfirm } from '../../utils/fnDialogs';

const ApplicationSelect: FC<{
    labelText?: string;
    placeholder?: string;
    toolTipText?: string;
    selectedApplication: string;
    onSelectedApplicationChange: (newSelectedApp: string) => void;
    errors?: { selectedApplication?: string };
    setErrors?: SetStateAction<any>;
    onApplicationFieldValuesChange: (newApplicationFieldValues?: ApplicationValues) => void;
}> = ({
    labelText,
    placeholder,
    toolTipText,
    selectedApplication,
    onSelectedApplicationChange,
    errors,
    setErrors,
    onApplicationFieldValuesChange
}) => {
    const [showNewApplicationDialog, setShowNewApplicationDialog] = useState(false);
    const [editingApplicationValues, setEditingApplicationValues] = useState<Application | undefined>(undefined);
    const { applications, loading: applicationsLoading }: ApplicationsState = useAppSelector((state) => state.applications);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);

    const [isEditOrDeleteActionClicked, setIsEditOrDeleteActionClicked] = useState(false);

    const dispatch = useDispatch();
    const loadApplications = async () => {
        return await dispatch(fetchApplications()).unwrap();
    };

    const _updateApplication = async (application: Application) => {
        await dispatch(updateApplication(application)).unwrap();
        await loadApplications();
        if (application.name === selectedApplication) {
            setErrors?.({});
            onApplicationFieldValuesChange(_.omit(application, 'name'));
        }
    };

    const _createApplication = async (application: Application) => {
        await dispatch(createApplication(application)).unwrap();
        await loadApplications();
        // when creating, select the newly created app
        setErrors?.({});
        onSelectedApplicationChange(application.name);
        onApplicationFieldValuesChange(_.omit(application, 'name'));
    };

    const _deleteApplication = async (applicationKey: string) => {
        await dispatch(deleteApplication({ applicationKey })).unwrap();
        await loadApplications();
        // if we are deleting the currently selected app, reset it to empty
        if (applicationKey === selectedApplication) {
            onSelectedApplicationChange('');
            onApplicationFieldValuesChange(undefined);
        }
    };

    useEffect(() => {
        if (!applications.length) loadApplications();
    }, []);
    const applicationsOptions = [
        ...Object.keys(applications || {}).map((key) => ({
            value: key,
            label: (
                <ApplicationOption>
                    <span>{key}</span>
                    <ApplicationOptionSpacer />
                    {userPermissions?.isSuperAdmin && (
                        <>
                            <SVGInline
                                key={'editIcon'}
                                src={icons.editIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    handleEditClick(key);
                                }}
                            />

                            <SVGInline
                                key={'deleteIcon'}
                                src={icons.trashIcon}
                                onMouseDown={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    handleDeleteClick(key);
                                }}
                            />
                        </>
                    )}
                </ApplicationOption>
            ),
            valueForSearch: key
        }))
    ];

    const handleEditClick = (key: string) => {
        setShowNewApplicationDialog(true);
        setEditingApplicationValues({ ...applications[key], name: key });
    };
    const handleDeleteClick = (key: string) => {
        dialogConfirm(
            DIALOG_NAMES.DELETE_APPLICATION,
            () => {
                _deleteApplication(key);
            },
            null,
            null,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Delete'
                }
            }
        );
    };

    return (
        <>
            <DialogDropdownSingle
                key={'extra_field'}
                options={applicationsOptions}
                value={applicationsOptions.find((opt) => opt.value === selectedApplication) || ''}
                placeholder={placeholder || 'Select Application'}
                labelText={labelText}
                toolTipText={toolTipText}
                onChange={(value: any) => {
                    setErrors?.({});
                    onSelectedApplicationChange(value.value);
                    const values = applications?.[value.value];
                    if (!values) return;
                    onApplicationFieldValuesChange({
                        ...values
                    });
                }}
                error={errors?.selectedApplication}
                notSorted
                newOption={{
                    name: 'Application',
                    onClick: () => {
                        setShowNewApplicationDialog(true);
                    }
                }}
                isDisabled={applicationsLoading}
                openOnClick={!isEditOrDeleteActionClicked}
            />
            <ApplicationDialog
                open={showNewApplicationDialog}
                onClose={() => {
                    setShowNewApplicationDialog(false);
                    setEditingApplicationValues(undefined);
                }}
                onSave={(app) => {
                    if (editingApplicationValues) {
                        _updateApplication(app);
                    } else {
                        _createApplication(app);
                    }
                }}
                editingApplicationValues={editingApplicationValues}
            />
        </>
    );
};

export default ApplicationSelect;
