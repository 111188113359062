import React, { useCallback } from 'react';
import { PaginationContainer, SearchBarWrapper, SelectPageSize } from './PaginationWrapper.css';
import { DialogDropdownSingle } from '../common/Dialog/GenericDialog';
import Pagination from '../common/Pagination/Pagination';
import _ from 'lodash';
import { SearchBarContainer, SearchIcon, SearchInput } from '../common/SearchBar/SearchBar.css';
import { InputAdornment } from '@material-ui/core';
import { renderTooltipWithKey } from '../common/Tooltips/Tooltips';
import { SearchSVGInline } from '../../style/styled-components/reusable.css';
import icons from '../../style';
import { ObjectFilter } from '../../utils/fnFilter';
import { dialogAlert, ToastAlert } from '../../utils/fnDialogs';
import { FancyFilter } from '../common/Select/FancyFilter';
import { ObjectType, ObjectTypes } from '../../types/Object';
import { useAppSelector } from '../../hooks/redux';

type PaginationWrapperProps = {
    orderBy: string;
    totalPages: number;
    pageSize: number;
    setPageSize: any;
    searchTerm?: string;
    setSearchTerm?: any;
    currentPage: number;
    setCurrentPage: any;
    setActiveObjectFilter?: any;
    activeObjectFilter?: ObjectFilter;
    onSearchTermOrPaginationChange: (
        pageSize: number,
        currentPage: number,
        orderBy: string,
        searchTerm?: string,
        filter?: ObjectFilter
    ) => void;
    extraPadding?: boolean;
    resetCallback?: (keep?: ResetCallbackProps) => void;
    showUnsaved?: boolean;
    type?: ObjectType;
};

export type ResetCallbackProps = {
    searchTerm?: boolean;
    currentPage?: boolean;
    sortConfig?: boolean;
    visualEditor?: boolean;
    filterObject?: boolean;
};

export const renderUnsavedAlertBeforeAction = () => {
    const values = {
        title: 'Action is blocked',
        text: 'You have unsaved changes, please save before proceeding with this action!'
    };
    ToastAlert('warning', values.title, values.text, undefined, undefined, () => {
        dialogAlert('', false, values, null, false, icons.warningYellowIcon);
    });
};

const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
    children,
    orderBy,
    totalPages,
    pageSize,
    setPageSize,
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    activeObjectFilter,
    setActiveObjectFilter,
    onSearchTermOrPaginationChange,
    extraPadding,
    resetCallback,
    showUnsaved,
    type
}) => {
    const { activeProjectId } = useAppSelector((state) => state.activeItem);

    const searchObjects = useCallback(
        _.debounce((pageSize: number, pageNumber: number, orderBy: string, searchTerm?: string) => {
            resetCallback?.({ searchTerm: true, sortConfig: true });
            onSearchTermOrPaginationChange(pageSize, pageNumber, orderBy, searchTerm);
        }, 1000),
        [activeProjectId]
    );

    const withFancyFilter = [ObjectTypes.PAGES, ObjectTypes.MODULES, ObjectTypes.ITEMS] as string[];
    const pageSizeOptions = [10, 20, 30, 40, 50].map((el) => {
        return {
            label: `${el}`,
            value: el
        };
    });

    const endAdornment = (
        <InputAdornment position="end">
            {renderTooltipWithKey(
                <SearchIcon
                    onClick={() => {
                        if (showUnsaved) return renderUnsavedAlertBeforeAction();
                        searchObjects.cancel();
                        resetCallback?.({ sortConfig: true });
                        onSearchTermOrPaginationChange(pageSize, 1, orderBy);
                    }}
                >
                    <SearchSVGInline src={searchTerm ? icons.closeIcon : icons.searchIcon} />
                </SearchIcon>,
                `${searchTerm ? 'generic_icon_close_search' : 'generic_icon_search'}`
            )}
        </InputAdornment>
    );

    return (
        <>
            <SearchBarContainer>
                <SearchBarWrapper>
                    {type && withFancyFilter.includes(type) ? (
                        <FancyFilter
                            type={type}
                            showUnsaved={showUnsaved}
                            setSearchTerm={setSearchTerm}
                            searchTerm={searchTerm || ''}
                            activeObjectFilter={activeObjectFilter}
                            onChange={(filter: any) => {
                                setCurrentPage(1);
                                if (typeof filter === 'string') {
                                    setSearchTerm(filter);
                                    onSearchTermOrPaginationChange(pageSize, 1, orderBy, filter);
                                } else {
                                    setActiveObjectFilter(filter);
                                    onSearchTermOrPaginationChange(pageSize, 1, orderBy, '', filter);
                                }
                            }}
                        />
                    ) : (
                        <SearchInput
                            title={'Search By Name'}
                            value={searchTerm || ''}
                            placeholder={'Search by Name'}
                            InputProps={{ endAdornment: endAdornment }}
                            onChange={(e: any) => {
                                if (showUnsaved) return renderUnsavedAlertBeforeAction();
                                const newSearchTerm = e.target.value;
                                setSearchTerm(newSearchTerm);
                                if (newSearchTerm && newSearchTerm.length > 0) {
                                    searchObjects(pageSize, 1, orderBy, newSearchTerm);
                                } else {
                                    searchObjects.cancel();
                                    onSearchTermOrPaginationChange(pageSize, 1, orderBy, newSearchTerm);
                                }
                            }}
                        />
                    )}
                </SearchBarWrapper>

                <SelectPageSize>
                    <DialogDropdownSingle
                        value={pageSizeOptions.find((el) => el.value === pageSize)}
                        options={pageSizeOptions}
                        placeholder=""
                        onChange={(val: any) => {
                            if (showUnsaved) return renderUnsavedAlertBeforeAction();
                            setCurrentPage(1);
                            setPageSize(val.value);
                            resetCallback?.({ searchTerm: true, filterObject: true, sortConfig: true });
                            onSearchTermOrPaginationChange(val.value, 1, orderBy, searchTerm, activeObjectFilter);
                        }}
                        adornmentStart={'Show'}
                        notSorted
                    />
                </SelectPageSize>
            </SearchBarContainer>

            {children}

            <PaginationContainer $extraPadding={extraPadding}>
                <Pagination
                    pageNeighbours={1}
                    pageLimit={pageSize}
                    totalPages={totalPages}
                    // if for currentPage there's a higher number than the totalPages
                    // the backend returns the last page, so here we set the last page for the currentPage too
                    currentPage={currentPage > totalPages ? totalPages : currentPage}
                    setCurrentPage={setCurrentPage}
                    onPageChanged={(data: any) => {
                        resetCallback?.({ searchTerm: true, currentPage: true, sortConfig: true, filterObject: true });
                        onSearchTermOrPaginationChange(data.pageLimit, data.currentPage, orderBy, searchTerm, activeObjectFilter);
                    }}
                    showUnsaved={showUnsaved}
                />
            </PaginationContainer>
        </>
    );
};

export default PaginationWrapper;
