import { FC, useState } from 'react';
import { MoreInfoDialog, MoreInfoTypes } from '../Dialog/MoreInfoDialog';
import { ShowMoreIconWrapper } from './ShowMoreIcon.css';
type ShowMoreIconProps = {
    values: any[];
    type: MoreInfoTypes;
    icon: string;
};

export const ShowMoreIcon: FC<ShowMoreIconProps> = ({ values, icon, type }) => {
    const [showMoreDialog, setShowMoreDialog] = useState<{ type: MoreInfoTypes | null; show: boolean; data: any[] }>({
        type: null,
        show: false,
        data: []
    });

    return (
        <>
            <ShowMoreIconWrapper
                src={icon}
                onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowMoreDialog({
                        type,
                        data: values,
                        show: true
                    });
                }}
            />

            <MoreInfoDialog
                open={showMoreDialog.show}
                type={showMoreDialog.type!}
                data={showMoreDialog.data}
                onClose={() => setShowMoreDialog({ type: null, show: false, data: [] })}
            />
        </>
    );
};
