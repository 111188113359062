import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../../config/cc-config.json';
import icons from '../../../style';
import { SidebarSVGInline, StyledSVGInline, TruncatedText } from '../../../style/styled-components/reusable.css';
import {
    AppLogo,
    AppLogoCollapsed,
    AppWrapper,
    CustomDrawer,
    GroupStyled,
    GroupTitle,
    ItemRow,
    ItemRowsWrapper,
    SideBarArrow,
    SidebarFooter,
    SidebarWrapper,
    TenantsMenuItemImg
} from '../../common/Sidebar/Sidebar.css';
import _ from 'lodash';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { closeSideBar, toggleSidebar } from '../../../redux/slices/sidebarOpenSlice';
import { Tooltip } from '@material-ui/core';
import useScreenSize from '../../../hooks/useScreenSize';
import { docsWidget } from '../../App';
import { MainTitle } from './Sidebar.css';
import { TenantsState, fetchTenants } from '../../../redux/slices/tenantsSlice';
import { PageRoutes } from '../../../types/RouteTypes';

const { system_templates, recycling_bin, extra_features } = config;

const SuperadminSidebar: React.FC = () => {
    const { sidebarOpen } = useAppSelector((state) => state.sidebarOpen);
    const { tenants, loading: tenantsLoading }: TenantsState = useAppSelector((state) => state.tenants);

    const sidebarRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { isDesktop } = useScreenSize();
    const [selectedTenant, setSelectedTenant] = useState<string>('');

    const toggleSidebarOpen = () => {
        dispatch(toggleSidebar());
    };

    const handleClose = () => {
        dispatch(closeSideBar());
    };

    const loadTenants = async () => {
        if (tenants.length) return;
        return await dispatch(fetchTenants({})).unwrap();
    };

    const renderSystemTemplatesSection = () => {
        const items: JSX.Element[] = [];
        system_templates?.forEach((item, index) => {
            const itemIcon = _.get(icons, `${item.icon}`, '');
            items.push(
                <ItemRow
                    key={`system_temapltes_item_${index}`}
                    $active={location.pathname === `/superadmin/${item.key}`}
                    onClick={() => navigate(`/superadmin/${item.key}`)}
                >
                    <a>
                        <SidebarSVGInline src={itemIcon} />
                        {sidebarOpen && <div className="title">{item.title}</div>}
                    </a>
                </ItemRow>
            );
        });
        return items;
    };

    const renderExtraSections = () => {
        const items: JSX.Element[] = [];
        extra_features.forEach((item, index) => {
            const icon = _.get(icons, `${item.icon}`, '');

            items.push(
                <ItemRow
                    key={`extra_feature_${index}`}
                    $active={location.pathname === `/superadmin/${item.key}`}
                    onClick={() => navigate(`/superadmin/${item.key}`)}
                >
                    <a>
                        <SidebarSVGInline src={icon} />
                        {sidebarOpen && <div className="title">{item.title}</div>}
                    </a>
                </ItemRow>
            );
        });

        return items;
    };

    const renderFeatureConfigSections = () => {
        const selectTenant = (id: string) => {
            setSelectedTenant(id);
            navigate(PageRoutes.SUPERADMIN_CONFIG.replace(':tenant_id', id));
        };
        const items: JSX.Element[] = [];

        if (tenantsLoading) {
            return (
                <ItemRow key={`feature_config_Load`} $active={false} onClick={() => {}}>
                    <a>
                        <TenantsMenuItemImg src={icons.projectIcon1} />
                        {sidebarOpen && <div className="title">Loading...</div>}
                    </a>
                </ItemRow>
            );
        }

        tenants.forEach((tenant, index) => {
            items.push(
                <ItemRow
                    key={`feature_config_${index}`}
                    $active={selectedTenant === tenant._id}
                    onClick={() => {
                        selectTenant(tenant._id);
                    }}
                >
                    <a>
                        <TenantsMenuItemImg src={tenant.logo || icons.projectIcon1} />
                        <TruncatedText>{sidebarOpen && <span className="title">{tenant.name}</span>}</TruncatedText>
                    </a>
                </ItemRow>
            );
        });

        return items;
    };

    const renderRecyclingBinSection = () => {
        const items: JSX.Element[] = [];
        recycling_bin?.forEach((item, index) => {
            const itemIcon = _.get(icons, `${item.icon}`, '');
            items.push(
                <ItemRow
                    key={`system_temapltes_item_${index}`}
                    $active={location.pathname === `/superadmin/${item.key}`}
                    onClick={() => navigate(`/superadmin/${item.key}`)}
                >
                    <a>
                        <SidebarSVGInline src={itemIcon} />
                        {sidebarOpen && <div className="title">{item.title}</div>}
                    </a>
                </ItemRow>
            );
        });
        return items;
    };

    useEffect(() => {
        const clickOutside = (evt: any) => {
            // Prevents unwanted collapsing of the menu on Desktop.
            if (evt.target.isSameNode(docsWidget?.element)) return;

            if (sidebarRef?.current && !sidebarRef.current.contains(evt.target)) {
                handleClose();
            }
        };
        if (!sidebarOpen || isDesktop) return;

        document.addEventListener('click', clickOutside);
        document.addEventListener('touchstart', clickOutside);
        return () => {
            document.removeEventListener('click', clickOutside);
            document.removeEventListener('touchstart', clickOutside);
        };
    }, [sidebarOpen, isDesktop]);

    useEffect(() => {
        loadTenants();
    }, []);

    return (
        <>
            <CustomDrawer ref={sidebarRef} variant="permanent" open={sidebarOpen}>
                <SideBarArrow onClick={toggleSidebarOpen} open={sidebarOpen}>
                    <Tooltip title={sidebarOpen ? 'Collapse' : 'Expand'} placement="right">
                        <div>
                            <StyledSVGInline src={icons.sidebarCollapse} />
                        </div>
                    </Tooltip>
                </SideBarArrow>
                <SidebarWrapper>
                    <AppWrapper>
                        {sidebarOpen ? (
                            <AppLogo src={icons.logo3Ready} alt="logo" onClick={() => navigate('/')} />
                        ) : (
                            <AppLogoCollapsed src={icons.logo3ReadyShort} alt="logo" onClick={() => navigate('/')} />
                        )}
                    </AppWrapper>
                    <MainTitle>
                        <SidebarSVGInline src={icons.homeIcon2} />
                        {sidebarOpen && <span>Superadmin UI</span>}
                    </MainTitle>
                    <GroupStyled>
                        {sidebarOpen && <GroupTitle>System Templates</GroupTitle>}
                        <ItemRowsWrapper>{renderSystemTemplatesSection()}</ItemRowsWrapper>
                    </GroupStyled>
                    <GroupStyled>
                        {sidebarOpen && <GroupTitle>Feature Configuration</GroupTitle>}
                        <ItemRowsWrapper>{renderFeatureConfigSections()}</ItemRowsWrapper>
                    </GroupStyled>
                    <GroupStyled>
                        {sidebarOpen && <GroupTitle>Recycling Bin</GroupTitle>}
                        <ItemRowsWrapper>{renderRecyclingBinSection()}</ItemRowsWrapper>
                    </GroupStyled>
                    <GroupStyled>
                        {sidebarOpen && <GroupTitle>Extra Features</GroupTitle>}
                        <ItemRowsWrapper>{renderExtraSections()}</ItemRowsWrapper>
                    </GroupStyled>
                    <SidebarFooter>{sidebarOpen ? `3READY CC 3.0 - version ${process.env.REACT_APP_VERSION}` : 'CC 3.0'}</SidebarFooter>
                </SidebarWrapper>
            </CustomDrawer>
        </>
    );
};

export default SuperadminSidebar;
