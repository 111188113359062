import { createTheme } from '@material-ui/core';

const interFont = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url('../../assets/fonts/Inter/Inter.ttf')`
};

const fontFamily = ['Inter', 'serif'].join(',');
const fontFamilyBold = ['Inter Bold', 'serif'].join(',');

export const fontSizes = {
    Body1: {
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 'normal'
    },
    Body2: {
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 'bold'
    },
    Title1: {
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: 'bold'
    },
    Title2: {
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: 500
    },
    Title3: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 'bold',
        letterSpacing: '0.25px'
    },
    Caption1: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        fontWeight: 'normal'
    },
    Caption2: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'bold',
        letterSpacing: '0.25px'
    },
    Caption3: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 'normal',
        letterSpacing: '0.25px'
    },
    Caption4: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: 'bold',
        letterSpacing: '0.25px'
    }
};

export const fontSizesCSS = {
    Body1: {
        'font-size': '15px',
        'line-height': '24px',
        'font-weight': 'normal'
    },
    Body2: {
        'font-size': '15px',
        'line-height': '24px',
        'font-weight': 'bold'
    },
    Title1: {
        'font-size': '32px',
        'line-height': '40px',
        'font-weight': 'bold'
    },
    Title2: {
        'font-size': '32px',
        'line-height': '40px',
        'font-weight': 500
    },
    Title3: {
        'font-size': '24px',
        'line-height': '32px',
        'font-weight': 'bold',
        'letter-spacing': '0.25px'
    },
    Title4: {
        'font-size': '20px',
        'line-height': '28px',
        'font-weight': 'normal',
        'letter-spacing': '0.25px'
    },
    Caption1: {
        'font-size': '14px',
        'line-height': '20px',
        'letter-spacing': '0.25px',
        'font-weight': 'normal'
    },
    Caption2: {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': 'bold',
        'letter-spacing': '0.25px'
    },
    Caption3: {
        'font-size': '12px',
        'line-height': '16px',
        'font-weight': 'normal',
        'letter-spacing': '0.25px'
    },
    Caption4: {
        'font-size': '10px',
        'line-height': '16px',
        'font-weight': 'normal',
        'letter-spacing': '0.25px'
    },
    EditorialTitle: {
        'font-size': '44px',
        'line-height': '44px',
        'font-weight': '900',
        'letter-spacing': '0.25px'
    },
    EditorialSubtitle: {
        'font-size': '28px',
        'line-height': '28px',
        'font-weight': '400',
        'letter-spacing': '0.25px'
    },
    EditorialDescription: {
        'font-size': '20px',
        'line-height': '28px',
        'font-weight': '400',
        'letter-spacing': '0.25px'
    },
    HintTitle: {
        'font-size': '40px',
        'line-height': '48px',
        'font-weight': 'bold'
    },
    HintSubtitle: {
        'font-size': '24px',
        'line-height': '32px',
        'font-weight': 'normal'
    }
};

export const zIndexesCSS = {
    zIndexItem: 1,
    zIndexFilter: 20,
    zIndexPageButtons: 50,
    zIndexDropdown: 100,
    zIndexProfile: 101,
    zIndexSideBar: 1299,
    zIndexLoader: 1298,
    zIndexDragAndDrop: 9999,
    zIndexMax: 99999
};

const getTheme = () => {
    return createTheme({
        typography: {
            fontFamily,
            fontFamilyBold,
            fontSizes,
            button: {
                ...fontSizes.Body2
            }
        },
        palette: {
            primary: {
                main: '#0066FF',
                mainTranslucent: 'rgba(0,102,255,0.4)',
                focused: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #0066FF',
                pressed: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #0066FF'
            },
            error: {
                main: '#F44336',
                hover: 'palevioletred',
                decrease: 'rgb(255, 17, 34)',
                focused: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #F44336'
            },
            success: {
                main: '#89B613',
                increase: 'rgb(85, 204, 17)',
                semantic: 'rgb(68, 187, 68)'
            },
            warning: {
                main: '#FFBB00'
            },
            background: {
                default: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #ffffff',
                main: '#FAFBFB',
                pressed: 'rgba(0, 0, 0, 0.06)',
                active: 'rgba(26, 26, 26, 0.05)',
                contrast: 'rgba(51, 51, 51, 0.05)',
                border: 'rgba(0, 0, 0, 0.2)',
                pageActions: 'rgba(0, 0, 0, 0.04)',
                smallBorder: '#000000aa',
                innerBorder: 'rgba(250, 251, 251, 0.24)',
                dashedBorderDarkBackground: '#4B4B4B',
                menuItem: '#F5F5F5',
                menuItemHover: '#EBEBEB',
                hovered: 'rgba(20, 20, 20, 0.7)',
                hoveredDark: 'rgba(20, 20, 20, 0.9)',
                feedback: '#CC2211',
                feedbackHover: 'rgb(170, 0, 13)',
                backdrop: 'rgba(0, 0, 0, 0.25)',
                hover: 'rgba(51, 51, 51, 0.8)',
                imageFields: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #333333',
                providers: {
                    amazon: '#3797FF'
                },
                light: '#EEEEEE',
                onboardingTip: '#3385FE'
            },
            foreground: {
                main: 'rgb(20, 20, 20)'
            },
            text: {
                main: '#333333',
                light: '#EEEEEE'
            },
            label: {
                main: 'rgba(0, 0, 0, 0.54)'
            },
            white: {
                main: '#FFFFFF'
            },
            black: {
                main: '#000000'
            },
            grey: {
                main: '#304156',
                dark: '#63696C',
                light: '#BABABA',
                lighter: '#E1E2E2'
            },
            boxShadow: {
                analytics: '0px 9.030119895935059px 27.09035873413086px 0px #B0BEC552',
                pieChartArc: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.32)'
            }
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [interFont]
                }
            }
        }
    });
};

export default getTheme;
