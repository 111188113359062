import React, { useEffect, useState } from 'react';

import {
    ChangeWrapper,
    InsightsDateAndChangeHolder,
    InsightsLineChartWidget,
    InsightsTitle,
    InsightsWidget,
    InsightsWidgetDifferenceContainer,
    InsightsWidgetMetricTitle,
    InsightsWidgetMetricValue,
    InsightsWidgetSkeleton,
    InsightsWrapper
} from './InsightsDashboardWidget.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import Carousel from '../../common/Carousel/Carousel';
import InsightsLineChart from './InsightsLineChart';
import { useAppSelector } from '../../../hooks/redux';
import { insightsWidgets } from '../../Insights/Insights';
import { changeInPercentage } from '../../../utils/fnData';
import { useNavigate } from 'react-router-dom';
import {
    AnalyticsOptions,
    getAnalyticsData,
    youboraHandlerArrayResponse,
    youboraHandlerResponse,
    youboraMetrics
} from '../../../utils/fnPreviews';
import { PageRoutes, buildPathWithProjectId } from '../../../types/RouteTypes';

const InsightsDashboardWidget: React.FC = () => {
    const oneWeekAgo = new Date().setUTCHours(0, 0, 0, 0) - 7 * 24 * 3600 * 1000;
    const today = new Date().setUTCHours(0, 0, 0, 0);
    const twoWeeksAgo = new Date().setUTCHours(0, 0, 0, 0) - 14 * 24 * 3600 * 1000;
    const [loadingData, setLoadingData] = useState(false);
    const [loadingLineChartData, setLoadingLineChartData] = useState(false);
    const [currentData, setCurrentData] = useState<youboraHandlerResponse>({});
    const [specificData, setSpecificData] = useState<youboraHandlerResponse>({});
    const [lineChartData, setLineChartData] = useState<youboraHandlerArrayResponse>({});

    const { activeProjectId } = useAppSelector((state) => state.activeItem);

    const loadCurrentData = async () => {
        let fromDate = oneWeekAgo;
        return await getAnalyticsData(AnalyticsOptions.YOUBORA, Object.values(youboraMetrics), fromDate, today);
    };

    const calculateDataForLineChart = () => {
        const data: any = { id: 'avgHappiness', data: [] };
        if (!lineChartData.avgHappiness) return data;
        let lastNonZeroValue: number = lineChartData.avgHappiness.find(([_, value]) => value !== 0)?.[1] || 1;
        lineChartData.avgHappiness.forEach(([timestamp, value]) => {
            if (value !== 0) lastNonZeroValue = value;
            data.data.push({ x: timestamp, y: value || lastNonZeroValue });
        });

        return data;
    };

    const loadSpecificData = async (startDate?: number, endDate?: number) => {
        return await getAnalyticsData(AnalyticsOptions.YOUBORA, Object.values(youboraMetrics), startDate, endDate);
    };

    const loadLineChartData = async () => {
        return await getAnalyticsData(AnalyticsOptions.YOUBORA, [youboraMetrics.HAPPINESS], undefined, undefined, undefined, true);
    };

    const navigate = useNavigate();

    useEffect(() => {
        let currentData = {};
        setLoadingData(true);
        loadCurrentData()
            .then((data) => {
                currentData = data;
                return loadSpecificData(twoWeeksAgo, oneWeekAgo);
            })
            .then((data) => {
                setCurrentData(currentData);
                setSpecificData(data);
                setLoadingData(false);
            });

        setLoadingLineChartData(true);
        loadLineChartData().then((data) => {
            setLineChartData(data);
            setLoadingLineChartData(false);
        });
    }, [activeProjectId]);

    const buildWidgetsToRender = () => {
        const widgetsToRender: JSX.Element[] = [];

        const { avgHappiness, joinTime, bufferRatio, playtime, playsVsErrors, subscribers, abandonUsers, views } = currentData;
        const renderComparison =
            !!Object.values(specificData).filter((val) => val).length && !!Object.values(currentData).filter((val) => val).length;

        const values: any = {};

        values.happiness = avgHappiness?.toFixed(1) ?? 'Data unavailable';
        values.joinTime =
            joinTime !== undefined && bufferRatio !== undefined ? `${joinTime.toFixed(2)} / ${bufferRatio.toFixed(2)}` : 'Data unavailable';
        values.views = views !== undefined ? `${views}` : 'Data unavailable';
        values.playtime = playtime !== undefined ? `${Math.floor(playtime / 60)}m ${Math.floor(playtime % 60)}s` : 'Data unavailable';
        values.errors = playsVsErrors !== undefined ? `1 error every ${playsVsErrors.toFixed(2)} plays` : 'Data unavailable';
        values.subscribers =
            subscribers !== undefined && abandonUsers !== undefined ? `${subscribers} / ${abandonUsers}` : 'Data unavailable';

        if (renderComparison) {
            if (avgHappiness !== undefined && specificData.avgHappiness !== undefined) {
                const isLower = avgHappiness < specificData.avgHappiness;
                const difference = Math.abs(avgHappiness - specificData.avgHappiness).toFixed(1);
                values.happiness = (
                    <>
                        <span>{avgHappiness.toFixed(1)}</span>
                        <InsightsWidgetDifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {avgHappiness !== specificData.avgHappiness && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
            if (
                joinTime !== undefined &&
                bufferRatio !== undefined &&
                specificData.joinTime !== undefined &&
                specificData.bufferRatio !== undefined
            ) {
                const valuesToCompare = [joinTime / bufferRatio, specificData.joinTime / specificData.bufferRatio];
                const isLower = valuesToCompare[0] < valuesToCompare[1];
                const difference = Math.abs(valuesToCompare[0] - valuesToCompare[1]).toFixed(2);
                values.joinTime = (
                    <>
                        <span>{`${joinTime.toFixed(2)} / ${bufferRatio.toFixed(2)}`}</span>
                        <InsightsWidgetDifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {valuesToCompare[0] !== valuesToCompare[1] && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
            if (views !== undefined && specificData.views !== undefined) {
                const isLower = views < specificData.views;
                const difference = Math.abs(views - specificData.views).toFixed(1);
                values.views = (
                    <>
                        <span>{views}</span>
                        <InsightsWidgetDifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {views !== specificData.views && <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
            if (playtime !== undefined && specificData.playtime !== undefined) {
                const isLower = playtime < specificData.playtime;
                const difference = Math.abs(playtime - specificData.playtime);
                const differenceText = `${Math.floor(difference / 60)}m ${Math.floor(difference % 60)}s`;
                values.playTime = (
                    <>
                        <span>{`${Math.floor(playtime / 60)}m ${Math.floor(playtime % 60)}s`}</span>
                        <InsightsWidgetDifferenceContainer>
                            <span>{differenceText}</span>
                            {playtime !== specificData.playtime && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
            if (playsVsErrors !== undefined && specificData.playsVsErrors !== undefined) {
                const isLower = playsVsErrors < specificData.playsVsErrors;
                const difference = Math.abs(playsVsErrors - specificData.playsVsErrors).toFixed(2);
                values.errors = (
                    <>
                        <span>{`1 error every ${playsVsErrors.toFixed(2)} plays`}</span>
                        <InsightsWidgetDifferenceContainer $reverseColors $isLower={isLower}>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {playsVsErrors !== specificData.playsVsErrors && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
            if (
                subscribers !== undefined &&
                abandonUsers !== undefined &&
                specificData.subscribers !== undefined &&
                specificData.abandonUsers !== undefined
            ) {
                const valuesToCompare = [subscribers / abandonUsers, specificData.subscribers / specificData.abandonUsers];
                const isLower = valuesToCompare[0] < valuesToCompare[1];
                const difference = Math.abs(valuesToCompare[0] - valuesToCompare[1]).toFixed(2);
                values.subscribers = (
                    <>
                        <span>{`${subscribers} / ${abandonUsers}`}</span>
                        <InsightsWidgetDifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {valuesToCompare[0] !== valuesToCompare[1] && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </InsightsWidgetDifferenceContainer>
                    </>
                );
            }
        }

        const widgets = (Object.keys(insightsWidgets) as Array<keyof typeof insightsWidgets>).map((key, index) => {
            return (
                <InsightsWidget key={`widget_${index}`}>
                    <InsightsWidgetMetricTitle>{insightsWidgets[key]}</InsightsWidgetMetricTitle>
                    <InsightsWidgetMetricValue>{values[key]}</InsightsWidgetMetricValue>
                </InsightsWidget>
            );
        });

        const lineChartDataObj = calculateDataForLineChart();
        const today = new Date();
        const oneMonthAgo = new Date(new Date().setDate(today.getDate() - 30));

        // When data is not loaded data.y is undefined so we fall back to a neutral value => 1
        const firstEntry = lineChartDataObj.data[0]?.y || 1;
        const lastEntry = lineChartDataObj.data[lineChartDataObj.data.length - 1]?.y || 1;

        const isLower = lastEntry < firstEntry;
        const changeInPercent = changeInPercentage(firstEntry, lastEntry);

        widgetsToRender.push(
            <InsightsLineChartWidget key={`widget_linechart`}>
                <InsightsDateAndChangeHolder>
                    <div>
                        <strong>{oneMonthAgo.getFullYear()}</strong> {oneMonthAgo.getMonth() + 1} {oneMonthAgo.getDate()} -{' '}
                        {today.getMonth() + 1} {today.getDate()}
                    </div>
                    <ChangeWrapper $isLower={isLower}>
                        {isLower ? '-' : '+'}
                        {changeInPercent}%
                        <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                    </ChangeWrapper>
                </InsightsDateAndChangeHolder>
                <div style={{ height: '36px' }}>
                    <InsightsLineChart data={[lineChartDataObj]} isGreen={!isLower} />
                </div>
            </InsightsLineChartWidget>
        );
        widgetsToRender.push(...widgets);
        return widgetsToRender;
    };

    const loading = loadingData || loadingLineChartData;

    return (
        <InsightsWrapper onClick={() => navigate(buildPathWithProjectId(activeProjectId, PageRoutes.INSIGHTS))}>
            <InsightsTitle>Insights</InsightsTitle>
            {loading ? <InsightsWidgetSkeleton /> : <Carousel components={buildWidgetsToRender()} timeout={5000} />}
        </InsightsWrapper>
    );
};

export default InsightsDashboardWidget;
