import React, { FC, useEffect, useState } from 'react';
import { DashboardCardDetails, DashboardCardSelectWrapper, DashboardCardWrapper, LandingCardTitle } from './DashboardCard.css';
import Button from '../../Buttons/Button/Button';
import { DialogDropdownSingle } from '../../common/Dialog/GenericDialog';

type DashboardCardPropTypes = {
    image: string;
    title: string;
    options: { label: string | JSX.Element; value: any }[];
    onPrimaryButtonClick?: (objectId: string) => void;
    onSecondaryButtonClick?: (objectId: string) => void;
    customObjectName?: string;
};

export const DashboardCard: FC<DashboardCardPropTypes> = ({
    image,
    title,
    options,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    customObjectName
}) => {
    const [selectedValue, setSelectedValue] = useState<{ label: string | JSX.Element; value: any }>();
    useEffect(() => {
        setSelectedValue(options[0]);
    }, [options]);

    const handlePrimaryButtonClick = (evt: any) => {
        evt.preventDefault();
        if (onPrimaryButtonClick) {
            onPrimaryButtonClick(selectedValue?.value || '');
        }
    };

    const handleSecondaryButtonClick = (evt: any) => {
        evt.preventDefault();
        if (onSecondaryButtonClick) {
            onSecondaryButtonClick(selectedValue?.value || '');
        }
    };

    return (
        <DashboardCardWrapper>
            <img src={image} />
            <DashboardCardDetails>
                <LandingCardTitle>{title}</LandingCardTitle>
                <DashboardCardSelectWrapper>
                    <DialogDropdownSingle
                        labelText={'Recently Edited'}
                        options={options}
                        value={selectedValue || ''}
                        onChange={(value: any) => {
                            setSelectedValue(value);
                        }}
                        placeholder={options.length === 0 ? `${title} are empty` : ''}
                    />
                </DashboardCardSelectWrapper>
                <Button
                    type="BLUE"
                    label={`Edit ${customObjectName ? customObjectName : title.slice(0, -1)}`}
                    onClick={handlePrimaryButtonClick}
                />
                <Button
                    type="DEFAULT"
                    label={`Create ${customObjectName ? customObjectName : title.slice(0, -1)}`}
                    onClick={handleSecondaryButtonClick}
                />
            </DashboardCardDetails>
        </DashboardCardWrapper>
    );
};
