import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { Setting, SettingsValues } from '../../types/Setting';

export const fetchSettings = createAsyncThunk<
    { settings: Setting[]; error: { message: string; code: string } | null; permissions?: any },
    { addPermissions?: boolean; projectId?: string; orderBy?: string; searchTerm?: string }
>('settings/fetchSettings', async ({ addPermissions, projectId, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllSettings(addPermissions, projectId, orderBy, searchTerm);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { settings: result.response as any as Setting[], error: null, permissions: result.permissions };
});

export const fetchSetting = createAsyncThunk<{ setting: Setting; error: { message: string; code: string } | null }, string>(
    'settings/fetchSetting',
    async (settingId, thunkApi) => {
        const result = await ConfigServiceAPI.getSettingById(settingId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { setting: result.response as any as Setting, error: null };
    }
);

export const fetchSettingsUMTypes = createAsyncThunk<{ settingsUMTypes: any; error: { message: string; code: string } | null }>(
    'settings/fetchSettingsUMTypes',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getSettingsUMTypes();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { settingsUMTypes: result.response as any, error: null };
    }
);
export const fetchSettingsTitles = createAsyncThunk<{ settingsTitles: any; error: { message: string; code: string } | null }>(
    'settings/fetchSettingsTitles',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getSettingsTitles();
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { settingsTitles: result.response as any, error: null };
    }
);

export const fetchSettingType = createAsyncThunk<{ settingType: any; error: { message: string; code: string } | null }, string>(
    'settings/fetchSettingType',
    async (tenantId, thunkApi) => {
        const result = await ConfigServiceAPI.getSettingType(tenantId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { settingType: result.response as any, error: null };
    }
);

export const createSetting = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { setting: Setting; addObjectToGroup?: boolean }
>('settings/createSetting', async ({ setting, addObjectToGroup = false }, thunkApi) => {
    const result = await ConfigServiceAPI.createSetting(setting, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any as string, error: null };
});

export const updateSetting = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null },
    { setting: Setting; shouldUnlockAfterSave?: boolean; shouldRepublish?: boolean; addObjectToGroup?: boolean }
>('settings/updateSetting', async ({ setting, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup }, thunkApi) => {
    const result = await ConfigServiceAPI.updateSetting(setting, shouldUnlockAfterSave, shouldRepublish, addObjectToGroup);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const deleteSetting = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'settings/deleteSetting',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteSetting(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const publishSetting = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { settingId: string; published?: number }
>('settings/publishSetting', async ({ settingId }, thunkApi) => {
    const result = await ConfigServiceAPI.publishSetting(settingId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const abortPublishSetting = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { settingId: string; published?: number }
>('settings/abortPublishSetting', async ({ settingId }, thunkApi) => {
    const result = await ConfigServiceAPI.abortPublishSetting(settingId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface settingsState {
    settings: Setting[];
    settingType: SettingsValues;
    selectedSetting?: Setting;
    settingsUMTypes?: any;
    settingsTitles?: any;
    settingTypeLoading?: boolean;
    loading: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: settingsState = {
    settings: [],
    settingType: {},
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        unsetSettingsError(state) {
            state.error = null;
        },
        unsetSelectedSetting(state) {
            state.selectedSetting = undefined;
        },
        unsetSettings(state) {
            state.settings = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettings.fulfilled, (state, action: any) => {
                state.settings = action.payload.settings;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSettings.rejected, (state, action: any) => {
                state.settings = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchSettings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchSettingsUMTypes.fulfilled, (state, action: any) => {
                state.settingsUMTypes = action.payload.settingsUMTypes;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSettingsUMTypes.rejected, (state, action: any) => {
                state.settingsUMTypes = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchSettingsUMTypes.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchSettingsTitles.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchSettingsTitles.fulfilled, (state, action: any) => {
                state.settingsTitles = action.payload.settingsTitles;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSettingsTitles.rejected, (state, action: any) => {
                state.settingsTitles = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchSettingType.fulfilled, (state, action: any) => {
                state.settingType = action.payload.settingType;
                state.error = null;
                state.settingTypeLoading = false;
            })
            .addCase(fetchSettingType.rejected, (state, action: any) => {
                state.settingType = {};
                state.error = { ...action.payload.error, status: action.payload.status };
                state.settingTypeLoading = false;
            })
            .addCase(fetchSettingType.pending, (state, _action) => {
                state.settingTypeLoading = true;
            })
            .addCase(fetchSetting.fulfilled, (state, action: any) => {
                state.selectedSetting = action.payload.setting;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchSetting.rejected, (state, action: any) => {
                state.selectedSetting = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchSetting.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createSetting.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createSetting.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createSetting.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishSetting.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(publishSetting.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(publishSetting.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(abortPublishSetting.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(abortPublishSetting.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(abortPublishSetting.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateSetting.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateSetting.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateSetting.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteSetting.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteSetting.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteSetting.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetSettingsError, unsetSelectedSetting, unsetSettings } = slice.actions;
export default slice.reducer;
