import { Project } from './Project';
import { Tenant } from './Tenant';

export type DynamicSource = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    modifiedBy?: string;
    service: string;
    method: string;
    subService?: string;
    queryParameters?: any;
    bodyParameters?: any;
    pathParameters?: any;
    collapse?: boolean;
    sort?: string;
    assetType?: string;
    moduleType?: string;
    channelId?: string;
    url: string;
    lastModified?: number;
    tenant?: Tenant;
    project?: Project;
    disabled?: boolean;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    placed?: { name: string; _id: string }[];
};

export const additionalSourceFields = ['collapse', 'sort', 'assetType', 'moduleType', 'channelId'];
