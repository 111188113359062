import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import useScreenSize from '../../../hooks/useScreenSize';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { audiencesState } from '../../../redux/slices/audienceSlice';
import { createDisplayCondition, DisplayConditionsState, fetchDisplayConditions } from '../../../redux/slices/displayConditionsSlice';
import { AUDIENCES_GROUP_LABEL, buildTargetOptions, CONDITIONS_GROUP_LABEL } from '../../../utils/fnDialogs';
import { DisplayConditionDialog } from '../../TargetConditions/DisplayConditions/Dialogs/DisplayConditionDialog';
import BackendErrorDialog from '../Dialog/BackendErrorDialog';
import Select, { components } from 'react-select';
import { AddNewOption, GroupColumnContainer, GroupHeadingText, OptionsColumnContainer, SelectWrapper } from './Select.css';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { ObjectNameLabel } from '../../../style/styled-components/reusable.css';
import { DROPDOWN_ADD_VALUE, DROPDOWN_GROUP_LABEL_VALUE } from '../../../utils/Globals';
import { InputLabelWithIconWrapper } from '../Dialog/GenericDialog.css';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../Tooltips/Tooltips';
import { CustomArrow } from './SelectSingle';

export type TargetConditionsSelectProps = {
    targets: any[];
    toolTipText?: string;
    onChange: (values: any[]) => void;
    hideCreateNew?: boolean;
    inDialog?: boolean;
    noOfLabels?: number;
};

export const TargetConditionsSelect: React.FC<TargetConditionsSelectProps> = ({
    targets,
    onChange,
    toolTipText,
    inDialog,
    hideCreateNew = false,
    noOfLabels
}) => {
    const [targetOptions, setTargetOptions] = useState<any[]>([]);
    const [openCreateNew, setOpenCreateNew] = useState(false);
    const [newId, setNewId] = useState('');

    const { audiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { displayConditions, loading, error }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const dispatch = useDispatch();

    const addDisplayCondition = async (condition: any) => {
        const response = await dispatch(createDisplayCondition(condition)).unwrap();
        if (response.id) {
            setNewId(response.id);
            loadDisplayConditions(false, activeProjectId);
        }
    };

    const loadDisplayConditions = async (addPermissions?: boolean, projectId?: string) => {
        return await dispatch(fetchDisplayConditions({ addPermissions, projectId })).unwrap();
    };

    useEffect(() => {
        setTargetOptions(buildTargetOptions(audiences, displayConditions));
    }, [audiences, displayConditions]);

    useEffect(() => {
        if (!newId) return;
        const option = targetOptions
            .find((group) => group.label === CONDITIONS_GROUP_LABEL)
            ?.options?.find((option: any) => option.value === newId);
        if (!option) return;

        const newValues = [...targets];
        const selectedCondition = targets.findIndex((id) => displayConditions.find((condition) => condition._id === id));
        if (selectedCondition >= 0) {
            // if a display condition is already selected, swap it with the new one (only one condition can be selected)
            newValues.splice(selectedCondition, 1, option.value);
        } else newValues.push(option.value);

        onChange(newValues);
        setNewId('');
    }, [targetOptions]);

    const createCondition = async (condition: any) => {
        condition.tenantId = activeTenantId;
        condition.projectId = activeProjectId;

        await addDisplayCondition(condition);

        loadDisplayConditions(false, activeProjectId);
    };

    const onGroupHeadingClick = (groupOptions: any[], isAllSelected: boolean) => {
        if (isAllSelected) {
            //deselect all and return
            const options = groupOptions.map((groupOption) => groupOption.value);
            return onChange(targets.filter((t) => !options.includes(t)));
        }
        const unChecked = groupOptions.map((groupOption) => groupOption.value).filter((val) => !targets.includes(val));
        onChange([...targets, ...unChecked]);
    };
    // flatten the options and filter the selected ones
    const selectedValues = targetOptions
        .reduce((options, current) => {
            options.push(...current.options);
            return options;
        }, [])
        .filter((opt: any) => targets.includes(opt.value));

    // Used to prevent the jumping of the MenuList upon selecting something
    const _CustomMenuList = useCallback((props: any) => {
        return CustomMenuList(props, () => setOpenCreateNew(true), hideCreateNew, inDialog);
    }, []);

    const _GroupHeading = useCallback(
        (props) => {
            return GroupHeading(props, onGroupHeadingClick, targets);
        },
        [targets]
    );

    const _Group = useCallback((props) => {
        return CustomGroup(props, inDialog);
    }, []);

    const _DropdownIndicator = useCallback((props) => {
        return CustomArrow(props, props.selectProps.menuIsOpen || false);
    }, []);
    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <SelectWrapper id={`select_wrapper_Target Conditions`} $error={false} className={'multiple'} inDialog={inDialog}>
                <label>
                    {toolTipText ? (
                        <InputLabelWithIconWrapper>
                            Target Conditions
                            {renderTooltip(<SVGInline src={icons.infoIcon} />, tooltipTypes.TEXT, toolTipText || '', tooltipPositions.TOP)}
                        </InputLabelWithIconWrapper>
                    ) : (
                        'Target Conditions'
                    )}
                </label>
                <Select
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    isDisabled={loading}
                    options={targetOptions}
                    value={selectedValues}
                    isClearable={false}
                    classNamePrefix={'conditions-select'}
                    placeholder="Target Conditions"
                    onChange={(val: any) => {
                        let newValues = val.map((elem: any) => elem.value);
                        const conditionIds = displayConditions.map((condition: any) => condition._id);
                        const selectedConditions = newValues.filter((value: string) => conditionIds.includes(value)) || [];
                        if (selectedConditions.length > 1) {
                            // only one display condition can be selected
                            newValues = newValues?.filter((value: string) => value !== selectedConditions[0]) || [];
                        }
                        onChange(newValues);
                    }}
                    filterOption={({ label, data }, inputValue) => {
                        const value = inputValue.toLowerCase().trim();
                        if (data?.value === DROPDOWN_GROUP_LABEL_VALUE || data?.value === DROPDOWN_ADD_VALUE) return true;
                        if (!data?.valueForSearch) {
                            return typeof label === 'string' && label.toLowerCase().includes(value);
                        }
                        return data?.valueForSearch.toLowerCase().includes(value);
                    }}
                    components={{
                        MenuList: _CustomMenuList,
                        Option: CustomOption,
                        MultiValueLabel: CustomMultiValueLabel,
                        MultiValueRemove: CustomMultiValueRemove,
                        MultiValue: (props) => CustomMultiValue(props, noOfLabels),
                        GroupHeading: _GroupHeading,
                        Group: _Group,
                        DropdownIndicator: _DropdownIndicator,
                        IndicatorSeparator: null
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                />
                {/*for correct height calculation, no error is needed for target conditions*/}
                <label className="error" />
            </SelectWrapper>
            <DisplayConditionDialog
                open={openCreateNew}
                onClose={() => setOpenCreateNew(false)}
                onSave={(condition: any) => createCondition(condition)}
            />
        </>
    );
};

const CustomMenuList = (props: any, onNewOptionClick: () => void, hideCreateNew: boolean, inDialog?: boolean) => {
    const { isDesktop } = useScreenSize();
    return (
        <components.MenuList {...props}>
            {!hideCreateNew && (
                <components.Option {...props}>
                    <AddNewOption
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onNewOptionClick();
                        }}
                    >
                        <SVGInline src={icons.addIcon} /> {props.label}
                        Create Display Condition
                    </AddNewOption>
                </components.Option>
            )}
            <GroupColumnContainer numOfColumns={isDesktop && !inDialog ? 3 : 2}>{props.children}</GroupColumnContainer>
        </components.MenuList>
    );
};

const CustomGroup = (props: any, inDialog?: boolean) => {
    const fullWidth = props.data.label === CONDITIONS_GROUP_LABEL || props.data.label === AUDIENCES_GROUP_LABEL;
    const { isDesktop } = useScreenSize();
    return (
        <components.Group {...props} className={fullWidth ? 'full-width' : ''}>
            {fullWidth ? (
                <OptionsColumnContainer numOfColumns={isDesktop && !inDialog ? 3 : 2}>{props.children}</OptionsColumnContainer>
            ) : (
                props.children
            )}
        </components.Group>
    );
};
const GroupHeading = (
    props: any,
    onGroupHeadingClick: (groupOptions: any[], isAllSelected: boolean) => void,
    selectedOptions: string[]
) => {
    const isNotConditionGroup = props.data.label !== CONDITIONS_GROUP_LABEL;
    const isAllSelected = props.data.options.every((opt: any) => selectedOptions.includes(opt.value));

    return (
        <components.GroupHeading
            {...props}
            className={isNotConditionGroup ? 'with-pointer' : ''}
            onClick={() => isNotConditionGroup && onGroupHeadingClick(props.data.options, isAllSelected)}
        >
            <GroupHeadingText>{props.children}</GroupHeadingText>
            {isNotConditionGroup && <SVGInline src={icons.selectCheckIcon} style={{ opacity: isAllSelected ? 1 : 0.5 }} />}
        </components.GroupHeading>
    );
};

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} onClick={(e: any) => e.stopPropagation()}>
            {props.label}
            {props.isSelected ? <SVGInline src={icons.selectCheckIcon} /> : ''}
        </components.Option>
    );
};

const CustomMultiValue = ({ index, ...props }: any, noOfLabels?: number) => {
    const { isSmallMobile, isDesktop } = useScreenSize();

    const elementsToShow = noOfLabels ? noOfLabels : isSmallMobile || isDesktop ? 2 : 1;
    const maxToShow = props?.selectProps?.maxLength ?? elementsToShow;
    return index < maxToShow ? (
        <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : index === maxToShow ? (
        <ObjectNameLabel>{`...`}</ObjectNameLabel>
    ) : null;
};

const CustomMultiValueLabel = (props: any) => {
    return (
        <components.MultiValueLabel {...props} innerProps={{ className: 'custom-value-label' }}>
            {props.children}
        </components.MultiValueLabel>
    );
};

const CustomMultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <SVGInline src={icons.closeIcon} />
        </components.MultiValueRemove>
    );
};
