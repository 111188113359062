import React, { FC } from 'react';
import { ResponsiveLine } from '@nivo/line';
import getTheme from '../../../style/themes/theme';

/**
 * make sure parent container have a defined height when using
 * responsive component, otherwise height will be 0 and
 * no chart will be rendered.
 *
 * Docs: https://nivo.rocks/line/
 **/
const theme: any = getTheme();
const InsightsLineChart: FC<{ data: any; isGreen?: boolean }> = ({ data, isGreen }) => {
    const chartColor = isGreen ? [theme.palette.success.increase] : [theme.palette.error.decrease];
    return (
        <ResponsiveLine
            data={data}
            lineWidth={1}
            enablePoints={false}
            enableArea
            enableGridX={false}
            enableGridY={false}
            colors={chartColor}
            areaOpacity={0.3}
        />
    );
};

export default InsightsLineChart;
