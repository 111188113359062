import { TableCell, TableRow } from '@material-ui/core';
import styled, { CSSProperties } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { UserOptionContainer } from '../../Projects/Projects.css';

const theme: any = getTheme();

export const UserAndImageContainer = styled.div`
    display: flex;
    ${fontSizesCSS.Body1};
`;

export const DialogTableCell = styled(TableCell)`
    border-bottom: 0;
`;
export const DialogTableRow = styled(TableRow)`
    border-bottom: 4px solid ${theme.palette.background.main};
    .MuiTableCell-root {
        padding: 0;
    }
`;

export const DialogTitleRowStyle: CSSProperties = {
    height: '24px',
    background: theme.palette.background.pressed,
    border: 0
};

export const DialogTitleCellStyle: CSSProperties = {
    ...fontSizesCSS.Caption4,
    fontWeight: 'bold',
    height: '24px',
    paddingLeft: '6px'
};

export const DialogRowStyle: CSSProperties = {
    height: '40px'
};

export const SearchBarStyledContainer = styled.div`
    margin-bottom: 12px;
    .MuiFormControl-root {
        height: 38px;
        min-height: unset;
    }
    .MuiInputBase-root {
        border-radius: 4px;
    }
`;

export const MoreInfoContentHolder = styled.div`
    max-height: 50vh;
    padding-right: 4px;
    overflow: auto;
    :not(::-webkit-scrollbar) {
        padding-right: 0;
    }
    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: ${theme.palette.background.border};
        border-radius: 4px;
    }
`;

export const UserImage = styled.img.attrs(({ src }) => ({ src }))`
    margin-right: 8px;
    margin-left: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const UserName = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const UserEmail = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
    ${fontSizesCSS.Body1};
    svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 8px;
    }
`;

export const TagName = styled.div<{ $isClickable?: boolean }>`
    margin-left: 12px;
    display: flex;
    align-items: center;
    svg {
        margin: 5px 5px 0;
    }

    ${({ $isClickable }) =>
        $isClickable &&
        `
        color: ${theme.palette.primary.main};
        cursor: pointer;
    `}
`;

export const AddUsersOptionContainer = styled(UserOptionContainer)`
    justify-content: space-between;
    width: 100%;
`;

export const UserOption = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
`;
