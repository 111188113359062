export type CC3BaseObject = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    lastModified: number;
    placed?: any[];
    modifiedBy: string;
    modifiedByUser?: { name: string; icon?: string };

    locked?: string;
    adminLocked?: boolean;
    lockedByUser?: { name: string; icon?: string };
    objectType: ObjectType;
};

// expand with values that are needed in the future
export const ObjectTypes = {
    PAGES: 'pages',
    MODULES: 'modules',
    ITEMS: 'items',
    MENUS: 'menus',
    LANGUAGES: 'languages',
    AUDIENCES: 'audiences',
    CONDITIONS: 'conditions',
    SETTINGS: 'settings',
    PAGE_STYLES: 'pageStyles',
    DYNAMIC_SOURCES: 'dynamicSources',
    TARGET_GROUPS: 'targetGroups'
} as const;

export type ObjectType = typeof ObjectTypes[keyof typeof ObjectTypes];

export type FailedDeletionMessages = {
    objectId: string;
    objectName: string;
    objectType: ObjectType;
    message: string;
}[];
