import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { devices } from '../../../style/styled-components/reusable.css';

const theme: any = getTheme();

export const InsightsWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
    overflow: hidden;
    box-sizing: border-box;
    height: 108px;
    width: 360px;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    ${devices.desktopS} {
        width: 251px;
    }
    ${devices.tablet} {
        width: 360px;
    }
`;

export const InsightsTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    ${fontSizesCSS.Title3}
    margin-bottom: 12px;
`;

export const InsightsSubtitle = styled.div`
    ${fontSizesCSS.Caption3}
    font-weight: bold;
`;

export const InsightsDateAndChangeHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${fontSizesCSS.Caption3};
`;

export const ChangeWrapper = styled.div<{ $reverseColors?: boolean; $isLower?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    ${fontSizesCSS.Caption4}
    svg {
        width: 16px;
        height: 16px;
    }
    ${({ $reverseColors, $isLower }) =>
        $reverseColors && `path{ fill: ${$isLower ? theme.palette.success.increase : theme.palette.error.decrease}}`}
`;

export const InsightsWidget = styled.div`
    background: transparent;
    border-radius: 4px;
    height: 68px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const InsightsWidgetSkeleton = styled(InsightsWidget)`
    animation: skeleton-loading 1s linear infinite alternate;

    @keyframes skeleton-loading {
        0% {
            background-color: ${theme.palette.background.pressed};
        }
        100% {
            background-color: ${theme.palette.background.border};
        }
    }
`;

export const InsightsLineChartWidget = styled.div`
    height: 68px;
    width: 100%;
`;

export const InsightsWidgetMetricTitle = styled.div`
    ${fontSizesCSS.Caption3};
    font-weight: bold;
    text-align: center;
`;

export const InsightsWidgetMetricValue = styled.div`
    ${fontSizesCSS.Caption3};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

export const InsightsWidgetDifferenceContainer = styled.div<{ $reverseColors?: boolean; $isLower?: boolean }>`
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    gap: 4px;
    border-radius: 40px;
    background: ${theme.palette.background.pressed};
    span {
        ${fontSizesCSS.Caption3};
    }
    svg {
        height: 16px;
        width: 16px;
        padding: 4px 0;
    }
    ${({ $reverseColors, $isLower }) =>
        $reverseColors && `path{ fill: ${$isLower ? theme.palette.success.increase : theme.palette.error.decrease}}`}
`;
