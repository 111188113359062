import styled, { CSSProperties } from 'styled-components';
import icons from '../../../assets/images/icons';
import getTheme, { fontSizesCSS, zIndexesCSS } from '../../../style/themes/theme';
import { StyledSVGInline } from '../../../style/styled-components/reusable.css';
import { DoubleFieldSecondContainer } from '../Dialog/GenericDialog.css';

const theme: any = getTheme();

export const ProfileComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 260px;
    height: auto;
    right: 36px;
    top: 76px;
    background: ${theme.palette.white.main};
    border: 1px solid #f0f1f2;
    box-sizing: border-box;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.04);
    z-index: ${zIndexesCSS.zIndexProfile};
`;

export const ProfileAvatarIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60px;
    height: 36px;
    cursor: pointer;
    img {
        width: 36px;
        height: 36px;
        object-position: center;
        object-fit: cover;
        display: inline-block;
        border-radius: 50%;
    }
`;

export const ProfileHeaderComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #f0f1f2;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin-top: 16px;
`;

export const ProfileHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
    height: auto;
`;

export const ProfileAvatarWrapper = styled.div`
    width: 48px;
    height: 48px;
    margin: 0 8px;
`;

export const ProfileAvatar = styled.div<{ $icon?: string }>`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url(${(props) => `'${props.$icon}'` || icons.avatarIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

export const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 156px;
    min-height: 64px;
    height: fit-content;
    flex: none;
    margin: 0 8px;
`;

export const ProfileName = styled.div`
    width: 100%;
    ${fontSizesCSS.Body2};
    word-break: break-word;
`;

export const ProfileEmail = styled.div`
    width: 100%;
    ${fontSizesCSS.Caption1}
    color: #0066ff;
    word-break: break-word;
`;

export const ProfileRoleName = styled.div`
    width: 100%;
    ${fontSizesCSS.Caption1}
    color: #333333;
    text-transform: capitalize;
`;

export const ProfileMenuItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 188px;
    height: auto;
    padding: 16px 36px 24px 32px;
`;

export const BrandingDialogStyle: CSSProperties = {
    width: '760px'
};

export const CameraIconHolder = styled.div`
    cursor: pointer;
    position: absolute;
    background: ${theme.palette.background.hovered};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all ease-in-out 300ms;
`;
export const AvatarHolder = styled.div`
    position: relative;
    width: 120px;
    height: 120px;
    ${CameraIconHolder} {
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
`;

export const DialogFileFieldBrandingWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const MyProfileAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MyProfileAvatarSVGInline = styled(StyledSVGInline)`
    width: 120px;
    height: 120px;
`;

export const MyProfileCameraSVGInlineWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const MyProfileCameraSVGInline = styled(StyledSVGInline)`
    width: 54px;
    height: 54px;
    cursor: pointer;
`;

export const MyProfileNameAndRole = styled.div`
    ${DoubleFieldSecondContainer} {
        div > div {
            input {
                text-align: center;
                color: ${theme.palette.text.main};
            }
        }
    }
`;

export const MyProfileTenantsAndGroups = styled.div`
    [id$='_Groups'] {
        [class$='-control'] {
            background-color: inherit;
            svg {
                opacity: 0;
                width: 0;
            }
        }
    }
`;

export const ChangePasswordLabel = styled.div`
    ${fontSizesCSS.Caption3}
    cursor: pointer;
    color: ${theme.palette.primary.main};
    margin-bottom: 4px;
`;

export const ChangePasswordFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .MuiFormControl-root {
        margin: 0;
        width: 100%;
    }
`;
