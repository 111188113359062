import { Project } from './Project';
import { Tenant } from './Tenant';
import { moduleTypes } from '../utils/Globals';
import { PUBLISHED_STATUS } from '../utils/fnPublish';

export type Module = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    template: templates;
    moduleType: moduleTypes;
    lastModified?: number;
    modifiedBy?: any;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    tenant?: Tenant;
    project?: Project;
    conditionIds?: string[];
    itemIds?: string[];
    conditions?: any[];
    items?: any[];
    autoCollection?: boolean;
    preview?: boolean;
    topTen?: boolean;
    viewAllCardPlacement?: ViewAllCardPlaces;
    slug?: string;
    autoScroll?: number;
    published?: boolean;
    abTestingGroupIds?: string[];
    includeProviderLogoCard?: boolean;
    pinnable?: boolean;
    invisible?: boolean;
    hideContentMarker?: boolean;
    contentWorld?: boolean;
    publishStatus?: PUBLISHED_STATUS;
    placed?: any[];
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    adminLocked?: boolean;
};

export enum templates {
    GALLERY = 'gallery',
    '16X9_M' = '16x9_m',
    '16X9_S' = '16x9_s',
    '2X3' = '2x3',
    '1X1_SQUARED' = '1x1_squared',
    '1X1_ROUNDED' = '1x1_rounded',
    BANNER_XXL = 'banner_xxl',
    BANNER_XL = 'banner_xl',
    BANNER_L = 'banner_l',
    BANNER_M = 'banner_m',
    BANNER_S = 'banner_s',
    BUTTON = 'button'
}

export const templateTitles = {
    GALLERY: 'Gallery',
    '16X9_M': '16X9 (M)',
    '16X9_S': '16X9 (S)',
    '2X3': '2X3',
    '1X1_SQUARED': '1X1 (squared)',
    '1X1_ROUNDED': '1X1 (rounded)',
    BANNER_XXL: 'Banner (XXL)',
    BANNER_XL: 'Banner (XL)',
    BANNER_L: 'Banner (L)',
    BANNER_M: 'Banner (M)',
    BANNER_S: 'Banner (S)',
    BUTTON: 'Button'
};

export const autoCollectionNotSupportedTemplates = [
    templates.GALLERY,
    templates.BUTTON,
    templates.BANNER_S,
    templates.BANNER_M,
    templates.BANNER_L,
    templates.BANNER_XL,
    templates.BANNER_XXL
];

export const isAutoCollectionSupported = (template?: templates, isPreview?: boolean) => {
    if (!template || isPreview) return true;
    return !autoCollectionNotSupportedTemplates.includes(template);
};

// USED FOR HANDLING RESTRICTED TEMPLATES / PROJECT IN THE COPY DIALOG
export const allTemplates = (Object.keys(templates) as Array<keyof typeof templates>)
    .map((template) => {
        return {
            key: template,
            value: templates[template],
            title: templateTitles[template]
        };
    })
    .filter((template) => !!template.title);

export type ViewAllCardPlaces = 'beginning' | 'end';
