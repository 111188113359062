import React, { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react';
import { Service, ServiceParamOptionLabels } from '../../../utils/Services';
import { DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';
import _ from 'lodash';
import usePrevious from '../../../hooks/usePrevious';

type YoutubeSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        queryParamsValues: any;
        pathParamsValues: any;
        bodyParamsValues: any;
        errors: { method?: string; name?: string; param?: string; bodyParams?: any; queryParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
        setBodyParamsValues: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

export enum YOUTUBE_METHOD_KEYS {
    VIDEOS = 'videos',
    PLAYLISTS = 'playlistItems',
    SEARCH = 'search',
    TRENDING = 'trending',
    RECOMMENDATIONS = 'recommendations',
    RECOMMENDATIONS_SEARCH = 'recommendations_search'
}

type ytField = {
    key: string;
    label: string;
    tooltipText: string;
    filterEmptySpaces?: boolean;
};

export enum YOUTUBE_RECO_SHELF_PARAMS {
    QUERY = 'shelf_params.query',
    KNOWLEDGE_GRAPH_ID = 'shelf_params.kgId',
    TOPIC = 'shelf_params.topic',
    PLAYLIST_ID = 'shelf_params.playlist_group.playlist_ids'
}

export const YOUTUBE_FIELD_LABELS_MAP = new Map<string, ytField | ytField[]>([
    [
        YOUTUBE_METHOD_KEYS.VIDEOS,
        {
            key: 'id',
            label: 'Video IDs',
            tooltipText: 'Enter the Video ID. Adding multiple Video IDs is also possible by using a comma between them',
            filterEmptySpaces: true
        }
    ],
    [
        YOUTUBE_METHOD_KEYS.PLAYLISTS,
        {
            key: 'playlistId',
            label: 'Playlist ID',
            tooltipText: 'Enter the Playlist ID',
            filterEmptySpaces: true
        }
    ],
    [
        YOUTUBE_METHOD_KEYS.SEARCH,
        [
            {
                key: 'q',
                label: 'Search Term',
                tooltipText:
                    'You can freely choose any search term you would like to use, just like you would use the search term on YouTube itself'
            },
            {
                key: 'channelId',
                label: 'Channel ID',
                tooltipText: 'Entering both a search term and a channel Id will search the channel videos by the search term',
                filterEmptySpaces: true
            }
        ]
    ],
    [
        YOUTUBE_METHOD_KEYS.TRENDING,
        { key: 'regionCode', label: 'Country', tooltipText: 'Choose a country for which to show the trending Youtube Videos' }
    ],
    [
        YOUTUBE_METHOD_KEYS.RECOMMENDATIONS,
        [
            { key: 'region_code', label: 'Country', tooltipText: '' },
            { key: 'language_code', label: 'Language', tooltipText: '' },
            { key: 'shelf_params', label: 'Parameter', tooltipText: '' },
            {
                key: YOUTUBE_RECO_SHELF_PARAMS.TOPIC,
                label: 'Topic List',
                tooltipText: ''
            },
            {
                key: YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID,
                label: 'Knowledge Graph IDs',
                tooltipText: ''
            },
            {
                key: YOUTUBE_RECO_SHELF_PARAMS.PLAYLIST_ID,
                label: 'Playlist ID',
                tooltipText: ''
            }
        ]
    ],
    [
        YOUTUBE_METHOD_KEYS.RECOMMENDATIONS_SEARCH,
        [
            { key: 'region_code', label: 'Country', tooltipText: '' },
            { key: 'language_code', label: 'Language', tooltipText: '' },
            {
                key: YOUTUBE_RECO_SHELF_PARAMS.QUERY,
                label: 'Search term',
                tooltipText: 'Values can be split by commas to conduct a search for multiple terms/queries'
            }
        ]
    ]
]);

const addNewOptionValue = 'ADD_NEW_OPTION_VALUE';

const YoutubeSourceFields: FC<YoutubeSourceFieldsProps> = ({
    service,
    dropdownOptions: { methodOptions },
    fieldValues: { selectedMethod, queryParamsValues, bodyParamsValues, errors },
    fieldSetters: { setSelectedMethod, setQueryParamsValues, setPathParamsValues, setBodyParamsValues, setErrors }
}) => {
    const [showAdditionalField, setShowAdditionalField] = useState(false);
    const [additionalValue, setAdditionalValue] = useState('');

    const previousAdditionalValue = usePrevious(additionalValue);

    useEffect(() => {
        if (!bodyParamsValues.shelf_params || previousAdditionalValue === undefined) return;
        const newBodyParams = { ...bodyParamsValues };
        if (bodyParamsValues.shelf_params === 'topic') {
            newBodyParams[YOUTUBE_RECO_SHELF_PARAMS.TOPIC] = additionalValue;
        }
        if (bodyParamsValues.shelf_params === 'kgId') {
            newBodyParams[YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID] = additionalValue;
        }
        setBodyParamsValues(newBodyParams);
        setErrors({
            ...errors,
            bodyParams: _.omit(errors.bodyParams, [YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID, YOUTUBE_RECO_SHELF_PARAMS.TOPIC])
        });
    }, [additionalValue]);

    useEffect(() => {
        const topicValues =
            service.methods.find((m) => m.key === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS)?.params.body[YOUTUBE_RECO_SHELF_PARAMS.TOPIC] || [];
        const kgIdValues =
            service.methods.find((m) => m.key === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS)?.params.body[
                YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID
            ] || [];
        const allValidValues = topicValues.concat(kgIdValues);

        if (bodyParamsValues[YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID]) {
            setShowAdditionalField(!allValidValues.includes(bodyParamsValues[YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID]));
            setAdditionalValue(bodyParamsValues[YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID]);
        }
    }, []);

    const renderQueryParamValuesFields = () => {
        const fields = YOUTUBE_FIELD_LABELS_MAP.get(selectedMethod) || {
            key: '',
            label: '',
            tooltipText: ''
        };
        if (Array.isArray(fields)) {
            const fieldsToRender = [];
            switch (selectedMethod) {
                case YOUTUBE_METHOD_KEYS.SEARCH:
                    for (const field of fields) {
                        const { key, label, tooltipText, filterEmptySpaces } = field;

                        fieldsToRender.push(
                            <DialogTextField
                                key={`param_${selectedMethod}_${key}`}
                                value={queryParamsValues[key] || ''}
                                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                    const newValue = filterEmptySpaces ? evt.target.value.replace(/ /g, '') : evt.target.value;
                                    setQueryParamsValues({ ...queryParamsValues, [key]: newValue });

                                    setErrors({
                                        ...errors,
                                        queryParams: _.omit(errors.queryParams, selectedMethod)
                                    });
                                }}
                                label={label}
                                placeholder={label}
                                toolTipText={tooltipText}
                                dataCy={'youtube-param-source-field'}
                                error={errors?.queryParams?.[selectedMethod]}
                            />
                        );
                    }
                    return fieldsToRender;
                case YOUTUBE_METHOD_KEYS.RECOMMENDATIONS:
                case YOUTUBE_METHOD_KEYS.RECOMMENDATIONS_SEARCH:
                    for (const field of fields) {
                        const { key, label, tooltipText } = field;
                        if (key === YOUTUBE_RECO_SHELF_PARAMS.TOPIC && bodyParamsValues.shelf_params !== 'topic') continue;
                        if (key === YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID && bodyParamsValues.shelf_params !== 'kgId') continue;
                        if (
                            key === YOUTUBE_RECO_SHELF_PARAMS.PLAYLIST_ID &&
                            bodyParamsValues.shelf_params !== 'playlist_group.playlist_ids'
                        )
                            continue;

                        const addNewOption = key === YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID;
                        const values = service.methods.find((m) => m.key === selectedMethod)?.params.body[key] || [];
                        const isFreeInput = values === 'string';

                        if (isFreeInput) {
                            fieldsToRender.push(
                                <DialogTextField
                                    key={`param_${selectedMethod}_${key}`}
                                    placeholder={label}
                                    label={label}
                                    value={bodyParamsValues[key] || ''}
                                    error={errors?.bodyParams?.[key]}
                                    toolTipText={tooltipText || undefined}
                                    onChange={(evt: any) => {
                                        const value = evt.target.value;
                                        const newBodyParams: any = {
                                            ..._.omit(bodyParamsValues, [
                                                ...Object.values(YOUTUBE_RECO_SHELF_PARAMS),
                                                'shelf_params.expand_playlist_shelf'
                                            ]),
                                            [key]: value
                                        };
                                        if (key === YOUTUBE_RECO_SHELF_PARAMS.PLAYLIST_ID) {
                                            newBodyParams['shelf_params.expand_playlist_shelf'] = !!value;
                                        }
                                        setBodyParamsValues(newBodyParams);
                                        setErrors({
                                            ...errors,
                                            bodyParams: _.omit(
                                                errors.bodyParams,
                                                key.includes('shelf_params') ? [...Object.values(YOUTUBE_RECO_SHELF_PARAMS), key] : key
                                            )
                                        });
                                    }}
                                />
                            );
                            continue;
                        }

                        const options = values.map((value: string) => ({
                            value,
                            label: _.get(ServiceParamOptionLabels, value, _.capitalize(value.replaceAll('_', ' '))),
                            valueForSearch: _.get(ServiceParamOptionLabels, value, value)
                        }));

                        if (addNewOption) {
                            options.push({
                                value: addNewOptionValue,
                                label: 'Add new'
                            });
                        }

                        const value = options.find((elem: any) =>
                            addNewOption && showAdditionalField ? elem.value === addNewOptionValue : elem.value === bodyParamsValues[key]
                        );
                        fieldsToRender.push(
                            <DialogDropdownSingle
                                key={`param_${selectedMethod}_${key}`}
                                placeholder={label}
                                options={options}
                                value={value || ''}
                                toolTipText={tooltipText || undefined}
                                onChange={(option: any) => {
                                    if (option.value === addNewOptionValue) {
                                        if (!bodyParamsValues[addNewOptionValue]) {
                                            setAdditionalValue('');
                                        }
                                        setShowAdditionalField(true);
                                        setBodyParamsValues({
                                            ..._.omit(bodyParamsValues, YOUTUBE_RECO_SHELF_PARAMS.KNOWLEDGE_GRAPH_ID)
                                        });
                                        return;
                                    }
                                    if (key.includes('shelf_params')) {
                                        setShowAdditionalField(false);
                                        setBodyParamsValues({
                                            ..._.omit(bodyParamsValues, [
                                                ...Object.values(YOUTUBE_RECO_SHELF_PARAMS),
                                                'shelf_params.expand_playlist_shelf'
                                            ]),
                                            [key]: option.value
                                        });
                                    } else {
                                        setBodyParamsValues({ ...bodyParamsValues, [key]: option.value });
                                    }
                                    setErrors({
                                        ...errors,
                                        bodyParams: _.omit(
                                            errors.bodyParams,
                                            key.includes('shelf_params') ? [...Object.values(YOUTUBE_RECO_SHELF_PARAMS), key] : key
                                        )
                                    });
                                }}
                                error={key.includes('shelf_params') && showAdditionalField ? undefined : errors?.bodyParams?.[key]}
                                labelText={label}
                            />
                        );
                    }
                    if (showAdditionalField) {
                        fieldsToRender.push(
                            <DialogTextField
                                key={'param_additional'}
                                placeholder={'Add a text value'}
                                value={additionalValue}
                                onChange={(evt: any) => setAdditionalValue(evt.target.value)}
                                error={_.get(errors, ['bodyParams', `shelf_params.${bodyParamsValues.shelf_params}`], undefined)}
                            />
                        );
                    }
                    return fieldsToRender;
                default:
                    return null;
            }
        }
        const { key, label, tooltipText, filterEmptySpaces } = fields;
        switch (selectedMethod) {
            case YOUTUBE_METHOD_KEYS.VIDEOS:
            case YOUTUBE_METHOD_KEYS.PLAYLISTS:
            case YOUTUBE_METHOD_KEYS.SEARCH:
                return (
                    <DialogTextField
                        key={`param_${selectedMethod}_${key}`}
                        value={queryParamsValues[key] || ''}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            const newValue = filterEmptySpaces ? evt.target.value.replace(/ /g, '') : evt.target.value;
                            setQueryParamsValues({ [key]: newValue });
                            setErrors({
                                ...errors,
                                queryParams: _.omit(errors.queryParams, key)
                            });
                        }}
                        label={label}
                        placeholder={label}
                        toolTipText={tooltipText}
                        dataCy={'youtube-param-source-field'}
                        error={errors.queryParams?.[key]}
                    />
                );
            case YOUTUBE_METHOD_KEYS.TRENDING:
                const regionCodes = service.methods.find((m) => m.key === YOUTUBE_METHOD_KEYS.TRENDING)?.params.query.regionCode || [];
                const regionOptions = regionCodes.map((code: string) => ({
                    value: code,
                    label: _.get(ServiceParamOptionLabels, code, code),
                    valueForSearch: _.get(ServiceParamOptionLabels, code, code)
                }));
                return (
                    <DialogDropdownSingle
                        key={`param_${selectedMethod}_${key}`}
                        placeholder={label}
                        options={regionOptions}
                        value={regionOptions.find((elem: any) => elem.value === queryParamsValues[key]) || ''}
                        onChange={(option: any) => {
                            setQueryParamsValues({ [key]: option.value, chart: 'mostPopular' });
                            setErrors({
                                ...errors,
                                queryParams: _.omit(errors.queryParams, key)
                            });
                        }}
                        toolTipText={tooltipText}
                        labelText={label}
                        error={errors?.queryParams?.[key]}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source: any) => source.value === selectedMethod) || ''}
                options={methodOptions}
                placeholder={'Select Method'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, ['method', 'bodyParams', 'queryParams']));
                    setSelectedMethod(value.value);
                    setQueryParamsValues({});
                    setPathParamsValues({});
                    setBodyParamsValues({});
                }}
                error={errors.method}
                dataCy={'youtube-method-source-field'}
            />
            {renderQueryParamValuesFields()}
        </>
    );
};

export default YoutubeSourceFields;
