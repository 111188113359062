import { TableBody, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import SVGInline from 'react-inlinesvg';
import { useNavigate, useParams } from 'react-router-dom';
import icons from '../../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { ActiveItemState } from '../../../../redux/slices/activeItemSlice';
import { audiencesState, fetchAudiences, unsetAudiences } from '../../../../redux/slices/audienceSlice';
import { createMenu, fetchMenu, menusState, unsetSelectedMenu } from '../../../../redux/slices/menusSlice';
import { createPage, fetchPage, pagesState, unsetSelectedPage } from '../../../../redux/slices/pagesSlice';
import { DIALOG_NAMES, dialogAlert, dialogConfirm, ToastAlert } from '../../../../utils/fnDialogs';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../../../utils/Globals';

import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import CreateResourceDialog from '../../../common/Dialog/CreateResourceDialog';
import { MoreInfoTypes } from '../../../common/Dialog/MoreInfoDialog';
import PageActions from '../../../common/PageActions/PageActions';
import GenericTable, { ActionsTableCell, tableActions } from '../../../common/Table/Table';
import { BlankTableRow, TitleTableRow, WidthTableCell } from '../../../common/Table/Table.css';
import { renderTooltipWithKey } from '../../../common/Tooltips/Tooltips';
import UseExistingDialog, { EXISTING_ITEMS } from '../../../PageEdit/Dialogs/UseExistingDialog';
import {
    AddElementCell,
    CardIcon,
    CardInfos,
    GridCardAddIcon,
    GridCardText,
    GridCardWrapper,
    GridContainerMoreOverlay,
    GridRowContainer,
    GridTitle,
    RemoveAndPublishStatusIconHolder,
    TargetGroupName,
    Targets,
    Title,
    WarningLabelPlaceHolder
} from '../../GroupEdit.css';
import { capitalizeAndSplitCamelCaseString } from '../../../../utils/fnString';
import { createSetting, fetchSetting, settingsState, unsetSelectedSetting } from '../../../../redux/slices/settingsSlice';
import { DisplayConditionsState } from '../../../../redux/slices/displayConditionsSlice';
import { targetGroupsState } from '../../../../redux/slices/targetGroupsSlice';
import useBlockNavigation from '../../../../hooks/useBlockNavigation';
import TranslationTooltip from '../../../common/TranslationTooltip/TranslationTooltip';
import useScreenSize from '../../../../hooks/useScreenSize';
import { buildPathWithProjectId, PageRoutes } from '../../../../types/RouteTypes';
import { PUBLISHED_STATUS, renderPublishStatusIcon } from '../../../../utils/fnPublish';
import { Audience } from '../../../../types/Audience';
import { Menu, MenuTypes } from '../../../../types/Menu';
import { Setting } from '../../../../types/Setting';
import { Page, PageIntents } from '../../../../types/Page';
import { NewMenuDialog } from '../../../Menus/Dialogs/NewMenuDialog';
import { NewPageDialog } from '../../../PageEdit/Dialogs/NewPageDialog';
import { NewSettingDialog } from '../../../Settings/Dialogs/NewSettingDialog';
import { renderLockedError, renderLockIcon, renderABIcon } from '../../../../utils/fnLockingSystem';
import useLockSystem, { LockableObjectTypes } from '../../../../hooks/useLockSystem';
import { UNSAFE_useEffectOnce } from '../../../../hooks/useEffectOnce';
import Labels from '../../../common/Labels/Labels';
import { ObjectTypes } from '../../../../types/Object';
import { ApplicationsState } from '../../../../redux/slices/applicationsSlice';
import { Loader } from '../../../common/Loader/Loader';
import { MissingAudiencesDialog } from '../../Dialogs/MissingAudiencesDialog';
import { renderAdminLockIcon } from '../../../../utils/fnAdminLocking';
import { AbTestingGroupsState } from '../../../../redux/slices/abTestingGroupSlice';
import { Audiences } from '../Audiences/Audiences';

export type ContentDataType = { menus: any[]; pages: any[]; settings: any[] };
export type ContentProps = {
    viewType: 'LIST' | 'GRID';
    contentData: ContentDataType;
    warning: boolean;
    criticalWarning: boolean;
    unsavedChanges: boolean;
    handleUniqueWarning: (arg: { warning?: boolean; criticalWarning?: boolean }) => void;
    setUnsavedChanges: (arg: boolean) => void;
    onSave: (
        content: { menus: any[]; pages: any[]; settings: any[]; appBrandings: any[] },
        objectsToUpdate?: { id: string; shouldRepublish: boolean }[]
    ) => Promise<void>;
    resetSearch?: () => void;
    filteredContentData?: ContentDataType;
};

export type ContentCategories = 'menus' | 'pages' | 'settings';

export enum Categories {
    MENUS = 'menus',
    PAGES = 'pages',
    SETTINGS = 'settings'
}

const uniqueObjectsProperties: any = {
    [Categories.PAGES]: 'intent',
    [Categories.MENUS]: 'type'
};

const gridIcons = {
    menus: icons.targetMenusIcon,
    appBrandings: icons.targetLayoutIcon,
    pages: icons.targetPagesIcon,
    settings: icons.addIcon
};

const existingItems = {
    menus: EXISTING_ITEMS.MENU,
    pages: EXISTING_ITEMS.PAGE,
    settings: EXISTING_ITEMS.SETTING
};

const redirectLinks = {
    menus: PageRoutes.MENUS,
    pages: PageRoutes.NEW_PAGE,
    settings: PageRoutes.SETTINGS
};

export const Content: FC<ContentProps> = ({
    viewType,
    contentData,
    warning,
    criticalWarning,
    unsavedChanges,
    setUnsavedChanges,
    onSave,
    handleUniqueWarning,
    resetSearch,
    filteredContentData
}) => {
    const { error: pageError, selectedPageLoading: pageLoading }: pagesState = useAppSelector((state) => state.pages);
    const { error: menuError, loading: menuLoading }: menusState = useAppSelector((state) => state.menus);
    const { error: settingError, loading: settingLoading }: settingsState = useAppSelector((state) => state.settings);
    const { error: applicationsError }: ApplicationsState = useAppSelector((state) => state.applications);
    const { screenSize } = useScreenSize();

    const { audiences, loading: audiencesLoading }: audiencesState = useAppSelector((state) => state.audiences);
    const { displayConditions, loading: displayConditionsLoading }: DisplayConditionsState = useAppSelector(
        (state) => state.displayConditions
    );
    const { abTestingGroups, loading: abTestingGroupsLoading }: AbTestingGroupsState = useAppSelector((state) => state.abTestingGroups);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { selectedTargetGroup, loading, loadingGroup }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.PAGES);
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const DEFAULT_CONTENT_DATA = {
        menus: [],
        pages: [],
        settings: []
    };

    const [openNewResourceDialog, setOpenNewResourceDialog] = useState<boolean>(false);
    const [resourceItem, setResourceItem] = useState<ContentCategories>(Categories.MENUS);
    const [openExistingDialog, setOpenExistingDialog] = useState<boolean>(false);
    const [openNewMenuDialog, setOpenNewMenuDialog] = useState<boolean>(false);
    const [openNewPageDialog, setOpenNewPageDialog] = useState<boolean>(false);
    const [openNewSettingDialog, setOpenNewSettingDialog] = useState<boolean>(false);
    const [withoutBlockingNavigation, setWithoutBlockingNavigation] = useState<boolean>(false);
    const [data, setData] = useState<ContentDataType>({ ...DEFAULT_CONTENT_DATA });
    const [filteredData, setFilteredData] = useState<ContentDataType | undefined>(undefined);

    const [uniqueObjectsError, setUniqueObjectsError] = useState<ContentDataType>({ ...DEFAULT_CONTENT_DATA });
    const [uniqueAudiencesError, setUniqueAudiencesError] = useState<ContentDataType>({ ...DEFAULT_CONTENT_DATA });
    const [uniqueABTestingError, setUniqueABTestingError] = useState<ContentDataType>({ ...DEFAULT_CONTENT_DATA });
    const [missingAudiences, setMissingAudiences] = useState<ContentDataType>({ ...DEFAULT_CONTENT_DATA });
    const [objectsToUpdate, setObjectsToUpdate] = useState<string[]>([]);

    const [expandedSection, setExpandedSection] = useState<Categories | undefined>(undefined);
    const [rowsNumber, setRowsNumber] = useState<{ [key in Categories]?: number }>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showAddMissingAudiencesDialog, setShowAddMissingAudiencesDialog] = useState<boolean>(false);

    useBlockNavigation(!withoutBlockingNavigation && unsavedChanges, () => renderAlertUnsavedChanges(), [data]);

    const menusContainerRef = useRef<HTMLDivElement>(null);
    const pagesContainerRef = useRef<HTMLDivElement>(null);
    const settingsContainerRef = useRef<HTMLDivElement>(null);

    const objectRefs = {
        menus: menusContainerRef,
        pages: pagesContainerRef,
        settings: settingsContainerRef
    };

    useEffect(() => {
        const rows: any = {};

        const elemHeight = 108;
        (Object.keys(objectRefs) as Array<keyof typeof objectRefs>).forEach((key) => {
            const containerHeight = objectRefs[key].current?.getBoundingClientRect().height || 0;
            rows[key] = Math.ceil(containerHeight / elemHeight);
        });

        setRowsNumber(rows);
    }, [menusContainerRef?.current, pagesContainerRef?.current, settingsContainerRef?.current, data, screenSize, filteredData, viewType]);

    const error = menuError || pageError || settingError || applicationsError;

    const newObjectsCreators = {
        menus: setOpenNewMenuDialog,
        pages: setOpenNewPageDialog,
        settings: setOpenNewSettingDialog
    };

    const handleCreateSetting = async (newSetting: Setting) => {
        const response = await dispatch(createSetting({ setting: newSetting })).unwrap();
        if (response.id) {
            const result = await loadSetting(response.id);
            if (!result.setting) return;
            addObjectsToContent(Categories.SETTINGS, [result.setting]);
        }
    };

    const handleCreatePage = async (newPage: Page) => {
        const response = await dispatch(createPage({ page: newPage })).unwrap();
        if (response.id) {
            const result = await loadPage(response.id);

            if (!result.page) return;
            addObjectsToContent(Categories.PAGES, [result.page]);
        }
    };

    const handleCreateMenu = async (newMenu: Menu, pages?: Page[]) => {
        const response = await dispatch(createMenu({ menu: newMenu })).unwrap();
        if (response.id) {
            const result = await loadMenu(response.id);
            if (!result.menu) return;
            addObjectsToContent(Categories.MENUS, [result.menu], pages);
        }
    };

    const resetState = () => {
        dispatch(unsetSelectedPage());
        dispatch(unsetSelectedMenu());
        dispatch(unsetSelectedSetting());
    };

    const loadPage = async (pageId: string) => {
        return await dispatch(fetchPage({ pageId })).unwrap();
    };

    const loadMenu = async (menuId: string) => {
        return await dispatch(fetchMenu(menuId)).unwrap();
    };

    const loadSetting = async (settingId: string) => {
        return await dispatch(fetchSetting(settingId)).unwrap();
    };

    const calculateShowUnsaved = () => {
        return JSON.stringify(data) !== JSON.stringify(contentData);
    };

    const getConditions = (conditionIds: string[]) => {
        if (!conditionIds.length) return [];
        if (!audiences.length && !displayConditions.length) return [{ id: '', name: '' }];

        const conditions = [
            ...audiences.map((audience) => ({
                ...audience,
                objectType: ObjectTypes.AUDIENCES
            })),
            ...displayConditions.map((displayCondition) => ({
                ...displayCondition,
                objectType: ObjectTypes.CONDITIONS
            }))
        ];
        return conditions.filter((condition) => conditionIds.includes(condition._id));
    };

    const getAbTestingGroups = (abTestingGroupIds: string[] = []) => {
        if (!abTestingGroupIds?.length) return [];
        return [...abTestingGroups].filter((group) => abTestingGroupIds.includes(group._id));
    };

    const handleDeleteIconClick = (id: string, category: ContentCategories) => {
        const newData = { ...data };
        const items = [...newData[category]];
        const index = items.findIndex((item) => item._id === id);
        if (index === undefined || index < 0) return;
        items.splice(index, 1);
        newData[category] = items;

        // in case there is a filter applied we need to update also the filteredData object to see tha changes on ui
        if (filteredData) {
            const updatedFilteredData = { ...filteredData };
            const filteredItems = [...filteredData[category]];
            const index = filteredItems.findIndex((item) => item._id === id);
            if (index < 0) return;
            filteredItems.splice(index, 1);
            updatedFilteredData[category] = filteredItems;
            setFilteredData?.(updatedFilteredData);
        }

        setData(newData);
    };

    const handleCancelClick = () => {
        setData(contentData);
        setObjectsToUpdate([]);
        setFilteredData(filteredContentData);
    };

    const getDuplicatedConditionsAndAbTestingGroups = (objects: Menu[] | Page[] | Setting[], category: Categories) => {
        const duplicatedIds: string[] = [];
        const duplicatedABTestingIds: string[] = [];
        if (category === Categories.SETTINGS) {
            (objects as any[]).forEach((object, index) => {
                const objectAudiences = audiences.filter((audience) => object.conditionIds?.includes(audience._id))?.map((aud) => aud._id);
                const objectCondition = displayConditions.find((condition) => object.conditionIds?.includes(condition._id))?._id || '';
                const objectABTestingGroups = abTestingGroups
                    .filter((group) => object.abTestingGroupIds?.includes(group._id))
                    .map((group) => group._id);
                // Check each setting, and if it has the same audiences and doesn't differentiate with a displayCondition, push its ID
                if (
                    (objects as any[]).some((obj, i) => {
                        const haveSameAudiences = obj.conditionIds?.some(
                            (condition: any) => i !== index && objectAudiences?.includes(condition)
                        );
                        const objCondition = displayConditions.find((condition) => obj.conditionIds?.includes(condition._id))?._id || '';
                        return haveSameAudiences && objectCondition === objCondition;
                    })
                ) {
                    duplicatedIds.push(object._id);
                }
                // Check each setting, and if it has the same abTestingGroup or doesn't have any, push its ID
                if (
                    !object.abTestingGroupIds?.length ||
                    (objects as any[]).some(
                        (obj) =>
                            obj._id !== object._id &&
                            (!obj.abTestingGroupIds?.length ||
                                obj.abTestingGroupIds.some((abTestingGroup: any) => objectABTestingGroups.includes(abTestingGroup)))
                    )
                ) {
                    duplicatedABTestingIds.push(object._id);
                }
            });

            return {
                duplicatedIds,
                duplicatedABTestingIds
            };
        }

        const isPage = category === Categories.PAGES;
        const uniqueProp = uniqueObjectsProperties[category];

        (objects as any[]).forEach((object, index) => {
            const objectAudiences = audiences.filter((audience) => object.conditionIds?.includes(audience._id))?.map((aud) => aud._id);
            const objectCondition = displayConditions.find((condition) => object.conditionIds?.includes(condition._id))?._id || '';
            const objectABTestingGroups = abTestingGroups
                .filter((group) => object.abTestingGroupIds?.includes(group._id))
                .map((group) => group._id);
            if (isPage && [PageIntents.BASIC, PageIntents.CONTENT_WORLD].includes(object?.[uniqueProp])) return;
            // Check each page/menu, and if it has the same audiences/unique props and doesn't differentiate with a displayCondition, push its ID
            if (
                (objects as any[]).some((obj, i) => {
                    const haveSameAudiences = obj.conditionIds?.some(
                        (condition: any) => i !== index && objectAudiences?.includes(condition)
                    );
                    const objCondition = displayConditions.find((condition) => obj.conditionIds?.includes(condition._id))?._id || '';
                    return obj?.[uniqueProp] === object?.[uniqueProp] && haveSameAudiences && objectCondition === objCondition;
                })
            ) {
                duplicatedIds.push(object._id);
            }
            // Check each page/menu, and if it has the same abTestingGroup or doesn't have any, push its ID
            if (
                !object.abTestingGroupIds?.length ||
                (objects as any[]).some(
                    (obj) =>
                        obj._id !== object._id &&
                        obj?.[uniqueProp] === object?.[uniqueProp] &&
                        (!obj.abTestingGroupIds?.length ||
                            obj.abTestingGroupIds.some((abTestingGroup: any) => objectABTestingGroups.includes(abTestingGroup)))
                )
            ) {
                duplicatedABTestingIds.push(object._id);
            }
        });

        return {
            duplicatedIds,
            duplicatedABTestingIds
        };
    };

    const validateUniqueConditionsOfObjects = (content: ContentDataType) => {
        const objects = { ...content };
        const audienceErrors = { ...DEFAULT_CONTENT_DATA } as ContentDataType;
        const abTestingErrors = { ...uniqueABTestingError };

        (Object.keys(objects) as Array<keyof typeof objects>).forEach((elem) => {
            const { duplicatedIds, duplicatedABTestingIds } = getDuplicatedConditionsAndAbTestingGroups(objects[elem], elem as Categories);
            const audienceErrorsForCategory = duplicatedIds.filter((id) => duplicatedABTestingIds.includes(id)); // critical warning only if also the ABTesting is duplicated
            if (audienceErrorsForCategory.length > 1) {
                // there can't only be one object with critical warning
                audienceErrors[elem] = audienceErrorsForCategory;
            }
            abTestingErrors[elem] = duplicatedIds.filter((id) => !duplicatedABTestingIds.includes(id)); // ab testing errors only if the ABTesting is not duplicated
        });

        setUniqueAudiencesError(audienceErrors);
        setUniqueABTestingError(abTestingErrors);

        return audienceErrors;
    };

    const validateUniqueTypesOfObjects = (content: typeof data, errorsToExclude?: typeof uniqueAudiencesError) => {
        const intents = Object.values(PageIntents);
        const menuTypes = Object.values(MenuTypes);

        let errors = { ...uniqueObjectsError };

        for (const cat in content) {
            const category = cat as keyof typeof content;

            if ([Categories.PAGES, Categories.MENUS].includes(category as any)) {
                const isPage = category === Categories.PAGES;

                const uniqueObjects = isPage
                    ? content[category].filter((page: Page) => ![PageIntents.BASIC, PageIntents.CONTENT_WORLD].includes(page?.intent))
                    : content[category];

                const uniqueProperties = isPage ? intents : menuTypes;
                const uniqueProp = uniqueObjectsProperties[category];

                for (const property of uniqueProperties) {
                    const objectsToCheck = uniqueObjects.filter((obj: any) => obj?.[uniqueProp] === property);
                    if (
                        objectsToCheck.some((object, index: number) => {
                            return objectsToCheck.some((obj, i) => {
                                return index !== i && obj?.[uniqueProp] === object?.[uniqueProp];
                            });
                        })
                    ) {
                        errors[category] = [
                            ...errors[category],
                            ...objectsToCheck.filter((obj: any) => !errors[category].includes(obj._id)).map((obj) => obj._id)
                        ];
                    } else {
                        objectsToCheck.forEach((obj) => {
                            const index = errors[category].indexOf(obj._id);
                            index !== -1 && errors[category].splice(index, 1);
                        });
                        // after deleting a page we need to delete it's id from errors array
                        const existingObjectsIds = uniqueObjects.map((page) => page._id);
                        errors[category] = errors[category].filter((id) => existingObjectsIds.includes(id));
                    }
                }
                continue;
            }

            if (content[category].length > 1) {
                errors[category] = content[category].map((cat) => cat._id);
            } else {
                errors[category] = [];
            }
        }

        if (errorsToExclude) {
            (Object.keys(errors) as Array<keyof typeof uniqueObjectsError>).forEach((key) => {
                errors[key] = errors[key].filter((error) => !errorsToExclude[key].includes(error));
            });
        }

        setUniqueObjectsError(errors);
        return errors;
    };

    const getUniqueErrorMessage = (error: typeof uniqueObjectsError) => {
        const errorObjects = [];

        for (const type in error) {
            const errType = type as keyof typeof error;
            const uniqueProp = uniqueObjectsProperties[type];
            const isPage = type === Categories.PAGES;

            if (!error[errType].length) {
                continue;
            }
            if ([Categories.PAGES, Categories.MENUS].includes(type as any)) {
                const objectIds = error[errType];
                const objects = data[errType];
                const properties = _.uniq(objects.filter((object) => objectIds.includes(object._id)).map((obj) => obj?.[uniqueProp]));

                properties.forEach((prop) => {
                    const message = isPage
                        ? `"Page" with ${prop.toLocaleLowerCase()} Intent`
                        : `"Menu" with ${prop.toLocaleLowerCase()} type`;
                    errorObjects.push(message);
                });
                continue;
            }
            errorObjects.push(`"${_.capitalize(errType).slice(0, -1)}"`);
        }

        return errorObjects
            .map((object) => `one ${object}`)
            .join(', ')
            .replaceAll('_', ' ');
    };

    const getABTestingErrorMessage = () => {
        return (Object.keys(uniqueABTestingError) as Array<keyof typeof uniqueABTestingError>)
            .filter((key) => uniqueABTestingError[key].length)
            .map((errType) => `"${_.capitalize(errType).slice(0, -1)}"`);
    };

    const renderUniqueObjectsAlert = async (isCritical?: boolean, withSave?: boolean, redirect?: any) => {
        const objects = getUniqueErrorMessage(isCritical ? uniqueAudiencesError : uniqueObjectsError);
        const addABTestingText =
            !isCritical &&
            Object.values(uniqueABTestingError)
                .flat()
                .some((id) => id);
        const values = {
            title: isCritical ? 'Critical Warning' : 'Warning',
            subtitle: `Only  ${objects} can be active on the same time! ${
                isCritical || addABTestingText ? 'We found similar conditions' : ''
            }`,
            text: `Saving will not be aborted but please make sure that you check your item's conditions and leave only one active at a certain time. ${
                addABTestingText
                    ? `Additionally, consider A/B Testing Groups assigned to each ${getABTestingErrorMessage()}, and ensure they are appropriately configured to avoid conflicts.`
                    : ''
            }`
        };

        if (withSave) {
            ToastAlert(isCritical ? 'critical_warning' : 'warning', values.title, values.subtitle, undefined, undefined, () => {
                dialogAlert('', false, values, undefined, false, isCritical ? icons.warningIcon : icons.warningYellowIcon);
            });
            await handleSaveClick();
            redirect?.();
        } else {
            dialogAlert('', false, values, undefined, false, isCritical ? icons.warningIcon : icons.warningYellowIcon);
        }
    };

    const handleSaveClick = async () => {
        let dataIds: any = {};
        let updatedObjects: { id: string; shouldRepublish: boolean }[] = [];

        (Object.keys(data) as Array<keyof typeof data>).forEach((category) => {
            dataIds[category] =
                data[category].map((item) => {
                    if (objectsToUpdate.includes(item._id)) {
                        updatedObjects.push({
                            id: item._id,
                            shouldRepublish: item.publishStatus === PUBLISHED_STATUS.PUBLISHED
                        });
                    }
                    return item?._id;
                }) || [];
        });

        setUniqueObjectsError({ menus: [], pages: [], settings: [] });
        setObjectsToUpdate([]);

        await onSave(dataIds, updatedObjects);
    };

    const handleSave = async (redirectCallback?: () => void) => {
        if (warning || criticalWarning) {
            renderUniqueObjectsAlert(criticalWarning, true, redirectCallback);
        } else {
            await handleSaveClick();
            redirectCallback?.();
        }
    };

    const handleEditClick = (id: string, category: ContentCategories) => {
        let link: string = redirectLinks[category];

        if (category === Categories.PAGES) {
            link = link.replace('new', id);

            navigate(buildPathWithProjectId(activeProjectId, link));
        } else {
            navigate(buildPathWithProjectId(activeProjectId, link), { state: { editingId: id } });
        }
    };

    const renderAlertUnsavedChanges = (redirectCallback?: () => void) => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_GROUP_OBJECTS,
            () => {
                handleSave(redirectCallback);
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
                redirectCallback?.();
            },
            true,
            (shouldClose?: boolean) => {
                // we should close the redirect Blocker only when the dialog is closed form the top corner close button
                shouldClose && setWithoutBlockingNavigation(false);
            }
        );
    };

    useEffect(() => {
        setIsLoading(menuLoading || pageLoading || settingLoading || loadingGroup || loading);
    }, [menuLoading, pageLoading, settingLoading, loadingGroup, loading]);

    useEffect(() => {
        return () => {
            dispatch(unsetAudiences());
            resetState();
        };
    }, []);

    useEffect(() => {
        if (!contentData) return;
        setData(contentData);
    }, [contentData]);

    useEffect(() => {
        setFilteredData(filteredContentData);
    }, [filteredContentData]);

    useEffect(() => {
        // for correct warning calculation we need to wait for audiences and display conditions to load
        // since they are used within the "getDuplicatedConditions" function, that is called in this useEffect
        if (audiencesLoading || displayConditionsLoading || abTestingGroupsLoading) return;
        const audiencesError = validateUniqueConditionsOfObjects(data);
        const objectTypesError = validateUniqueTypesOfObjects(data, audiencesError);

        handleUniqueWarning({
            warning: !Object.values(objectTypesError).every((val) => !val.length),
            criticalWarning: !Object.values(audiencesError).every((val) => !val.length)
        });
    }, [data, audiencesLoading, displayConditionsLoading, abTestingGroupsLoading]);

    useEffect(() => {
        setUnsavedChanges(calculateShowUnsaved());
    }, [data]);

    const addObjectsToContent = (resource: ContentCategories, objects: any[], pages?: Page[]) => {
        const contentData = { ...data };
        const audiences = { ...missingAudiences };

        const groupAudienceIds = (selectedTargetGroup?.audiences as Audience[])?.map((aud) => aud._id) || [];

        if (resource === Categories.MENUS && !!pages) {
            const pagesToAdd = [...new Set(pages.filter((page) => !contentData.pages.some((pg) => pg._id === page._id)))];

            pagesToAdd.forEach((page) => {
                const doesPageHaveAllAudiencesOfGroup = groupAudienceIds.every((groupAud) => page.conditionIds?.includes(groupAud));
                if (!doesPageHaveAllAudiencesOfGroup) {
                    audiences.pages.push(page);
                }
            });

            contentData.pages = [...contentData.pages, ...pagesToAdd];
        }

        objects.forEach((object) => {
            if (object.adminLocked) return;
            const doesObjectHaveAllAudiencesOfGroup = groupAudienceIds.every((groupAud) => object.conditionIds?.includes(groupAud));
            if (!doesObjectHaveAllAudiencesOfGroup) {
                audiences[resource].push(object);
            }
        });

        contentData[resource] = [...contentData[resource], ...objects];

        setData(contentData);
        setMissingAudiences(audiences);
        resetSearch?.();
    };

    const updateObjectsAudiences = (objectsIds: string[]) => {
        const updatedData = { ...data };
        const groupAudienceIds = (selectedTargetGroup?.audiences as Audience[])?.map((aud) => aud._id) || [];

        for (const key in updatedData) {
            const category = key as keyof typeof data;
            const objects = [...updatedData[category]] || [];
            if (!objects.length) continue;
            // fore each object that was selected in the missing audiences dialog we need to update the ui with the group audiences
            objects.forEach((object) => {
                if (objectsIds.includes(object._id)) {
                    const objectIndex = objects.findIndex((obj) => obj._id === object._id);
                    const objectWithAudiences = {
                        ...object,
                        conditionIds: [...new Set([...(object.conditionIds || []), ...groupAudienceIds])]
                    };
                    objects.splice(objectIndex, 1, objectWithAudiences);
                }
            });
            updatedData[category] = objects;
        }

        setData(updatedData);
    };

    useEffect(() => {
        const shouldShowMissingDialog = Object.values(missingAudiences).some((value) => !!value.length);

        if (shouldShowMissingDialog) {
            setShowAddMissingAudiencesDialog(true);
        }
    }, [missingAudiences]);

    const buildTableBody = () => {
        const dataToShow = filteredData || data;
        const actions = [tableActions.EDIT, tableActions.REMOVE];
        const rows: JSX.Element[] = [];
        (Object.keys(data) as Array<keyof typeof data>).forEach((category, index) => {
            rows.push(
                <>
                    <BlankTableRow key={index}></BlankTableRow>
                    <TitleTableRow>
                        <TableCell colSpan={3}>{`Manage ${capitalizeAndSplitCamelCaseString(category)}`}</TableCell>
                    </TitleTableRow>
                    <TableRow style={{ height: '8px' }} />
                </>
            );

            dataToShow[category].forEach((item) => {
                rows.push(
                    <TableRow>
                        <WidthTableCell $width={30} $um={'%'}>
                            <TargetGroupName
                                onClick={() => {
                                    if (unsavedChanges) {
                                        renderAlertUnsavedChanges(() => handleEditClick(item._id, category));
                                        setWithoutBlockingNavigation(true);
                                        return;
                                    }
                                    handleEditClick(item._id, category);
                                }}
                            >
                                {item.name || EMPTY_WORD_STRING}
                                {category === 'pages' && <TranslationTooltip translationKey={item.name} />}
                                {renderABIcon({ abTestingGroups: getAbTestingGroups(item.abTestingGroupIds) })}
                            </TargetGroupName>
                        </WidthTableCell>
                        <WidthTableCell $width={40} $um={'%'}>
                            <Labels
                                values={getConditions(item.conditionIds)}
                                type={MoreInfoTypes.TARGETS}
                                noOfLabels={isMobile ? 1 : 2}
                                onClickLabel={(obj) => {
                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                    navigate(
                                        buildPathWithProjectId(
                                            activeProjectId,
                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                        ),
                                        {
                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                        }
                                    );
                                }}
                            />
                        </WidthTableCell>

                        {/* ACTIONS TABLE CELL */}
                        <WidthTableCell $wTab={92} $um={'px'}>
                            <ActionsTableCell
                                actions={actions}
                                onRemove={() => {
                                    handleDeleteIconClick(item._id, category);
                                }}
                                onEdit={() => {
                                    if (unsavedChanges) {
                                        renderAlertUnsavedChanges(() => handleEditClick(item._id, category));
                                        setWithoutBlockingNavigation(true);
                                        return;
                                    }
                                    handleEditClick(item._id, category);
                                }}
                                tooltipTexts={{
                                    edit: `target_groups_ux_content_icon_edit_${category}`,
                                    delete: `target_groups_ux_content_icon_remove_${category}`
                                }}
                                publishedStatus={item.publishStatus}
                            />
                        </WidthTableCell>
                    </TableRow>
                );
            });

            rows.push(
                <TableRow
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        resetState();
                        setOpenNewResourceDialog(true);
                        setResourceItem(category);
                    }}
                >
                    <TableCell colSpan={3}>
                        <AddElementCell>
                            {`Add ${capitalizeAndSplitCamelCaseString(category).slice(0, -1)}`}
                            {renderTooltipWithKey(<InlineSVG src={icons.addIcon} />, `target_groups_ux_content_icon_add_${category}`)}
                        </AddElementCell>
                    </TableCell>
                </TableRow>
            );
        });

        return <TableBody>{rows}</TableBody>;
    };

    const renderContentListView = () => {
        return <GenericTable body={buildTableBody()} columns={[]} />;
    };

    const renderContentGridView = () => {
        const dataToShow = filteredData || data;
        return (Object.keys(dataToShow) as Array<keyof typeof data>).map((category, index) => {
            const isExpandable = (rowsNumber?.[category as Categories] || 0) > 3;
            const isExpanded = expandedSection === category;
            return (
                <>
                    <GridTitle
                        $expandable={isExpandable}
                        onClick={() => {
                            if (!isExpandable) return;
                            setExpandedSection(isExpanded ? undefined : (category as Categories));
                        }}
                    >
                        {`Manage ${capitalizeAndSplitCamelCaseString(category)} `}
                        {isExpandable && <SVGInline src={isExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />}
                    </GridTitle>

                    <GridRowContainer key={index} ref={objectRefs[category]} $expanded={isExpanded}>
                        {dataToShow[category].map((item, index) => {
                            const locked = isObjectLocked(item);
                            const lockedBy = objectIsLockedBy(item);
                            const showWarning = uniqueObjectsError[category].includes(item._id);
                            const showCriticalWarning = uniqueAudiencesError[category].includes(item._id);
                            return (
                                <GridCardWrapper key={index} withWarning={showWarning} withCriticalWarning={showCriticalWarning}>
                                    <CardIcon>
                                        <InlineSVG src={gridIcons[category]} />
                                    </CardIcon>

                                    <CardInfos>
                                        <Title
                                            onClick={() => {
                                                if (unsavedChanges) {
                                                    renderAlertUnsavedChanges(() => handleEditClick(item._id, category));
                                                    setWithoutBlockingNavigation(true);
                                                    return;
                                                }
                                                handleEditClick(item._id, category);
                                            }}
                                        >
                                            {_.truncate(item.name, { length: 20 }) || EMPTY_WORD_STRING}
                                            {locked && renderLockIcon(lockedBy)}
                                            {item.adminLocked && renderAdminLockIcon()}
                                            {category === 'pages' && <TranslationTooltip translationKey={item.name} />}
                                            {renderABIcon({ abTestingGroups: getAbTestingGroups(item.abTestingGroupIds) })}
                                        </Title>
                                        <Targets>
                                            <Labels
                                                values={getConditions(item.conditionIds)}
                                                type={MoreInfoTypes.TARGETS}
                                                noOfLabels={1}
                                                onClickLabel={(obj) => {
                                                    const isAudience = obj?.objectType === ObjectTypes.AUDIENCES;

                                                    navigate(
                                                        buildPathWithProjectId(
                                                            activeProjectId,
                                                            isAudience ? PageRoutes.AUDIENCES : PageRoutes.TARGET_CONDITIONS
                                                        ),
                                                        {
                                                            state: isAudience ? { audienceId: obj._id } : { conditionId: obj._id }
                                                        }
                                                    );
                                                }}
                                            />
                                        </Targets>
                                    </CardInfos>
                                    <RemoveAndPublishStatusIconHolder>
                                        {renderPublishStatusIcon(item.publishStatus)}
                                        {renderTooltipWithKey(
                                            <SVGInline
                                                src={icons.closeIcon}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleDeleteIconClick(item._id, category);
                                                }}
                                            />,
                                            `target_groups_ux_content_icon_remove_${category}`
                                        )}
                                    </RemoveAndPublishStatusIconHolder>
                                </GridCardWrapper>
                            );
                        })}

                        <GridCardWrapper
                            isEmpty
                            onClick={() => {
                                resetState();
                                setResourceItem(category);
                                setOpenNewResourceDialog(true);
                            }}
                        >
                            <GridCardText>{`Add ${capitalizeAndSplitCamelCaseString(category).slice(0, -1)}`}</GridCardText>
                            <GridCardAddIcon>
                                {renderTooltipWithKey(
                                    <InlineSVG src={icons.addIcon} style={{ cursor: 'pointer' }} />,
                                    `target_groups_ux_content_icon_add_${category}`
                                )}
                            </GridCardAddIcon>
                        </GridCardWrapper>
                        {isExpandable && !isExpanded && <GridContainerMoreOverlay />}
                    </GridRowContainer>
                </>
            );
        });
    };

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <>
            {isLoading && <Loader title={'Group'} />}
            {error && renderError(error)}
            {!loadingGroup && (viewType === 'LIST' ? renderContentListView() : renderContentGridView())}

            <CreateResourceDialog
                title={capitalizeAndSplitCamelCaseString(resourceItem).slice(0, -1)}
                open={openNewResourceDialog}
                onClose={() => {
                    setOpenNewResourceDialog(false);
                    setResourceItem(Categories.MENUS);
                }}
                handleCreateNewResourceClick={() => {
                    newObjectsCreators[resourceItem]?.(true);
                }}
                historyUrl={''}
                withSelectExisting
                withoutSelectTemplate
                handleSelectExistingClick={() => setOpenExistingDialog(true)}
            />

            <UseExistingDialog
                open={openExistingDialog}
                onClose={() => {
                    setOpenExistingDialog(false);
                }}
                existingItemType={existingItems[resourceItem]}
                onMultipleSave={(objects, itemsToAdd) => {
                    if (!objects) return;
                    addObjectsToContent(resourceItem, objects, itemsToAdd);
                    setOpenExistingDialog(false);
                    setOpenNewResourceDialog(false);
                }}
                itemIdsToExclude={data[resourceItem].map((item) => item._id)}
                isMultiSelect
            />

            <NewMenuDialog
                open={openNewMenuDialog}
                onClose={() => {
                    setOpenNewMenuDialog(false);
                }}
                onSave={(menu, itemsToAdd) => {
                    handleCreateMenu(menu, itemsToAdd);
                    setOpenNewResourceDialog(false);
                }}
            />

            <NewPageDialog
                open={openNewPageDialog}
                onClose={() => {
                    setOpenNewPageDialog(false);
                }}
                onSave={(page) => {
                    handleCreatePage(page);
                    setOpenNewResourceDialog(false);
                }}
            />

            <NewSettingDialog
                open={openNewSettingDialog}
                onClose={() => {
                    setOpenNewSettingDialog(false);
                }}
                onSave={(setting) => {
                    handleCreateSetting(setting);
                    setOpenNewResourceDialog(false);
                }}
            />

            <MissingAudiencesDialog
                open={showAddMissingAudiencesDialog}
                onClose={() => {
                    setShowAddMissingAudiencesDialog(false);
                    setMissingAudiences({ ...DEFAULT_CONTENT_DATA });
                }}
                onSave={(objectsIdsToUpdate) => {
                    updateObjectsAudiences(objectsIdsToUpdate);
                    setObjectsToUpdate([...objectsToUpdate, ...objectsIdsToUpdate]);
                }}
                missingAudiences={missingAudiences}
            />

            <PageActions
                onSave={() => {
                    handleSave();
                }}
                disabled={{ save: !unsavedChanges }}
                onCancel={() => {
                    unsavedChanges ? renderAlertUnsavedChanges() : handleCancelClick();
                }}
            />

            {/* these buttons are needed to be able to handle the click listener based on the id for each warning label */}
            <>
                <WarningLabelPlaceHolder
                    hidden
                    id={'warning_label'}
                    onClick={() => {
                        renderUniqueObjectsAlert();
                    }}
                />
                <WarningLabelPlaceHolder
                    hidden
                    id={'critical_warning_label'}
                    onClick={() => {
                        renderUniqueObjectsAlert(true);
                    }}
                />
            </>
        </>
    );
};
