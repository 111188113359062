import { TargetGroup } from './TargetGroup';

export interface AudienceVersionInterval {
    type: AudienceVersionTypes;
    firstVersion: string;
    secondVersion?: string;
}

export enum AudienceVersionTypes {
    GREATER_THAN = 'greater_than',
    LOWER_THAN = 'lower_than',
    IN_BETWEEN = 'in_between'
}

export interface Audience {
    _id: string;
    name: string;
    tenantId: string;
    targetGroupId: string;
    targetGroup?: TargetGroup;
    projectId: string;
    deviceClass?: string;
    deviceType?: string;
    operatingSystem?: string;
    operatingSystemVersion?: string | AudienceVersionInterval;
    clientAppVersion?: string | AudienceVersionInterval;
    deviceIds?: string[];
    userIds?: string[];
    clientAppVariants?: string[];
    location?: string;
    cableOperator?: string;
    manufacturer?: string;
    modifiedBy?: string;
    lastModified?: number;
    disabled?: boolean;
    placed?: any[];
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
}

export const deviceTypeValuesForClasses: { [deviceClass: string]: string[] } = {
    bigscreen: ['set_top_box', 'smart_tv', 'smart_tv_stick', 'game_console', 'roku', 'xr_headset'],
    mobile: ['mobile'],
    pc: ['pc'],
    tablet: ['tablet', 'automotive']
};

export const predefinedAudienceValues = {
    deviceClass: ['bigscreen', 'mobile', 'pc', 'tablet'],
    deviceType: ['pc', 'mobile', 'tablet', 'set_top_box', 'smart_tv', 'smart_tv_stick', 'game_console', 'roku', 'automotive', 'xr_headset'],
    operatingSystem: [
        'android',
        'ios',
        'fire_tv',
        'roku',
        'tvos',
        'webos',
        'tizen',
        'browser',
        'vidaa_os',
        'ipad_os',
        'qnx',
        'linux',
        'rdk',
        'vision_os'
    ]
};

export const audienceFilterKeys = [
    'deviceClass',
    'deviceType',
    'operatingSystem',
    'operatingSystemVersion',
    'clientAppVersion',
    'deviceIds',
    'userIds',
    'cableOperator',
    'location',
    'manufacturer',
    'clientAppVariants'
];

export const AudienceFilterHeaders: { [key: string]: string } = {
    deviceClass: 'x-device-class',
    deviceType: 'x-device-type',
    operatingSystem: 'x-operating-system',
    operatingSystemVersion: 'x-operating-system-version',
    clientAppVersion: 'x-client-app-version',
    deviceIds: 'x-device-id',
    userIds: 'x-user-id',
    cableOperator: 'x-cable-operator',
    manufacturer: 'x-manufacturer',
    location: 'x-location',
    clientAppVariants: 'x-client-app-variant'
};
