import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { StyledDialog } from '../Dialog/GenericDialog.css';

const theme: any = getTheme();

export const ColorPickerContainer = styled.div`
    position: relative;
    width: 114px;
    height: 28px;

    .color-swatch {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 4px;
        top: 6px;
        border-radius: 8px;
    }
    .chrome-picker {
        position: absolute;
        margin-top: 28px;
        z-index: 100;
    }
    .cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
    }
    input[type='text'] {
        border: 1px solid ${theme.palette.grey.light};
        caret-color: transparent;
        cursor: pointer;
        border-radius: 4px;
        padding-left: 24px;
        width: 114px;
        height: 28px;
        box-sizing: border-box;
        ${fontSizesCSS.Caption3};
        font-family: ${theme.typography.fontFamily};
        &:focus {
            outline: none;
        }
    }
    svg {
        position: absolute;
        height: 16px;
        width: 16px;
        cursor: pointer;
        right: 4px;
        top: 6px;
        path {
            fill: ${theme.palette.grey.main};
        }
    }
`;

export const LabeledColorPickerContainer = styled.div`
    display: flex;
    width: 128px;
    height: 80px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    flex-direction: column;
    background-color: ${theme.palette.background.active};
    font-weight: bold;
    ${fontSizesCSS.Caption3};
    font-family: ${theme.typography.fontFamily};
    label {
        align-self: flex-start;
    }
    ${ColorPickerContainer} {
        margin-top: auto;
    }
`;

export const ColorPickerDialog = styled(StyledDialog)`
    div.MuiPaper-root {
        width: fit-content;
    }
`;
