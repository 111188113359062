import { FC, useEffect, useState } from 'react';
import { Content, ContentDataType } from './Content/Content';
import { Audiences } from './Audiences/Audiences';
import { TargetGroup } from '../../../types/TargetGroup';
import { fetchTargetGroup, targetGroupsState, updateTargetGroup } from '../../../redux/slices/targetGroupsSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { audiencesState, fetchAudiences } from '../../../redux/slices/audienceSlice';
import { Audience } from '../../../types/Audience';
import { UNSAFE_useEffectOnce } from '../../../hooks/useEffectOnce';
import { DisplayConditionsState, fetchDisplayConditions } from '../../../redux/slices/displayConditionsSlice';
import { AbTestingGroupsState, fetchAbTestingGroupsByTargetGroupId } from '../../../redux/slices/abTestingGroupSlice';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useParams } from 'react-router-dom';
import { DIALOG_NAMES, ToastAlert, dialogAlert } from '../../../utils/fnDialogs';
import icons from '../../../style';
import { searchInGroup } from '../../common/SearchBar/SearchBar';
import { FancyFilter, searchTermUnsetValue } from '../../common/Select/FancyFilter';
import { Menu } from '../../../types/Menu';
import { Setting } from '../../../types/Setting';
import { Page } from '../../../types/Page';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import { GroupEditSearchContainer } from '../GroupEdit.css';
import { ObjectFilter } from '../../../utils/fnFilter';
import _ from 'lodash';

export type UiElementsProps = {
    view: 'LIST' | 'GRID';
    handleUniqueWarning: (arg: { warning?: boolean; criticalWarning?: boolean }) => void;
    warnings: { warning: boolean; criticalWarning: boolean };
};

const DEFAULT_CONTENT_DATA = {
    menus: [],
    pages: [],
    settings: []
};

const groupFields = [
    {
        label: 'Audiences',
        value: 'audiences',
        icon: 'conditionsIcon',
        type: 'field'
    },
    {
        label: 'Display Conditions',
        value: 'conditions',
        icon: 'displayConditionsIcon',
        type: 'field'
    },
    // {
    //     label: 'A/B Testing Groups',
    //     value: 'abGroups',
    //     icon: 'abTestingStatusDefaultIcon',
    //     type: 'field'
    // },
    {
        label: 'Search for this text',
        value: 'search',
        icon: 'searchIcon',
        type: 'field'
    }
];

export const UiElements: FC<UiElementsProps> = ({ view, handleUniqueWarning, warnings }) => {
    const dispatch = useDispatch();
    const { group_id } = useParams();

    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { selectedTargetGroup }: targetGroupsState = useAppSelector((state) => state.targetGroups);
    const { audiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { displayConditions }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { abTestingGroups }: AbTestingGroupsState = useAppSelector((state) => state.abTestingGroups);

    const [groupData, setGroupData] = useState<ContentDataType>(DEFAULT_CONTENT_DATA);
    const [groupAudiences, setGroupAudiences] = useState<Audience[]>([]);
    // used for filtering data
    const [filteredAudiences, setFilteredAudiences] = useState<Audience[]>([]);
    const [filteredGroupData, setFilteredGroupData] = useState<ContentDataType>(DEFAULT_CONTENT_DATA);

    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activeFilter, setActiveFilter] = useState<ObjectFilter | undefined>(undefined);

    const [groupFilters, setGroupFilters] = useState<any>({});

    const loadAudiences = async () => {
        return await dispatch(fetchAudiences({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadDisplayConditions = async () => {
        return await dispatch(fetchDisplayConditions({ addPermissions: false, projectId: activeProjectId }));
    };

    const loadAbTestingGroups = async () => {
        if (group_id) return await dispatch(fetchAbTestingGroupsByTargetGroupId({ targetGroupId: group_id }));
    };

    const loadTargetGroup = async () => {
        await dispatch(fetchTargetGroup({ targetGroupId: group_id! })).unwrap();
    };

    useEffect(() => {
        const filters: Record<string, { label?: string; value?: string }[]> = { audiences: [], conditions: [], abGroups: [] };
        if (!selectedTargetGroup) return;
        const objects: Menu[] | Setting[] | Page[] = [...groupData.menus, ...groupData.pages, ...groupData.settings];

        objects.forEach((object: any) => {
            if (!object.conditionIds?.length) return;

            const objAudiences = [...audiences].filter((audience) => object.conditionIds?.includes(audience._id));
            const objConditions = [...displayConditions].filter((cond) => object.conditionIds?.includes(cond._id));
            const objAbGroups = [...abTestingGroups].filter((group) => object.abTestingGroupIds?.includes(group._id));

            filters.audiences.push(
                ...objAudiences
                    .filter((aud) => !filters.audiences.some((a) => a.value === aud._id))
                    .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }))
            );

            filters.conditions.push(
                ...objConditions
                    .filter((cond) => !filters.conditions.some((c) => c.value === cond._id))
                    .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }))
            );

            filters.abGroups.push(
                ...objAbGroups
                    .filter((group) => !filters.abGroups.some((g) => g.value === group._id))
                    .map((el) => ({ label: el.name || EMPTY_WORD_STRING, value: el._id }))
            );
        });

        setGroupFilters(filters);
    }, [groupData, displayConditions, audiences]);

    UNSAFE_useEffectOnce(() => {
        loadAudiences();
        loadDisplayConditions();
        loadAbTestingGroups();
    }, [group_id, activeProjectId]);

    useEffect(() => {
        if (!selectedTargetGroup) return;

        setGroupData({
            ...groupData,
            menus: selectedTargetGroup.menus || [],
            pages: selectedTargetGroup.pages || [],
            settings: selectedTargetGroup.settings || []
        });
        const grAudiences = audiences.filter((aud) => {
            return (selectedTargetGroup.audiences as Audience[])?.some((grAud) => grAud._id === aud._id);
        });

        setGroupAudiences(grAudiences);
    }, [selectedTargetGroup, audiences]);

    const resetCallback = () => {
        setSearchTerm(searchTermUnsetValue);
        setActiveFilter(undefined);
    };

    const modifyTargetGroup = async (targetGroup: TargetGroup, objectsToUpdate?: { id: string; shouldRepublish: boolean }[]) => {
        const response = await dispatch(updateTargetGroup({ targetGroup, objectsToUpdate })).unwrap();
        // If there's an error while modifying the group, we do not reload it,
        // because that will clear out the error occurred during update, and it won't be visible to the user
        !response.error && loadTargetGroup();
        return response;
    };

    const saveGroup = async (content: any, updatedObjects?: { id: string; shouldRepublish: boolean }[]) => {
        if (!selectedTargetGroup) return;

        const newGroup: TargetGroup = {
            ...selectedTargetGroup,
            menus: content.menus,
            pages: content.pages,
            settings: content.settings,
            appBrandings: content.appBrandings,
            // in case the edited group has audiences, they are put in state and sent as full objects instead of ids which causes a BE error
            audiences: selectedTargetGroup.audiences?.length ? (selectedTargetGroup.audiences as any[]).map((a) => a._id || a) : []
        };

        const response = await modifyTargetGroup(newGroup, updatedObjects?.length ? updatedObjects : undefined);

        if (response?.republishedObjects) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.GROUP_OBJECT_REPUBLISH, () => {
                dialogAlert(DIALOG_NAMES.GROUP_OBJECT_REPUBLISH, undefined, undefined, undefined, undefined, icons.warningYellowIcon);
            });
        }

        resetCallback();
    };

    const isDataFiltered = !!searchTerm || (!!activeFilter && !_.isEmpty(activeFilter));

    return (
        <>
            <GroupEditSearchContainer>
                <FancyFilter
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm || ''}
                    onChange={(filter: any) => {
                        const { content, audiences } =
                            typeof filter === 'string'
                                ? searchInGroup(groupData, groupAudiences, filter)
                                : searchInGroup(groupData, groupAudiences, undefined, filter);

                        typeof filter === 'string' ? setSearchTerm(filter) : setActiveFilter(filter);
                        setFilteredGroupData(content);
                        setFilteredAudiences(audiences);
                    }}
                    extraFilters={{ fields: groupFields, filterValues: groupFilters }}
                    activeObjectFilter={activeFilter}
                />
            </GroupEditSearchContainer>

            <Content
                viewType={view}
                contentData={groupData}
                filteredContentData={isDataFiltered ? filteredGroupData : undefined}
                onSave={async (data, updatedObjects) => await saveGroup(data, updatedObjects)}
                warning={warnings.warning}
                criticalWarning={warnings.criticalWarning}
                handleUniqueWarning={handleUniqueWarning}
                unsavedChanges={showUnsaved}
                setUnsavedChanges={setShowUnsaved}
                resetSearch={resetCallback}
            />
            <Audiences
                viewType={view}
                groupId={group_id}
                groupAudiences={groupAudiences}
                filteredAudiences={isDataFiltered ? filteredAudiences : undefined}
                unsavedChanges={showUnsaved}
                resetSearch={resetCallback}
            />
        </>
    );
};
