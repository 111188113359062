import React from 'react';
import { LabeledStripeContainer } from './PageEdit.css';

interface IProps {
    labelText: string;
    stripeBody: JSX.Element | JSX.Element[];
    className?: string;
}
const LabeledStripe: React.FC<IProps> = ({ labelText, stripeBody, className }) => {
    return (
        <LabeledStripeContainer className={className}>
            <label className="title">{labelText}</label>
            <div className="content">{stripeBody}</div>
        </LabeledStripeContainer>
    );
};

export default LabeledStripe;
