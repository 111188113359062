import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { TEMPLATE_SIZES, TemplateSizeKey } from '../VisualEditor.css';
import SVGInline from 'react-inlinesvg';
import { TranslationTooltipIconContainer } from '../../common/TranslationTooltip/TranslationTooltip.css';

const theme: any = getTheme();

export const VisualContainer = styled.div<{ isGalleryOrPreview?: boolean; isButton?: boolean }>`
    position: ${(props) => (props.isGalleryOrPreview ? 'unset' : 'absolute')};
    background: ${(props) => (props.isGalleryOrPreview ? 'transparent' : theme.palette.black.main)};
    padding-left: ${(props) => (props.isGalleryOrPreview ? '0' : '96px')};
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    border-radius: 4px;
    box-sizing: border-box;
`;

const isColor = (background: string = '') => /^rgba\(/.test(background);
export const EditorialCustomizeVisualContainer = styled(VisualContainer)<{ background?: string }>`
    background-color: ${(props) => (isColor(props.background) ? props.background : theme.palette.black.main)};
    background-image: ${(props) => (!isColor(props.background) ? props.background : 'none')};
    background-size: 60%;
    background-position: right;
    background-repeat: no-repeat;
`;

export const DragAndDropTextWrapper = styled.div`
    color: ${theme.palette.white.main};
    width: 220px;
    left: 60px;
    opacity: 0;
    transition: opacity 0.1s linear;
`;

export const BackgroundSelector = styled.div<{ $background?: string; showAsBanner?: boolean }>`
    box-sizing: border-box;
    position: relative;
    border: 2px dashed ${theme.palette.background.innerBorder};
    background: ${(props) => (props.$background ? ` ${props.$background}` : props.showAsBanner ? 'none' : theme.palette.text.main)};
    border-radius: 5.5px;
    width: 100%;
    height: 100%;
    color: ${theme.palette.white.main};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.showAsBanner ? 'flex-start' : 'center')};
    gap: 260px;
    background-position: center;
    background-size: cover;
    svg {
        opacity: 0.7;
        width: 32px;
        height: 32px;
    }
    &:hover {
        border: 2px dashed ${theme.palette.white.main};
        svg {
            opacity: 1;
        }
    }

    padding-left: ${(props) => (props.showAsBanner ? '36px' : '0')};
`;
export const BrowseIcon = styled.div`
    cursor: pointer;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 44px;
        path {
            fill: black;
        }
    }
`;

export const BrowseWrapper = styled.div`
    z-index: 2;
    width: 44px;
    padding: 0;
    height: 44px;
    border-radius: 38px;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 200px;
    overflow: hidden;
    transition: width 0.5s, margin-right 0.5s, background-color 0.5s, padding 0.5s;
    &:hover {
        background-color: ${theme.palette.background.smallBorder};
        cursor: pointer;
        padding: 8px;
        width: 400px;
        margin-right: 100px;
        transition: width 0.5s, margin-right 0.5s, background-color 0.5s, padding 0.5s;
        ${DragAndDropTextWrapper} {
            transition: opacity 0.2s linear 0.3s;
            opacity: 1;
        }
    }
`;

export const BackgroundContainer = styled.div<{ template: TemplateSizeKey }>`
    position: relative;
    width: ${(props) => TEMPLATE_SIZES[props.template]?.width || TEMPLATE_SIZES['UNKNOWN'].width};
    height: ${(props) => TEMPLATE_SIZES[props.template]?.height || TEMPLATE_SIZES['UNKNOWN'].height};
    cursor: pointer;

    ${BackgroundSelector} {
        border-radius: ${(props) => props.template === '1X1_ROUNDED' && '50%'};
    }
`;

export const ButtonsContainer = styled.div<{ buttonsDisabled?: boolean }>`
    padding-top: 20px;
    justify-content: flex-start;
    display: flex;
    gap: 16px;

    button {
        width: 120px;
        height: 36px;
        margin: 0 0 0 0;
        ${(props) =>
            props.buttonsDisabled &&
            css`
                &:hover {
                    cursor: initial;
                }
            `}
    }
`;

export const CloseIcon = styled(SVGInline)`
    position: absolute;
    top: 36px;
    right: 36px;
    z-index: 3;
    cursor: pointer;
    path {
        fill: white;
    }
`;

export const EditorialInfo = styled.div<{ template: TemplateSizeKey; showAsBanner?: boolean }>`
    position: relative;
    width: 50%;
    .MuiInput-root {
        border: none;
    }

    .MuiInputBase-input {
        padding: 0 0 0 2px;
        margin-left: unset;
        background-color: transparent;
        color: ${theme.palette.text.light};
        &:focus {
            border: 1px solid ${theme.palette.primary.main};
        }

        &::placeholder {
            color: ${theme.palette.white.main};
            opacity: 1;
        }
    }

    ${ButtonsContainer} {
        ${(props) =>
            props.showAsBanner
                ? css`
                      margin-top: 36px;
                  `
                : ''}
    }
`;

export const TextFieldContainer = styled.div`
    .MuiIconButton-root {
        min-width: 64px;
    }
    ${TranslationTooltipIconContainer} {
        svg {
            width: 18px;
            height: 18px;
        }
    }
`;

export const TitleContainer = styled(TextFieldContainer)`
    .MuiFormControl-root {
        margin: 0;
        min-height: 34px;
    }
    .MuiInputBase-input {
        margin: 4px 0 4px 0;
        height: 34px;
        ${fontSizesCSS.Title3}
    }
`;

export const SubTitleContainer = styled(TextFieldContainer)`
    div {
        margin: 0;
        min-height: 20px;
    }
    .MuiInputBase-input {
        margin: 4px 0 2px 0px;
        height: 24px;
        ${fontSizesCSS.Caption2}
    }
`;

export const DescriptionContainer = styled(TextFieldContainer)`
    div {
        margin: 0;
        min-height: 20px;
    }
    .MuiInputBase-input {
        margin: 2px 0;
        ${fontSizesCSS.Caption1}
        height: 20px;
    }
`;

export const ButtonWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 4px;
    text-transform: none;

    .MuiInputAdornment-root {
        margin-top: 12px;
        button {
            width: 50px;
        }
    }

    svg {
        path {
            fill: ${theme.palette.black.main};
        }
    }
`;

export const GalleryAndPreviewCustomizeHolder = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 48px;
    align-items: flex-end;
    padding-left: 32px;
    padding-right: 96px;
`;
