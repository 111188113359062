import { Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';

const theme: any = getTheme();
export const StyledSnackBar = styled(Snackbar)``;

export const SnackBarWrapper = styled.div`
    padding: 36px;
    background-color: ${theme.palette.background.main};
    box-sizing: border-box;
    position: relative;
    min-width: 405px;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

export const SnackBarContent = styled.div`
    display: flex;
    align-items: center;
`;

export const SnackBarTitle = styled.div`
    ${fontSizesCSS.Caption2};
`;

export const SnackBarMessage = styled.div`
    ${fontSizesCSS.Caption1};
`;

export const IconWrapper = styled.div`
    margin-right: 4px;
`;

export const SnackBarText = styled.div``;
