import React, { FC, useEffect, useState } from 'react';
import { Language } from '../../../types/Language';
import { useAppSelector } from '../../../hooks/redux';
import GenericDialog, {
    DialogButton,
    DialogDropdownSingle,
    DialogFileField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { SelectLanguageDropdownLabel, StyledText } from './NewLanguage.css';
import { LanguagesState } from '../../../redux/slices/languagesSlice';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import _ from 'lodash';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { flattenTranslations } from '../../../utils/fnData';
import { validateTranslations } from '../../../utils/fnValidator';
import { dialogAlert } from '../../../utils/fnDialogs';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';

export type NewLanguageDialogProps = {
    open: boolean;
    onSave: (language: Language) => void;
    onClose: () => void;
    language?: Language | null;
    order: number;
};
export const NewLanguageDialog: FC<NewLanguageDialogProps> = ({ open, onSave, onClose, language, order }) => {
    const [isDefaultLanguage, setIsDefaultLanguage] = useState<boolean>(false);
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('');
    const [languageName, setLanguageName] = useState<string>('');
    const [translations, setTranslations] = useState<any>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<{ language?: string; translations?: string }>({});

    const { error: languageError, languageCodes }: LanguagesState = useAppSelector((state) => state.languages);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    const validateLanguage = (translations: any) => {
        const newError = {
            ...error
        };
        let showWarning = false;

        if (!languageName || !selectedLanguageCode) {
            newError.language = 'Please select a language';
        } else delete newError.language;
        if (translations.length > 0) {
            const { error, warning } = validateTranslations(translations);
            if (error) {
                newError.translations = 'Must be JSON with all values string or non-empty object';
            }
            showWarning = warning;
        } else delete newError.translations;

        setError(newError);
        const isValid = Object.keys(newError).length === 0;
        if (showWarning) {
            const values = {
                title: 'Warning',
                subtitle: `Special characters in keys are not allowed!`,
                text: "We have noticed some special characters in your keys. Allowed characters in keys are 'a-zA-Z0-9_&-/ '. Other characters will be replaced with ''"
            };
            dialogAlert('', false, values, () => isValid && saveLanguage(translations));
        }
        return isValid && !showWarning;
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (language) {
            const { code, name, isDefault, translations } = language;
            setTranslations(Object.keys(translations).length ? translations : null);
            setSelectedLanguageCode(code);
            setIsDefaultLanguage(isDefault);
            setLanguageName(name);
        }
    }, [language]);

    const translationsToArray = (translations: any) => {
        return Object.keys(translations).map((key) => ({
            key,
            value: translations[key]
        }));
    };

    const saveLanguage = (translations: any) => {
        if (!onSave) return;
        const newLanguage: Language = {
            _id: language?._id || '',
            name: languageName,
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            code: selectedLanguageCode,
            isDefault: isDefaultLanguage,
            translations,
            icon: '',
            lastModified: language?.lastModified,
            order
        };
        onSave(newLanguage);
        onCloseClick();
    };

    const onSaveClick = () => {
        const newTranslations = translationsToArray(translations ? flattenTranslations(translations) : {});
        if (!validateLanguage(newTranslations)) return;
        saveLanguage(newTranslations);
    };

    const onCloseClick = () => {
        if (onClose) {
            onClose();
            setIsDefaultLanguage(false);
            setSelectedLanguageCode('');
            setLanguageName('');
            setTranslations(null);
            setError({});
        }
    };

    const handleRemoveFile = () => {
        setTranslations(language && Object.keys(language.translations).length ? language.translations : null);
    };

    const saveButton: DialogButton = {
        label: !language ? 'Add Language' : 'Save',
        type: 'BLUE',
        disabled: !!error.translations,
        onClick: onSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onCloseClick
    };

    const languageOptions = languageCodes?.map((elem: any) => {
        return {
            label: (
                <span>
                    <SelectLanguageDropdownLabel>
                        <img alt={elem.code} src={elem.flag} />
                        {`${elem.name} (${elem.code})`}
                    </SelectLanguageDropdownLabel>
                </span>
            ),
            value: elem.code,
            valueForSearch: `${elem.name} ${elem.code}`
        };
    });

    const languageValue = languageOptions.find((language: any) => language.value === selectedLanguageCode);
    const dialogError = languageError;

    if (!isOpen) return null;

    if (dialogError) {
        return <BackendErrorDialog error={dialogError} />;
    }

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={language ? 'Edit Language' : 'Add Language'}
            actionButtons={[cancelButton, saveButton]}
            onClose={() => {
                onCloseClick();
            }}
            dataCy={'new-dialog-language'}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.languages }}
        >
            <DialogDropdownSingle
                options={languageOptions}
                value={languageValue}
                onChange={(e: any) => {
                    setSelectedLanguageCode(e.value);
                    setLanguageName(languageCodes.find((elem) => elem.code === e.value)?.name || '');
                    setError(_.omit(error, 'language'));
                }}
                placeholder="Add Language"
                error={error.language}
                dataCy={'language-language-dropdown'}
            />
            <DialogFileField
                accepts={['.json']}
                imageInfo={{ previewImage: false, height: 100, width: 100, um: '%' }}
                fieldKey="Import translations"
                customLabel="Import translations"
                additionalText={
                    <StyledText>
                        Drag and Drop or <span>Browse Files</span>
                    </StyledText>
                }
                localFileCallBack={(value) => {
                    setError(_.omit(error, 'translations'));
                    const newTranslationsList = flattenTranslations({ translations, value });
                    if (newTranslationsList.cc_error_translation)
                        return setError({ ...error, translations: newTranslationsList.cc_error_translation });
                    value && setTranslations((oldTranslations: any) => ({ ...value, ...oldTranslations }));
                }}
                localFileErrorCallBack={() => {
                    setError({ ...error, translations: 'Translations must be a valid JSON file' });
                }}
                error={error.translations}
                localFileOnly
                handleRemoveFile={handleRemoveFile}
                dataCy={'language-translations-field'}
            />
            <DialogToggleButton
                checked={isDefaultLanguage}
                toggleCallback={() => {
                    setIsDefaultLanguage(!isDefaultLanguage);
                }}
                text={'Set as default'}
                dataCy={'language-default-toggle'}
            />
        </GenericDialog>
    );
};
