import styled from 'styled-components';
import { devices, DropdownOptionWrapper, IconPreviewImage } from '../../style/styled-components/reusable.css';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import { ToggleViewSwitchWrapper } from '../common/Switch/ToggleViewSwitch.css';
import { TranslationTooltipIconContainer } from '../common/TranslationTooltip/TranslationTooltip.css';
import { CardActions, DragContainer } from '../PageEdit/VisualEditor.css';
import { ActionContainer, ActionWrapper, ActionIconHolder as Holder } from '../common/Actions/Actions.css';
import { InputLabelWithIconWrapper, RadioText } from '../common/Dialog/GenericDialog.css';
import { HideWhenSelected } from '../common/Select/Select.css';

const theme: any = getTheme();

export const ActionIconHolder = styled.div``;

export const ToggleViewSwitchHolder = styled.div`
    display: flex;
    margin-bottom: 16px;
    ${ToggleViewSwitchWrapper} {
        margin-left: auto;
    }
`;

export const MenuContainer = styled.div`
    background: ${theme.palette.white.main};
    height: 104px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin-bottom: 24px;

    ${ActionContainer} {
        width: 138px;
        margin-left: auto;
    }

    &:hover {
        ${ActionWrapper} {
            ${Holder} {
                display: inline;
                &:last-child {
                    display: none;
                }
            }
        }
    }
`;

export const MenuFieldsContainer = styled.div`
    gap: 8px;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    ${fontSizesCSS.Body1};

    .MuiTextField-root {
        margin: 12px 0 0;
    }
    a {
        margin: 0;
    }
    label {
        color: ${theme.palette.label.main};
    }
    ${devices.desktopS} {
        flex-direction: column;
        padding: 24px 0;
        gap: 0;
    }
`;

export const MenuFieldsSubContainer = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 100%;
    gap: 8px;

    ${devices.tablet} {
        flex-direction: column;
        gap: 0;
    }
    & > div {
        width: 100%;
    }
`;

export const AbTestingSelectWrapper = styled.div`
    width: 100%;
    &:has(${InputLabelWithIconWrapper}) {
        margin-top: 10px;
    }
    .conditions-select__menu {
        right: 0;
    }

    ${devices.tablet} {
        .conditions-select__menu {
            left: 0;
        }
    }
    ${devices.desktopL} {
        .conditions-select__menu {
            width: 540px;
        }
    }
`;

/* MENUS LIST VIEW */
export const MenuViewName = styled.div`
    ${fontSizesCSS.Body2};
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
`;

export const MenuViewNoOfItems = styled.div`
    ${fontSizesCSS.Body1};
`;

export const MenuViewTargets = styled.div`
    display: flex;
    gap: 4px;
`;

export const MenuViewBindToPages = styled.div`
    display: flex;
    max-width: 100%;

    a {
        width: calc(100% - 32px);
        margin: 0;
    }
`;

export const MenuViewLastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
        ${devices.desktopS} {
            display: none;
        }
    }
`;

/* MENU ITEMS */
export const MenuItemDrag = styled.div``;
export const MenuItemTitle = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const MenuItemType = styled.div``;

export const MenuItemComponentHorizontal = styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    ${fontSizesCSS.Body1};

    ${TranslationTooltipIconContainer} {
        cursor: pointer;
    }
`;

export const MenuItemIcon = styled.div`
    ${IconPreviewImage} {
        width: 24px;
        height: 24px;
    }
`;

export const MenuItemWrapper = styled(MenuContainer)<{ $isEmpty?: boolean }>`
    opacity: ${({ $isEmpty }) => ($isEmpty ? 0.6 : 1)};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 1px;
    ${MenuItemDrag} {
        cursor: move;
    }
    border-top: 1px solid ${theme.palette.background.pressed};
    &:hover {
        cursor: ${({ $isEmpty }) => ($isEmpty ? 'pointer' : 'default')};
    }
`;

export const ExpandedListViewHolder = styled.div``;

export const MenuListHeader = styled.div<{ creating?: boolean }>`
    background: ${theme.palette.background.default};
    ${fontSizesCSS.Caption3}
    align-items: center;
    display: flex;
    width: 100%;

    ${MenuItemTitle} {
        width: ${({ creating }) => (creating ? '31%' : '33%')};
        height: 24px;
        margin-left: 24px;
    }
    ${MenuItemType} {
        width: 28%;
        height: 24px;
    }
    ${MenuItemIcon} {
        width: 2%;
        min-width: 24px;
    }
`;

export const MenuItemContainer = styled.div<{ $creating?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    ${MenuItemTitle} {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 33%;
        height: 24px;
        margin-right: ${({ $creating }) => ($creating ? '18px' : '24px')};
        overflow: hidden;
        ${fontSizesCSS.Body2};
        svg {
            cursor: pointer;
        }
    }
    ${MenuItemComponentHorizontal} {
        width: 30%;
        height: 24px;
        margin-right: 24px;
    }
    ${MenuItemIcon} {
        width: 2%;
        min-width: 24px;
        height: 24px;
        margin-left: 10px;
    }
`;

export const GridViewHolder = styled.div`
    padding: 24px;
    background: black;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;
export const ActionIconWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`;
export const MenuItemCard = styled.div`
    width: 210px;
    height: 60px;
    padding: 16px;
    background: white;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    gap: 8px;
    border: 2px solid transparent;
    ${MenuItemTitle} {
        width: 115px;
        ${fontSizesCSS.Caption2}
    }
    &:hover {
        cursor: pointer;
        svg {
            opacity: 0.7;
            pointer-events: none;
            &:hover {
                opacity: 1;
            }
        }
        border: 2px solid ${theme.palette.white.main};
        ${DragContainer} {
            left: unset;
            position: unset;
            svg {
                cursor: move;
                pointer-events: all;
            }
        }
        ${CardActions} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background: ${theme.palette.background.hoveredDark};
            div {
                &:nth-child(1) {
                    padding-left: 10px;
                    padding-right: 20px;
                }
            }
        }
    }
`;

export const MenuDialogFieldsContainer = styled.div``;

export const MenuDialogItemContainer = styled(MenuItemContainer)<{ $withIcon?: boolean }>`
    height: 76px;
    width: 100%;
    background: ${theme.palette.white.main};
    margin: 4px 0 8px;
    padding: 10px;
    box-sizing: border-box;
    ${(props) => props.$creating && 'cursor: pointer'};
    border-radius: 4px;

    ${MenuItemTitle} {
        width: 45%;
    }

    ${ActionContainer} {
        ${(props) => props.$withIcon && 'margin-left: unset'};
    }

    &:hover {
        ${ActionWrapper} {
            ${Holder} {
                display: inline;
                &:last-child {
                    display: none;
                }
            }
        }
    }
`;

export const MenuDialogItemWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

export const MenuDialogOptionsWrapper = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 12px;

    ${RadioText} {
        font-size: 12px;
        font-weight: unset;
    }
`;

export const NewMenuItemIcon = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    svg {
        width: 100%;
        height: 100%;
    }
    margin-left: auto;
`;

export const MenuItemsOptionsWrapper = styled.div`
    width: calc(100% - 26px);
    align-items: center;
    display: flex;
    gap: 16px;

    ${DropdownOptionWrapper} {
        width: 55%;
    }
`;

export const LabelsWrapper = styled(HideWhenSelected)`
    margin-left: auto;
    max-width: 40%;
    display: flex;
    height: 28px;
    gap: 4px;
`;
