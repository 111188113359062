import styled, { CSSProperties } from 'styled-components';
import { RadioText } from '../../common/Dialog/GenericDialog.css';

export const ModuleTemplateIconHolder = styled.div`
    height: 24px;
    svg {
        height: 24px;
    }
`;

export const ModuleOption = styled.div`
    height: 36px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const PublishedStatusIconHolder = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
`;

export const useExistingDialogStyle: CSSProperties = {
    width: '608px'
};

export const ExistingMenuOptionsWrapper = styled.div`
    display: flex;
    gap: 24px;

    ${RadioText} {
        font-size: 12px;
        font-weight: unset;
    }
`;
